import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { BounceLoading } from 'components/loader/bounce.loading';
import { PATH_CONSTANT } from 'config/path.constant';
import { StandartImageComponent } from 'components/image/standart-image.component';

import { useShareInfoDetails } from 'api/share-info/info/info.query';
import { IsJsonString, catchErrorMessage } from 'ui-utils/string.utils';
import { converSlateToHTML } from 'components/form/text-editor/serializer';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Share Info',
    path: PATH_CONSTANT.SHARE_INFO.LIST_INFO,
  },
  {
    title: 'List Info',
    path: PATH_CONSTANT.SHARE_INFO.LIST_INFO,
  },
  {
    title: 'Detail Page',
    path: null,
  },
];

export const DetailsInfoComponent = () => {
  const { recordId } = useParams();
  const { data: shareInfoDetails, isLoading, isError, error } = useShareInfoDetails(recordId);
  return (
    <MainDashboardLayoutComponent
      breadCumbs={BREAD_CUMB_ITEMS}
      isError={isError}
      errorTitle={error?.response?.data?.status}
      erroMessage={catchErrorMessage(error?.response?.data?.message)}
    >
      {isLoading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          <div className='flex w-full justify-between pb-8'>
            <div className='w-[calc(100%-420px)]'>
              <h3 className='text-gray text-lg font-bold mb-5'>{shareInfoDetails?.data?.title}</h3>

              <StandartImageComponent
                src={shareInfoDetails?.data?.image || '/img/default-product.jpg'}
                class='w-full h-auto object-cover mb-5'
              />

              <p className='text-gray italic text-sm'>
                {IsJsonString(shareInfoDetails?.data?.description) &&
                  converSlateToHTML(JSON.parse(shareInfoDetails?.data?.description))}
              </p>
              {(shareInfoDetails?.data?.start_date || shareInfoDetails?.data?.end_date) && (
                <div class='w-full flex  flex-col bg-gray-3 px-6 py-8 mt-5'>
                  {shareInfoDetails?.data?.start_date && (
                    <>
                      <p className='font-bold text-sm mb-2'>Tanggal & Jam Mulai</p>
                      <p className='font-bold text-sm text-green'>
                        {shareInfoDetails?.data?.start_time
                          ? `${moment(shareInfoDetails?.data?.start_date).format('DD MMM YYYY')}, ${
                              shareInfoDetails?.data?.start_time
                            }`
                          : moment(shareInfoDetails?.data?.start_date).format('DD MMM YYYY')}
                      </p>
                    </>
                  )}

                  {shareInfoDetails?.data?.end_date && (
                    <>
                      <p className='font-bold text-sm mb-2 mt-4'>Tanggal & Jam Selesai</p>
                      <p className='font-bold text-sm text-green'>
                        {shareInfoDetails?.data?.end_time
                          ? `${moment(shareInfoDetails?.data?.end_date).format('DD MMM YYYY')}, ${
                              shareInfoDetails?.data?.end_time
                            }`
                          : moment(shareInfoDetails?.data?.end_date).format('DD MMM YYYY')}
                      </p>
                    </>
                  )}
                </div>
              )}
            </div>
            <div class='w-[300px]'>
              <div class='border border-solid border-gray-1 rounded-sm p-2 mb-3'>
                <div class='block text-sm font-semibold text-dark mb-3'>Edit Information</div>
                <Link to={PATH_CONSTANT.SHARE_INFO.EDIT_INFO.replace(':recordId', recordId)}>
                  <button
                    class='bg-green flex justify-center items-center w-full hover:bg-dark-green 
                  transition duration-75  ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                    type='button'
                    onClick={() => {}}
                  >
                    <span class='icon-ico-pencil-edit mr-3 text-lg'></span> Edit
                  </button>
                </Link>
              </div>

              <div class='bg-gray-3 py-5 px-4 mb-3'>
                <div className='flex flex-row items-center gap-5'>
                  <div class='icon-ico-clock text-2xl  text-green-3 font-normal '></div>
                  <div>
                    <div class='block text-sm font-semibold text-dark mb-1'>Will be notified on</div>
                    <div class='text-sm font-semibold  text-green mb-2'>
                      {shareInfoDetails?.data?.formatted_broadcast_date}
                    </div>
                  </div>
                </div>
              </div>

              <div className='my-2 p-2 border border-gray-1'>
                <div class='block text-sm font-semibold text-dark mb-1'>Share To</div>
                <p className='text-xs text-gray-2 mb-2 italic'>
                  {shareInfoDetails?.data?.is_all_users
                    ? 'This information will be shared to all users'
                    : `This information will be shared to ${shareInfoDetails?.data?.receivers?.length?.toLocaleString()} users`}
                </p>
                {!shareInfoDetails?.data?.is_all_users && (
                  <div className='max-h-[300px] scroll '>
                    {shareInfoDetails?.data?.receivers?.map((userItem) => (
                      <div
                        className='flex items-center justify-between  border-b border-gray-3 px-1 py-1 my-1'
                        key={userItem.name}
                      >
                        <div className='flex items-center '>
                          <StandartImageComponent
                            src={userItem.user_photo || '/img/default-user.png'}
                            alt='user'
                            className='w-10 h-10 mr-3 object-cover'
                          />
                          <div className='flex flex-col'>
                            <p className='text-sm font-semibold'>{userItem.user_name}</p>
                            <p className='text-xs text-gray-2'>{userItem.store_name}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div class='bg-gray-3 py-5 px-4 max-h-[45vh] scroll'>
                <div class='block text-sm font-semibold text-dark mb-3'>Attachments</div>

                {shareInfoDetails?.data?.attachments.length > 0 &&
                  shareInfoDetails?.data?.attachments.map((attachment, index) => (
                    <button
                      class='outline-none w-full  items-center border mb-2  border-gray-1 
                    bg-green-status 
                    text-left  
                    rounded-sm  transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
                      type='button'
                      onClick={() => {
                        window.open(attachment.file_url, '_blank');
                      }}
                    >
                      <div className='flex items-center'>
                        <span class='icon-ico-attachment text-xl text-green'></span>
                        <span class='pl-2 font-semibold text-green text-sm block   whitespace-nowrap overflow-hidden  text-ellipsis'>
                          {attachment.file_name || 'Untitled'}
                        </span>
                      </div>
                    </button>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </MainDashboardLayoutComponent>
  );
};
