import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { TableSearchInputComponent } from 'components/table/table-search.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { TableButtonLimitComponent } from 'components/table/table-button-limit.component';
import { TableButtonSortComponent } from 'components/table/table-button-sort.component';
import { BasicTableComponent } from 'components/table/table-basic.component';
import { Pagination } from 'components/table/table-pagination.component';
import { EmptyStateComponent } from 'components/empty-data';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ModalTableSortComponent } from 'components/modal/modal-table-sort.component';
import { ModalTableLimitComponent } from 'components/modal/modal-table-limit.component';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { setToolsReducer } from 'reducers/tools.reducer';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { useProductInStore } from 'api/product/product-list/product-list.query.api';
import { useStoreDetail } from 'api/store/store-list/store-list.query.api';
import { ModalStockEdit } from './modal.update-stock.component';
import { useExportStockProductStore } from 'api/store/store-list/store-list.mutation.api';
import { PATH_CONSTANT } from 'config/path.constant';
import { TableButtonImport } from 'components/table/table-button-import.component';
import { catchErrorMessage } from 'ui-utils/string.utils';
import { Tooltip } from 'flowbite-react';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Product',
    path: PATH_CONSTANT.PRODUCT.PRODUCT_LIST,
  },
  {
    title: 'List Stock Product Store',
    path: PATH_CONSTANT.PRODUCT.PRODUCT_STOCK_STORE_LIST,
  },
  {
    title: 'Detail',
    path: PATH_CONSTANT.PRODUCT.PRODUCT_STOCK_STORE_LIST,
  },
];

const INITIAL_VALUE_FILTER_QUERY = {
  limit: 20,
  page: 1,
  orderBy: 'product_name',
  orderType: 'asc',
  search: undefined,
};

const SORT_OPTIONS = [
  { label: 'Urutkan Nama A-Z', sortType: 'asc', sortBy: 'product_name' },
  { label: 'Urutkan Nama Z-A', sortType: 'desc', sortBy: 'product_name' },
  { label: 'Tanggal Expired Terlama - Terbaru', sortType: 'desc', sortBy: 'expired_date' },
  { label: 'Tanggal Expired Terbaru - Terlama', sortType: 'asc', sortBy: 'expired_date' },
  { label: 'Qty Terbanyak - Terendah', sortType: 'desc', sortBy: 'total_qty' },
  { label: 'Qty Terendah - Terbanyak', sortType: 'asc', sortBy: 'total_qty' },
  { label: 'Qty Terjual Terbanyak - Terendah', sortType: 'desc', sortBy: 'qty_sold' },
  { label: 'Qty Terjual Terendah - Terbanyak', sortType: 'asc', sortBy: 'qty_sold' },
  { label: 'Sisa Stock Terbanyak - Terendah', sortType: 'desc', sortBy: 'remaining_stock' },
  { label: 'Sisa Stock Terendah - Terbanyak', sortType: 'asc', sortBy: 'remaining_stock' },
];

export const StockInStoreDetail = () => {
  const { storeId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);

  const { selectedRowKeys: selectedIds } = useSelector((state) => state.tools);

  const [modals, setModals] = useState({
    sort: false,
    limit: false,
    export: false,
    filter: false,
  });

  const [modalStockEdit, setModalStockEdit] = useState({
    visible: false,
    store_id: storeId,
    variant_id: null,
    initialValue: 0,
  });

  const { mutate: exportProduct, isLoading: isExporting } = useExportStockProductStore();

  const { data: storeDetails, isError: isErrorStoreDetails, error: errorStoreDetails } = useStoreDetail(storeId);

  const {
    data: productList,
    isLoading,
    isError,
    error,
  } = useProductInStore({
    queryFilter,
    bodyFilter: { store_id: storeId },
  });

  const columns = useMemo(
    () => [
      {
        name: 'Product',
        className: 'w-[30%] text-sm font-semibold text-green text-center',
        dataIndex: 'name',
        render: (record) => (
          <div class='w-[30%] flex items-center'>
            <div class='w-12'>
              <StandartImageComponent
                src={record?.product?.product_image || '/img/default-product.jpg'}
                class='w-14 h-14  object-cover'
              />
            </div>
            <div class='w-64 pl-3 pr-4'>
              <Tooltip className='max-w-[30%]' content={record?.product?.name}>
                <div class='block text-xs font-semibold text-dark hover:text-green line-clamp-3'>
                  {record?.product?.name}
                </div>
              </Tooltip>
            </div>
          </div>
        ),
      },
      {
        name: 'Expired Date',
        className: 'w-[15%] text-sm font-semibold text-green text-center',
        dataIndex: 'expired_date_formatted',
        render: (record, expired_date_formatted) => (
          <div class='w-[15%] overflow-hidden'>
            <div class='text-sm font-normal text-gray text-center'>{expired_date_formatted}</div>
          </div>
        ),
      },
      {
        name: 'Total Qty',
        className: 'w-[15%] justify-center flex text-sm font-semibold text-green text-center',
        dataIndex: 'total_qty',
        renderHeader: (columnConfig, index) => {
          return (
            <div key={`${columnConfig.name}-${index}`} class={columnConfig.className}>
              {columnConfig.name}
              <Tooltip
                className='max-w-[20%]'
                content='Merupakan jumlah produk virtual yang belum dikurangi report sales dengan status SUBMITTED'
              >
                <button class='p-0 outline-none ml-2 text-green' type='button'>
                  <span class='icon-ico-help'></span>
                </button>
              </Tooltip>
            </div>
          );
        },
        render: (record, total_qty) => (
          <div class='w-[15%] overflow-hidden'>
            <div class='text-sm font-normal text-gray text-center'>{total_qty}</div>
          </div>
        ),
      },
      {
        name: 'Qty Terjual',
        className: 'w-[15%] justify-center flex text-sm font-semibold text-green text-center',
        dataIndex: 'qty_sold',
        renderHeader: (columnConfig, index) => {
          return (
            <div key={`${columnConfig.name}-${index}`} class={columnConfig.className}>
              {columnConfig.name}
              <Tooltip
                className='max-w-[20%]'
                content='Merupakan jumlah produk terjual yang di report oleh BA melalui aplikasi dengan status report SUBMITTED'
              >
                <button class='p-0 outline-none ml-2 text-green' type='button'>
                  <span class='icon-ico-help'></span>
                </button>
              </Tooltip>
            </div>
          );
        },
        render: (record, qty_sold) => (
          <div class='w-[15%]'>
            <div class='text-sm font-normal text-gray text-center'>{qty_sold}</div>
          </div>
        ),
      },
      {
        name: 'Sisa Stock',
        className: 'w-[15%] flex justify-center text-sm font-semibold text-green text-center',
        dataIndex: 'remaining_stock',
        renderHeader: (columnConfig, index) => {
          return (
            <div key={`${columnConfig.name}-${index}`} class={columnConfig.className}>
              {columnConfig.name}
              <Tooltip
                className='max-w-[20%]'
                content='Merupakan jumlah produk yang masih tersedia di dalam store dan dapat dibeli oleh customer'
              >
                <button class='p-0 outline-none ml-2 text-green' type='button'>
                  <span class='icon-ico-help'></span>
                </button>
              </Tooltip>
            </div>
          );
        },
        render: (record, remaining_stock) => (
          <div class='w-[15%]'>
            <div class='text-sm font-normal text-gray text-center'>{remaining_stock}</div>
          </div>
        ),
      },
      {
        name: 'Action',
        className: 'w-[10%] text-sm font-semibold text-green text-center',
        dataIndex: '',
        render: (record) => (
          <div class='w-[10%] flex justify-center'>
            <button
              onClick={() => {
                setModalStockEdit({
                  visible: true,
                  store_id: storeId,
                  variant_id: record?.variant_id,
                  initialValue: record?.remaining_stock,
                });
              }}
            >
              <span class='icon-ico-pencil-edit text-yellow text-xl'></span>
            </button>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const resetCheckList = () => {
    if (selectedIds.length > 0) {
      dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
    }
  };

  const handleExport = () => {
    exportProduct(
      {
        queryFilter: queryFilter,
        bodyFilter: { store_id: storeId },
      },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  useEffect(() => {
    resetCheckList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productList]);

  return (
    <MainDashboardLayoutComponent
      breadCumbs={BREAD_CUMB_ITEMS}
      isError={isErrorStoreDetails}
      errorTitle={errorStoreDetails?.response?.data?.status}
      erroMessage={catchErrorMessage(errorStoreDetails?.response?.data?.message)}
    >
      <div class='w-full flex items-center bg-gray-3 px-6 py-8 mb-5'>
        <div class='w-full'>
          <div class='flex items-center'>
            <div class='w-[350px] flex items-center'>
              <StandartImageComponent
                src={storeDetails?.data?.image || '/img/default-store.jpg'}
                class='overflow-hidden w-[60px] h-[60px] border-2 border-solid border-green-3'
              />
              <div class='w-[calc(100%-3rem)] pl-3'>
                <div class='overflow-hidden block text-ellipsis whitespace-nowrap text-sm font-semibold text-green mb-2'>
                  {storeDetails?.data?.name}
                </div>
                <div class='overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-gray'>
                  {storeDetails?.data?.city?.name}
                </div>
              </div>
            </div>
            <div class='w-[500px]'>
              <div class='text-sm text-gray'>{storeDetails?.data?.address}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex items-center mb-5'>
        <div className='w-full flex items-center justify-between'>
          <div className='xl:w-[calc(100%-27%)] lg:w-[calc(100%-22%)] md:w-[calc(100%-19%)] flex justify-start items-center'>
            {productList?.data?.total_result && (
              <div class='mr-4 text-sm text-gray italic'>{`Total ${productList?.data?.total_result} products`}</div>
            )}
            <TableButtonSortComponent
              disabled={isLoading}
              className='mr-2 outline-none flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
              onClick={() => {
                setModals((prev) => ({ ...prev, sort: true }));
              }}
            />

            <TableButtonLimitComponent
              disabled={isLoading}
              className='mr-2 outline-none  relative flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
              value={queryFilter.limit}
              onClick={() => setModals({ ...modals, limit: true })}
            />
            <TableButtonExport
              className='mr-2 outline-none flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
              disabled={isLoading}
              loading={isExporting}
              onClick={() => {
                setModals({ ...modals, export: true });
              }}
            />
            <TableButtonImport
              className='mr-2 outline-none flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
              disabled={isLoading}
              loading={isExporting}
              onClick={() => {
                navigate(`${PATH_CONSTANT.PRODUCT.PRODUCT_STOCK_STORE_IMPORT}/${storeId}`);
              }}
            />
          </div>
          <TableSearchInputComponent
            containerClassName='xl:w-[27%] lg:w-[27%] md:w-[19%] lg:pr-[30px] md:pr-2 relative'
            placeholder='Cari nama product...'
            inputProps={{
              disabled: isLoading,
            }}
            onSearch={(value) => {
              setQueryFilter({ ...queryFilter, search: value.keyword, page: 1 });
            }}
          />
        </div>
      </div>

      {isLoading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          <BasicTableComponent
            rowClassName='flex mb-3 items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
            columns={columns}
            dataSource={productList?.data?.rows || []}
            loading={isLoading}
            error={isError}
            errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
            errorMessage={catchErrorMessage(error?.response?.data?.message)}
          />

          {productList?.data?.total_result === 0 && (
            <EmptyStateComponent
              message={queryFilter.search ? `No result for query "${queryFilter.search}"` : 'No data available'}
            />
          )}

          <Pagination
            currentPage={queryFilter.page}
            pageSize={queryFilter.limit}
            siblingCount={1}
            totalCount={productList?.data?.total_result || 0}
            onPageChange={(page) => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
              setQueryFilter({ ...queryFilter, page });
            }}
          />
        </>
      )}
      <ModalTableSortComponent
        options={SORT_OPTIONS}
        initialValue={SORT_OPTIONS.find(
          (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
        )}
        onChange={({ selected }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            orderBy: selected.sortBy,
            orderType: selected.sortType,
          }));
          setModals({ ...modals, sort: false });
        }}
        visible={modals.sort}
        onClose={() => setModals({ ...modals, sort: false })}
      />

      <ModalTableLimitComponent
        onChange={({ limit }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            limit,
            page: 1,
          }));
          setModals({ ...modals, limit: false });
        }}
        initialValue={queryFilter.limit}
        visible={modals.limit}
        onClose={() => setModals({ ...modals, limit: false })}
      />

      <ModalConfirmation
        description='Apakah anda yakin ingin eksport data yang anda pilih ?'
        title='Export Data'
        visible={modals.export}
        onConfirm={handleExport}
        onClose={() => setModals({ ...modals, export: false })}
      />
      <ModalStockEdit
        title='Edit Stock'
        initialValue={modalStockEdit.initialValue}
        storeId={modalStockEdit.store_id}
        variantId={modalStockEdit.variant_id}
        visible={modalStockEdit.visible}
        onClose={() => setModalStockEdit({ ...modalStockEdit, visible: false })}
      />
    </MainDashboardLayoutComponent>
  );
};
