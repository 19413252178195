import qs from 'query-string';
import axios from 'api/api.config';
import { useMutation, useQueryClient } from 'react-query';
import { STOCK_MOVEMENT_PRE_ORDER } from './pre-order.query.api';
import { STOCK_MOVEMENT_PICKING } from '../picking/picking.query.api';
export function useCreatePreOrder() {
  const queryClient = useQueryClient();
  return useMutation((formValues) => axios.post(`/api/v1/admin/pre-order/create`, formValues).then((res) => res.data), {
    onSuccess: () => {
      queryClient.refetchQueries([STOCK_MOVEMENT_PRE_ORDER.PO_LIST]);
    },
  });
}

export function useUpdatePreOrder() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ formValues, id }) => axios.put(`/api/v1/admin/pre-order/update/${id}`, formValues).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.refetchQueries([STOCK_MOVEMENT_PRE_ORDER.PO_LIST]);
        queryClient.removeQueries([STOCK_MOVEMENT_PRE_ORDER.PO_DETAILS]);
        queryClient.removeQueries([STOCK_MOVEMENT_PRE_ORDER.PO_DETAILS_LOG]);
      },
    }
  );
}

export function useExportPreOrderDetails() {
  return useMutation(({ id }) => axios.get(`/api/v1/admin/pre-order/export-detail/${id}`).then((res) => res.data), {});
}

export function useExportPreOrderList() {
  return useMutation(
    ({ bodyFilter, queryFilter }) =>
      axios
        .post(`/api/v1/admin/pre-order/export-list?${qs.stringify(queryFilter)}`, bodyFilter)
        .then((res) => res.data),
    {}
  );
}

export function useUpdateExpireDate() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, formValues }) =>
      axios.put(`/api/v1/admin/pre-order/picking/exp-dates/update/${id}`, formValues).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.refetchQueries([STOCK_MOVEMENT_PRE_ORDER.PO_DETAILS]);
        queryClient.refetchQueries([STOCK_MOVEMENT_PRE_ORDER.PO_LIST]);
        queryClient.invalidateQueries([STOCK_MOVEMENT_PICKING.DETAIL_EXPIRED_DATE]);
      },
    }
  );
}

export function useUpdatePreOrderStatus() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ status, pre_order_ids }) =>
      axios.put(`/api/v1/admin/pre-order/update-status/${status}`, { pre_order_ids }).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.refetchQueries([STOCK_MOVEMENT_PRE_ORDER.PO_DETAILS]);
        queryClient.refetchQueries([STOCK_MOVEMENT_PRE_ORDER.PO_LIST]);
      },
    }
  );
}

export function useUpdateStatusPOToComplete() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id }) => axios.put(`/api/v1/admin/pre-order/outbond/complete-po/${id}`).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.refetchQueries([STOCK_MOVEMENT_PRE_ORDER.PO_DETAILS]);
        queryClient.refetchQueries([STOCK_MOVEMENT_PRE_ORDER.PO_LIST]);
        queryClient.invalidateQueries([STOCK_MOVEMENT_PRE_ORDER.PO_DETAILS_LOG]);
      },
    }
  );
}
