import qs from 'query-string';
import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const ANALYTIC_PRODUCT_QUERY = {
  TOTAL_PRODUCT: 'TOTAL_PRODUCT',
  PRODUCT_GRAPH: 'PRODUCT_GRAPH',
  LIST_PRODUCT: 'LIST_PRODUCT',
};

export function useGetAnalyticsTotalProduct({ filter, body }) {
  return useQuery([ANALYTIC_PRODUCT_QUERY.TOTAL_PRODUCT, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/analytic-product/total?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}

export function useGetAnalyticsProductGraph({ filter, body }) {
  return useQuery([ANALYTIC_PRODUCT_QUERY.PRODUCT_GRAPH, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/analytic-product/graph?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}
export function useGetAnalyticsListProduct({ filter, body }) {
  return useQuery([ANALYTIC_PRODUCT_QUERY.LIST_PRODUCT, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/analytic-product/list?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}
