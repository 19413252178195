import qs from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { TableSearchInputComponent } from 'components/table/table-search.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { TableButtonLimitComponent } from 'components/table/table-button-limit.component';
import { TableButtonSortComponent } from 'components/table/table-button-sort.component';
import { BasicTableComponent } from 'components/table/table-basic.component';
import { Pagination } from 'components/table/table-pagination.component';
import { EmptyStateComponent } from 'components/empty-data';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ModalTableSortComponent } from 'components/modal/modal-table-sort.component';
import { ModalTableLimitComponent } from 'components/modal/modal-table-limit.component';
import { ModalTableFilter } from 'components/modal/modal-table-filter.component';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { setToolsReducer } from 'reducers/tools.reducer';
import { Tooltip } from 'flowbite-react';
import { PATH_CONSTANT } from 'config/path.constant';
import { ModalResult } from 'components/modal/modal.result.component';
import { catchErrorMessage } from 'ui-utils/string.utils';
import { useGetScheduleListUser } from 'api/schedules/jadwal/jadwal.query.api';
import { useExportScheduleList } from 'api/schedules/jadwal/jadwai.mutation.api';

import { HighlightedText } from 'components/highlighted-text';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Jadwal & Presensi',
    path: PATH_CONSTANT.SCHEDULE.LIST,
  },
  {
    title: 'Jadwal',
    path: PATH_CONSTANT.SCHEDULE.LIST,
  },
];

const INITIAL_VALUE_FILTER_BODY = {
  status: null,
  store_ids: [],
};

const SORT_OPTIONS = [
  { label: 'Urutkan Nama A-Z', sortType: 'asc', sortBy: 'name' },
  { label: 'Urutkan Nama Z-A', sortType: 'desc', sortBy: 'name' },
  { label: 'Tanggal Buat Terbaru - Terdahulu', sortType: 'desc', sortBy: 'created_at' },
  { label: 'Tanggal Buat Terdahulu - Terbaru', sortType: 'asc', sortBy: 'created_at' },
];

export const PresenceListComponent = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const INITIAL_VALUE_FILTER_QUERY = {
    limit: searchParams.get('limit')?.replace(/[^0-9]/g, '') || 20,
    page: searchParams.get('page')?.replace(/[^0-9]/g, '') || 1,
    orderBy: searchParams.get('orderBy')?.replace(/[^a-zA-Z_]/g, '') || 'created_at',
    orderType: searchParams.get('orderType')?.replace(/[^a-zA-Z]/g, '') || 'desc',
    search: searchParams.get('search')?.replace(/[^a-zA-Z0-9 ]/g, '') || undefined,
  };

  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);
  const [bodyFilter, setBodyFilter] = useState(INITIAL_VALUE_FILTER_BODY);

  const { selectedRowKeys: selectedIds } = useSelector((state) => state.tools);

  const [modals, setModals] = useState({
    sort: false,
    limit: false,
    filter: false,
    export: false,
    updateStatus: false,
    result: false,
  });

  const { mutate: exportScheduleList, isLoading: isExporting } = useExportScheduleList();
  const {
    data: userList,
    isLoading,
    isError,
    error,
  } = useGetScheduleListUser({
    filter: queryFilter,
    body: bodyFilter,
  });

  const columns = useMemo(
    () => [
      {
        name: 'User',
        className: 'w-[37%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record) => (
          <div className='w-[37%] text-grey-70 text-sm font-medium'>
            <div class='flex items-center pl-2 xl:pl-5'>
              <div class='w-12'>
                <img
                  src={record?.profile_picture || '/img/default-user.jpg'}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/img/default-user.jpg';
                  }}
                  class='w-12 h-12 object-cover'
                  alt='User'
                />
              </div>
              <div class='w-auto pl-3 xl:pl-5'>
                <Link
                  to={`${PATH_CONSTANT.SCHEDULE.DETAILS}/${record?.id}`}
                  class='w-48 text-ellipsis line-clamp-2 whitespace-normal text-sm font-semibold text-dark hover:text-green mb-1'
                >
                  <Tooltip className='max-w-[40%]' content={record?.name}>
                    <HighlightedText text={record?.name} highlight={queryFilter?.search} />
                  </Tooltip>
                </Link>
                <div class='text-sm font-medium text-gray text-ellipsis line-clamp-2'>
                  <HighlightedText text={record?.email} highlight={queryFilter?.search} />
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: 'Store Penempatan',
        className: 'w-[20%] text-sm font-semibold text-green',
        dataIndex: 'user',
        render: (record) => (
          <div className='w-[20%] text-grey-70 text-sm font-medium'>
            <div class='text-sm font-semibold text-green mb-1 line-clamp-2'>
              <Tooltip className='max-w-[40%]' content={record?.store?.name}>
                <HighlightedText text={record?.store?.name} highlight={queryFilter?.search} />
              </Tooltip>
            </div>
            <div class='text-sm font-medium italic text-gray'>{record?.store?.city?.name}</div>
          </div>
        ),
      },
      {
        name: 'Mulai',
        className: 'w-[15%] text-sm font-semibold text-green text-left',
        dataIndex: 'clock_in_formatted',
        render: (record, clock_in_formatted) => (
          <div class='w-[15%]'>
            <div class='text-sm font-medium text-gray text-left'>
              {clock_in_formatted ? (
                <div>
                  {record?.date_formatted}
                  <div>{`${clock_in_formatted}`}</div>
                </div>
              ) : (
                '-'
              )}
            </div>
          </div>
        ),
      },
      {
        name: 'Selesai',
        className: 'w-[15%] text-sm font-semibold text-green overflow-hidden text-left text-ellipsis whitespace-nowrap',
        dataIndex: 'clock_out_formatted',
        render: (record, clock_out_formatted) => (
          <div class='w-[15%]'>
            <div class='text-sm font-medium text-gray text-left'>
              {clock_out_formatted ? (
                <div>
                  {record?.date_formatted}
                  <div>{`${clock_out_formatted}`}</div>
                </div>
              ) : (
                '-'
              )}
            </div>
          </div>
        ),
      },
      {
        name: '',
        className: 'w-[10%] text-sm font-semibold text-green',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[10%] flex justify-center'>
            <Link
              to={`${PATH_CONSTANT.SCHEDULE.DETAILS}/${record?.id}`}
              class='w-48 text-center text-ellipsis line-clamp-2 whitespace-normal text-sm font-semibold text-green mb-1'
            >
              LIHAT DETAIL
            </Link>
          </div>
        ),
      },
    ],
    [queryFilter?.search]
  );

  const resetCheckList = () => {
    if (selectedIds.length > 0) {
      dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
    }
  };

  const handleExport = () => {
    exportScheduleList(
      {
        queryFilter: queryFilter,
        bodyFilter: { ...bodyFilter, user_ids: selectedIds },
      },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  useEffect(() => {
    resetCheckList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      <div className='flex items-center mb-5'>
        <div className='w-full flex items-center justify-between'>
          <TableSearchInputComponent
            initialValue={INITIAL_VALUE_FILTER_QUERY.search}
            inputProps={{
              disabled: isLoading,
            }}
            onSearch={(value) => {
              setQueryFilter({ ...queryFilter, search: value.keyword, page: 1 });
              navigate({
                search: qs.stringify({
                  ...queryFilter,
                  search: value.keyword || undefined,
                  page: 1,
                }),
              });
            }}
          />

          <div className='w-[calc(100%-30%)] flex gap-3 items-center'>
            <TableButtonSortComponent
              disabled={isLoading}
              onClick={() => {
                setModals((prev) => ({ ...prev, sort: true }));
              }}
            />

            <TableButtonLimitComponent
              disabled={isLoading}
              value={queryFilter.limit}
              onClick={() => setModals({ ...modals, limit: true })}
            />
            <TableButtonExport
              disabled={isLoading}
              loading={isExporting}
              onClick={() => {
                setModals({ ...modals, export: true });
              }}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          <div className='italic text-dark text-sm mb-5'>{`Total ${userList?.data?.total_result} data`}</div>

          <BasicTableComponent
            rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
            columns={columns}
            dataSource={userList?.data?.rows || []}
            loading={isLoading}
            error={isError}
            errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
            errorMessage={catchErrorMessage(error?.response?.data?.message)}
          />

          {userList?.data?.total_result === 0 && (
            <EmptyStateComponent
              message={queryFilter.search ? `No result for query "${queryFilter.search}"` : 'No data available'}
            />
          )}

          <Pagination
            currentPage={queryFilter.page}
            pageSize={queryFilter.limit}
            siblingCount={1}
            totalCount={userList?.data?.total_result || 0}
            onPageChange={(page) => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
              setQueryFilter({ ...queryFilter, page });
              navigate({
                search: qs.stringify({
                  ...queryFilter,
                  page,
                }),
              });
            }}
          />
        </>
      )}
      <ModalTableSortComponent
        options={SORT_OPTIONS}
        initialValue={SORT_OPTIONS.find(
          (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
        )}
        onChange={({ selected }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            orderBy: selected.sortBy,
            orderType: selected.sortType,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              orderBy: selected.sortBy,
              orderType: selected.sortType,
            }),
          });
          setModals({ ...modals, sort: false });
        }}
        visible={modals.sort}
        onClose={() => setModals({ ...modals, sort: false })}
      />

      <ModalTableLimitComponent
        onChange={({ limit }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            limit,
            page: 1,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              limit,
              page: 1,
            }),
          });
          setModals({ ...modals, limit: false });
        }}
        initialValue={queryFilter.limit}
        visible={modals.limit}
        onClose={() => setModals({ ...modals, limit: false })}
      />
      <ModalConfirmation
        description='Apakah anda yakin ingin eksport data jadwal yang anda pilih?'
        title='Export Data'
        visible={modals.export}
        loading={isExporting}
        onConfirm={handleExport}
        onClose={() => setModals({ ...modals, export: false })}
      />

      <ModalResult
        description='Update status user berhasil dilakukan!'
        title='Update Status User'
        visible={modals.result}
        textConfirm='OK'
        onConfirm={() => setModals({ ...modals, result: false })}
        onClose={() => setModals({ ...modals, result: false })}
      />

      <ModalTableFilter
        initialValue={bodyFilter}
        visible={modals.filter}
        onChange={(selectedFilter) => {
          setBodyFilter(selectedFilter);
          setQueryFilter((prevState) => ({
            ...prevState,
            page: 1,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              page: 1,
            }),
          });

          setModals({ ...modals, filter: false });
        }}
        onClose={() => setModals({ ...modals, filter: false })}
      />
    </MainDashboardLayoutComponent>
  );
};
