import axios from 'api/api.config';
import { useMutation, useQueryClient } from 'react-query';
import { STOCK_MOVEMENT_PRE_ORDER } from '../pre-order/pre-order.query.api';

export function useApprovePORequest() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ formPayload, id }) =>
      axios.put(`/api/v1/admin/pre-order/approvement/request/${id}`, formPayload).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.refetchQueries([STOCK_MOVEMENT_PRE_ORDER.PO_LIST]);
        queryClient.removeQueries([STOCK_MOVEMENT_PRE_ORDER.PO_DETAILS]);
        queryClient.removeQueries([STOCK_MOVEMENT_PRE_ORDER.PO_DETAILS_LOG]);
      },
    }
  );
}

export function useApprovePayment() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ formPayload, id }) =>
      axios.put(`/api/v1/admin/pre-order/approvement/confirm-payment/${id}`, formPayload).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.refetchQueries([STOCK_MOVEMENT_PRE_ORDER.PO_LIST]);
        queryClient.removeQueries([STOCK_MOVEMENT_PRE_ORDER.PO_DETAILS]);
        queryClient.removeQueries([STOCK_MOVEMENT_PRE_ORDER.PO_DETAILS_LOG]);
      },
    }
  );
}
