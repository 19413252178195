import { useEffect } from 'react';

export function useConfirmOnUnload(showConfirm, message) {
  useEffect(() => {
    if (!showConfirm) {
      return;
    }

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = message;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [showConfirm, message]);
}
