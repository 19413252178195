import axios from 'api/api.config';
import qs from 'query-string';
import { useMutation } from 'react-query';

export function useExportAnalyticTraffic() {
  return useMutation(({ queryFilter, bodyFilter }) =>
    axios
      .post(`/api/v1/admin/analytic-store-traffic/export?${qs.stringify(queryFilter)}`, bodyFilter)
      .then((res) => res.data)
  );
}
