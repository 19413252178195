import React from 'react';

const SplashScreen = () => {
  return (
    <body className='bg-login'>
      <div className='flex justify-center h-screen items-center flex-col relative'>
        <div className='block text-center mb-12'>
          <img src='/img/logo.svg' className='max-w-full h-16  inline-block' alt='Logo' />
        </div>
        <div class='loader-circular' />
      </div>

      <div class='absolute left-0 bottom-0'>
        <img src='/img/leaf-login.svg' class='max-w-full' alt='Background' />
      </div>
    </body>
  );
};

export default SplashScreen;
