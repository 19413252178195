import clsx from 'clsx';

export function TableCollapseComponent({
  name = 'collapse-table-1',
  title = 'Title',
  columns = [],
  initialOpen = false,
  data = [],
  renderFooter = () => {},
  columnContainerClassName = 'bg-green-1 rounded-sm p-2 flex items-center mb-4',
  rowClassName = 'flex items-center rounded-sm py-2 border-b border-solid border-gray-1 px-2 bg-white w-full',
}) {
  return (
    <>
      <a
        class='flex mb-3 items-center justify-between drop-collapse pl-6 pr-3 py-2.5 bg-gray-3 text-dark font-semibold text-sm leading-tight border border-solid border-gray-1 rounded-sm focus:outline-none transition duration-150 ease-in-out collapsed'
        data-bs-toggle='collapse'
        href={`#${name}`}
        role='button'
        aria-expanded='false'
        aria-controls={name}
      >
        {title}
        <span class={clsx(' text-xl leading-[20px] icon-ico-arrow-down')}></span>
      </a>
      <div class={clsx('mb-4 collapse', initialOpen && 'show')} id={name}>
        <div class={columnContainerClassName}>
          {columns.map((column, index) => (
            <div className={column.className} key={index}>
              {column.name}
            </div>
          ))}
        </div>
        <div class='border border-gray-1 border-solid'>
          {data.map((row) => {
            return (
              <div class={rowClassName}>
                <>
                  {columns.map((column, index) => {
                    if (column.render) {
                      return column.render(row, row[column.dataIndex]);
                    }
                    return (
                      <div key={index.toString().concat(index.toString())} className={'text-center'}>
                        {row[column.dataIndex]}
                      </div>
                    );
                  })}
                </>
              </div>
            );
          })}
        </div>
        {renderFooter && renderFooter()}
      </div>
    </>
  );
}
