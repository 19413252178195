import { FieldArray, Field, ErrorMessage } from 'formik';
import { InputComponent } from 'components/form/input.component';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { generateUUID } from 'ui-utils/string.utils';
import { useEffect, useState } from 'react';

export function SpeakerFormComponent({ values, setFieldValue, setFieldTouched }) {
  const [isNamePositionEmpty, setIsNamePositionEmpty] = useState(true);

  useEffect(() => {
    setIsNamePositionEmpty(values.speakers.some((speaker) => speaker.name === '' || speaker.position === ''));
  }, [values]);
  return (
    <FieldArray
      name='speakers'
      render={(arrayHelpers) => (
        <div>
          <div class='mb-4 mt-7'>
            <div className='flex flex-row justify-between items-center'>
              <p className='text-sm text-gray font-semibold'>Speakers</p>
              <ButtonSubmit
                disabled={isNamePositionEmpty}
                onClick={() => {
                  arrayHelpers.unshift({
                    name: '',
                    position: '',
                    file: null,
                    profile_picture: null,
                    isNew: true,
                    uuid: generateUUID(),
                  });
                  setIsNamePositionEmpty(true);
                }}
                className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
              >
                <span class='icon-ico-plus-circle mr-3'></span> Add Speaker
              </ButtonSubmit>
            </div>
          </div>
          {values.speakers && values.speakers.length > 0
            ? values.speakers.map((speaker, index) => (
                <div
                  className='bg-green-status border border-solid border-green-1 status flex flex-row gap-6 items-center p-4 mt-3'
                  key={speaker.uuid}
                >
                  <div className='flex flex-col items-center'>
                    <p className='text-sm text-gray font-semibold mb-1'>Profile Picture</p>
                    <img
                      class='w-28 h-28 overflow-hidden object-cover object-center border border-solid border-gray-1'
                      alt='Preview'
                      name={speaker.uuid}
                      key={speaker.uuid}
                      src={
                        speaker.profile_picture
                          ? speaker.profile_picture
                          : speaker?.file
                          ? URL.createObjectURL(speaker.file)
                          : '/img/default-user.jpg'
                      }
                    />
                    <div class='mt-3 flex justify-center'>
                      <input
                        name={`speakers.${index}.file`}
                        type='file'
                        id={`upload${index}`}
                        class='hidden'
                        onBlur={() => setFieldTouched(`speakers.${index}.file`, true)}
                        onChange={(event) => {
                          setFieldValue(`speakers.${index}.file`, event.currentTarget.files[0]);
                          // check if profile_picture is not empty when user upload new image
                          if (speaker.profile_picture) {
                            setFieldValue(`speakers.${index}.profile_picture`, undefined);
                          }
                        }}
                        accept='image/*'
                      />
                      <label
                        htmlFor={`upload${index}`}
                        className='bg-green w-28 inline-block text-center hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-3 cursor-pointer'
                      >
                        <span className='icon-ico-upload mr-2'></span>
                        <span className='text-sm'>Upload</span>
                      </label>
                    </div>
                    <ErrorMessage
                      name={`speakers.${index}.file`}
                      component='p'
                      className='text-red-1 text-xs -mt-3 mb-2'
                    />
                  </div>
                  <div className='flex flex-col w-full'>
                    <Field
                      component={InputComponent}
                      name={`speakers.${index}.name`}
                      value={speaker.name}
                      label='Name'
                      placeholder='Speaker Name'
                      onChange={(event) => {
                        setFieldValue(`speakers.${index}.name`, event.target.value);
                        setIsNamePositionEmpty(event.target.value === '' || values.speakers[index].position === '');
                      }}
                      onBlur={(event) => setFieldTouched(`speakers.${index}.name`, true)}
                    />
                    <ErrorMessage
                      name={`speakers.${index}.name`}
                      component='p'
                      className='text-red-1 text-xs -mt-3 mb-2'
                    />
                    <Field
                      component={InputComponent}
                      name={`speakers.${index}.position`}
                      label='Position'
                      value={speaker.position}
                      placeholder='Speaker Position'
                      onChange={(event) => {
                        setFieldValue(`speakers.${index}.position`, event.target.value);
                        setIsNamePositionEmpty(event.target.value === '' || values.speakers[index].name === '');
                      }}
                      onBlur={(event) => setFieldTouched(`speakers.${index}.position`, true)}
                    />
                    <ErrorMessage
                      name={`speakers.${index}.position`}
                      component='p'
                      className='text-red-1 text-xs -mt-3 mb-2'
                    />
                    <div className='flex flex-row justify-end'>
                      <button
                        disabled={values.speakers.length === 1}
                        type='button'
                        className='disabled:opacity-50 text-red-1 text-sm font-semibold flex flex-row items-center gap-1'
                        onClick={() => {
                          arrayHelpers.remove(index);
                          if (index === 0) setIsNamePositionEmpty(true);
                        }}
                      >
                        <span class='icon-ico-delete text-red-1 text-xl'></span>
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>
      )}
    />
  );
}
