import clsx from 'clsx';

export function TableButtonLimitComponent({
  value,
  disabled,
  onClick,
  className = 'xl:mr-2 outline-none flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3',
}) {
  return (
    <button className={clsx(className, 'disabled:opacity-50')} type='button' disabled={disabled} onClick={onClick}>
      <span className='icon-ico-eye text-xl text-green'></span>
      <span className='pl-2 font-semibold text-dark text-sm'>{`Show ${value} Items`}</span>
    </button>
  );
}
