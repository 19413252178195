import moment from 'moment';
import { useMemo, useState } from 'react';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { useParams } from 'react-router';
import { PATH_CONSTANT } from 'config/path.constant';
import { useGetListPODetails, useGetLogPODetails } from 'api/stock-movement/pre-order/pre-order.query.api';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { TableCollapseComponent } from 'components/table/table-collaps.component';
import { catchErrorMessage, convertToRupiah } from 'ui-utils/string.utils';
import {
  STOCK_MOVEMENT_STATUS_COLOR_CLASSNAME,
  STOCK_MOVEMENT_STATUS_TEXT_LAGEL,
} from 'pages/stock-movement/pre-order/status-color.util';
import { useApprovePayment } from 'api/stock-movement/approvement/approvement.mutation.api';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import {
  useExportPreOrderDetails,
  useUpdateStatusPOToComplete,
} from 'api/stock-movement/pre-order/pre-order.mutation.api';
import { ModalReportAttachmentComponent } from 'pages/report-ba/sales/report-sales-details/modal-report-attachment.component';
import { ModalStockAudit } from 'pages/report-ba/cycle-count/cycle-count-details/modal-stock-audit.component';
import { AUDIT_STOCK_TYPE, useStockAuditData } from 'api/audit/audit-stock.query';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Stock Movement',
    path: PATH_CONSTANT.STOCK_MOVEMENT.PO_LIST,
  },
  {
    title: 'Detail PO',
    path: PATH_CONSTANT.STOCK_MOVEMENT.PO_LIST,
  },
];

const PO_TYPE_LABEL = {
  konsinyasi: 'Konsinyasi',
  beli_putus: 'Beli Putus',
};

const MODAL_CONFIRMATION_TYPE = {
  APPROVE_PAYMENT: 'APPROVE_PAYMENT',
  COMPLETE_PO: 'COMPLETE_PO',
};

export function POWaitingPaymentDetail() {
  const { poId } = useParams();

  const [modals, setModals] = useState({
    stockAudit: false,
  });
  const [modalConfirmation, setModalConfirmation] = useState({
    visible: false,
    title: '',
    message: '',
    confirmText: '',
    type: '',
  });
  const [showModalAttachment, setShowModalAttachment] = useState(false);

  const { data: stockAuditData, isLoading: isLoadingStockAudit } = useStockAuditData({
    recordId: poId,
    type: AUDIT_STOCK_TYPE.PRE_ORDER,
  });

  const { data: poDetails, isLoading, isError, error } = useGetListPODetails(poId);
  const { data: logDetails } = useGetLogPODetails(poId);
  const { mutate: approvePayment, isLoading: isSubmittingApprovePayment } = useApprovePayment();
  const { mutate: completePO, isLoading: isUpdating } = useUpdateStatusPOToComplete();
  const { mutate: exportPreOrderDetails, isLoading: isExporting } = useExportPreOrderDetails();

  const columnsTableCollapse = useMemo(() => {
    return [
      {
        name: 'Nama Produk',
        className: 'w-[40%] text-sm font-semibold text-green pl-4',
        dataIndex: 'name',
        render: (record) => (
          <div class='w-[40%]'>
            <div class='block text-sm font-semibold text-dark pl-4 pr-5'>{record?.name}</div>
          </div>
        ),
      },

      {
        name: 'Expired Date',
        className: 'w-[15%] text-sm font-semibold text-green text-center',
        dataIndex: 'expired_date',
        render: (record, expired_date) => (
          <div class='w-[15%]'>
            <div class='block text-sm font-semibold text-dark text-center '>
              {expired_date ? moment(expired_date).format('MMM YYYY') : '-'}
            </div>
          </div>
        ),
      },

      {
        name: 'Qty',
        className: 'w-[20%] text-sm font-semibold text-green text-center',
        dataIndex: 'qty',
        render: (record, qty) => (
          <div class='w-[20%]'>
            <div class='block text-sm font-semibold text-dark text-center '>{qty}</div>
          </div>
        ),
      },
      {
        name: 'Harga',
        className: 'w-[25%] text-sm font-semibold text-green text-center',
        dataIndex: 'price',
        render: (record, price) => (
          <div class='w-[25%]'>
            <div class='block text-sm font-semibold text-dark text-center'>{convertToRupiah(price)}</div>
          </div>
        ),
      },
    ];
  }, []);

  const handleOnApprovePayment = () => {
    approvePayment(
      {
        id: poId,
      },
      {
        onSuccess: () => {
          toast.success('Berhasil Approve Payment!');
          setModalConfirmation({
            visible: false,
            title: '',
            message: '',
            type: '',
          });
        },
      }
    );
  };

  const handleOnCompletePO = () => {
    completePO(
      {
        id: poId,
      },
      {
        onSuccess: () => {
          toast.success('Berhasil Menyelesaikan PO!');
          setModalConfirmation({
            visible: false,
            title: '',
            message: '',
            type: '',
          });
        },
      }
    );
  };
  const handleOnPreOrderExport = () => {
    exportPreOrderDetails(
      {
        id: poId,
      },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
        },
      }
    );
  };

  const modalAction = {
    [MODAL_CONFIRMATION_TYPE.APPROVE_PAYMENT]: handleOnApprovePayment,
    [MODAL_CONFIRMATION_TYPE.COMPLETE_PO]: handleOnCompletePO,
  };

  return (
    <>
      <MainDashboardLayoutComponent
        headerContainerClassName='pt-8 pl-8 pr-8 z-10'
        breadCumbs={BREAD_CUMB_ITEMS}
        className='w-[calc(100%-256px)] h-screen z-10'
        isError={isError}
        errorTitle={error?.response?.data?.status}
        erroMessage={catchErrorMessage(error?.response?.data?.message)}
      >
        <div className='px-8  flex w-full justify-between pb-8'>
          {isLoading || isLoadingStockAudit ? (
            <div className='h-[70vh] flex w-full items-center text-center'>
              <BounceLoading color='#5E755A' />
            </div>
          ) : (
            <>
              <div className='w-[calc(100%-290px)]'>
                <div class='bg-gray-3 mb-5'>
                  <div class='py-4 px-6 flex justify-between items-center'>
                    <div class='w-auto flex items-center'>
                      <div className='flex flex-col'>
                        <div class='text-sm text-green font-bold mr-3'>{poDetails?.data?.po_number}</div>
                        <div class='text-sm text-gray font-normal mr-3'>
                          {poDetails?.data?.type && PO_TYPE_LABEL[poDetails?.data?.type]}
                        </div>
                      </div>
                      <div
                        class={clsx(
                          'inline-block  text-center w-52 py-1 px-1 rounded-sm text-sm font-bold',
                          STOCK_MOVEMENT_STATUS_COLOR_CLASSNAME[poDetails?.data?.status.toUpperCase()]
                        )}
                      >
                        {STOCK_MOVEMENT_STATUS_TEXT_LAGEL[poDetails?.data?.status.toUpperCase()]}
                      </div>
                    </div>
                    <div class='text-sm text-gray font-medium'>{poDetails?.data?.request_date}</div>
                  </div>
                  <div class='border-t border-solid border-gray-1 flex'>
                    <div class='w-1/2 border-r border-solid border-gray-1 p-6'>
                      <div class='text-sm text-dark mb-4 font-semibold'>{poDetails?.data?.store_name}</div>
                      <p class='text-sm text-gray  mb-4'>{poDetails?.data?.store_address}</p>
                      <div class='text-sm text-gray italic mb-4 font-medium'>{poDetails?.data?.store_city}</div>
                    </div>
                    <div class='w-1/2 p-6'>
                      <div class='text-sm text-dark mb-4 font-semibold'>{poDetails?.data?.user_name}</div>
                      <p class='text-sm text-gray italic mb-4'>{poDetails?.data?.user_phone}</p>
                      <p class='text-sm text-gray italic mb-4'>Beauty Advisor</p>
                    </div>
                  </div>
                </div>

                <TableCollapseComponent
                  columns={columnsTableCollapse}
                  data={poDetails?.data?.requested_products}
                  title='Request Pre Order'
                  name='requested_products'
                  renderFooter={() => {
                    return (
                      <div class='bg-gray-1 rounded-sm py-2.5 px-2 flex items-center'>
                        <div class='w-[55%]'>
                          <div class='block text-sm font-bold text-dark uppercase pl-4'>Total</div>
                        </div>
                        <div class='w-[20%]'>
                          <div class='block text-sm font-bold text-dark text-center uppercase'>
                            {poDetails?.data?.total_requested_product_qty.toLocaleString()}
                          </div>
                        </div>
                        <div class='w-[25%] overflow-hidden'>
                          <div class='block text-sm font-bold text-dark uppercase text-center overflow-hidden text-ellipsis whitespace-nowrap'>
                            {convertToRupiah(poDetails?.data?.total_requested_product_price)}
                          </div>
                        </div>
                      </div>
                    );
                  }}
                />

                <TableCollapseComponent
                  columns={columnsTableCollapse}
                  initialOpen
                  data={poDetails?.data?.approved_products}
                  title='Approvement Pre Order'
                  name='approved_products'
                  renderFooter={() => {
                    return (
                      <div class='bg-gray-1 rounded-sm py-2.5 px-2 flex items-center'>
                        <div class='w-[55%]'>
                          <div class='block text-sm font-bold text-dark uppercase pl-4'>Total</div>
                        </div>
                        <div class='w-[20%]'>
                          <div class='block text-sm font-bold text-dark text-center uppercase'>
                            {' '}
                            {poDetails?.data?.total_approved_product_qty.toLocaleString()}
                          </div>
                        </div>
                        <div class='w-[25%] overflow-hidden'>
                          <div class='block text-sm font-bold text-dark uppercase text-center overflow-hidden text-ellipsis whitespace-nowrap'>
                            {convertToRupiah(poDetails?.data?.total_approved_product_price)}
                          </div>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
              <div class='w-[270px]'>
                <ButtonSubmit
                  loadingColor='#AAB998'
                  onClick={handleOnPreOrderExport}
                  loading={isExporting}
                  className='outline-none w-full flex items-center border justify-center border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3 mb-3'
                >
                  <span class='icon-ico-export text-xl text-green'></span>
                  <span class='pl-2 font-semibold text-dark text-sm'>Export Data</span>
                </ButtonSubmit>

                {poDetails?.data?.status === 'waiting_for_payment' && (
                  <div class='border border-solid border-gray-1 rounded-sm p-2 mb-3'>
                    <div class='block text-sm font-semibold text-dark mb-3'>Payment Confirmation</div>
                    <ButtonSubmit
                      loading={isSubmittingApprovePayment}
                      onClick={() => {
                        setModalConfirmation({
                          visible: true,
                          title: 'Approve Payment',
                          message:
                            'Apakah anda yakin pembayaran telah terkonfirmasi dan ingin mengubah status PO menjadi Approved?',
                          confirmText: 'Approve',
                          type: MODAL_CONFIRMATION_TYPE.APPROVE_PAYMENT,
                        });
                      }}
                      className='bg-green flex justify-center items-center w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                    >
                      <span class='icon-ico-apply mr-3 text-lg'></span> Confirm
                    </ButtonSubmit>
                  </div>
                )}
                {poDetails?.data?.status === 'validate' && poDetails?.data?.type === 'konsinyasi' && (
                  <div class='border border-solid border-gray-1 rounded-sm p-2 mb-3'>
                    <div class='block text-sm font-semibold text-dark mb-3'>Delivery Confirmation</div>
                    <ButtonSubmit
                      loading={isUpdating}
                      onClick={() => {
                        setModalConfirmation({
                          visible: true,
                          title: 'Complete PO',
                          message: 'Apakah anda yakin ingin mengubah status PO menjadi Completed?',
                          confirmText: 'Confirm',
                          type: MODAL_CONFIRMATION_TYPE.COMPLETE_PO,
                        });
                      }}
                      className='bg-green flex justify-center items-center w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                    >
                      <span class='icon-ico-apply mr-3 text-lg'></span> Complete PO
                    </ButtonSubmit>
                  </div>
                )}
                {poDetails?.data?.status === 'delivery' && poDetails?.data?.type === 'beli_putus' && (
                  <div class='border border-solid border-gray-1 rounded-sm p-2 mb-3'>
                    <div class='block text-sm font-semibold text-dark mb-3'>Delivery Confirmation</div>
                    <ButtonSubmit
                      loading={isUpdating}
                      onClick={() => {
                        setModalConfirmation({
                          visible: true,
                          title: 'Complete PO',
                          message: 'Apakah anda yakin ingin mengubah status PO menjadi Completed?',
                          confirmText: 'Confirm',
                          type: MODAL_CONFIRMATION_TYPE.COMPLETE_PO,
                        });
                      }}
                      className='bg-green flex justify-center items-center w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                    >
                      <span class='icon-ico-apply mr-3 text-lg'></span> Complete PO
                    </ButtonSubmit>
                  </div>
                )}
                <div class='bg-gray-3 py-5 px-4 mb-3'>
                  <div class='block text-sm font-semibold text-dark mb-6'>Pre Order Log</div>

                  <ul class='step max-h-[45vh] scroll'>
                    {logDetails?.data?.length === 0 && (
                      <div class='text-xs  italic text-gray mb-2'>No action log available</div>
                    )}
                    {logDetails?.data?.map((actionLogItem) => (
                      <li>
                        <div class='bullet'></div>
                        <div class='text-sm font-semibold text-gray '>{actionLogItem?.action_description}</div>
                        <div class='text-xs font-semibold italic text-gray mb-2'>{`Modified by ${actionLogItem?.actor_email}`}</div>
                        <div class='text-sm font-medium italic text-gray'>{actionLogItem?.created_at_formatted}</div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div class='bg-gray-3 py-5 px-4'>
                  <div class='block text-sm font-semibold text-dark mb-3'>Notes BA</div>
                  <p class='text-gray text-sm mb-4'>{poDetails?.data?.user_note || '-'}</p>
                  {poDetails?.data?.product_images.length > 0 && (
                    <button
                      class='outline-none w-full flex items-center border  border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
                      type='button'
                      onClick={() => {
                        setShowModalAttachment(true);
                      }}
                    >
                      <span class='icon-ico-attachment text-xl text-green'></span>
                      <span class='pl-2 font-semibold text-dark text-sm'>Attachment</span>
                    </button>
                  )}
                </div>
                <button
                  class='bg-green flex  my-2 justify-center items-center w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                  type='button'
                  onClick={() => {
                    setModals((prev) => ({
                      ...prev,
                      stockAudit: true,
                    }));
                  }}
                >
                  <span class='icon-ico-info mr-3 text-lg'></span> Stock Audit
                </button>
              </div>
            </>
          )}
        </div>
        <ModalReportAttachmentComponent
          visible={showModalAttachment}
          attachments={
            poDetails?.data?.product_images?.map((item) => ({
              file_url: item.product_image,
            })) || []
          }
          onClose={() => {
            setShowModalAttachment(false);
          }}
        />

        <ModalStockAudit
          visible={modals.stockAudit}
          onClose={() => setModals({ ...modals, stockAudit: false })}
          data={stockAuditData?.data}
        />
        <ModalConfirmation
          description={modalConfirmation.message}
          title={modalConfirmation.title}
          visible={modalConfirmation.visible}
          onConfirm={modalAction[modalConfirmation.type]}
          textConfirm={modalConfirmation.confirmText}
          onClose={() => setModalConfirmation({ visible: false })}
        />
      </MainDashboardLayoutComponent>
    </>
  );
}
