export function LocationComponent({ title = 'Location Clock In', description = '' }) {
  return (
    <div className='mt-10 mb-8' id='clock-in-location'>
      <div className='text-md text-green  font-medium mb-3'>{title}</div>
      <div className='text-sm text-gray font-medium mb-3'>
        {description || <p className='text-sm text-gray-2  italic'>No Location Available</p>}
      </div>
    </div>
  );
}
