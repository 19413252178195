import qs from 'query-string';
import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const REPORT_BA_VISITOR_QUERY_KEY = {
  VISITOR_LIST: 'VISITOR_LIST',
};

export function useReportBAVisitorList({ filter, body }) {
  return useQuery([REPORT_BA_VISITOR_QUERY_KEY.VISITOR_LIST, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/store-traffic?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}
