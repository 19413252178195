import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { InputComponent } from 'components/form/input.component';
import { toast } from 'react-toastify';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { MobieAppVersionValidationSchema } from 'config/form/setttings/mobile-app-version-validation.schema';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { PATH_CONSTANT } from 'config/path.constant';
import { useGetMobileAppVersion } from 'api/settings/mobile-app-version/mobile-app-version.query.api';
import { useUpdateMobileAppVersion } from 'api/settings/mobile-app-version/mobile-app-version.mutation.api';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Settings',
    path: PATH_CONSTANT.SETTINGS.MOBILE_VERSION,
  },
  {
    title: 'Mobile App Version',
    path: PATH_CONSTANT.SETTINGS.MOBILE_VERSION,
  },
];

const formInitialValue = {
  ios: null,
  android: null,
};

export default function SettingMobileAppVersion() {
  const formikRef = useRef();

  const { data: mobileAppVersionDetails, isLoading } = useGetMobileAppVersion();

  const [modalConfirmation, setModalConfirmation] = useState(false);
  const { mutateAsync: updateMobileAppVersionAsync, isLoading: isUpdating } = useUpdateMobileAppVersion();

  const handleOnFormSubmit = async (formValues) => {
    try {
      const ios = mobileAppVersionDetails?.data?.find((item) => item?.platform === 'ios');
      const android = mobileAppVersionDetails?.data?.find((item) => item?.platform === 'android');
      await updateMobileAppVersionAsync({ id: ios?.id, latest_version: formValues?.ios });
      await updateMobileAppVersionAsync({ id: android?.id, latest_version: formValues?.android });
      setModalConfirmation(false);
      toast.success('Update mobile app version success');
    } catch (error) {
      toast.error('Update mobile app version failed');
    }
  };

  useEffect(() => {
    if (mobileAppVersionDetails) {
      mobileAppVersionDetails?.data?.forEach((appVersionItem) => {
        formikRef.current.setFieldValue(appVersionItem?.platform, appVersionItem?.latest_version);
      });
    }
  }, [mobileAppVersionDetails]);

  const isDisabledAllForm = isUpdating;

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      <div className='flex'>
        {isLoading ? (
          <div className='h-[70vh] flex w-full items-center text-center'>
            <BounceLoading color='#5E755A' />
          </div>
        ) : (
          <Formik
            innerRef={formikRef}
            initialValues={formInitialValue}
            onSubmit={handleOnFormSubmit}
            validationSchema={MobieAppVersionValidationSchema}
          >
            {({ errors, values, handleChange, handleBlur, touched }) => {
              return (
                <>
                  <div class='w-1/2'>
                    <div class='py-4 px-2 mb-4 rounded-md relative flex justify-center text-xs text-gray bg-amber-100'>
                      <i>
                        Mobile App versioning must be using Semantic Versioning format. For more information, please
                        refer to
                        <a href='https://semver.org/' target='_blank' rel='noreferrer' class='text-blue-500 ml-1'>
                          https://semver.org/
                        </a>
                      </i>
                    </div>

                    <span class='text-lg font-semibold'>Android</span>
                    <span className='text-sm text-gray-500 block mb-4'>App versioning for Android</span>
                    <InputComponent
                      disabled={isDisabledAllForm}
                      name='android'
                      value={values.android}
                      error={touched.android && errors.android}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label='Recomended Version'
                      placeholder='Ex: 1.0.0'
                    />
                    <span class='text-lg font-semibold'>iOS</span>
                    <span className='text-sm text-gray-500 block mb-4'>App versioning for iOS</span>
                    <InputComponent
                      disabled={isDisabledAllForm}
                      name='ios'
                      value={values.ios}
                      error={touched.ios && errors.ios}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label='Recomended Version'
                      placeholder='Ex: 1.0.0'
                    />

                    <div class='py-5 relative flex justify-center'>
                      <ButtonSubmit
                        loading={isUpdating}
                        disabled={!MobieAppVersionValidationSchema.isValidSync(values)}
                        onClick={() => {
                          setModalConfirmation(true);
                        }}
                        className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
                      >
                        <span class='icon-ico-apply mr-3'></span> Update Version
                      </ButtonSubmit>
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        )}
      </div>
      <ModalConfirmation
        description='Apakah anda yakin ingin mengubah versi mobile app?'
        title='Update Confirmation'
        imageIcon='/img/info.svg'
        textConfirm='Update'
        loading={isUpdating}
        visible={modalConfirmation}
        onConfirm={() => {
          formikRef?.current?.handleSubmit();
        }}
        onClose={() => setModalConfirmation(false)}
      />
    </MainDashboardLayoutComponent>
  );
}
