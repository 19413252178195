import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useRef, useState } from 'react';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { InputComponent } from 'components/form/input.component';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { useNavigate } from 'react-router-dom';
import { UPLOAD_IMAGE_CONSTANT, useUploadSingleFile, useUploadSingleImage } from 'api/upload/upload-single-image.api';
import { TOOLBAR_OPTIONS, TextEditorComponent } from 'components/form/text-editor';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { PATH_CONSTANT } from 'config/path.constant';
import { Select } from 'components/form/select.component';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { SpeakerFormComponent } from '../components/speaker-form.component';
import { CreateSkilDevelopmentValidationSchema } from 'config/form/share-info/create-skill-development-validation.schema';
import { useCreateNewSkillDevelopment } from 'api/share-info/skill-development/skill-development.mutation';
import { SHARE_INFO_ATTACHMENT_TYPE, UploadAttachmentInfo } from '../components/upload-attachment.component';

const SUBMIT_TYPE = {
  SAVE: 'publish',
  DRAFT: 'draft',
};

const EVENT_TYPE = [
  { id: 'offline', name: 'Offline' },
  { id: 'online', name: 'Online' },
];
const DEFAULT_IMAGE = '/img/upload-image.jpg';
const BREAD_CUMB_ITEMS = [
  {
    title: 'Share Info',
    path: PATH_CONSTANT.SHARE_INFO.CREATE_INFO_SKILL_DEVELOPMENT,
  },
  {
    title: 'Create Skill Development',
    path: PATH_CONSTANT.SHARE_INFO.CREATE_INFO_SKILL_DEVELOPMENT,
  },
];

const formInitialValue = {
  title: '',
  description: '',
  type: '',
  status: '',
  start_date: '',
  start_time: '',
  end_date: '',
  end_time: '',
  attachments: [],
  speakers: [
    {
      name: '',
      position: '',
      profile_picture: '',
      file: null,
    },
  ],
};

const minDate = new Date().toISOString().split('T')[0].split('-');

export const CreateSkillDevelopment = () => {
  const navigate = useNavigate();
  const uploadComponentRef = useRef(null);
  const formikRef = useRef(null);

  const [modalConfirmation, setModalConfirmation] = useState(false);

  const { mutateAsync: createSkillDevelopment, isLoading: isCreating } = useCreateNewSkillDevelopment();

  const { mutateAsync: uploadInfoBanner, isLoading: isUploading } = useUploadSingleFile({
    type: UPLOAD_IMAGE_CONSTANT.TYPE.ORIGINAL,
    path: UPLOAD_IMAGE_CONSTANT.DIRECTORY_PATH.SKILL_DEVELOPMENT,
  });

  const [selectedFileBuffer, setSelectedFileBuffer] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(DEFAULT_IMAGE);

  const handleOnFormSubmit = async (formValues, submitType) => {
    try {
      let infoImageBannerURL = null;
      if (selectedFileBuffer) {
        const formData = new FormData();
        formData.append('image', selectedFileBuffer);
        const response = await uploadInfoBanner(formData);
        infoImageBannerURL = response?.data?.url;
      }

      const filesBuffer = uploadComponentRef.current?.getFiles();

      const linkAttachments = uploadComponentRef.current
        ?.getFilesMetaData()
        .filter((file) => file.type === SHARE_INFO_ATTACHMENT_TYPE.LINK)
        .map((file) => ({
          file_name: file.name,
          url: file.url,
          type: file.type,
        }));

      formValues.attachments = linkAttachments;

      if (filesBuffer.length > 0) {
        const attachmentsURL = await Promise.all(
          filesBuffer.map(async (file) => {
            const formData = new FormData();
            formData.append('image', file);
            const response = await uploadInfoBanner(formData);
            return {
              file_name: file.name,
              url: response?.data?.url,
              type: SHARE_INFO_ATTACHMENT_TYPE.FILE,
            };
          })
        );
        formValues.attachments = [...attachmentsURL, ...linkAttachments];
      }
      if (formValues.speakers.length > 0) {
        // filter speaker with file is not null
        const speakers = formValues.speakers.filter((speaker) => speaker.file !== null);
        // upload speaker image
        const speakerImageURL = await Promise.all(
          speakers.map(async (speaker) => {
            const formData = new FormData();
            formData.append('image', speaker.file);
            const response = await uploadInfoBanner(formData);
            return response?.data?.url;
          })
        );
        // update speaker image url
        formValues.speakers = formValues.speakers.map((speaker, index) => {
          speaker.profile_picture = speakerImageURL[index];
          return speaker;
        });
      }

      const payload = {
        ...formValues,
        status: submitType,
        image: infoImageBannerURL,
        is_all_users: formValues.is_all_users === 'ALL_USERS',
      };

      await createSkillDevelopment(payload);
      toast.success('Info created successfully', {
        autoClose: 2000,
      });
      navigate(PATH_CONSTANT.SHARE_INFO.LIST_INFO_SKILL_DEVELOPMENT);
    } catch (error) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleOnUploadFileChange = (e) => {
    //chedck file size with maximum 5mb and type, only accept image
    if (e.target.files[0].size > 5000000) {
      toast('File size is too large. Maximum 5mb is allowed');
      return;
    }
    if (!e.target.files[0].type.includes('image')) {
      toast('Only image file is allowed');
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setSelectedFileBuffer(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const isDisabledAllForm = isCreating || isUploading;

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      <Formik
        innerRef={formikRef}
        initialValues={formInitialValue}
        validationSchema={CreateSkilDevelopmentValidationSchema}
      >
        {({
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setTouched,
          touched,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <>
              <div className='flex justify-between mb-8'>
                <div class='w-[40%] '>
                  <div class='w-auto'>
                    <img
                      src={imagePreviewUrl}
                      class='w-[400px] h-[400px] overflow-hidden object-cover object-center border border-solid border-gray-1'
                      alt='Preview'
                    />
                    <div class='mt-7 flex justify-center'>
                      <input
                        type='file'
                        id='upload'
                        class='hidden'
                        onChange={handleOnUploadFileChange}
                        accept='image/*'
                      />
                      <label
                        for='upload'
                        class='bg-green w-28 inline-block text-center hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-3 cursor-pointer'
                      >
                        <span class='icon-ico-upload mr-2'></span>
                        <span class='text-sm'>Upload</span>
                      </label>
                    </div>
                  </div>

                  <SpeakerFormComponent
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  />
                </div>
                <div class='w-[55%]'>
                  <div class='w-full mb-2'>
                    <Select
                      label='Online/Offline'
                      onBlur={handleBlur}
                      onChange={(selectedValue) => {
                        setFieldValue('type', selectedValue.target?.value);
                        setTouched({ ...touched, users: true });
                      }}
                      name='type'
                      containerClassName='form-input btn-arrow-create z-1   relative mb-3'
                      placeholder='Select Online/Offline'
                      error={touched.type && errors.type}
                      options={EVENT_TYPE}
                      value={values.type}
                    />
                  </div>
                  <InputComponent
                    disabled={isDisabledAllForm}
                    name='title'
                    value={values.title}
                    error={touched.title && errors.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Title'
                    placeholder='Enter title...'
                  />
                  <div class='mb-7'>
                    <div class='text-sm block text-dark font-semibold mb-2'>Description</div>

                    <TextEditorComponent
                      toolbarOptions={[
                        TOOLBAR_OPTIONS.BOLD,
                        TOOLBAR_OPTIONS.ITALIC,
                        TOOLBAR_OPTIONS.UNDERLINE,
                        TOOLBAR_OPTIONS.CENTER_ALIGN,
                        TOOLBAR_OPTIONS.RIGHT_ALIGN,
                        TOOLBAR_OPTIONS.LEFT_ALIGN,
                      ]}
                      initialValue={undefined}
                      onChange={(nodeValue) => setFieldValue('description', nodeValue)}
                    />
                  </div>

                  <div className='w-full flex-row flex justify-between'>
                    <div class='w-1/2 py-2 pr-6'>
                      <InputComponent
                        disabled={isDisabledAllForm}
                        name='start_date'
                        type='date'
                        value={values.start_date}
                        min={minDate.join('-')}
                        error={touched.start_date && errors.start_date}
                        onChange={(e) => {
                          setFieldValue('start_date', e.target.value);
                        }}
                        onBlur={handleBlur}
                        label='Start Date'
                        placeholder='Start Date'
                      />
                    </div>
                    <div class='w-1/2 py-2 pr-6'>
                      <InputComponent
                        disabled={isDisabledAllForm}
                        name='start_time'
                        type='time'
                        value={values.start_time}
                        error={touched.start_time && errors.start_time}
                        onChange={(e) => {
                          setFieldValue('start_time', e.target.value);
                        }}
                        onBlur={handleBlur}
                        label='Start Time'
                        placeholder='Start Time'
                      />
                    </div>
                  </div>
                  <div className='w-full flex-row flex justify-between'>
                    <div class='w-1/2 py-2 pr-6'>
                      <InputComponent
                        disabled={isDisabledAllForm}
                        name='end_date'
                        type='date'
                        value={values.end_date}
                        min={minDate.join('-')}
                        error={touched.end_date && errors.end_date}
                        onChange={(e) => {
                          setFieldValue('end_date', e.target.value);
                        }}
                        onBlur={handleBlur}
                        label='End Date'
                        placeholder='End Date'
                      />
                    </div>
                    <div class='w-1/2 py-2 pr-6'>
                      <InputComponent
                        disabled={isDisabledAllForm}
                        name='end_time'
                        type='time'
                        value={values.end_time}
                        error={touched.end_time && errors.end_time}
                        onChange={(e) => {
                          setFieldValue('end_time', e.target.value);
                        }}
                        onBlur={handleBlur}
                        label='End Time'
                        placeholder='End Time'
                      />
                    </div>
                  </div>
                  <p class='text-md block text-gray font-semibold mt-4 mb-2'>Attachments</p>
                  <UploadAttachmentInfo ref={uploadComponentRef} withInputLink />
                </div>
              </div>

              <div class='py-14 relative flex justify-center'>
                <ButtonSubmit
                  disabled={
                    !CreateSkilDevelopmentValidationSchema?.isValidSync(values) ||
                    !imagePreviewUrl ||
                    imagePreviewUrl === DEFAULT_IMAGE
                  }
                  loading={isCreating | isUploading}
                  onClick={() => handleOnFormSubmit(values, SUBMIT_TYPE.DRAFT)}
                  className='bg-gray-1 mx-3 hover:bg-gray-2 font-semibold transition duration-75 ease-in-out rounded-sm text-dark py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-10'
                >
                  <StandartImageComponent src='/img/icon/file.svg' class='max-w-full mr-3' /> Save As Draft
                </ButtonSubmit>
                <ButtonSubmit
                  loading={isCreating | isUploading}
                  disabled={
                    !CreateSkilDevelopmentValidationSchema.isValidSync(values) ||
                    !imagePreviewUrl ||
                    imagePreviewUrl === DEFAULT_IMAGE
                  }
                  onClick={() => handleOnFormSubmit(values, SUBMIT_TYPE.SAVE)}
                  className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
                >
                  <span class='icon-ico-plus-circle mr-3'></span> Save dan Publish
                </ButtonSubmit>
              </div>
            </>
          );
        }}
      </Formik>
      <ModalConfirmation
        description='Apakah anda yakin ingin membuat product baru?'
        title='Create Prodcut'
        imageIcon='/img/info.svg'
        textConfirm='Create'
        loading={isCreating}
        visible={modalConfirmation}
        onConfirm={() => {
          formikRef?.current?.handleSubmit();
        }}
        onClose={() => setModalConfirmation(false)}
      />
    </MainDashboardLayoutComponent>
  );
};
