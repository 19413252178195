import qs from 'query-string';
import moment from 'moment';
import { capitalize } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { TableSearchInputComponent } from 'components/table/table-search.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { TableButtonLimitComponent } from 'components/table/table-button-limit.component';
import { TableButtonSortComponent } from 'components/table/table-button-sort.component';
import { TableButtonFilterComponent } from 'components/table/table-button-filter.component';
import { BasicTableComponent } from 'components/table/table-basic.component';
import { Pagination } from 'components/table/table-pagination.component';
import { EmptyStateComponent } from 'components/empty-data';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ModalTableSortComponent } from 'components/modal/modal-table-sort.component';
import { ModalTableLimitComponent } from 'components/modal/modal-table-limit.component';
import { ModalTableFilter } from 'components/modal/modal-table-filter.component';
import { ModalResult } from 'components/modal/modal.result.component';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { isAllPropertyObjectFalsy } from 'ui-utils/object.utils';
import { catchErrorMessage } from 'ui-utils/string.utils';
import { createAndClickLink } from 'ui-utils/dom.utils';
import { setToolsReducer } from 'reducers/tools.reducer';
import { PATH_CONSTANT } from 'config/path.constant';
import { useReportBANEDProductList } from 'api/report-ba/ned-product/ned-product.query.api';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { useApproveNEDReport, useExportNEDReportList } from 'api/report-ba/ned-product/ned-product.mutation.api';
import { HighlightedText } from 'components/highlighted-text';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Report BA',
    path: PATH_CONSTANT.REPORT_BA.NED_PRODUCT,
  },
  {
    title: 'NED Product',
    path: PATH_CONSTANT.REPORT_BA.NED_PRODUCT,
  },
];

const STATUS_CLASSNAME = {
  submitted: 'inline-block bg-yellow-light text-center w-28 py-1 px-1 rounded-sm text-yellow text-sm font-bold',
  approved: 'inline-block bg-green-status text-center w-28 py-1 px-1 rounded-sm text-green text-sm font-medium',
};
const INITIAL_VALUE_FILTER_BODY = {
  status: null,
  store_ids: [],
};

const SORT_OPTIONS = [
  { label: 'Nama A-Z', sortType: 'asc', sortBy: 'user_name' },
  { label: 'Nama Z-A', sortType: 'desc', sortBy: 'user_name' },
  { label: 'Tanggal (Terbaru)', sortType: 'desc', sortBy: 'created_at' },
  { label: 'Tanggal (Terlama)', sortType: 'asc', sortBy: 'created_at' },
];

const getFilteredSearchParam = (searchParams, key, regex, defaultValue) => {
  return searchParams.get(key)?.replace(regex, '') || defaultValue;
};

export const ReportBANEDProductListComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const INITIAL_VALUE_FILTER_QUERY = {
    limit: getFilteredSearchParam(searchParams, 'limit', /[^0-9]/g, 20),
    page: getFilteredSearchParam(searchParams, 'page', /[^0-9]/g, 1),
    orderBy: getFilteredSearchParam(searchParams, 'orderBy', /[^a-zA-Z_]/g, 'created_at'),
    orderType: getFilteredSearchParam(searchParams, 'orderType', /[^a-zA-Z]/g, 'desc'),
    search: getFilteredSearchParam(searchParams, 'search', /[^a-zA-Z0-9 ]/g, undefined),
  };

  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);
  const [bodyFilter, setBodyFilter] = useState(INITIAL_VALUE_FILTER_BODY);
  const isFilterActive = !isAllPropertyObjectFalsy(bodyFilter);
  const { selectedRowKeys: selectedIds } = useSelector((state) => state.tools);

  const [modals, setModals] = useState({
    sort: false,
    limit: false,
    filter: false,
    export: false,
    updateStatus: false,
    result: false,
    approveConfirmation: false,
  });

  const { mutate: exportVisitorList, isLoading: isExporting } = useExportNEDReportList();
  const { mutate: approveNEDReport, isLoading: isApproving } = useApproveNEDReport();
  const {
    data: userList,
    isLoading,
    isError,
    error,
  } = useReportBANEDProductList({
    filter: {
      ...queryFilter,
      startDate: moment(queryFilter.startDate).format('YYYY-MM-DD'),
      endDate: moment(queryFilter.endDate).format('YYYY-MM-DD'),
    },
    body: {
      ...bodyFilter,
      status: bodyFilter.status ? [bodyFilter.status] : [],
    },
  });

  const TABLE_COLUMNS = useMemo(
    () => [
      {
        type: 'checkbox',
        isRightBorderAvailable: true,
        render: (record) => {
          return (
            <div className='form-check px-2'>
              <input
                className='form-check-input appearance-none h-4 w-4 border border-green rounded-sm bg-white checked:bg-green checked:border-green focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                type='checkbox'
                readOnly
              />
            </div>
          );
        },
      },

      {
        name: 'BA Name & Store',
        className: 'w-[25%] text-sm font-semibold text-green text-center',
        dataIndex: 'user_name',
        render: (record, user_name) => (
          <div className='w-[25%] text-grey-70 text-sm font-medium'>
            <div class='flex items-center pl-2 xl:pl-5'>
              <div class='w-12'>
                <img src={record?.user_image || '/img/default-user.jpg'} class='w-12 h-12 object-cover' alt='User' />
              </div>
              <div
                class='w-56 pl-3 xl:pl-5 cursor-pointer'
                onClick={() => {
                  navigate(`${PATH_CONSTANT.REPORT_BA.NED_PRODUCT_DETAILS}/${record.id}`);
                }}
              >
                <div class='text-sm font-semibold text-dark mb-1'>
                  <HighlightedText text={user_name} highlight={queryFilter?.search} />
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: 'Store',
        className: 'w-[20%] text-sm font-semibold text-center text-green',
        dataIndex: 'store_name',
        render: (record, store_name) => (
          <div class='w-[20%] text-center'>
            <div class='text-sm font-medium text-green'>
              <HighlightedText text={store_name} highlight={queryFilter?.search} />
            </div>
          </div>
        ),
      },
      {
        name: 'Tanggal',
        className: 'w-[15%] text-sm font-semibold text-green text-center',
        dataIndex: 'created_at_formatted',
        render: (record, created_at_formatted) => (
          <div class='w-[15%]'>
            <div class='text-sm font-medium text-center text-gray'>{created_at_formatted}</div>
          </div>
        ),
      },
      {
        name: 'Qty Product NED',
        className: 'w-[15%] text-sm font-semibold text-green text-center',
        dataIndex: 'total_qty',
        render: (record, total_qty) => (
          <div class='w-[15%]'>
            <div class='text-sm font-medium text-center text-gray'>{total_qty.toLocaleString()}</div>
          </div>
        ),
      },
      {
        name: 'Status',
        className: 'w-[15%] text-sm font-semibold text-green text-center',
        dataIndex: 'status',
        render: (record, status) => (
          <div class='w-[15%] flex justify-center'>
            <div class={STATUS_CLASSNAME[status]}>{capitalize(status)}</div>
          </div>
        ),
      },
    ],
    [queryFilter.search]
  );

  const resetCheckList = () => {
    if (selectedIds.length > 0) {
      dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
    }
  };

  const updateQueryFilterAndNavigate = (updatedQueryFilter) => {
    setQueryFilter((prevState) => ({
      ...prevState,
      ...updatedQueryFilter,
    }));
    navigate({
      search: qs.stringify({ ...queryFilter, ...updatedQueryFilter }),
    });
  };

  const handleOnPageChange = (page) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    updateQueryFilterAndNavigate({ page });
  };

  const handleOnSortChange = ({ selected }) => {
    updateQueryFilterAndNavigate({
      orderBy: selected.sortBy,
      orderType: selected.sortType,
    });
    setModals({ ...modals, sort: false });
  };

  const handleOnApproveReport = () => {
    if (!selectedIds.length) return;
    approveNEDReport(
      {
        ned_report_ids: selectedIds,
      },
      {
        onSuccess: () => {
          setModals({ ...modals, approveConfirmation: false, result: true });
          resetCheckList();
        },
      }
    );
  };

  const handleOnLimitChange = ({ limit }) => {
    updateQueryFilterAndNavigate({ limit, page: 1 });
    setModals({ ...modals, limit: false });
  };

  const handleOnFilterChange = (selectedFilter) => {
    setBodyFilter({
      ...bodyFilter,
      ...selectedFilter,
    });
    setModals({ ...modals, filter: false });
    setQueryFilter((prevState) => ({
      ...prevState,
      page: 1,
    }));
  };

  const handleOnSearch = (value) => {
    updateQueryFilterAndNavigate({ search: value.keyword, page: 1 });
  };

  const handleOnClickApproveButton = () => {
    if (!selectedIds.length) return;
    setModals({ ...modals, approveConfirmation: true });
  };

  const handleExport = () => {
    exportVisitorList(
      {
        queryFilter: {
          ...queryFilter,
          startDate: moment(queryFilter.startDate).format('YYYY-MM-DD'),
          endDate: moment(queryFilter.endDate).format('YYYY-MM-DD'),
        },
        bodyFilter: { ...bodyFilter, report_ids: selectedIds, status: bodyFilter.status ? [bodyFilter.status] : [] },
      },
      {
        onSuccess: (res) => {
          createAndClickLink(res.data.download_url);
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  useEffect(() => {
    resetCheckList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      <div className='flex items-center mb-5 justify-between'>
        <TableSearchInputComponent
          containerClassName='relative w-[30%] mr-2'
          placeholder='Cari user atau store...'
          initialValue={INITIAL_VALUE_FILTER_QUERY.search}
          inputProps={{
            disabled: isLoading,
          }}
          onSearch={handleOnSearch}
        />

        <div className='flex xl:justify-start lg:justify-between md:justify-between items-center gap-x-2'>
          <TableButtonSortComponent
            disabled={isLoading}
            onClick={() => {
              setModals((prev) => ({ ...prev, sort: true }));
            }}
          />
          <TableButtonFilterComponent
            disabled={isLoading}
            active={isFilterActive}
            onClick={() => {
              setModals((prev) => ({ ...prev, filter: true }));
            }}
          />
          <TableButtonLimitComponent
            disabled={isLoading}
            value={queryFilter.limit}
            onClick={() => setModals({ ...modals, limit: true })}
          />

          <TableButtonExport
            disabled={isLoading}
            loading={isExporting}
            onClick={() => {
              setModals({ ...modals, export: true });
            }}
          />
        </div>

        <div className='w-[200px] flex items-center justify-end'>
          <div className='w-[170px]'>
            <ButtonSubmit
              disabled={isLoading || selectedIds.length === 0}
              onClick={handleOnClickApproveButton}
              className='bg-green w-full flex items-center justify-center hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
            >
              <span class='icon-ico-apply text-[20px] leading-[21px] mr-3'></span>
              Approve
            </ButtonSubmit>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          <div className='italic text-dark text-sm mb-5'>{`Total ${userList?.data?.total_result} data`}</div>

          <BasicTableComponent
            rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
            columns={TABLE_COLUMNS}
            dataSource={userList?.data?.rows || []}
            loading={isLoading}
            error={isError}
            errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
            errorMessage={catchErrorMessage(error?.response?.data?.message)}
          />

          {userList?.data?.total_result === 0 && (
            <EmptyStateComponent
              message={queryFilter.search ? `No result for query "${queryFilter.search}"` : 'No data available'}
            />
          )}

          <Pagination
            currentPage={queryFilter.page}
            pageSize={queryFilter.limit}
            siblingCount={1}
            totalCount={userList?.data?.total_result || 0}
            onPageChange={handleOnPageChange}
          />
        </>
      )}
      <ModalTableSortComponent
        options={SORT_OPTIONS}
        initialValue={SORT_OPTIONS.find(
          (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
        )}
        onChange={handleOnSortChange}
        visible={modals.sort}
        onClose={() => setModals({ ...modals, sort: false })}
      />

      <ModalTableLimitComponent
        onChange={handleOnLimitChange}
        initialValue={queryFilter.limit}
        visible={modals.limit}
        onClose={() => setModals({ ...modals, limit: false })}
      />
      <ModalConfirmation
        description='Apakah anda yakin ingin eksport data yang anda pilih ?'
        title='Export Data'
        loading={isExporting}
        visible={modals.export}
        onConfirm={handleExport}
        onClose={() => setModals({ ...modals, export: false })}
      />

      <ModalConfirmation
        description='Apakah anda yakin ingin menyetujui data yang anda pilih ?'
        title='Approve Report'
        loading={isApproving}
        visible={modals.approveConfirmation}
        textConfirm='Approve'
        onConfirm={handleOnApproveReport}
        onClose={() => setModals({ ...modals, approveConfirmation: false })}
      />

      <ModalResult
        description='Update status report berhasil dilakukan!'
        title='Update Status Report'
        visible={modals.result}
        textConfirm='OK'
        onConfirm={() => setModals({ ...modals, result: false })}
        onClose={() => setModals({ ...modals, result: false })}
      />

      <ModalTableFilter
        initialValue={bodyFilter}
        filterStatusOptions={{
          title: 'Status',
          key: 'status',
          type: 'checkbox',
          multiple: false,
          searchFilter: false,
          options: [
            {
              label: 'Approved',
              value: 'approved',
            },
            {
              label: 'Submitted',
              value: 'submitted',
            },
          ],
        }}
        visible={modals.filter}
        onChange={handleOnFilterChange}
        onClose={() => setModals({ ...modals, filter: false })}
      />
    </MainDashboardLayoutComponent>
  );
};
