import { Tooltip } from 'flowbite-react';

export const renderPercentageSummary = (value, renderBehavior) => {
  if (+value < 0) {
    return (
      <>
        <span class={renderBehavior.downIcon}></span>

        <div class={renderBehavior.classNameOnValueDown}>{`Menurun ${value}%`}</div>
      </>
    );
  }
  if (+value === 0) {
    return (
      <>
        <div class='text-gray pl-2 text-sm font-medium'>{`${value}%`}</div>
      </>
    );
  }

  return (
    <>
      <span class={renderBehavior.upIcon}></span>

      <div class={renderBehavior.classNameOnValueUp}>{`Meningkat ${value}%`}</div>
    </>
  );
};

export function AnalyticsSummaryCardInfo({
  title,
  tooltipText,
  containerClassName = 'w-[49.5%]',
  percentageSummaryValue,
  currentPeriodeValue,
  currentPeriodeDate,
  previousPeriodeValue,
  previousPeriodeDate,
  classNameOnValueDown = 'text-red-1 pl-2 text-sm font-medium',
  classNameOnValueUp = 'text-green-3 pl-2 text-sm font-medium',
  classNameIconOnValueDown = 'icon-arrow-down text-red-1 text-xl',
  classNameIconOnValueUp = 'icon-arrow-up text-green-3 text-xl',
}) {
  return (
    <div class={containerClassName}>
      <div class='border border-solid border-gray-1'>
        <div class='bg-green-status flex items-center py-1 px-2'>
          <div class='flex-auto text-center'>
            <div class='text-sm font-bold text-green'>{title}</div>
          </div>
          <Tooltip className='max-w-[17%] text-center' content={tooltipText}>
            <button class='p-0 outline-none ml-2 text-green' type='button'>
              <span class='icon-ico-help'></span>
            </button>
          </Tooltip>
        </div>

        <div class='flex items-center px-2 py-3'>
          <div class='w-2/6 px-3'>
            <div class='flex items-center xl:justify-center'>
              {renderPercentageSummary(percentageSummaryValue, {
                classNameOnValueDown,
                classNameOnValueUp,
                upIcon: classNameIconOnValueUp,
                downIcon: classNameIconOnValueDown,
              })}
            </div>
          </div>
          <div class='w-2/6 border-l border-r border-solid border-gray-1 px-3 xl:px-6'>
            <div class='text-sm font-semibold text-dark mb-1 whitespace-nowrap text-ellipsis overflow-hidden'>
              Periode Saat Ini
            </div>
            <Tooltip content={currentPeriodeValue}>
              <div class='md:text-base lg:text-base text-green font-bold mb-1  whitespace-nowrap text-ellipsis overflow-hidden'>
                {currentPeriodeValue}
              </div>
            </Tooltip>
            <div class='text-xs font-normal text-gray'> {currentPeriodeDate}</div>
          </div>
          <div class='w-2/6 px-3 xl:px-6'>
            <div class='text-sm font-semibold text-dark mb-1 whitespace-nowrap text-ellipsis overflow-hidden'>
              Periode Sebelumnya
            </div>
            <div class='md:text-base lg:text-base text-green font-bold mb-1  whitespace-nowrap text-ellipsis overflow-hidden'>
              {previousPeriodeValue}
            </div>
            <div class='text-xs font-normal text-gray'>{previousPeriodeDate}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
