import * as Yup from 'yup';

/**
 * Form validation schema for /settings/mobile-app-version
 */

export const MobieAppVersionValidationSchema = Yup.object().shape({
  ios: Yup.string()
    .required('iOS version is required')
    .matches(/^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)$/, 'Invalid Semantic Version'),
  android: Yup.string()
    .required('Android version is required')
    .matches(/^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)$/, 'Invalid Semantic Version'),
});
