import React, { useEffect, useState } from 'react';

export function TabsComponent({ value, children, onChange, disabled = false }) {
  const [activeTabValue, setActiveTabValue] = useState(value || '');

  useEffect(() => {
    setActiveTabValue(value);
  }, [value]);

  const handlTabChange = (value) => {
    if (!disabled) {
      setActiveTabValue(value);
      onChange && onChange(value);
    }
  };

  return (
    <ul
      class='nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 px-8'
      id='tabs-tabFill'
      role='tablist'
    >
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          isActive: child.props.value === activeTabValue,
          onClick: handlTabChange,
        });
      })}
    </ul>
  );
}
