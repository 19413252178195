import moment from 'moment';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useEffect, useState, useRef } from 'react';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { InputComponent } from 'components/form/input.component';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { useNavigate, useParams } from 'react-router-dom';
import { UPLOAD_IMAGE_CONSTANT, useUploadSingleImage } from 'api/upload/upload-single-image.api';
import { CreateProductValidationSchema } from 'config/form/product/product-validation.schema';
import { ALL_TOOLBAR_OPTIONS, TextEditorComponent } from 'components/form/text-editor';
import { useEditProduct } from 'api/product/product-create/product-create.mutation';
import { useProductDetails } from 'api/product/product-list/product-list.query.api';
import { BounceLoading } from 'components/loader/bounce.loading';
import { PATH_CONSTANT } from 'config/path.constant';
import { catchErrorMessage } from 'ui-utils/string.utils';

const DEFAULT_IMAGE = '/img/default-product.jpg';
const BREAD_CUMB_ITEMS = [
  {
    title: 'Product',
    path: '/product/create',
  },
  {
    title: 'Edit Product',
    path: '/product/create',
  },
];

const formInitialValue = {
  product_image: null,
  name: '',
  gs_one_barcode: '',
  sku_code: '',
  price: '',
  variants: [],
  description: '',
  ingredients: '',
  how_to_use: '',
  used_by: '',
};

const minDate = new Date().toISOString().split('T')[0].split('-');
const minMonthDate = `${minDate[0]}-${minDate[1]}`;

export const ProdcutEditComponent = () => {
  const formikRef = useRef();
  const { productId } = useParams();
  const { data: productDetails, isLoading, isError, error } = useProductDetails(productId);
  const navigate = useNavigate();

  const { mutateAsync: editProduct, isLoading: isCreating } = useEditProduct();
  const [variantExpiredDates, setVariantExpiredDates] = useState([]);

  const { mutateAsync: uploadProductImage, isLoading: isUploading } = useUploadSingleImage({
    type: UPLOAD_IMAGE_CONSTANT.TYPE.COMPRESSED,
    path: UPLOAD_IMAGE_CONSTANT.DIRECTORY_PATH.PRODUCT_IMAGE,
  });

  const [selectedFileBuffer, setSelectedFileBuffer] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(DEFAULT_IMAGE);

  useEffect(() => {
    if (productDetails) {
      Object.keys(formInitialValue).forEach((key) => {
        if (key === 'variants') {
          formikRef.current.setFieldValue(
            key,
            productDetails?.data?.[key]?.map((variant) => variant.expired_date)
          );
        } else if (key === 'price') {
          formikRef.current.setFieldValue(key, new Intl.NumberFormat('id-ID').format(productDetails?.data?.[key]));
        } else {
          formikRef.current.setFieldValue(key, productDetails?.data?.[key]);
        }
      });
      setImagePreviewUrl(productDetails?.data?.product_image || '/img/default-product.jpg');
      setVariantExpiredDates(productDetails?.data?.variants.map((variant) => variant.expired_date));
    }
  }, [productDetails]);

  const handleOnFormSubmit = async (formValues) => {
    try {
      let productPictureURL = null;
      if (selectedFileBuffer) {
        const formData = new FormData();
        formData.append('image', selectedFileBuffer);
        const response = await uploadProductImage(formData);
        productPictureURL = response?.data?.url;
      }

      const payload = {
        ...formValues,
        product_image: selectedFileBuffer ? productPictureURL : formValues.product_image,
        price: formValues.price.replaceAll('.', ''),
      };
      await editProduct({ productId, ...payload });
      toast.success('Sucessfully updated product!', {
        autoClose: 2000,
      });
      navigate(PATH_CONSTANT.PRODUCT.PRODUCT_LIST);
    } catch (error) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleOnUploadFileChange = (e) => {
    //chedck file size with maximum 5mb and type, only accept image
    if (e.target.files[0].size > 5000000) {
      toast('File size is too large. Maximum 5mb is allowed');
      return;
    }
    if (!e.target.files[0].type.includes('image')) {
      toast('Only image file is allowed');
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setSelectedFileBuffer(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const isDisabledAllForm = isCreating || isUploading;

  return (
    <MainDashboardLayoutComponent
      breadCumbs={BREAD_CUMB_ITEMS}
      isError={isError}
      errorTitle={error?.response?.data?.status}
      erroMessage={catchErrorMessage(error?.response?.data?.message)}
    >
      {isLoading ? (
        <div className='h-[70vh] flex w-full items-center text-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <Formik
          innerRef={formikRef}
          initialValues={formInitialValue}
          onSubmit={handleOnFormSubmit}
          validationSchema={CreateProductValidationSchema}
        >
          {({ errors, values, handleChange, handleBlur, handleSubmit, touched, setFieldValue }) => {
            return (
              <>
                <div className='flex justify-between mb-8'>
                  <div class='w-[400px] flex justify-center'>
                    <div class='w-auto'>
                      <img
                        src={imagePreviewUrl}
                        class='w-[400px] h-[400px] overflow-hidden object-cover object-center'
                        alt='Preview'
                      />
                      <div class='mt-7 flex justify-center'>
                        <input
                          type='file'
                          id='upload'
                          class='hidden'
                          onChange={handleOnUploadFileChange}
                          accept='image/*'
                        />
                        <label
                          for='upload'
                          class='bg-green w-28 inline-block text-center hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-3 cursor-pointer'
                        >
                          <span class='icon-ico-upload mr-2'></span>
                          <span class='text-sm'>Upload</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class='w-1/2 xl:w-[60%]'>
                    <InputComponent
                      disabled={isDisabledAllForm}
                      name='name'
                      value={values.name}
                      error={touched.name && errors.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label='Nama Produk'
                      placeholder='Input Nama Produk'
                    />
                    <InputComponent
                      disabled={isDisabledAllForm}
                      name='gs_one_barcode'
                      value={values.gs_one_barcode}
                      error={touched.gs_one_barcode && errors.gs_one_barcode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label='GS1 Barcode'
                      placeholder='Input Barcode'
                    />
                    <InputComponent
                      disabled={isDisabledAllForm}
                      name='sku_code'
                      value={values.sku_code}
                      error={touched.sku_code && errors.sku_code}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label='Kode SKU'
                      placeholder='Input SKU'
                    />

                    <InputComponent
                      disabled={isDisabledAllForm}
                      numberOnly={true}
                      name='price'
                      value={values.price}
                      error={touched.price && errors.price}
                      onChange={(e) => {
                        const { value } = e.target;
                        //format to amount
                        const formattedValue = new Intl.NumberFormat('id-ID').format(value);
                        setFieldValue('price', formattedValue);
                      }}
                      onBlur={handleBlur}
                      containerClass='form-input relative mb-3'
                      label='Harga Produk'
                      prefix='Rp'
                      inputClass='w-full border rounded-sm outline-none border-solid border-gray-1 py-2.5 pl-16 pr-4 bg-white text-sm text-dark placeholder:text-gray-2 focus:border-green focus:text-green focus:bg-gray-focus transition duration-100 ease-in-out'
                      type='tel'
                      placeholder='Input Harga Product'
                    />
                    <InputComponent
                      disabled={isDisabledAllForm}
                      name='variants'
                      type='month'
                      excludeDates={variantExpiredDates.map((item) => new Date(item))}
                      min={minMonthDate}
                      error={touched.variants && errors.variants}
                      onChange={(e) => {
                        if (values.variants.includes(e.target.value)) return;
                        setFieldValue('variants', [...values.variants, e.target.value]);
                        setVariantExpiredDates([...variantExpiredDates, e.target.value]);
                      }}
                      onBlur={handleBlur}
                      label='Expired Date'
                      placeholder='Expired Date'
                    />
                    <div class='relative mt-[-1px] mb-3'>
                      {variantExpiredDates.length > 0 ? (
                        <div class='w-full min-h-[150px] border border-solid border-gray-1 rounded-sm p-3'>
                          <ul class='flex flex-wrap'>
                            {variantExpiredDates.map((item, index) => {
                              return (
                                <li
                                  key={item}
                                  class='px-3 mb-2 py-2 text-sm flex items-center bg-gray-3 text-green font-semibold mr-2'
                                >
                                  {moment(item).format('MMMM YYYY')}
                                  <button
                                    class='text-red-1 outline-none text-lg ml-2'
                                    onClick={() => {
                                      setFieldValue(
                                        'variants',
                                        variantExpiredDates.filter((i) => i !== item)
                                      );
                                      setVariantExpiredDates(variantExpiredDates.filter((i) => i !== item));
                                    }}
                                  >
                                    <span class='icon-ico-close-circle'></span>
                                  </button>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      ) : (
                        <div class='w-full min-h-[150px] border border-solid border-gray-1 rounded-sm p-3  flex justify-center items-center'>
                          <div class='text-sm text-dark'>Tidak Ada Data Terpilih</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div class='flex border-t border-solid border-gray-1'>
                  <div class='w-1/2 py-6 pr-6'>
                    <div class='mb-7'>
                      <div class='text-sm block text-dark font-semibold mb-2'>Deskripsi Produk</div>

                      <TextEditorComponent
                        toolbarOptions={ALL_TOOLBAR_OPTIONS}
                        initialValue={values.description}
                        onChange={(nodeValue) => setFieldValue('description', nodeValue)}
                      />
                    </div>
                    <div class='mb-7'>
                      <div class='text-sm block text-dark font-semibold mb-2'>Kandungan Produk</div>
                      <TextEditorComponent
                        toolbarOptions={ALL_TOOLBAR_OPTIONS}
                        initialValue={values.ingredients}
                        onChange={(nodeValue) => setFieldValue('ingredients', nodeValue)}
                      />
                    </div>
                  </div>
                  <div class='w-1/2 pl-6 py-6'>
                    <div class='mb-7'>
                      <div class='text-sm block text-dark font-semibold mb-2'>Cara Penggunaan</div>
                      <TextEditorComponent
                        toolbarOptions={ALL_TOOLBAR_OPTIONS}
                        initialValue={values.how_to_use}
                        onChange={(nodeValue) => setFieldValue('how_to_use', nodeValue)}
                      />
                    </div>
                    <div class='mb-7'>
                      <div class='text-sm block text-dark font-semibold mb-2'>Dapat Digunakan Untuk Siapa ?</div>
                      <TextEditorComponent
                        toolbarOptions={ALL_TOOLBAR_OPTIONS}
                        initialValue={values.used_by}
                        onChange={(nodeValue) => setFieldValue('used_by', nodeValue)}
                      />
                    </div>
                  </div>
                </div>
                <div class='py-14 relative flex justify-center'>
                  <ButtonSubmit
                    loading={isCreating | isUploading}
                    disabled={!CreateProductValidationSchema.isValidSync(values)}
                    onClick={handleSubmit}
                    className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
                  >
                    <span class='icon-ico-apply mr-3'></span> Update Product
                  </ButtonSubmit>
                </div>
              </>
            );
          }}
        </Formik>
      )}
    </MainDashboardLayoutComponent>
  );
};
