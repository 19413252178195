import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const SETTING_MOBILE_APP_VERSION = {
  MOBILE_VERSION_DETAILS: 'MOBILE_VERSION_DETAILS',
};

export function useGetMobileAppVersion() {
  return useQuery([SETTING_MOBILE_APP_VERSION.MOBILE_VERSION_DETAILS], () =>
    axios.get(`api/v1/admin/app-version`).then((res) => res.data)
  );
}
