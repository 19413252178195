import qs from 'query-string';
import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const AUDIT_QUERY_KEY = {
  AUDIT_STOCK_KEY: 'AUDIT_STOCK_KEY',
};

export const AUDIT_STOCK_TYPE = {
  SALES_REPORT: 'sales-report',
  STOCK_TRANSFER: 'stock-transfer',
  PRE_ORDER: 'pre-order',
  RETURN_PRODUCT: 'return-product',
};
export function useStockAuditData({ recordId, type }) {
  return useQuery(
    [AUDIT_QUERY_KEY.AUDIT_STOCK_KEY, recordId],
    () => axios.get(`/api/v1/admin/audit-stock/report/${type}/${recordId}?orderType=desc`).then((res) => res.data),
    {
      enabled: !!recordId && !!type,
    }
  );
}
