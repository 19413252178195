export const STOCK_MOVEMENT_STATUS_COLOR_CLASSNAME = {
  APPROVED: 'bg-green-status text-green-3',
  SUBMITTED: 'bg-yellow-light text-yellow',
  WAITING_FOR_PAYMENT: 'bg-blue-light text-blue',
  PACKING: 'bg-violet-light text-violet',
  DELIVERY: 'bg-purple-light text-purple',
  VALIDATE: 'bg-maroon-light text-maroon',
  PACKED: 'bg-brown-light text-brown',
  COMPLETED: 'bg-complete-light text-gray',
  PICKING: 'bg-olive-light text-olive',
  PICKED: 'bg-olive-light text-olive',
};

export const STOCK_MOVEMENT_STATUS_TEXT_LAGEL = {
  APPROVED: 'Approved',
  SUBMITTED: 'Submitted',
  WAITING_FOR_PAYMENT: 'Waiting for Payment',
  PICKED: 'Picked',
  PACKING: 'Packing',
  DELIVERY: 'Delivery',
  VALIDATE: 'Validate',
  PACKED: 'Packed',
  COMPLETED: 'Completed',
  PICKING: 'Picking',
};

export const RETURN_PRODUCT_STATUS = {
  SUBMITTED: 'submitted',
  READY_TO_PROCESS: 'ready_to_process',
  PACKING: 'packing',
  DELIVERY: 'delivery',
  COMPLETE: 'completed',
  APPROVED: 'approved',
};

export const RETURN_PRODUCT_STATUS_COLOR_CLASSNAME = {
  [RETURN_PRODUCT_STATUS.SUBMITTED]: 'bg-yellow-light text-yellow ',
  [RETURN_PRODUCT_STATUS.READY_TO_PROCESS]: ' bg-green-status text-green  ',
  [RETURN_PRODUCT_STATUS.PACKING]: ' bg-violet-light text-violet',
  [RETURN_PRODUCT_STATUS.DELIVERY]: ' bg-purple-light text-purple',
  [RETURN_PRODUCT_STATUS.COMPLETE]: ' bg-complete-light text-gray',
  [RETURN_PRODUCT_STATUS.APPROVED]: ' bg-blue-light text-blue',
};

export const RETURN_PRODUCT_DETAILS_STATUS_COLOR_CLASSNAME = {
  [RETURN_PRODUCT_STATUS.SUBMITTED]: 'bg-yellow text-white',
  [RETURN_PRODUCT_STATUS.READY_TO_PROCESS]: 'bg-green text-white',
  [RETURN_PRODUCT_STATUS.PACKING]: 'bg-violet text-white',
  [RETURN_PRODUCT_STATUS.DELIVERY]: 'bg-purple text-white',
  [RETURN_PRODUCT_STATUS.COMPLETE]: 'bg-complete text-white',
  [RETURN_PRODUCT_STATUS.APPROVED]: 'bg-blue text-white',
};

export const RETURN_PRODUCT_STATUS_LABEL = {
  [RETURN_PRODUCT_STATUS.SUBMITTED]: 'Submitted',
  [RETURN_PRODUCT_STATUS.READY_TO_PROCESS]: 'Ready to Process',
  [RETURN_PRODUCT_STATUS.PACKING]: 'Packing',
  [RETURN_PRODUCT_STATUS.DELIVERY]: 'Delivery',
  [RETURN_PRODUCT_STATUS.COMPLETE]: 'Completed',
  [RETURN_PRODUCT_STATUS.APPROVED]: 'Approved',
};
