import qs from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { capitalize } from 'lodash';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { TableSearchInputComponent } from 'components/table/table-search.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { TableButtonLimitComponent } from 'components/table/table-button-limit.component';
import { TableButtonSortComponent } from 'components/table/table-button-sort.component';
import { TableButtonFilterComponent } from 'components/table/table-button-filter.component';
import { BasicTableComponent } from 'components/table/table-basic.component';
import { Pagination } from 'components/table/table-pagination.component';
import { EmptyStateComponent } from 'components/empty-data';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ModalTableSortComponent } from 'components/modal/modal-table-sort.component';
import { ModalTableLimitComponent } from 'components/modal/modal-table-limit.component';
import { ModalTableFilter } from 'components/modal/modal-table-filter.component';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { setToolsReducer } from 'reducers/tools.reducer';
import { PATH_CONSTANT } from 'config/path.constant';
import { catchErrorMessage } from 'ui-utils/string.utils';
import { isAllPropertyObjectFalsy } from 'ui-utils/object.utils';
import { useReturnProductList } from 'api/return-product/return-product.query.api';
import { HighlightedText } from 'components/highlighted-text';
import { useExportReturnProductList } from 'api/return-product/return-produict.mutation.api';
import {
  RETURN_PRODUCT_STATUS,
  RETURN_PRODUCT_STATUS_COLOR_CLASSNAME,
} from 'pages/stock-movement/pre-order/status-color.util';
import clsx from 'clsx';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Return Product',
    path: PATH_CONSTANT.RETURN_PRODUCT.LIST,
  },
  {
    title: 'List Return Product',
    path: PATH_CONSTANT.RETURN_PRODUCT.LIST,
  },
];

const INITIAL_VALUE_FILTER_BODY = {
  status: [],
  store_ids: [],
};

const SORT_OPTIONS = [
  { label: 'Tanggal buat - Terbaru', sortType: 'desc', sortBy: 'created_at' },
  { label: 'Tanggal buat - Terdahulu', sortType: 'asc', sortBy: 'created_at' },
];

const FILTER_STATUS_OPTIONS = {
  title: 'Status',
  key: 'status',
  type: 'checkbox',
  multiple: true,
  searchFilter: false,
  options: [
    {
      label: 'Submitted',
      value: RETURN_PRODUCT_STATUS.SUBMITTED,
    },
    {
      label: 'Approved',
      value: RETURN_PRODUCT_STATUS.APPROVED,
    },
    {
      label: 'Packing',
      value: RETURN_PRODUCT_STATUS.PACKING,
    },
    {
      label: 'Delivery',
      value: RETURN_PRODUCT_STATUS.DELIVERY,
    },
    {
      label: 'Complete',
      value: RETURN_PRODUCT_STATUS.COMPLETE,
    },
  ],
};

export const ReturnProductListComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { selectedRowKeys: selectedIds } = useSelector((state) => state.tools);

  const INITIAL_VALUE_FILTER_QUERY = {
    limit: searchParams.get('limit')?.replace(/[^0-9]/g, '') || 20,
    page: searchParams.get('page')?.replace(/[^0-9]/g, '') || 1,
    orderBy: searchParams.get('orderBy')?.replace(/[^a-zA-Z_]/g, '') || 'created_at',
    orderType: searchParams.get('orderType')?.replace(/[^a-zA-Z]/g, '') || 'desc',
    search: searchParams.get('search')?.replace(/[^a-zA-Z0-9 ]/g, '') || undefined,
  };

  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);
  const [bodyFilter, setBodyFilter] = useState(INITIAL_VALUE_FILTER_BODY);
  const [modals, setModals] = useState({
    sort: false,
    limit: false,
    filter: false,
    export: false,
    updateStatus: false,
    result: false,
  });
  const { mutate: exportReturnProduct, isLoading: isExporting } = useExportReturnProductList();

  const {
    data: returnProductList,
    isLoading,
    isError,
    error,
  } = useReturnProductList({
    filter: queryFilter,
    body: bodyFilter,
  });

  const columns = useMemo(
    () => [
      {
        type: 'checkbox',
        isRightBorderAvailable: true,
        render: (record) => {
          return (
            <div className='form-check px-2'>
              <input
                className='form-check-input appearance-none h-4 w-4 border border-green rounded-sm bg-white checked:bg-green checked:border-green focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                type='checkbox'
                readOnly
              />
            </div>
          );
        },
      },
      {
        name: 'BA Name',
        className: 'w-[25%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record) => (
          <div className='w-[25%] text-grey-70 text-sm font-medium'>
            <div class='flex items-center pl-2 xl:pl-5'>
              <div class='w-12'>
                <StandartImageComponent
                  src={record?.user_image || '/img/default-user.jpg'}
                  class='w-12 h-12 object-cover'
                  alt='User'
                />
              </div>

              <div class='w-48 pl-3 xl:pl-5'>
                <div class='overflow-hidden block text-ellipsis whitespace-nowrap text-sm font-semibold text-dark mb-1'>
                  <HighlightedText text={record?.user_name} highlight={queryFilter.search} />
                </div>
                <div class='text-sm text-gray mb-1 overflow-hidden block text-ellipsis whitespace-nowrap'>
                  <HighlightedText text={record?.user_email} highlight={queryFilter.search} />
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: 'Store',
        className: 'w-[15%] text-sm font-semibold text-green',
        dataIndex: 'report_date_formatted',
        render: (record, report_date_formatted) => (
          <div class='w-[15%]'>
            <div class='text-sm font-bold  text-green mb-1'>
              <HighlightedText text={record?.store_name} highlight={queryFilter.search} />
            </div>
            <div class='text-sm italic text-gray'>
              <HighlightedText text={record?.city_name} highlight={queryFilter.search} />
            </div>
          </div>
        ),
      },

      {
        name: 'Tanggal Request',
        className: 'w-[17%] text-sm font-semibold text-green overflow-hidden text-ellipsis whitespace-nowrap pl-7',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[17%] pl-7'>
            <div class='text-sm font-semibold text-gray'>
              <HighlightedText text={record?.request_date} highlight={queryFilter.search} />
            </div>
          </div>
        ),
      },
      {
        name: 'Product Qty',
        className: 'w-[10%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record, total_qty_sold) => (
          <div class='w-[10%]'>
            <div class='text-sm font-medium text-center text-gray'>
              <HighlightedText text={`${record?.total_product} Unit`} highlight={queryFilter.search} />
            </div>
          </div>
        ),
      },

      {
        name: 'Status',
        className: 'w-[15%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[15%] flex justify-center'>
            <div
              class={clsx(
                'inline-block  text-center w-28 py-1 px-1 rounded-sm text-sm font-bold',
                RETURN_PRODUCT_STATUS_COLOR_CLASSNAME[record?.status]
              )}
            >
              {capitalize(record?.status)}
            </div>
          </div>
        ),
      },
      {
        name: '',
        className: 'w-[15%] text-sm font-semibold text-green',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[15%] flex justify-center'>
            <Link
              to={PATH_CONSTANT.RETURN_PRODUCT.DETAILS.replace(':recordId', record?.id)}
              class='w-48 text-center text-ellipsis line-clamp-2 whitespace-normal text-sm font-semibold text-green mb-1'
            >
              LIHAT DETAIL
            </Link>
          </div>
        ),
      },
    ],
    [queryFilter]
  );

  const isFilterActive = !isAllPropertyObjectFalsy(bodyFilter);

  const resetCheckList = () => {
    if (selectedIds.length > 0) {
      dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
    }
  };

  const handleExport = () => {
    exportReturnProduct(
      {
        queryFilter: queryFilter,
        bodyFilter: { ...bodyFilter, return_product_ids: selectedIds },
      },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  const handleOnSearchData = (value) => {
    setQueryFilter({ ...queryFilter, search: value.keyword, page: 1 });
    navigate({
      search: qs.stringify({
        ...queryFilter,
        search: value.keyword || undefined,
        page: 1,
      }),
    });
  };

  const handleOnPageDataChange = (page) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setQueryFilter({ ...queryFilter, page });
    navigate({
      search: qs.stringify({
        ...queryFilter,
        page,
      }),
    });
  };

  const handleOnSortDataChange = ({ selected }) => {
    setQueryFilter((prevState) => ({
      ...prevState,
      orderBy: selected.sortBy,
      orderType: selected.sortType,
    }));
    navigate({
      search: qs.stringify({
        ...queryFilter,
        orderBy: selected.sortBy,
        orderType: selected.sortType,
      }),
    });
    setModals({ ...modals, sort: false });
  };

  const handleOnLimitDataChange = ({ limit }) => {
    setQueryFilter((prevState) => ({
      ...prevState,
      limit,
      page: 1,
    }));
    navigate({
      search: qs.stringify({
        ...queryFilter,
        limit,
        page: 1,
      }),
    });
    setModals({ ...modals, limit: false });
  };

  const handleOnFilterDataChange = (selectedFilter) => {
    setBodyFilter(selectedFilter);
    setQueryFilter((prevState) => ({
      ...prevState,
      page: 1,
    }));

    navigate({
      search: qs.stringify({
        ...queryFilter,
        page: 1,
      }),
    });

    setModals({ ...modals, filter: false });
  };

  useEffect(() => {
    resetCheckList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnProductList]);

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      <div className='flex items-center mb-5'>
        <TableSearchInputComponent
          containerClassName='relative w-[30%] mr-4'
          placeholder='Cari user atau store...'
          initialValue={INITIAL_VALUE_FILTER_QUERY.search}
          inputProps={{
            disabled: isLoading,
          }}
          onSearch={handleOnSearchData}
        />

        <div className='flex justify-between items-center gap-x-2'>
          <TableButtonSortComponent
            disabled={isLoading}
            onClick={() => {
              setModals((prev) => ({ ...prev, sort: true }));
            }}
          />
          <TableButtonFilterComponent
            disabled={isLoading}
            active={isFilterActive}
            onClick={() => {
              setModals((prev) => ({ ...prev, filter: true }));
            }}
          />
          <TableButtonLimitComponent
            disabled={isLoading}
            value={queryFilter.limit}
            onClick={() => setModals({ ...modals, limit: true })}
          />

          <TableButtonExport
            disabled={isLoading}
            loading={isExporting}
            onClick={() => {
              setModals({ ...modals, export: true });
            }}
          />
        </div>
      </div>

      {isLoading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          <div className='italic text-dark text-sm mb-5'>{`Total ${returnProductList?.data?.total_result} data`}</div>

          <BasicTableComponent
            rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
            columns={columns}
            dataSource={returnProductList?.data?.rows || []}
            loading={isLoading}
            error={isError}
            errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
            errorMessage={catchErrorMessage(error?.response?.data?.message)}
          />

          {returnProductList?.data?.total_result === 0 && (
            <EmptyStateComponent
              message={queryFilter.search ? `No result for query "${queryFilter.search}"` : 'No data available'}
            />
          )}

          <Pagination
            currentPage={queryFilter.page}
            pageSize={queryFilter.limit}
            siblingCount={1}
            totalCount={returnProductList?.data?.total_result || 0}
            onPageChange={handleOnPageDataChange}
          />
        </>
      )}
      <ModalTableSortComponent
        options={SORT_OPTIONS}
        initialValue={SORT_OPTIONS.find(
          (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
        )}
        onChange={handleOnSortDataChange}
        visible={modals.sort}
        onClose={() => setModals({ ...modals, sort: false })}
      />

      <ModalTableLimitComponent
        onChange={handleOnLimitDataChange}
        initialValue={queryFilter.limit}
        visible={modals.limit}
        onClose={() => setModals({ ...modals, limit: false })}
      />
      <ModalConfirmation
        description='Apakah anda yakin ingin eksport data yang anda pilih?'
        title='Export Data'
        visible={modals.export}
        loading={isExporting}
        onConfirm={handleExport}
        onClose={() => setModals({ ...modals, export: false })}
      />

      <ModalTableFilter
        initialValue={bodyFilter}
        filterStatusOptions={FILTER_STATUS_OPTIONS}
        visible={modals.filter}
        onChange={handleOnFilterDataChange}
        onClose={() => setModals({ ...modals, filter: false })}
      />
    </MainDashboardLayoutComponent>
  );
};
