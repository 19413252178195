import axios from 'api/api.config';
import { useMutation, useQueryClient } from 'react-query';
import { SETTING_MOBILE_APP_VERSION } from './mobile-app-version.query.api';

export function useUpdateMobileAppVersion() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, latest_version }) =>
      axios.put(`/api/v1/admin/app-version/update/${id}`, { latest_version }).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SETTING_MOBILE_APP_VERSION.MOBILE_VERSION_DETAILS]);
      },
    }
  );
}
