import * as Yup from 'yup';

/**
 * Form validation schema for
 */

export const CreateSkilDevelopmentValidationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  type: Yup.string().required('Type is required'),
  start_date: Yup.string().required('Start Date is required'),
  start_time: Yup.string().required('Start Time is required'),
  end_date: Yup.string().required('End Date is required'),
  end_time: Yup.string().required('End Time is required'),
  speakers: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Speaker Name is required'),
        position: Yup.string().required('Speaker Position is required'),
        file: Yup.mixed().when('profile_picture', {
          is: (val) => !val || val.trim() === '',
          then: Yup.mixed().required('Speaker Image is required'),
          otherwise: Yup.mixed(),
        }),
        profile_picture: Yup.string().nullable(),
      })
    )
    .required('At least one speaker must be entered'),
});
