import clsx from 'clsx';

export function ModalContainer({ children, visible }) {
  return (
    <div
      class={clsx(
        'fixed z-[1000] bg-[rgba(0,0,0,0.4)] top-0 bottom-0 left-0 right-0 w-full h-full flex justify-center items-center',
        !visible && 'opacity-0 pointer-events-none bg-transparent',
        visible && 'opacity-100 pointer-events-auto bg-[rgba(0,0,0,0.6)]',
        'transtiion-all duration-300 '
      )}
    >
      {children}
    </div>
  );
}
