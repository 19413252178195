import axios from 'api/api.config';
import qs from 'query-string';
import { useMutation, useQueryClient } from 'react-query';
import { CHANGE_SCHEDULE_REQUEST_QUERY_KEY } from './change-schedule.query.api';

export function useUpdateScheduleRequests() {
  const queryClient = useQueryClient();
  return useMutation(
    (payload) => axios.post(`/api/v1/admin/schedule/request/approval`, payload).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CHANGE_SCHEDULE_REQUEST_QUERY_KEY.CHANGE_SCHEDULE_REQUEST_DETAILS]);
        queryClient.removeQueries([CHANGE_SCHEDULE_REQUEST_QUERY_KEY.CHANGE_SCHEDULE_REQUEST_LIST]);
      },
    }
  );
}

export function useExportScheduleRequestsList() {
  return useMutation(({ queryFilter, bodyFilter }) =>
    axios.post(`/api/v1/admin/schedule/request/export?${qs.stringify(queryFilter)}`, bodyFilter).then((res) => res.data)
  );
}
