import clsx from 'clsx';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import { useAllUserList } from 'api/users/user-list/user-list.query.api';
import { HighlightedText } from 'components/highlighted-text';
import { TableSearchInputComponent } from 'components/table/table-search.component';
import { useState, useMemo, useEffect } from 'react';
import { StandartImageComponent } from 'components/image/standart-image.component';

function ButtonRemoveItem({ onClick = () => {} }) {
  return (
    <button class='text-red-1 outline-none text-lg mr-3' onClick={onClick}>
      <span class='icon-ico-close-circle'></span>
    </button>
  );
}

function ButtonAddItem({ onClick = () => {} }) {
  return (
    <button class='text-green-3 outline-none text-lg mr-3' onClick={onClick}>
      <span class='icon-ico-plus-circle'></span>
    </button>
  );
}

export function SelectShareUserComponent({ selectedUserIds = [], onSelectUser = () => {} }) {
  const { data: users, isLoading } = useAllUserList();

  const [searchKeyword, setSearchKeyword] = useState('');

  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    if (selectedUserIds.length > 0) {
      setSelectedIds(selectedUserIds);
    }
  }, [selectedUserIds]);

  useEffect(() => {
    onSelectUser(selectedIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds]);

  const userOptions = useMemo(() => {
    if (!users) return [];
    if (!searchKeyword) return users.data;
    return _.sortBy(matchSorter(users.data, searchKeyword, { keys: ['name', 'store_name'] }), 'name');
  }, [users, searchKeyword]);

  const handleAddUser = (userId) => {
    setSelectedIds((prev) => [...prev, userId]);
  };

  const handleRemoveUser = (userId) => {
    setSelectedIds((prev) => prev.filter((id) => id !== userId));
  };

  return (
    <div>
      <TableSearchInputComponent
        containerClassName='w-full relative mr-2'
        disabled={isLoading}
        onSearch={(value) => {
          setSearchKeyword(value.keyword);
        }}
      />
      {isLoading ? (
        <div className='mt-6 text-center text-sm text-gray-2 italic'>Loading users...</div>
      ) : (
        <>
          <div className='mt-2 max-h-[300px] scroll'>
            {userOptions.map((userItem) => (
              <div
                className={clsx('flex items-center justify-between border border-gray-1 px-2 py-2 my-2', {
                  'border-green-3': selectedIds.includes(userItem.id),
                })}
                key={userItem.name}
              >
                <div className='flex items-center'>
                  <StandartImageComponent
                    src={userItem?.profile_picture || undefined}
                    alt='user'
                    className='w-10 h-10 mr-3 object-cover'
                  />
                  <div className='flex flex-col'>
                    <p className='text-sm font-semibold'>
                      <HighlightedText text={userItem.name} highlight={searchKeyword} />
                    </p>
                    <p className='text-xs text-gray-2'>
                      <HighlightedText text={userItem.store_name} highlight={searchKeyword} />
                    </p>
                  </div>
                </div>
                <div className='flex items-center'>
                  {selectedIds.includes(userItem.id) ? (
                    <ButtonRemoveItem onClick={() => handleRemoveUser(userItem?.id)} />
                  ) : (
                    <ButtonAddItem onClick={() => handleAddUser(userItem?.id)} />
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
