import { useMemo, useState } from 'react';
import { BasicTableComponent } from 'components/table/table-basic.component';
import { TableButtonSortComponent } from 'components/table/table-button-sort.component';
import { TableSearchInputComponent } from 'components/table/table-search.component';
import { ModalTableSortComponent } from 'components/modal/modal-table-sort.component';
import { catchErrorMessage } from 'ui-utils/string.utils';
import { EmptyStateComponent } from 'components/empty-data';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { Pagination } from 'components/table/table-pagination.component';
import { useNavigate } from 'react-router-dom';
import { useReportBAVisitorList } from 'api/report-ba/visitor/visitor.query.api';
import { HighlightedText } from 'components/highlighted-text';

const SORT_OPTIONS = [
  { label: 'Urutkan Nama BA A-Z', sortType: 'asc', sortBy: 'user_name' },
  { label: 'Urutkan Nama BA Z-A', sortType: 'desc', sortBy: 'user_name' },
  { label: 'Tanggal Report Terbaru - Terdahulu', sortType: 'desc', sortBy: 'report_date' },
  { label: 'Tanggal Report Terdahulu - Terbaru', sortType: 'asc', sortBy: 'report_date' },
];

export function AnalyticVisitorTable({ bodyFilter, dateFilter, queryFilter, setQueryFilter }) {
  const navigate = useNavigate();
  const [modals, setModals] = useState({
    sort: false,
  });

  const { data, isLoading, isError, error } = useReportBAVisitorList({
    body: bodyFilter,
    filter: {
      ...queryFilter,
      ...dateFilter,
    },
  });

  const columns = useMemo(
    () => [
      {
        name: 'BA Name',
        className: 'w-[35%] text-sm font-semibold text-green pl-6',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[35%] text-sm font-semibold text-green pl-6'>
            <div class='flex items-center'>
              <div class='w-12'>
                <StandartImageComponent
                  src={record?.user_image || '/img/default-user.jpg'}
                  class='w-12 h-12 object-cover'
                />
              </div>
              <div class='w-auto pl-5 xl:pl-5'>
                <div
                  onClick={() => {
                    navigate(`/users/list/details/${record?.user_id}`);
                  }}
                  class='overflow-hidden block text-ellipsis whitespace-nowrap text-sm font-semibold text-dark hover:text-green mb-1'
                >
                  <HighlightedText text={record?.user_name} highlight={queryFilter?.search} />
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: 'Store Name',
        className: 'w-[20%] text-sm font-semibold text-green',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[20%]'>
            <div class='text-sm font-semibold text-dark mb-1'>
              <HighlightedText text={record?.store_name} highlight={queryFilter?.search} />
            </div>
          </div>
        ),
      },
      {
        name: 'Jml Pengunjung',
        className: 'w-[15%] text-sm font-semibold text-green text-center',
        dataIndex: 'total_visitor',
        render: (record, total_visitor) => (
          <div class='w-[15%]'>
            <div class='text-sm font-medium text-center text-gray'>{total_visitor?.toLocaleString()}</div>
          </div>
        ),
      },
      {
        name: 'Jml Pembeli',
        className:
          'w-[15%] text-sm font-semibold text-center text-green overflow-hidden text-ellipsis whitespace-nowrap pl-7',
        dataIndex: 'total_buyer',
        render: (record, total_buyer) => (
          <div class='w-[15%]'>
            <div class='text-sm font-medium text-center text-gray'>{total_buyer?.toLocaleString()}</div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryFilter?.search]
  );

  return (
    <>
      <div class='flex items-center mb-5'>
        <TableSearchInputComponent
          placeholder='Cari nama BA atau nama toko'
          inputProps={{
            disabled: isLoading,
          }}
          onSearch={(value) => {
            setQueryFilter({ ...queryFilter, search: value.keyword, page: 1 });
          }}
        />
        <TableButtonSortComponent disabled={isLoading} onClick={() => setModals({ ...modals, sort: true })} />
      </div>

      <BasicTableComponent
        error={isError}
        loading={isLoading}
        errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
        errorMessage={catchErrorMessage(error?.response?.data?.message)}
        rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
        columns={columns}
        dataSource={data?.data?.rows || []}
      />

      {data?.data?.total_result === 0 && (
        <EmptyStateComponent
          className='flex flex-col items-center justify-center h-44'
          message={queryFilter.search ? `No result for query "${queryFilter.search}"` : 'No data available'}
        />
      )}

      <Pagination
        currentPage={queryFilter.page}
        pageSize={queryFilter.limit}
        siblingCount={1}
        totalCount={data?.data?.total_result || 0}
        onPageChange={(page) => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          setQueryFilter({ ...queryFilter, page });
        }}
      />
      <ModalTableSortComponent
        options={SORT_OPTIONS}
        initialValue={SORT_OPTIONS.find(
          (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
        )}
        onChange={({ selected }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            orderBy: selected.sortBy,
            orderType: selected.sortType,
          }));
          setModals({ ...modals, sort: false });
        }}
        visible={modals.sort}
        onClose={() => setModals({ ...modals, sort: false })}
      />
    </>
  );
}
