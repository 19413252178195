import escapeHtml from 'escape-html';
import parse from 'html-react-parser';
import { Text } from 'slate';
import { urlify } from 'ui-utils/string.utils';

export function trimText(text, length) {
  if (text.length > length) {
    return text.substring(0, length) + '...';
  }
  return text;
}

// Convert the slate node to plain text
export const convertSlateToPlainText = (node) => {
  // Check if node is an array
  if (Array.isArray(node)) {
    return node.map(convertSlateToPlainText).join(' ');
  }

  // Check if node is an object
  if (typeof node === 'object' && node !== null) {
    if (node.text) {
      return node.text;
    } else if (node.children) {
      return convertSlateToPlainText(node.children);
    }
  }

  // In case of other data types
  return '';
};

// Convert node slate format to html for general
export const converSlateToHTML = (node) =>
  node?.map((element, index) => formatToHTMLInterviewNote(element, index)).map((el) => parse(el));

function formatToHTMLInterviewNote(node, index) {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<i>${string}</i>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    return string;
  }
  const children = node?.children?.map((n, idx) => formatToHTMLInterviewNote(n, idx)).join('');

  switch (node?.type) {
    case 'bulleted-list':
      return `<ul  class='text-${node?.align} list-disc pl-4'>${children}</ul>`;
    case 'list-item':
      return `<li  class='text-${node?.align}'>${children}</li>`;

    case 'numbered-list':
      return `<ol  class='text-${node?.align} list-decimal pl-4'>${children}</ol>`;

    case 'paragraph':
      return `<p  class='text-${node?.align} my-1'>${urlify(children)}</p>`;
    default:
      return children;
  }
}
