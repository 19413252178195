import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { ButtonSubmit } from 'components/button/button-submit.component';
import { InputComponent, InputPasswordComponent } from 'components/form/input.component';
import { LoginValidationSchema } from 'config/form/validation-schema/auth/login-validation.schema';
import { useLogin } from 'api/auth/login.api';
import { setAccessToken, setBasicProfile } from 'reducers/auth.reducer';
import moment from 'moment';
import { setAuthHeader } from 'api/api.config';
import { useGetAdminProfile } from 'api/auth/profile.api';

const formInitialValues = {
  email: '',
  password: '',
};

export const LoginComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [searchParams] = useSearchParams();
  const redirectPath = searchParams.get('redirect') || '/';

  const { mutate: login, isLoading: isSubmitting } = useLogin();
  const { mutateAsync: getAdminProfile } = useGetAdminProfile();

  const handleOnFormSubmit = async (formData) => {
    login(formData, {
      onSuccess: async (response) => {
        setAuthHeader(response.data.token);
        const profile = await getAdminProfile();
        dispatch(setBasicProfile(profile.data));

        navigate(redirectPath);
        dispatch(
          setAccessToken({
            accessToken: response.data.token,
            expiredData: moment().add(7, 'days').toISOString(),
          })
        );
      },
    });
  };

  return (
    <body className='bg-login'>
      <div className='flex justify-center h-screen items-center relative'>
        <div className='w-[400px]'>
          <div className='block text-center mb-12'>
            <img src='/img/logo.svg' className='max-w-full inline-block' alt='Logo' />
          </div>

          <div className='w-full bg-white rounded-sm p-4 pb-14'>
            <Formik
              validationSchema={LoginValidationSchema}
              initialValues={formInitialValues}
              onSubmit={handleOnFormSubmit}
            >
              {({ errors, touched, handleChange, handleBlur, handleSubmit, values }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className='text-2xl font-bold text-green text-center pt-2 pb-6'>Login</div>
                    <InputComponent
                      disabled={isSubmitting}
                      label='Email / Username'
                      placeholder='Input your email or username'
                      name='email'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && errors.email}
                      value={values.email}
                    />

                    <InputPasswordComponent
                      name='password'
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password && errors.password}
                      value={values.password}
                      onToggleShow={() => setShowPassword(!showPassword)}
                      showPassword={showPassword}
                      label='Password'
                      placeholder='Input your password'
                    />

                    <Link
                      to='/auth/forgot-password'
                      className='text-sm font-bold inline-block text-green mb-3 hover:text-dark-green'
                    >
                      Forgot Password
                    </Link>

                    <ButtonSubmit
                      type='submit'
                      loading={isSubmitting}
                      disabled={!LoginValidationSchema.isValidSync(values)}
                      className='bg-green w-full hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
                      title='Login'
                    />
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <div class='absolute left-0 bottom-0'>
        <img src='/img/leaf-login.svg' class='max-w-full' alt='Background' />
      </div>
    </body>
  );
};
