import qs from 'query-string';
import axios from 'api/api.config';
import { useMutation, useQueryClient } from 'react-query';
import { RETURN_PRODUCT_QUERY_KEY } from './return-product.query.api';

export function useExportReturnProductList() {
  return useMutation(({ queryFilter, bodyFilter }) =>
    axios.post(`/api/v1/admin/return-product/export?${qs.stringify(queryFilter)}`, bodyFilter).then((res) => res.data)
  );
}

export function useExportReturnProductDetails() {
  return useMutation(({ recordId }) =>
    axios.get(`/api/v1/admin/return-product/export-items/${recordId}`).then((res) => res.data)
  );
}

export function useApproveReturnProduct() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ recordId }) => axios.put(`/api/v1/admin/return-product/approve/${recordId}`).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RETURN_PRODUCT_QUERY_KEY.RETURN_PRODUCT_DETAILS);
        queryClient.invalidateQueries(RETURN_PRODUCT_QUERY_KEY.RETURN_PRODUCT_LIST);
      },
    }
  );
}

export function useCompleteReturnProduct() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ recordId, feedback }) =>
      axios
        .put(`/api/v1/admin/return-product/complete/${recordId}`, {
          feedback: feedback === '' ? null : feedback,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(RETURN_PRODUCT_QUERY_KEY.RETURN_PRODUCT_DETAILS);
        queryClient.invalidateQueries(RETURN_PRODUCT_QUERY_KEY.RETURN_PRODUCT_LIST);
      },
    }
  );
}
