import axios from 'api/api.config';
import qs from 'query-string';
import { useQuery } from 'react-query';

export const SCHEDULE_USER_QUERY_KEY = {
  SCHEDULE_USER_LIST: 'SCHEDULE_USER_LIST',
  SCHEDULE_USER_DETAILS: 'SCHEDULE_USER_DETAILS',
  SCHEDULE_USER_DETAILS_INFO: 'SCHEDULE_USER_DETAILS_INFO',
  SCHEDULE_DETAILS: 'SCHEDULE_DETAILS',
};

export function useGetScheduleListUser({ filter, bodyFilter }) {
  return useQuery([SCHEDULE_USER_QUERY_KEY.SCHEDULE_USER_LIST, { filter, bodyFilter }], () =>
    axios.get(`/api/v1/admin/schedule/user?${qs.stringify(filter)}`, bodyFilter).then((res) => res.data)
  );
}

export function useGetDetailSchedulesUser(userId, queryFIlter) {
  return useQuery([SCHEDULE_USER_QUERY_KEY.SCHEDULE_USER_DETAILS, { userId, queryFIlter }], () =>
    axios.get(`/api/v1/admin/schedule/user/detail/list/${userId}?${qs.stringify(queryFIlter)}`).then((res) => res.data)
  );
}

export function useGetDetailSchedulesUserInfo(userId) {
  return useQuery([SCHEDULE_USER_QUERY_KEY.SCHEDULE_USER_DETAILS_INFO, userId], () =>
    axios.get(`/api/v1/admin/schedule/user/detail/info/${userId}`).then((res) => res.data)
  );
}

export function useGetDetailSchedule(scheduleId) {
  return useQuery(
    [SCHEDULE_USER_QUERY_KEY.SCHEDULE_DETAILS, scheduleId],
    () => axios.get(`/api/v1/admin/schedule/detail/${scheduleId}`).then((res) => res.data),
    {
      enabled: !!scheduleId,
    }
  );
}
