import React, { useCallback, useEffect, useMemo, useState, memo } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, useSlate, Slate } from 'slate-react';
import { Editor, Transforms, createEditor, Element as SlateElement } from 'slate';
import { withHistory } from 'slate-history';

import { Button, Icon, Toolbar } from './text-sub-editor.component';
import { IsJsonString } from 'ui-utils/string.utils';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

const defaultValue = [
  {
    type: 'paragraph',
    align: 'left',
    children: [{ text: '' }],
  },
];

export const TOOLBAR_OPTIONS = {
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  NUMBERED_LIST: 'numbered-list',
  BULLETED_LIST: 'bulleted-list',
  LEFT_ALIGN: 'left',
  CENTER_ALIGN: 'center',
  RIGHT_ALIGN: 'right',
};

export const ALL_TOOLBAR_OPTIONS = Object.values(TOOLBAR_OPTIONS);

const TextEditor = ({
  onChange = () => {},
  initialValue,
  disabled,
  contentValue = undefined,
  triggerChangesOnBlurOnly = false,
  placeholder = 'Enter description...',
  error = null,
  onBlur = () => {},
  name = '',
  setFieldTouched = null,
  toolbarOptions = undefined,
}) => {
  const [editorValue, setEditorValue] = useState(defaultValue);
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  useEffect(() => {
    if (initialValue) {
      if (typeof initialValue === 'string' && IsJsonString(initialValue)) {
        setEditorValue(JSON.parse(initialValue));
      } else {
        setEditorValue(initialValue);
      }
    }
  }, [initialValue]);

  return (
    <Slate
      editor={editor}
      value={editorValue}
      onBlur={(e) => {
        if (triggerChangesOnBlurOnly) {
          onChange(JSON.stringify(editorValue));
        }
      }}
      onChange={(value) => {
        setEditorValue(value);
        !triggerChangesOnBlurOnly && onChange(JSON.stringify(value));
      }}
    >
      <Toolbar>
        {toolbarOptions && toolbarOptions.includes(TOOLBAR_OPTIONS.BOLD) && (
          <MarkButton format='bold' icon='format_bold' />
        )}
        {toolbarOptions && toolbarOptions.includes(TOOLBAR_OPTIONS.ITALIC) && (
          <MarkButton format='italic' icon='format_italic' />
        )}
        {toolbarOptions && toolbarOptions.includes(TOOLBAR_OPTIONS.UNDERLINE) && (
          <MarkButton format='underline' icon='format_underlined' />
        )}
        {toolbarOptions && toolbarOptions.includes(TOOLBAR_OPTIONS.NUMBERED_LIST) && (
          <BlockButton format='numbered-list' icon='format_list_numbered' />
        )}
        {toolbarOptions && toolbarOptions.includes(TOOLBAR_OPTIONS.BULLETED_LIST) && (
          <BlockButton format='bulleted-list' icon='format_list_bulleted' />
        )}
        {toolbarOptions && toolbarOptions.includes(TOOLBAR_OPTIONS.LEFT_ALIGN) && (
          <BlockButton format='left' icon='format_align_left' />
        )}
        {toolbarOptions && toolbarOptions.includes(TOOLBAR_OPTIONS.CENTER_ALIGN) && (
          <BlockButton format='center' icon='format_align_center' />
        )}
        {toolbarOptions && toolbarOptions.includes(TOOLBAR_OPTIONS.RIGHT_ALIGN) && (
          <BlockButton format='right' icon='format_align_right' />
        )}
      </Toolbar>
      <Editable
        disabled={disabled}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        className='w-full border h-44 scroll rounded-sm outline-none border-solid border-gray-1 py-2.5 px-4 bg-white text-sm text-dark placeholder:text-gray-2 placeholder:italic focus:border-green focus:text-green focus:bg-gray-focus transition duration-100 ease-in-out'
        placeholder={placeholder}
        spellCheck
        onBlur={(e) => {
          onBlur(e);
          if (setFieldTouched && name) {
            setFieldTouched(name, true);
          }
        }}
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
      />
      {error && <p className='text-xs mt-1 text-red-600'>{error}</p>}
    </Slate>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type');
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul className='ml-5 list-disc' style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol className='ml-5 list-decimal' style={style} {...attributes}>
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type')}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon>{icon}</Icon>
    </Button>
  );
};

export const TextEditorComponent = memo(TextEditor);
