import qs from 'query-string';
import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const STORE_LIST_QUERY = {
  ALL_STORE_LIST: 'ALL_STORE_LIST',
  STORE_LIST: 'STORE_LIST',
  STORE_DETAIL: 'STORE_DETAIL',
};

export function useAllStoreList() {
  return useQuery([STORE_LIST_QUERY.ALL_STORE_LIST], () =>
    axios.get(`/api/v1/admin/store/all`).then((res) => res.data)
  );
}
export function useStoreList({ filter, body }) {
  return useQuery([STORE_LIST_QUERY.STORE_LIST, { filter, body }, body], () =>
    axios.get(`/api/v1/admin/store?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}

export function useStoreDetail(storeId) {
  return useQuery([STORE_LIST_QUERY.STORE_DETAIL, storeId], () =>
    axios.get(`/api/v1/admin/store/detail/${storeId}`).then((res) => res.data)
  );
}
