import axios from 'api/api.config';
import qs from 'query-string';
import { useQuery } from 'react-query';

export const STOCK_MOVEMENT_PRE_ORDER = {
  LIST_WAREHOUSE: 'LIST_WAREHOUSE',
  PO_LIST: 'PO_LIST',
  PO_DETAILS: 'PO_DETAILS',
  PO_DETAILS_LOG: 'PO_DETAILS_LOG',
};

export function useGetListWarehouse() {
  return useQuery([STOCK_MOVEMENT_PRE_ORDER.LIST_WAREHOUSE], () =>
    axios.get(`/api/v1/admin/warehouse`).then((res) => res.data)
  );
}

export function useGetListPO({ queryFilter, bodyFilter }) {
  return useQuery([STOCK_MOVEMENT_PRE_ORDER.PO_LIST, { queryFilter, bodyFilter }], () =>
    axios.post(`/api/v1/admin/pre-order?${qs.stringify(queryFilter)}`, bodyFilter).then((res) => res.data)
  );
}

export function useGetListPODetails(poId) {
  return useQuery(
    [STOCK_MOVEMENT_PRE_ORDER.PO_DETAILS, { poId }],
    () => axios.get(`/api/v1/admin/pre-order/detail/${poId}`).then((res) => res.data),
    {
      enabled: !!poId,
    }
  );
}
export function useGetLogPODetails(poId) {
  return useQuery(
    [STOCK_MOVEMENT_PRE_ORDER.PO_DETAILS_LOG, { poId }],
    () => axios.get(`/api/v1/admin/pre-order/logs/${poId}`).then((res) => res.data),
    {
      enabled: !!poId,
    }
  );
}
