import _ from 'lodash';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import { BounceLoading } from 'components/loader/bounce.loading';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { InputComponent } from 'components/form/input.component';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { useNavigate, useParams } from 'react-router-dom';
import { UPLOAD_IMAGE_CONSTANT, useUploadSingleFile } from 'api/upload/upload-single-image.api';
import { TOOLBAR_OPTIONS, TextEditorComponent } from 'components/form/text-editor';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { PATH_CONSTANT } from 'config/path.constant';
import { Select } from 'components/form/select.component';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { SpeakerFormComponent } from '../components/speaker-form.component';
import { CreateSkilDevelopmentValidationSchema } from 'config/form/share-info/create-skill-development-validation.schema';
import { useEditSkillDevelopment } from 'api/share-info/skill-development/skill-development.mutation';
import { SHARE_INFO_ATTACHMENT_TYPE, UploadAttachmentInfo } from '../components/upload-attachment.component';
import { useGetInfoSkillDevelopmentDetails } from 'api/share-info/skill-development/skill-development.query';
import { catchErrorMessage, generateUUID } from 'ui-utils/string.utils';

const SUBMIT_TYPE = {
  SAVE: 'publish',
  DRAFT: 'draft',
};

const EVENT_TYPE = [
  { id: 'offline', name: 'Offline' },
  { id: 'online', name: 'Online' },
];
const DEFAULT_IMAGE = '/img/upload-image.jpg';
const BREAD_CUMB_ITEMS = [
  {
    title: 'Share Info',
    path: null,
  },
  {
    title: 'Edit Skill Development',
    path: null,
  },
];

const formInitialValue = {
  title: '',
  description: '',
  type: '',
  status: '',
  start_date: '',
  start_time: '',
  end_date: '',
  end_time: '',
  attachments: [],
  speakers: [
    {
      name: '',
      position: '',
      profile_picture: '',
      isNew: true,
      file: null,
      uuid: '#uniqueid-initial',
    },
  ],
};

const minDate = new Date().toISOString().split('T')[0].split('-');

export const EditSkillDevelopment = () => {
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const uploadComponentRef = useRef(null);
  const { recordId } = useParams();
  const [modalConfirmation, setModalConfirmation] = useState(false);

  const { data: skillDevelopmentDetails, isLoading, isError, error } = useGetInfoSkillDevelopmentDetails(recordId);

  const { mutateAsync: editSkillDevelopment, isLoading: isCreating } = useEditSkillDevelopment();

  const { mutateAsync: uploadInfoBanner, isLoading: isUploading } = useUploadSingleFile({
    type: UPLOAD_IMAGE_CONSTANT.TYPE.ORIGINAL,
    path: UPLOAD_IMAGE_CONSTANT.DIRECTORY_PATH.SKILL_DEVELOPMENT,
  });

  const [selectedFileBuffer, setSelectedFileBuffer] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(DEFAULT_IMAGE);
  const [attachmentsMetaData, setAttachmentsMetaData] = useState([]);

  const handleOnFormSubmit = async (formValues, submitType) => {
    const formValuesCloned = _.cloneDeep(formValues);
    try {
      let infoImageBannerURL = null;
      if (selectedFileBuffer) {
        const formData = new FormData();
        formData.append('image', selectedFileBuffer);
        const response = await uploadInfoBanner(formData);
        infoImageBannerURL = response?.data?.url;
      }
      const isAnyUnuploadedImage = formValuesCloned.speakers.some((speaker) => !!speaker.file);

      const filesBuffer = uploadComponentRef.current?.getFiles();

      const currentFileAttachments = uploadComponentRef.current
        ?.getFilesMetaData()
        .filter((file) => !file.isNew && file.type === SHARE_INFO_ATTACHMENT_TYPE.FILE)
        .map((file) => ({
          file_name: file.name,
          url: file.url,
          type: file.type,
        }));

      const linkAttachments = uploadComponentRef.current
        ?.getFilesMetaData()
        .filter((file) => file.type === SHARE_INFO_ATTACHMENT_TYPE.LINK)
        .map((file) => ({
          file_name: file.name,
          url: file.url,
          type: file.type,
        }));

      formValuesCloned.attachments = [...currentFileAttachments, ...linkAttachments];

      if (filesBuffer.length > 0) {
        const attachmentsURL = await Promise.all(
          filesBuffer.map(async (file) => {
            const formData = new FormData();
            formData.append('image', file);
            const response = await uploadInfoBanner(formData);
            return {
              file_name: file.name,
              url: response?.data?.url,
              type: SHARE_INFO_ATTACHMENT_TYPE.FILE,
            };
          })
        );
        formValuesCloned.attachments = [...currentFileAttachments, ...linkAttachments, ...attachmentsURL];
      }

      if (formValuesCloned.speakers.length > 0 && isAnyUnuploadedImage) {
        // filter speaker with file is not null
        const speakers = formValuesCloned.speakers.filter((speaker) => !!speaker.file);
        // upload speaker image

        // upload speaker image
        const speakerImageURL = await Promise.all(
          speakers.map(async (speaker) => {
            const formData = new FormData();
            formData.append('image', speaker.file);
            const response = await uploadInfoBanner(formData);
            return response?.data?.url;
          })
        );

        // Create a map of speaker file to URL for easier lookup
        const speakerFileToURL = new Map();
        speakers.forEach((speaker, index) => {
          speakerFileToURL.set(speaker.file, speakerImageURL[index]);
        });

        // update speaker image url
        formValuesCloned.speakers = formValuesCloned.speakers.map((speaker) => {
          // Only update if this speaker had a file uploaded
          if (speaker.file) {
            speaker.profile_picture = speakerFileToURL.get(speaker.file);
          }
          return speaker;
        });
      }

      const payload = {
        ...formValuesCloned,
        status: submitType,
        image: infoImageBannerURL ? infoImageBannerURL : formValuesCloned.image,
      };

      await editSkillDevelopment({
        id: recordId,
        ...payload,
      });
      toast.success('Info updated successfully', {
        autoClose: 2000,
      });
      navigate(PATH_CONSTANT.SHARE_INFO.DETAILS_SKILL_DEVELOPMENT.replace(':recordId', recordId));
    } catch (error) {
      console.error(error);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleOnUploadFileChange = (e) => {
    //chedck file size with maximum 5mb and type, only accept image
    if (e.target.files[0].size > 5000000) {
      toast('File size is too large. Maximum 5mb is allowed');
      return;
    }
    if (!e.target.files[0].type.includes('image')) {
      toast('Only image file is allowed');
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setSelectedFileBuffer(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (skillDevelopmentDetails?.data) {
      const formikInitialValues = {
        title: skillDevelopmentDetails?.data?.title,
        description: skillDevelopmentDetails?.data?.description,
        type: skillDevelopmentDetails?.data?.type,
        image: skillDevelopmentDetails?.data?.image,
        start_date: skillDevelopmentDetails?.data?.start_date,
        start_time: skillDevelopmentDetails?.data?.start_time,
        end_date: skillDevelopmentDetails?.data?.end_date,
        end_time: skillDevelopmentDetails?.data?.end_time,
        attachments: skillDevelopmentDetails?.data?.attachments,
        speakers: skillDevelopmentDetails?.data?.speakers?.map((speaker) => ({
          name: speaker.name,
          position: speaker.position,
          profile_picture: speaker.profile_picture,
          isNew: false,
          uui: generateUUID(),
        })),
      };
      const attachmentsMeta = skillDevelopmentDetails?.data?.attachments?.map((attachment, index) => ({
        name: attachment.file_name || 'Untitled',
        size: 0,
        url: attachment.url,
        type: attachment.type,
        isNew: false,
      }));
      setAttachmentsMetaData(attachmentsMeta);

      setImagePreviewUrl(skillDevelopmentDetails?.data?.image || DEFAULT_IMAGE);
      formikRef.current?.setValues(formikInitialValues);
    }
  }, [skillDevelopmentDetails]);

  const isDisabledAllForm = isCreating || isUploading;

  return (
    <MainDashboardLayoutComponent
      breadCumbs={BREAD_CUMB_ITEMS}
      isError={isError}
      errorTitle={error?.response?.data?.status}
      erroMessage={catchErrorMessage(error?.response?.data?.message)}
    >
      {isLoading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <Formik
          innerRef={formikRef}
          initialValues={formInitialValue}
          validationSchema={CreateSkilDevelopmentValidationSchema}
        >
          {({
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setTouched,
            touched,
            setFieldValue,
            setFieldTouched,
          }) => {
            return (
              <>
                <div className='flex justify-between mb-8'>
                  <div class='w-[40%] '>
                    <div class='w-auto'>
                      <img
                        src={imagePreviewUrl}
                        class='w-[400px] h-[400px] overflow-hidden object-cover object-center border border-solid border-gray-1'
                        alt='Preview'
                      />
                      <div class='mt-7 flex justify-center'>
                        <input
                          type='file'
                          id='upload'
                          class='hidden'
                          onChange={handleOnUploadFileChange}
                          accept='image/*'
                        />
                        <label
                          for='upload'
                          class='bg-green w-28 inline-block text-center hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-3 cursor-pointer'
                        >
                          <span class='icon-ico-upload mr-2'></span>
                          <span class='text-sm'>Upload</span>
                        </label>
                      </div>
                    </div>

                    <SpeakerFormComponent
                      values={values}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                  </div>
                  <div class='w-[55%]'>
                    <div class='w-full mb-2'>
                      <Select
                        label='Online/Offline'
                        onBlur={handleBlur}
                        onChange={(selectedValue) => {
                          setFieldValue('type', selectedValue.target?.value);
                          setTouched({ ...touched, users: true });
                        }}
                        name='type'
                        containerClassName='form-input btn-arrow-create z-1   relative mb-3'
                        placeholder='Select Online/Offline'
                        error={touched.type && errors.type}
                        options={EVENT_TYPE}
                        value={values.type}
                      />
                    </div>
                    <InputComponent
                      disabled={isDisabledAllForm}
                      name='title'
                      value={values.title}
                      error={touched.title && errors.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label='Title'
                      placeholder='Enter title...'
                    />
                    <div class='mb-7'>
                      <div class='text-sm block text-dark font-semibold mb-2'>Description</div>

                      <TextEditorComponent
                        toolbarOptions={[
                          TOOLBAR_OPTIONS.BOLD,
                          TOOLBAR_OPTIONS.ITALIC,
                          TOOLBAR_OPTIONS.UNDERLINE,
                          TOOLBAR_OPTIONS.CENTER_ALIGN,
                          TOOLBAR_OPTIONS.RIGHT_ALIGN,
                          TOOLBAR_OPTIONS.LEFT_ALIGN,
                        ]}
                        initialValue={values.description}
                        onChange={(nodeValue) => setFieldValue('description', nodeValue)}
                      />
                    </div>

                    <div className='w-full flex-row flex justify-between'>
                      <div class='w-1/2 py-2 pr-6'>
                        <InputComponent
                          disabled={isDisabledAllForm}
                          name='start_date'
                          type='date'
                          min={minDate.join('-')}
                          error={touched.start_date && errors.start_date}
                          onChange={(e) => {
                            setFieldValue('start_date', e.target.value);
                          }}
                          value={values.start_date}
                          onBlur={handleBlur}
                          label='Start Date'
                          placeholder='Start Date'
                        />
                      </div>
                      <div class='w-1/2 py-2 pr-6'>
                        <InputComponent
                          disabled={isDisabledAllForm}
                          name='start_time'
                          type='time'
                          error={touched.start_time && errors.start_time}
                          onChange={(e) => {
                            setFieldValue('start_time', e.target.value);
                          }}
                          value={values.start_time}
                          onBlur={handleBlur}
                          label='Start Time'
                          placeholder='Start Time'
                        />
                      </div>
                    </div>
                    <div className='w-full flex-row flex justify-between'>
                      <div class='w-1/2 py-2 pr-6'>
                        <InputComponent
                          disabled={isDisabledAllForm}
                          name='end_date'
                          type='date'
                          min={minDate.join('-')}
                          error={touched.end_date && errors.end_date}
                          onChange={(e) => {
                            setFieldValue('end_date', e.target.value);
                          }}
                          value={values.end_date}
                          onBlur={handleBlur}
                          label='End Date'
                          placeholder='End Date'
                        />
                      </div>
                      <div class='w-1/2 py-2 pr-6'>
                        <InputComponent
                          disabled={isDisabledAllForm}
                          name='end_time'
                          type='time'
                          error={touched.end_time && errors.end_time}
                          onChange={(e) => {
                            setFieldValue('end_time', e.target.value);
                          }}
                          onBlur={handleBlur}
                          value={values.end_time}
                          label='End Time'
                          placeholder='End Time'
                        />
                      </div>
                    </div>
                    <p class='text-md block text-gray font-semibold mt-4 mb-2'>Attachments</p>
                    <UploadAttachmentInfo
                      withInputLink
                      ref={uploadComponentRef}
                      initialAttacthments={attachmentsMetaData}
                    />
                  </div>
                </div>

                <div class='py-14 relative flex justify-center'>
                  <ButtonSubmit
                    disabled={!CreateSkilDevelopmentValidationSchema?.isValidSync(values)}
                    loading={isCreating | isUploading}
                    onClick={() => handleOnFormSubmit(values, SUBMIT_TYPE.DRAFT)}
                    className='bg-gray-1 mx-3 hover:bg-gray-2 font-semibold transition duration-75 ease-in-out rounded-sm text-dark py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-10'
                  >
                    <StandartImageComponent src='/img/icon/file.svg' class='max-w-full mr-3' /> Save As Draft
                  </ButtonSubmit>
                  <ButtonSubmit
                    loading={isCreating | isUploading}
                    disabled={!CreateSkilDevelopmentValidationSchema.isValidSync(values)}
                    onClick={() => handleOnFormSubmit(values, SUBMIT_TYPE.SAVE)}
                    className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
                  >
                    <span class='icon-ico-plus-circle mr-3'></span> Update
                  </ButtonSubmit>
                </div>
              </>
            );
          }}
        </Formik>
      )}

      <ModalConfirmation
        description='Apakah anda yakin ingin membuat product baru?'
        title='Create Prodcut'
        imageIcon='/img/info.svg'
        textConfirm='Create'
        loading={isCreating}
        visible={modalConfirmation}
        onConfirm={() => {
          formikRef?.current?.handleSubmit();
        }}
        onClose={() => setModalConfirmation(false)}
      />
    </MainDashboardLayoutComponent>
  );
};
