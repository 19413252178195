import { useState } from 'react';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { useNavigate, useParams } from 'react-router-dom';
import { getReactDropZoneErrorMessage } from 'ui-utils/string.utils';
import { useImportVariantStock } from 'api/product/stock-in-store/stock-in-store.mutation';
import { useExportStockProductStore } from 'api/store/store-list/store-list.mutation.api';
import { PATH_CONSTANT } from 'config/path.constant';

const INITIAL_VALUE_FILTER_QUERY_TEMPLATE = {
  limit: 20,
  page: 1,
  orderBy: 'product_name',
  orderType: 'desc',
  search: undefined,
};

const BREAD_CUMB_ITEMS = [
  {
    title: 'List Stock Product Store',
    path: '/product/list',
  },
  {
    title: 'Import Stock Product',
    path: '/product/list',
  },
];

export const ImportStockStore = () => {
  const { storeId } = useParams();
  const {
    mutate: downloadTemplateImport,
    isLoading: isDownloading,
    isSuccess: isTemplateDownloaded,
  } = useExportStockProductStore();
  const navigate = useNavigate();

  const { mutate: importProduct, isLoading: isUploading } = useImportVariantStock();

  const [fileBuffer, setFileBuffer] = useState(null);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      // excel file only
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls'],
    },
    onDropAccepted: (files) => {
      setFileBuffer(files[0]);
    },
    onDropRejected: (files) => {
      toast.error(getReactDropZoneErrorMessage(files[0].errors[0]));
    },
  });

  const handleOnDownloadTemplateImport = () => {
    downloadTemplateImport(
      {
        queryFilter: INITIAL_VALUE_FILTER_QUERY_TEMPLATE,
        bodyFilter: { store_id: storeId },
      },
      {
        onSuccess: (data) => {
          const link = document.createElement('a');
          link.href = data?.data?.download_url;
          link.setAttribute('download', 'template-import-product.xlsx');
          document.body.appendChild(link);
          link.click();
        },
      }
    );
  };

  const handleOnImportProduct = () => {
    const formData = new FormData();
    formData.append('file', fileBuffer);
    importProduct(formData, {
      onSuccess: (data) => {
        setFileBuffer(null);
        toast.success('Import variant stock success!');
        navigate(`${PATH_CONSTANT.PRODUCT.PRODUCT_STOCK_STORE_INFO}/${storeId}`);
      },
    });
  };

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      {!isTemplateDownloaded && (
        <div class='flex justify-between items-center border-t border-solid border-t-gray-1 p-3'>
          <div class='w-[80%]'>
            <div class='flex items-center'>
              <div class='max-w-[350px] flex items-center'>
                <div class='w-auto'>
                  <div class='text-sm text-gray font-semibold overflow-hidden text-ellipsis whitespace-nowrap'>
                    Please download the template first!
                  </div>
                </div>
                <div class='w-6 ml-3'>
                  <div class='w-[220px]'>
                    <ButtonSubmit
                      onClick={handleOnDownloadTemplateImport}
                      loading={isDownloading}
                      className='w-full flex items-center justify-center bg-green hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                      type='button'
                    >
                      <span class='icon-ico-download mr-2'></span>
                      Download Now
                    </ButtonSubmit>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div class='bg-gray-3'>
        <div class='drag-upload' {...getRootProps({ className: 'dropzone' })}>
          <input type='file' id='upload-drag' class='hidden' {...getInputProps()} />
          <div for='upload-drag' class='flex justify-center items-center w-full h-64 cursor-pointer'>
            <div class='w-auto'>
              <StandartImageComponent src='/img/import.png' class='max-w-full' />
              <p class='text-center text-green block text-base mt-4 font-semibold'>Drag File Here</p>
              <p class='text-center text-gray block text-sm '>EXCEL File Format</p>
            </div>
          </div>
        </div>

        <div class='flex justify-between items-center border-t border-solid border-t-gray-1 p-3'>
          <div class='w-[80%]'>
            <div class='flex items-center'>
              <div {...getRootProps({ className: 'dropzone' })} class='mr-5'>
                <input type='file' id='upload' class='hidden' {...getInputProps()} disabled={isUploading} />
                <div
                  for='upload'
                  class='disabled:opacity-40 outline-none flex items-center border border-gray-1 font-semibold rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-2 px-3 cursor-pointer'
                >
                  <span class='icon-ico-upload mr-3'></span>
                  <span class='text-sm'>Select File</span>
                </div>
              </div>

              {fileBuffer && (
                <>
                  <div class='mr-5'>
                    <div class='text-base font-semibold text-green'>File Imported</div>
                  </div>

                  <div class='max-w-[350px] flex items-center'>
                    <div class='w-auto'>
                      <div class='text-sm text-gray font-semibold overflow-hidden text-ellipsis whitespace-nowrap'>
                        {fileBuffer.name}
                      </div>
                    </div>
                    <div class='w-6 ml-3'>
                      <button class='text-red-1 outline-none text-lg' onClick={() => setFileBuffer(null)}>
                        <span class='icon-ico-close-circle'></span>
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          {fileBuffer && (
            <div class='w-[220px]'>
              <ButtonSubmit
                loading={isUploading}
                onClick={handleOnImportProduct}
                className='w-full flex items-center justify-center bg-green hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                type='button'
              >
                <StandartImageComponent src='/img/icon/arrow-down-left.svg' class='mr-3 max-w-full' /> Import Now
              </ButtonSubmit>
            </div>
          )}
        </div>
      </div>
    </MainDashboardLayoutComponent>
  );
};
