import axios from 'api/api.config';
import qs from 'query-string';
import { useQuery } from 'react-query';

export const OVERTIME_QUERY_KEY = {
  LIST_OVERTIME: 'LIST_OVERTIME',
  DETAILS_OVERTIME: 'DETAILS_OVERTIME',
};

export function useGetListOvertime({ filter, bodyFilter }) {
  return useQuery([OVERTIME_QUERY_KEY.LIST_OVERTIME, { filter, bodyFilter }], () =>
    axios.post(`/api/v1/admin/overtime?${qs.stringify(filter)}`, bodyFilter).then((res) => res.data)
  );
}
export function useGetDetailsOvertime(overTimeId) {
  return useQuery([OVERTIME_QUERY_KEY.DETAILS_OVERTIME, { overTimeId }], () =>
    axios.get(`/api/v1/admin/overtime/detail/${overTimeId}`).then((res) => res.data)
  );
}
