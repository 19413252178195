export function isMaxWords(str, totalMaxWords) {
  // Split the string into an array of words
  const words = str.split(' ');

  // Initialize a variable to keep track of the maximum number of words
  let maxWords = 0;

  // Loop through the array of words and update the maxWords variable if necessary
  for (let i = 0; i < words.length; i++) {
    if (words[i].length > 0) {
      maxWords = Math.max(maxWords, i + 1);
    }
  }

  // Return the maximum number of words
  return totalMaxWords <= maxWords;
}

export function trucanateWords(str, totalMaxWords) {
  // Split the string into an array of words
  const words = str.split(' ');

  // Initialize a variable to keep track of the maximum number of words
  let maxWords = 0;

  // Loop through the array of words and update the maxWords variable if necessary
  for (let i = 0; i < words.length; i++) {
    if (words[i].length > 0) {
      maxWords = Math.max(maxWords, i + 1);
    }
  }

  // Return the maximum number of words
  return maxWords <= totalMaxWords ? str : words.slice(0, totalMaxWords).join(' ') + '...';
}

export function catchErrorMessage(error) {
  if (typeof error === 'string') {
    return error;
  }
  if (Array.isArray(error)) {
    return error.map((err) => err.msg).join(', ');
  }
  return 'Opps! Something went wrong. Please try again later.';
}

export function convertToTitleCase(str) {
  if (!str) return null;
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

/**
abbreviateCurrency(50000) // Outputs: "50,000"
abbreviateCurrency(973000) // Outputs: "973,000"
abbreviateCurrency(1300000) // Outputs: "1,3jt"
abbreviateCurrency(1640000) // Outputs: "1,64 jt"
abbreviateCurrency(135000000) // Outputs: "135jt"
abbreviateCurrency(1000000000) // Outputs: "1m"
abbreviateCurrency(1300000000) // Outputs: "1,3m"
abbreviateCurrency(1340000000) // Outputs: "1,34m"
 */
export function abbreviateCurrency(value) {
  // Convert the value to a string
  value = value.toString();

  // Split the value into integer and decimal parts
  const parts = value.split('.');
  let integerPart = parts[0];

  // Abbreviate the integer part
  let abbreviation = '';
  if (integerPart.length > 9) {
    abbreviation = (integerPart / 1000000000).toFixed(1) + ' miliar';
  } else if (integerPart.length > 6) {
    abbreviation = (integerPart / 1000000).toFixed(1) + ' juta';
  } else {
    let integerPartLength = integerPart.length;
    while (integerPartLength > 3) {
      abbreviation = '.' + integerPart.slice(integerPartLength - 3) + abbreviation;
      integerPart = integerPart.slice(0, integerPartLength - 3);
      integerPartLength = integerPart.length;
    }
    abbreviation = integerPart + abbreviation;
  }

  // Trim trailing .0 from abbreviation (e.g. 2.0 juta -> 2 juta)
  if (abbreviation.endsWith('.0 juta')) {
    abbreviation = abbreviation.replace('.0 juta', ' juta');
  } else if (abbreviation.endsWith('.0 miliar')) {
    abbreviation = abbreviation.replace('.0 miliar', ' miliar');
  }

  // Return the abbreviation with the decimal part
  return abbreviation;
}
export function convertToRupiah(angka) {
  if (!angka) return `Rp. 0`;
  var rupiah = '';
  var angkarev = angka.toString().split('').reverse().join('');
  for (var i = 0; i < angkarev.length; i++) if (i % 3 === 0) rupiah += angkarev.substr(i, 3) + '.';
  return (
    'Rp. ' +
    rupiah
      .split('', rupiah.length - 1)
      .reverse()
      .join('')
  );
}
/**
 * Usage example:
 * alert(convertToRupiah(10000000)); -> "Rp. 10.000.000"
 */

export function convertToAngka(rupiah) {
  return parseInt(rupiah.replace(/,.*|[^0-9]/g, ''), 10);
}
/**
 * Usage example:
 * alert(convertToAngka("Rp 10.000.123")); -> 10000123
 */

export const getReactDropZoneErrorMessage = (error) => {
  if (error.code === 'file-too-large') {
    return 'File is too large';
  }
  if (error.code === 'too-many-files') {
    return 'Too many files';
  }
  if (error.code === 'file-invalid-type') {
    return 'File type is not supported';
  }
  return 'File is not supported';
};

export function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return JSON.parse(str);
}

export function generateRandomPassword(length) {
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+';
  let password = '';
  for (let i = 0; i < length; i++) {
    password += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return password;
}

export function generateUUID() {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export function urlify(text) {
  if (!text) return '';
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return `<a href="${url}" class="content-url" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });
}
