import moment from 'moment';
import { Tooltip } from 'flowbite-react';
import { useMemo, useState } from 'react';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';

import { BounceLoading } from 'components/loader/bounce.loading';
import { PATH_CONSTANT } from 'config/path.constant';
import { AnalyticsProductChart } from './analytics-product.chart';
import { RangeDatePicker } from 'components/date-picker/range-date-picker.component';
import {
  useGetAnalyticsProductGraph,
  useGetAnalyticsTotalProduct,
} from 'api/analytics/product/analytic-product.query.api';
import { AnalyticsProductTable } from './product-table.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { TableButtonFilterComponent } from 'components/table/table-button-filter.component';
import { ModalTableFilter } from 'components/modal/modal-table-filter.component';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { renderPercentageSummary } from '../sales/summary-card.component';
import { useExportAnalyticProduct } from 'api/analytics/product/analytic-product.mutation.api';

const INITIAL_PREVIOUS_DAY_RANGE = 6;

const BREAD_CUMB_ITEMS = [
  {
    title: 'Analytics',
    path: PATH_CONSTANT.ANALYTICS.PRODUCT,
  },
  {
    title: 'Product Analytic',
    path: PATH_CONSTANT.ANALYTICS.PRODUCT,
  },
];

const INITIAL_DATE_FILTER = {
  startDate: moment().subtract(INITIAL_PREVIOUS_DAY_RANGE, 'day').toDate(),
  endDate: moment().toDate(),
};

const INITIAL_VALUE_FILTER_QUERY = {
  limit: 20,
  page: 1,
  orderBy: 'total_product_sold',
  orderType: 'desc',
  search: undefined,
};
const INITIAL_VALUE_FILTER_BODY = {
  store_ids: [],
};

export const AnalyticsProductComponent = () => {
  const [dateFilter, setDateFilter] = useState({
    startDate: moment(INITIAL_DATE_FILTER.startDate).format('YYYY-MM-DD'),
    endDate: moment(INITIAL_DATE_FILTER.endDate).format('YYYY-MM-DD'),
  });
  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);
  const [bodyFilter, setBodyFilter] = useState(INITIAL_VALUE_FILTER_BODY);

  const [modals, setModals] = useState({
    export: false,
    filter: false,
    result: false,
  });

  const { data: totalProducts, isLoading: isLoadingProducts } = useGetAnalyticsTotalProduct({
    filter: dateFilter,
    body: bodyFilter,
  });

  const { data: productGraph } = useGetAnalyticsProductGraph({
    filter: dateFilter,
    body: bodyFilter,
  });

  const { mutate: exportData, isLoading: isExporting } = useExportAnalyticProduct();

  // format data for chart
  const salesGraphData = useMemo(() => {
    if (productGraph) {
      return {
        label: productGraph.data.map((item) => item.label && moment(item.label).format('D MMM YYYY')),
        data: productGraph.data.map((item) => item.value),
      };
    }
    return {
      label: [],
      data: [],
    };
  }, [productGraph]);

  const isFilterActive = useMemo(() => {
    if (bodyFilter.store_ids.length > 0) {
      return true;
    }
  }, [bodyFilter]);

  const handleExport = () => {
    exportData(
      {
        queryFilter: {
          ...queryFilter,
          ...dateFilter,
        },
        bodyFilter,
      },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      {isLoadingProducts ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          <div class='flex items-center justify-center w-full gap-x-2 mb-5'>
            <RangeDatePicker
              initialStartDate={moment(dateFilter.startDate).toDate()}
              initialEndDate={moment(dateFilter.endDate).toDate()}
              maxDate={moment().toDate()}
              onChange={({ start, end }) => {
                setDateFilter({
                  startDate: moment(start).format('YYYY-MM-DD'),
                  endDate: moment(end).format('YYYY-MM-DD'),
                });
              }}
            />

            <TableButtonFilterComponent
              active={isFilterActive}
              onClick={() => setModals({ ...modals, filter: true })}
            />
            <TableButtonExport onClick={() => setModals({ ...modals, export: true })} loading={isExporting} />
          </div>
          <div class='bg-green-status relative flex items-center py-2 px-2 mb-2'>
            <div class='w-2/6 text-center flex justify-center'>
              <div class='text-sm font-bold text-green text-ellipsis whitespace-nowrap overflow-hidden'>
                Presentase Kenaikan / Penurunan
              </div>
              <button class='p-0 outline-none ml-2 text-green' type='button'>
                <Tooltip
                  className='max-w-[20%]'
                  content='Merupakan persentase perbandingan produk terjual pada periode saat ini dengan periode sebelumnya'
                >
                  <span class='icon-ico-help'></span>
                </Tooltip>
              </button>
            </div>
            <div class='w-2/6 text-center flex justify-center'>
              <div class='text-sm font-bold text-green text-ellipsis whitespace-nowrap overflow-hidden'>
                Produk Terjual Saat Ini
              </div>
              <button class='p-0 outline-none ml-2 text-green' type='button'>
                <Tooltip
                  className='max-w-[20%]'
                  content='Merupakan jumlah produk yang terjual pada periode saat ini, berdasarkan hasil report yang dilakukan oleh BA'
                >
                  <span class='icon-ico-help'></span>
                </Tooltip>
              </button>
            </div>
            <div class='w-2/6 text-center flex justify-center'>
              <div class='text-sm font-bold text-green text-ellipsis whitespace-nowrap overflow-hidden'>
                Produk Terjual Sebelumnya
              </div>
              <button class='p-0 outline-none ml-2 text-green' type='button'>
                <Tooltip
                  className='max-w-[20%]'
                  content='Merupakan jumlah produk yang terjual pada periode sebelumnya, berdasarkan hasil report yang dilakukan oleh BA'
                >
                  <span class='icon-ico-help'></span>
                </Tooltip>
              </button>
            </div>
          </div>
          <div class='border border-solid border-gray-1 flex items-center mb-5'>
            <div class='w-2/6 py-5 px-3'>
              <div class='flex items-center justify-center'>
                {renderPercentageSummary(totalProducts?.data?.value, {
                  classNameOnValueDown: 'text-red-1 pl-2 text-sm font-medium',
                  classNameOnValueUp: 'text-green-3 pl-2 text-sm font-medium',
                  upIcon: ' icon-arrow-up text-green-3  text-xl',
                  downIcon: 'icon-arrow-down text-red-1 text-xl',
                })}
              </div>
            </div>
            <div class='w-2/6 py-5 border-l border-r border-solid border-gray-1 px-3 xl:px-6'>
              <div class='md:text-base text-center lg:text-base text-dark font-semibold mb-1  whitespace-nowrap text-ellipsis overflow-hidden'>
                {totalProducts?.data?.total_product_sold?.toLocaleString()} Unit
              </div>
              <div class='text-sm text-center font-normal text-gray'>{`${moment(
                totalProducts?.data?.current_period?.[0]
              ).format('DD MMM YY')} - ${moment(totalProducts?.data?.current_period?.[1]).format('DD MMM YY')}`}</div>
            </div>
            <div class='w-2/6 py-5 px-3 xl:px-6'>
              <div class='md:text-base text-center lg:text-base text-dark font-semibold mb-1  whitespace-nowrap text-ellipsis overflow-hidden'>
                {totalProducts?.data?.prev_total_product_sold?.toLocaleString()} Unit
              </div>
              <div class='text-sm font-normal text-center text-gray'>{`${moment(
                totalProducts?.data?.prev_period?.[0]
              ).format('DD MMM YY')} - ${moment(totalProducts?.data?.prev_period?.[1]).format('DD MMM YY')}`}</div>
            </div>
          </div>
          <AnalyticsProductChart labels={salesGraphData.label} data={salesGraphData.data} />
          <AnalyticsProductTable
            queryFilter={queryFilter}
            setQueryFilter={setQueryFilter}
            bodyFilter={bodyFilter}
            dateFilter={dateFilter}
          />
        </>
      )}
      <ModalTableFilter
        initialValue={bodyFilter}
        visible={modals.filter}
        onChange={(selectedFilter) => {
          setBodyFilter(selectedFilter);

          setModals({ ...modals, filter: false });
        }}
        onClose={() => setModals({ ...modals, filter: false })}
      />
      <ModalConfirmation
        description='Apakah anda yakin ingin export data?'
        title='Export Data'
        visible={modals.export}
        onConfirm={handleExport}
        onClose={() => setModals({ ...modals, export: false })}
      />
    </MainDashboardLayoutComponent>
  );
};
