import axios from 'api/api.config';
import qs from 'query-string';
import { useQuery } from 'react-query';

export const INFO_SKIL_DEVELOPMENT_QUERY_KEY = {
  LIST_INFO: 'LIST_INFO_SKIL_DEVELOPMENT',
  INFO_DETAILS: 'INFO_DETAILS_SKIL_DEVELOPMENT',
};

export function useGetInfoSkillDevelopmentList({ filter, body }) {
  return useQuery([INFO_SKIL_DEVELOPMENT_QUERY_KEY.LIST_INFO, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/skill-development?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}

export function useGetInfoSkillDevelopmentDetails(recordId) {
  return useQuery(
    [INFO_SKIL_DEVELOPMENT_QUERY_KEY.INFO_DETAILS, recordId],
    () => axios.get(`/api/v1/admin/skill-development/detail/${recordId}`).then((res) => res.data),
    {
      enabled: !!recordId,
    }
  );
}
