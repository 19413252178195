import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { ModalContainer } from 'components/modal/modal-container.component';
import { useUpdateStockProductStore } from 'api/store/store-list/store-list.mutation.api';

export const ModalStockEdit = (props) => {
  const { mutate: updateStock, isLoading } = useUpdateStockProductStore();
  const { title, visible, onClose, initialValue = 0, storeId, variantId } = props;
  const [initialValueStock, setInitialValueStock] = useState(0);

  useEffect(() => {
    if (visible) {
      setInitialValueStock(initialValue);
    }
  }, [visible, initialValue]);

  const handleOnUpdateStock = () => {
    updateStock({
      store_id: storeId,
      variant_id: variantId,
      total_qty: Number(initialValueStock),
    });
    onClose();
  };

  return (
    <ModalContainer visible={visible}>
      <div id='show-item' class={clsx('w-[330px] bg-white rounded-sm p-5 relative', !visible && 'hidden')}>
        <div class='mb-5 text-center font-bold text-sm'>{title}</div>
        <button type='button' class='absolute top-2 right-3' disabled={isLoading} onClick={onClose}>
          <span class='icon-ico-close text-xl text-gray-2'></span>
        </button>
        <div class='max-h-[45vh] flex justify-between items-center scroll my-8'>
          <div class='text-sm'>Total Qty</div>
          <div class='w-36'>
            <div class='flex border border-solid border-gray-1 rounded-sm justify-between items-center'>
              <button
                onClick={(e) => {
                  if (initialValueStock > 0) {
                    setInitialValueStock(initialValueStock - 1);
                  }
                }}
                class='w-10 h-10 rounded-sm flex justify-center items-center bg-gray-3'
              >
                <span class='icon-ico-mminus text-green text-lg'></span>
              </button>
              <input
                onChange={(e) => {
                  setInitialValueStock(e.target.value.replace(/[^0-9]+/g, ''));
                }}
                class='input-number w-16 text-center'
                name='quantity'
                value={initialValueStock}
                type='text'
              />
              <button
                onClick={(e) => {
                  setInitialValueStock(initialValueStock + 1);
                }}
                class='w-10 h-10 rounded-sm flex justify-center items-center bg-gray-3'
              >
                <span class='icon-ico-plus text-green text-lg'></span>
              </button>
            </div>
          </div>
        </div>
        <ButtonSubmit
          loading={isLoading}
          onClick={handleOnUpdateStock}
          title='OK'
          className='bg-green w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
        />
      </div>
    </ModalContainer>
  );
};
