import clsx from 'clsx';
import { useEffect, useState } from 'react';

export function TableSearchInputComponent({
  containerClassName = 'relative md:w-1/4 lg:w-[30%] xl:w-2/5 mr-2',
  onSearch,
  disabled = false,
  placeholder = 'Cari User...',
  inputClassName = 'form-control block w-full text-sm pr-3 pl-9 py-2 ',
  controllerInput = false,
  initialValue,
  onChange = () => {},
  value = undefined,
  inputProps = {},
}) {
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    setKeyword(initialValue);
  }, [initialValue]);

  return (
    <div className={containerClassName}>
      <input
        type='text'
        disabled={disabled}
        className={clsx(
          'disabled:opacity-50 disabled:cursor-not-allowed placeholder:italic font-normal text-gray-700 focus:ring-0 bg-light-green bg-clip-padding outline-none border border-solid border-green rounded-sm transition ease-in-out m-0 focus:text-green focus:bg-white focus:shadow-none focus:border-green focus:outline-none',
          inputClassName
        )}
        value={controllerInput ? value : keyword}
        onChange={(e) => {
          if (controllerInput) {
            onChange(e);
          } else {
            setKeyword(e.target.value);
          }
        }}
        placeholder={placeholder}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSearch({ keyword });
          }
        }}
        {...inputProps}
      />
      <div
        className='absolute left-3 top-1 text-red-50'
        onClick={() => {
          onSearch({ keyword });
        }}
      >
        <span className='icon-ico-search text-green text-lg'></span>
      </div>
      {keyword && (
        <div
          className='absolute right-3 top-3 text-red-50 cursor-pointer bg-green-1 rounded-full h-4 w-4 flex items-center justify-center'
          onClick={() => {
            onSearch({ keyword: '' });
            setKeyword('');
          }}
        >
          <span className='icon-ico-close text-green-2 text-sm'></span>
        </div>
      )}
    </div>
  );
}
