import axios from 'api/api.config';
import { USER_LIST_QUERY_KEY } from 'api/users/user-list/user-list.query.api';
import { REPORT_BA_SALES_QUERY } from 'api/report-ba/sales/sales.query.api';
import { ANALYTIC_SALES_QUERY } from 'api/analytics/sales/analytic-sales.query.api';
import { useQueryClient, useMutation } from 'react-query';
import { STORE_LIST_QUERY } from '../store-list/store-list.query.api';

export function useCreateStore() {
  const queryClient = useQueryClient();
  return useMutation((body) => axios.post(`/api/v1/admin/store/create`, body).then((res) => res.data), {
    onSuccess: () => {
      queryClient.removeQueries([STORE_LIST_QUERY.ALL_STORE_LIST]);
      queryClient.removeQueries([STORE_LIST_QUERY.STORE_LIST]);
    },
  });
}

export function useEditStore() {
  const queryClient = useQueryClient();
  return useMutation(({ id, ...body }) => axios.put(`/api/v1/admin/store/update/${id}`, body).then((res) => res.data), {
    onSuccess: () => {
      queryClient.removeQueries([STORE_LIST_QUERY.ALL_STORE_LIST]);
      queryClient.removeQueries([STORE_LIST_QUERY.STORE_LIST]);
      queryClient.removeQueries([STORE_LIST_QUERY.STORE_DETAIL]);
      queryClient.removeQueries([USER_LIST_QUERY_KEY.USER_LIST]);
      queryClient.refetchQueries([REPORT_BA_SALES_QUERY.SALES_DETAILS_REPORT]);
      queryClient.refetchQueries([REPORT_BA_SALES_QUERY.SALES_LIST]);
      queryClient.refetchQueries([ANALYTIC_SALES_QUERY.LIST_SALES_BA]);
    },
  });
}
