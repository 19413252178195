import * as Yup from 'yup';

/**
 * Form validation schema for /share-info/create
 */

export const CreateShareInfoValidationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  broadcast_date: Yup.string().required('Broadcast Date is required'),
  broadcast_time: Yup.string().required('Broadcast Time is required'),
  start_date: Yup.string().nullable(),
  start_time: Yup.string().nullable(),
  end_date: Yup.string().nullable(),
  end_time: Yup.string().nullable(),
  is_all_users: Yup.string().required('Share to is required'),
  description: Yup.string()
    .required('Description is required')
    .test('check-empty', 'Description is required', (value) => {
      if (!value) return false;
      const slateValue = JSON.parse(value);
      return !(
        slateValue?.length === 1 &&
        slateValue[0]?.type === 'paragraph' &&
        slateValue[0]?.children?.length === 1 &&
        slateValue[0]?.children?.[0]?.text?.trim() === ''
      );
    }),
  users: Yup.array().when('is_all_users', {
    is: (value) => value === 'SPECIFIC_USER',
    then: Yup.array()
      .required('Users are required')
      .min(1, 'Users are required when sharing to specific users, please select at least one user'),
    otherwise: Yup.array().notRequired(),
  }),
});
