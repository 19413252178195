export function getStatuClassNameByStockTransferStatus(status) {
  // APPROVED: 'bg-green-status text-green-3',
  // SUBMITTED: 'bg-yellow-light text-yellow',
  // WAITING_FOR_PAYMENT: 'bg-blue-light text-blue',
  // PACKING: 'bg-violet-light text-violet',
  // DELIVERY: 'bg-purple-light text-purple',
  // VALIDATE: 'bg-maroon-light text-maroon',
  // PACKED: 'bg-brown-light text-brown',
  // COMPLETED: 'bg-complete-light text-gray',
  // PICKING: 'bg-olive-light text-olive',
  // PICKED: 'bg-olive-light text-olive',
  switch (status) {
    case 'submitted':
      return 'bg-yellow-light text-yellow';
    case 'completed':
      return 'bg-green-status text-green-3';
    case 'rejected':
      return 'bg-red-status text-red-1';
    case 'approved':
      return 'bg-blue-light text-blue';
    default:
      return 'primary';
  }
}
