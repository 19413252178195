import qs from 'query-string';
import axios from 'api/api.config';
import { useQueryClient, useMutation } from 'react-query';
import { REPORT_BA_SALES_QUERY } from 'api/report-ba/sales/sales.query.api';
import { ANALYTIC_SALES_QUERY } from 'api/analytics/sales/analytic-sales.query.api';
import { USER_LIST_QUERY_KEY } from '../user-list/user-list.query.api';
import { USER_DETAIL_QUERY_KEY } from '../user-detail/user-detail.api';

export function useCreateUser() {
  const queryClient = useQueryClient();
  return useMutation((body) => axios.post(`/api/v1/admin/user/create`, body).then((res) => res.data), {
    onSuccess: () => {
      queryClient.removeQueries([USER_LIST_QUERY_KEY.USER_LIST]);
      queryClient.removeQueries([USER_LIST_QUERY_KEY.USER_LIST]);
      queryClient.removeQueries([USER_LIST_QUERY_KEY.ALL_USER]);
      queryClient.refetchQueries([REPORT_BA_SALES_QUERY.SALES_DETAILS_REPORT]);
      queryClient.refetchQueries([REPORT_BA_SALES_QUERY.SALES_LIST]);
      queryClient.refetchQueries([ANALYTIC_SALES_QUERY.LIST_SALES_BA]);
    },
  });
}

export function useEditUser() {
  const queryClient = useQueryClient();
  return useMutation(({ id, ...body }) => axios.put(`/api/v1/admin/user/update/${id}`, body).then((res) => res.data), {
    onSuccess: () => {
      queryClient.removeQueries([USER_DETAIL_QUERY_KEY.USER_DETAIL]);
      queryClient.removeQueries([USER_LIST_QUERY_KEY.USER_LIST]);
      queryClient.removeQueries([USER_LIST_QUERY_KEY.ALL_USER]);
      queryClient.refetchQueries([REPORT_BA_SALES_QUERY.SALES_DETAILS_REPORT]);
      queryClient.refetchQueries([REPORT_BA_SALES_QUERY.SALES_LIST]);
      queryClient.refetchQueries([ANALYTIC_SALES_QUERY.LIST_SALES_BA]);
    },
  });
}

export function useExportUserList() {
  return useMutation(({ queryFilter, bodyFilter }) =>
    axios.post(`/api/v1/admin/user/export?${qs.stringify(queryFilter)}`, bodyFilter).then((res) => res.data)
  );
}

export function useUpdateUserStatus() {
  const queryClient = useQueryClient();
  return useMutation((body) => axios.put(`api/v1/admin/user/update-status`, body).then((res) => res.data), {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_DETAIL_QUERY_KEY.USER_DETAIL]);
      queryClient.invalidateQueries([USER_LIST_QUERY_KEY.USER_LIST]);
    },
  });
}
