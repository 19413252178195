import React from 'react';
import { ButtonSubmit } from 'components/button/button-submit.component';

import { useNavigate, useSearchParams } from 'react-router-dom';

const ErrorPage = (props) => {
  const { errorCode = 404, errorMessage = 'Oh snap! Please double check your URL.' } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const countBack = +searchParams.get('countBack') || 1;

  return (
    <body className='bg-login'>
      <div className='flex justify-center h-screen items-center relative'>
        <div className='w-[600px]'>
          <div className='w-full rounded-sm p-4 pb-8'>
            <div className='text-8xl font-bold text-green text-center '>{errorCode}</div>
          </div>
          <div className='text-xl  text-green text-center  pb-6'>{errorMessage}</div>
          <div className='block text-center'>
            <ButtonSubmit title='Back to Home' onClick={() => navigate(-countBack)} />
          </div>
        </div>
        <div className='block text-center mb-12'>
          <img src='/img/logo.svg' className='max-w-full inline-block' alt='Logo' />
        </div>
      </div>
      <div class='absolute left-0 bottom-0'>
        <img src='/img/leaf-login.svg' class='max-w-full' alt='Background' />
      </div>
    </body>
  );
};

export default ErrorPage;
