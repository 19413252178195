import qs from 'query-string';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { TableSearchInputComponent } from 'components/table/table-search.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { TableButtonLimitComponent } from 'components/table/table-button-limit.component';
import { TableButtonSortComponent } from 'components/table/table-button-sort.component';
import { BasicTableComponent } from 'components/table/table-basic.component';
import { Pagination } from 'components/table/table-pagination.component';
import { EmptyStateComponent } from 'components/empty-data';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ModalTableSortComponent } from 'components/modal/modal-table-sort.component';
import { ModalTableLimitComponent } from 'components/modal/modal-table-limit.component';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { setToolsReducer } from 'reducers/tools.reducer';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { IsJsonString, catchErrorMessage } from 'ui-utils/string.utils';
import { PATH_CONSTANT } from 'config/path.constant';
import { Tooltip } from 'flowbite-react';
import { HighlightedText } from 'components/highlighted-text';
import { convertSlateToPlainText } from 'components/form/text-editor/serializer';
import { ModalTableFilter } from 'components/modal/modal-table-filter.component';
import { useGetInfoSkillDevelopmentList } from 'api/share-info/skill-development/skill-development.query';
import { useExportSkillDevelopment } from 'api/share-info/skill-development/skill-development.mutation';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Share Info',
    path: PATH_CONSTANT.SHARE_INFO.LIST_INFO_SKILL_DEVELOPMENT,
  },
  {
    title: 'List Skill Development',
    path: PATH_CONSTANT.SHARE_INFO.LIST_INFO_SKILL_DEVELOPMENT,
  },
];

const FILTER_OPTIONS = [
  {
    key: 'status',
    type: 'checkbox',
    multiple: false,
    searchFilter: false,
    options: [
      {
        label: 'Draft',
        value: 'draft',
      },
      {
        label: 'Publish',
        value: 'publish',
      },
    ],
  },
];

const SORT_OPTIONS = [
  { label: 'Tanggal Acara Terdekat', sortType: 'asc', sortBy: 'start_date' },
  { label: 'Tanggal Acara Terjauh', sortType: 'desc', sortBy: 'start_date' },
];

const INITIAL_VALUE_FILTER_BODY = {
  status: undefined,
};
export const ListSkillDevelopmentComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const INITIAL_VALUE_FILTER_QUERY = {
    limit: searchParams.get('limit')?.replace(/[^0-9]/g, '') || 20,
    page: searchParams.get('page')?.replace(/[^0-9]/g, '') || 1,
    orderBy: searchParams.get('orderBy')?.replace(/[^a-zA-Z_]/g, '') || 'start_date',
    orderType: searchParams.get('orderType')?.replace(/[^a-zA-Z]/g, '') || 'desc',
    search: searchParams.get('search')?.replace(/[^a-zA-Z0-9 ]/g, '') || undefined,
  };
  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);
  const [bodyFilter, setBodyFilter] = useState(INITIAL_VALUE_FILTER_BODY);
  const { selectedRowKeys: selectedIds } = useSelector((state) => state.tools);

  const [modals, setModals] = useState({
    sort: false,
    limit: false,
    export: false,
    filter: false,
  });

  const { mutate: exportSkillDevelopment, isLoading: isExporting } = useExportSkillDevelopment();

  const {
    data: infoList,
    isLoading,
    isError,
    error,
  } = useGetInfoSkillDevelopmentList({
    filter: queryFilter,
    body: {
      status: bodyFilter.status,
    },
  });

  const columns = useMemo(
    () => [
      {
        name: 'Title',
        className: 'w-[27%] text-center text-sm font-semibold text-green ',
        dataIndex: 'name',
        render: (record) => (
          <div class='w-[27%] text-sm font-semibold text-green'>
            <div class='flex items-center'>
              <div class='w-12 relative'>
                <StandartImageComponent
                  defaultImage='/img/default-product.jpg'
                  src={record?.image}
                  class='w-12 h-12 object-cover relative'
                />
                {record?.status === 'draft' && (
                  <div class='w-12 bg-yellow bottom-0 italic  h-4 absolute'>
                    <p className='text-white text-xs text-center'>Draft</p>
                  </div>
                )}
              </div>
              <div class='w-[calc(100%-3rem)] pl-2 pr-4'>
                <Tooltip className='max-w-[30%]' content={record?.title}>
                  <div class='block text-sm font-semibold text-dark hover:text-green line-clamp-3'>
                    <HighlightedText text={record?.title} highlight={queryFilter?.search} />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: 'Description',
        className: 'w-[20%] text-sm font-semibold text-green',
        dataIndex: 'description',
        render: (record) => (
          <div class='w-[20%] block'>
            <div class='text-sm font-normal text-gray pr-2 italic  text-ellipsis line-clamp-2 whitespace-normal'>
              {IsJsonString(record?.description) ? (
                <HighlightedText
                  text={convertSlateToPlainText(JSON.parse(record?.description))}
                  highlight={queryFilter?.search}
                />
              ) : (
                '-'
              )}
            </div>
          </div>
        ),
      },
      {
        name: 'Tanggal',
        className: 'w-[15%] text-sm font-semibold text-green',
        dataIndex: 'location',
        render: (record) => (
          <div class='w-[15%] overflow-hidden'>
            <div class='text-sm font-normal text-gray overflow-hidden text-ellipsis whitespace-nowrap'>
              <HighlightedText text={record?.formatted_start_date} highlight={queryFilter?.search} />
            </div>
          </div>
        ),
      },
      {
        name: 'Attachment',
        className: 'w-[20%] text-sm font-semibold text-green',
        dataIndex: '',
        render: (record) => (
          <div class='w-[20%] pr-3'>
            <div class='text-sm font-normal text-gray'>
              {record?.attachments?.length > 0 ? (
                <p className='text-center flex flex-row items-center gap-2 font-semibold'>
                  <span class='icon-ico-attachment text-xl text-green'></span>
                  {`${record?.attachments?.length} Attachment(s)`}
                </p>
              ) : (
                'No Attachment'
              )}
            </div>
          </div>
        ),
      },
      {
        name: 'Pemateri',
        className: 'w-[18%] text-sm font-semibold text-green',
        dataIndex: '',
        render: (record) => (
          <div class='w-[18%]'>
            <div class='text-sm font-normal text-gray flex flex-row justify-between'>
              <p className='text-center'>
                {record?.speakers?.length > 0 ? `${record?.speakers?.[0]?.name}` : '-'}{' '}
                {record?.speakers?.length > 1 && <span class='text-green '>{`+${record?.speakers?.length - 1}`}</span>}
              </p>

              <Link to={`${PATH_CONSTANT.SHARE_INFO.DETAILS_SKILL_DEVELOPMENT.replace(':recordId', record?.id)}`}>
                <button className='text-green cursor-pointer'>
                  <span class='icon-ico-arrow-circle-right mr-2'></span>
                </button>
              </Link>
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryFilter?.search]
  );

  const resetCheckList = () => {
    if (selectedIds.length > 0) {
      dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
    }
  };

  const handleExport = () => {
    exportSkillDevelopment(
      {
        queryFilter: queryFilter,
        bodyFilter: { product_ids: [], is_all_users: null, status: bodyFilter.status },
      },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  useEffect(() => {
    resetCheckList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoList]);

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      <div className='flex items-center mb-5'>
        <div className='w-full flex items-center justify-between'>
          <TableSearchInputComponent
            containerClassName='relative w-[27.6%] mr-2'
            placeholder='Cari...'
            inputProps={{
              disabled: isLoading,
            }}
            initialValue={INITIAL_VALUE_FILTER_QUERY.search}
            onSearch={(value) => {
              setQueryFilter({ ...queryFilter, search: value.keyword, page: 1 });
              navigate({
                search: qs.stringify({
                  ...queryFilter,
                  search: value.keyword || undefined,
                  page: 1,
                }),
              });
            }}
          />

          <div className='w-[calc(100%-40%)] flex justify-start items-center'>
            <TableButtonSortComponent
              disabled={isLoading}
              className='mr-2 outline-none flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
              onClick={() => {
                setModals((prev) => ({ ...prev, sort: true }));
              }}
            />

            <TableButtonLimitComponent
              disabled={isLoading}
              className='mr-2  outline-none  relative flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
              value={queryFilter.limit}
              onClick={() => setModals({ ...modals, limit: true })}
            />

            <TableButtonExport
              className='mr-2 outline-none flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
              disabled={isLoading}
              loading={isExporting}
              onClick={() => {
                setModals({ ...modals, export: true });
              }}
            />
          </div>
          <div className='w-[35%]'>
            <ButtonSubmit
              disabled={isLoading}
              onClick={() => navigate(PATH_CONSTANT.SHARE_INFO.CREATE_INFO_SKILL_DEVELOPMENT)}
              className='bg-green w-full flex items-center justify-center hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
            >
              <span class='icon-ico-plus-circle text-[20px] leading-[21px] mr-3'></span>
              Add Skill Development
            </ButtonSubmit>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          {Boolean(infoList?.data?.total_result) && (
            <div className='italic text-dark text-sm mb-5'>{`Total ${infoList?.data?.total_result} data`}</div>
          )}

          <BasicTableComponent
            withExpand={true}
            rowClassName='flex mb-3 items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out '
            columns={columns}
            dataSource={infoList?.data?.rows || []}
            loading={isLoading}
            error={isError}
            errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
            errorMessage={catchErrorMessage(error?.response?.data?.message)}
          />

          {infoList?.data?.total_result === 0 && (
            <EmptyStateComponent
              message={queryFilter.search ? `No result for query "${queryFilter.search}"` : 'No data available'}
            />
          )}

          <Pagination
            currentPage={queryFilter.page}
            pageSize={queryFilter.limit}
            siblingCount={1}
            totalCount={infoList?.data?.total_result || 0}
            onPageChange={(page) => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
              setQueryFilter({ ...queryFilter, page });
              navigate({
                search: qs.stringify({
                  ...queryFilter,
                  page,
                }),
              });
            }}
          />
        </>
      )}

      <ModalTableFilter
        initialValue={bodyFilter}
        visible={modals.filter}
        filterOptionsProps={FILTER_OPTIONS}
        onChange={(selectedFilter) => {
          setBodyFilter(selectedFilter);
          setQueryFilter((prevState) => ({
            ...prevState,
            page: 1,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              page: 1,
            }),
          });

          setModals({ ...modals, filter: false });
        }}
        onClose={() => setModals({ ...modals, filter: false })}
      />
      <ModalTableSortComponent
        options={SORT_OPTIONS}
        initialValue={SORT_OPTIONS.find(
          (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
        )}
        onChange={({ selected }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            orderBy: selected.sortBy,
            orderType: selected.sortType,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              orderBy: selected.sortBy,
              orderType: selected.sortType,
            }),
          });
          setModals({ ...modals, sort: false });
        }}
        visible={modals.sort}
        onClose={() => setModals({ ...modals, sort: false })}
      />

      <ModalTableLimitComponent
        onChange={({ limit }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            limit,
            page: 1,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              limit,
              page: 1,
            }),
          });
          setModals({ ...modals, limit: false });
        }}
        initialValue={queryFilter.limit}
        visible={modals.limit}
        onClose={() => setModals({ ...modals, limit: false })}
      />

      <ModalConfirmation
        description='Apakah anda yakin ingin eksport data?'
        title='Export Data'
        visible={modals.export}
        onConfirm={handleExport}
        onClose={() => setModals({ ...modals, export: false })}
      />
    </MainDashboardLayoutComponent>
  );
};
