import axios from 'api/api.config';
import qs from 'query-string';
import { useQuery } from 'react-query';

export const ATTENDANCE_USER_QUERY_KEY = {
  ATTENDANCE_USER_LIST: 'ATTENDANCE_USER_LIST',
  ATTENDANCE_USER_DETAILS: 'ATTENDANCE_USER_DETAILS',
  ATTENDANCE_USER_DETAILS_INFO: 'ATTENDANCE_USER_DETAILS_INFO',
};

export function useAttendanceList({ filter, bodyFilter }) {
  return useQuery([ATTENDANCE_USER_QUERY_KEY.ATTENDANCE_USER_LIST, { filter, bodyFilter }], () =>
    axios.post(`/api/v1/admin/attendance?${qs.stringify(filter)}`, bodyFilter).then((res) => res.data)
  );
}

export function useAttendanceDetails(attendanceId) {
  return useQuery([ATTENDANCE_USER_QUERY_KEY.ATTENDANCE_USER_DETAILS, attendanceId], () =>
    axios.get(`/api/v1/admin/attendance/detail/${attendanceId}`).then((res) => res.data)
  );
}
