import React from 'react';

export function Timeline({ data }) {
  return (
    <ol className='border-l border-neutral-300 dark:border-neutral-500'>
      {data.map((item) => (
        <li key={item.id}>
          <div className='flex-start flex items-center pt-3'>
            <div className='-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-neutral-300 dark:bg-neutral-500'></div>

            {/* <p className='text-sm text-neutral-500 dark:text-neutral-300'>{item.date}</p> */}
            <span class='font-medium px-1 bg-white rounded-full text-green-700 bg-green-100 border border-green-300 opacity-40'>
              <span class='text-xs font-normal leading-3 max-w-full flex-initial'>{item.date}</span>
            </span>
          </div>
          <div className='mb-4 ml-4 mt-2'>
            <h4 className='mb-1 leading-normal text-sm text-gray'>{item.title}</h4>
            {/* <p className='mb-3 text-neutral-500 dark:text-neutral-300'>{item.content}</p> */}
          </div>
        </li>
      ))}
    </ol>
  );
}
