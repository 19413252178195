import clsx from 'clsx';

export function TabItem({
  label,
  value,
  onClick,
  isActive,
  className = 'nav-item flex-auto text-center',
  labelClassName = 'cursor-pointer nav-link w-full block font-semibold text-sm leading-tight border border-transparent rounded-tl-sm rounded-tr-sm text-gray border-solid border-b-1 px-2 py-3 hover:bg-gray-100',
}) {
  return (
    <li
      key={`tab-item-${value}`}
      className={className}
      onClick={() => {
        onClick(value);
      }}
    >
      <span className={clsx(labelClassName, isActive && 'active')}>{label}</span>
    </li>
  );
}
