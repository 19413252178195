import moment from 'moment';
import { useMemo, useState } from 'react';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';

import { BounceLoading } from 'components/loader/bounce.loading';
import { PATH_CONSTANT } from 'config/path.constant';
import { RangeDatePicker } from 'components/date-picker/range-date-picker.component';

import { TableButtonExport } from 'components/table/table-button-export.component';
import { TableButtonFilterComponent } from 'components/table/table-button-filter.component';
import { ModalTableFilter } from 'components/modal/modal-table-filter.component';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { AnalyticsSummaryCardInfo } from '../sales/summary-card.component';
import { AnalyticVisitorTable } from './visitor-table.component';
import {
  useGetAnalyticsTotalTraffic,
  useGetAnalyticsTrafficGraph,
} from 'api/analytics/traffic/analytic-traffic.query.api';
import { AnalyticsTrafficChart } from './analytics-traffic.chart';
import { TabsComponent } from 'components/tab/tabs.component';
import { TabItem } from 'components/tab/tab-item.component';
import { useExportAnalyticTraffic } from 'api/analytics/traffic/analytic-traffic.mutation.api';

const INITIAL_PREVIOUS_DAY_RANGE = 6;

export const DATA_GRAPH_TYPE = {
  BUYER: 'buyer',
  VISITOR: 'visitor',
};

const GRAPH_TYPE_TAB = [
  {
    label: 'Grafik Pengunjung',
    value: DATA_GRAPH_TYPE.VISITOR,
  },
  {
    label: 'Grafik Pembeli',
    value: DATA_GRAPH_TYPE.BUYER,
  },
];

const BREAD_CUMB_ITEMS = [
  {
    title: 'Analytics',
    path: PATH_CONSTANT.ANALYTICS.TRAFFIC,
  },
  {
    title: 'Traffic Store',
    path: PATH_CONSTANT.ANALYTICS.TRAFFIC,
  },
];

const INITIAL_DATE_FILTER = {
  startDate: moment().subtract(INITIAL_PREVIOUS_DAY_RANGE, 'day').toDate(),
  endDate: moment().toDate(),
};

const INITIAL_VALUE_FILTER_QUERY = {
  limit: 20,
  page: 1,
  orderBy: 'report_date',
  orderType: 'desc',
  search: undefined,
};
const INITIAL_VALUE_FILTER_BODY = {
  store_ids: [],
};

export const AnalyticsTraficComponent = () => {
  const [dateFilter, setDateFilter] = useState({
    startDate: moment(INITIAL_DATE_FILTER.startDate).format('YYYY-MM-DD'),
    endDate: moment(INITIAL_DATE_FILTER.endDate).format('YYYY-MM-DD'),
  });

  const [selectedTab, setSelectedTab] = useState(DATA_GRAPH_TYPE.VISITOR);
  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);
  const [bodyFilter, setBodyFilter] = useState(INITIAL_VALUE_FILTER_BODY);

  const [modals, setModals] = useState({
    export: false,
    filter: false,
    result: false,
  });

  const { data: totalTraffic, isLoading: isLoadingTotalSales } = useGetAnalyticsTotalTraffic({
    filter: dateFilter,
    body: bodyFilter,
  });

  const { data: trafficGraph, isLoading: isLoadingTrafficGraph } = useGetAnalyticsTrafficGraph({
    filter: dateFilter,
    body: bodyFilter,
    type: selectedTab,
  });

  const { mutate: exportData, isLoading: isExporting } = useExportAnalyticTraffic();

  // format data for chart
  const salesGraphData = useMemo(() => {
    if (trafficGraph) {
      return {
        label: trafficGraph.data.map((item) => item.label && moment(item.label).format('D MMM YYYY')),
        data: trafficGraph.data.map((item) => item.value),
      };
    }
    return {
      label: [],
      data: null,
    };
  }, [trafficGraph]);

  const isFilterActive = useMemo(() => {
    if (bodyFilter.store_ids.length > 0) {
      return true;
    }
  }, [bodyFilter]);

  const handleExport = () => {
    exportData(
      {
        queryFilter: {
          ...dateFilter,
          orderBy: 'total_visitor',
          orderType: 'desc',
        },
        bodyFilter,
      },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  const handleOnTabChange = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      {isLoadingTotalSales && isLoadingTrafficGraph ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          <div class='flex items-center justify-center w-full gap-x-2 mb-5'>
            <RangeDatePicker
              initialStartDate={moment(dateFilter.startDate).toDate()}
              initialEndDate={moment(dateFilter.endDate).toDate()}
              maxDate={moment().toDate()}
              onChange={({ start, end }) => {
                setDateFilter({
                  startDate: moment(start).format('YYYY-MM-DD'),
                  endDate: moment(end).format('YYYY-MM-DD'),
                });
              }}
            />

            <TableButtonFilterComponent
              active={isFilterActive}
              onClick={() => setModals({ ...modals, filter: true })}
            />
            <TableButtonExport onClick={() => setModals({ ...modals, export: true })} loading={isExporting} />
          </div>
          <div class='flex justify-between mb-5'>
            <AnalyticsSummaryCardInfo
              title='Total Pengunjung'
              tooltipText='Merupakan persentase perbandingan Total Pengunjung pada periode saat ini dengan periode sebelumnya'
              percentageSummaryValue={totalTraffic?.data?.value_visitor}
              currentPeriodeDate={`${moment(totalTraffic?.data?.current_period?.[0]).format('DD MMM YY')} - ${moment(
                totalTraffic?.data?.current_period?.[1]
              ).format('DD MMM YY')}`}
              currentPeriodeValue={
                totalTraffic?.data?.total_visitor ? `${totalTraffic?.data?.total_visitor.toLocaleString()} Orang` : '-'
              }
              previousPeriodeDate={`${moment(totalTraffic?.data?.prev_period?.[0]).format('DD MMM YY')} - ${moment(
                totalTraffic?.data?.prev_period?.[1]
              ).format('DD MMM YY')}`}
              previousPeriodeValue={
                totalTraffic?.data?.prev_total_visitor
                  ? `${totalTraffic?.data?.prev_total_visitor.toLocaleString()} Orang`
                  : '-'
              }
            />
            <AnalyticsSummaryCardInfo
              title='Total Pembeli'
              tooltipText='Merupakan persentase perbandingan Total Pembeli pada periode saat ini dengan periode sebelumnya'
              percentageSummaryValue={totalTraffic?.data?.value_buyer}
              currentPeriodeDate={`${moment(totalTraffic?.data?.current_period?.[0]).format('DD MMM YY')} - ${moment(
                totalTraffic?.data?.current_period?.[1]
              ).format('DD MMM YY')}`}
              currentPeriodeValue={
                totalTraffic?.data?.total_buyer ? `${totalTraffic?.data?.total_buyer.toLocaleString()} Orang` : '-'
              }
              previousPeriodeDate={`${moment(totalTraffic?.data?.prev_period?.[0]).format('DD MMM YY')} - ${moment(
                totalTraffic?.data?.prev_period?.[1]
              ).format('DD MMM YY')}`}
              previousPeriodeValue={
                totalTraffic?.data?.prev_total_buyer
                  ? `${totalTraffic?.data?.prev_total_buyer.toLocaleString()} Orang`
                  : '-'
              }
            />
          </div>

          <TabsComponent value={selectedTab} onChange={handleOnTabChange} disabled={isLoadingTrafficGraph}>
            {GRAPH_TYPE_TAB.map((tab) => (
              <TabItem
                label={tab.label}
                key={tab.value}
                value={tab.value}
                className='nav-item text-center'
                labelClassName='nav-link cursor-pointer w-full block font-semibold text-sm leading-tight border border-transparent rounded-tl-sm rounded-tr-sm text-gray border-solid border-b-1 px-6 py-3 hover:bg-gray-100'
              />
            ))}
          </TabsComponent>

          <AnalyticsTrafficChart type={selectedTab} labels={salesGraphData.label} data={salesGraphData.data} />
          <AnalyticVisitorTable
            queryFilter={queryFilter}
            bodyFilter={bodyFilter}
            setQueryFilter={setQueryFilter}
            dateFilter={dateFilter}
          />
        </>
      )}
      <ModalTableFilter
        initialValue={bodyFilter}
        filterStatusOptions={null}
        visible={modals.filter}
        onChange={(selectedFilter) => {
          setBodyFilter(selectedFilter);

          setModals({ ...modals, filter: false });
        }}
        onClose={() => setModals({ ...modals, filter: false })}
      />
      <ModalConfirmation
        description='Apakah anda yakin ingin export data?'
        title='Export Data'
        visible={modals.export}
        onConfirm={handleExport}
        onClose={() => setModals({ ...modals, export: false })}
      />
    </MainDashboardLayoutComponent>
  );
};
