import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { BounceLoading } from 'components/loader/bounce.loading';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { PATH_CONSTANT } from 'config/path.constant';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { Select } from 'components/form/select.component';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { useGetDetailsOvertime } from 'api/schedules/overtime/overtime.query.api';
import { useApprovalOvertime } from 'api/schedules/overtime/overtime.mutation.api';
import { FileAttachmentComponent } from 'components/attachment/file-attacment.component';
import { catchErrorMessage } from 'ui-utils/string.utils';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Jadwal & Presensi',
    path: PATH_CONSTANT.OVERTIME.LIST,
  },
  {
    title: 'Lembur',
    path: PATH_CONSTANT.OVERTIME.LIST,
  },
  {
    title: 'Detail Lembur',
    path: PATH_CONSTANT.OVERTIME.LIST,
  },
];

const STATUS_CLASSNAME = {
  REJECTED: 'inline-block bg-red-status text-center py-1 px-1 rounded-sm text-red-1 text-sm font-medium',
  APPROVED: 'inline-block bg-green-status text-center  py-1 px-1 rounded-sm text-green text-sm font-medium',
  WAITING_APPROVEMENT: 'inline-block bg-yellow-light text-center  py-1 px-1 rounded-sm text-yellow text-sm font-medium',
};

const STATUS_LABEL = {
  REJECTED: 'Rejected',
  APPROVED: 'Approved',
  WAITING_APPROVEMENT: 'Waiting Approvement',
};

const APPROVE_OPTIONS = [
  {
    id: 'approved',
    name: 'Approve',
  },
  {
    id: 'rejected',
    name: 'Reject',
  },
];

export const DetailOvertimeComponent = () => {
  const { id } = useParams();

  const [modalConfirmUpdateStatus, setModalConfirmUpdateStatus] = useState({
    open: false,
    scheduleId: null,
  });

  const [selectedAction, setSelectedAction] = useState(null);

  const { data: overtimeDetails, isLoading, isSuccess, isError, error } = useGetDetailsOvertime(id);

  const { mutate: approveOvertime, isLoading: isSubmitting } = useApprovalOvertime();

  const handleOnUpdateSchedule = () => {
    approveOvertime(
      {
        overtime_ids: [id],
        status: selectedAction,
      },
      {
        onSuccess: () => {
          setModalConfirmUpdateStatus({
            open: false,
            scheduleId: null,
          });
          toast.success('Success update this overtime!');
        },
      }
    );
  };

  const handleOnChangeAction = (e) => {
    setSelectedAction(e.target.value);
  };

  useEffect(() => {
    if (overtimeDetails?.data?.status && overtimeDetails?.data?.status !== 'waiting_approvement') {
      setSelectedAction(overtimeDetails?.data?.status);
    }
  }, [overtimeDetails]);

  return (
    <MainDashboardLayoutComponent
      breadCumbs={BREAD_CUMB_ITEMS}
      isError={isError}
      errorTitle={error?.response?.data?.status}
      erroMessage={catchErrorMessage(error?.response?.data?.message)}
    >
      <>
        {isLoading && (
          <div className='h-[70vh] flex w-full items-center text-center'>
            <BounceLoading color='#5E755A' />
          </div>
        )}

        {isSuccess && (
          <>
            <div class='bg-gray-3 p-5 xl:p-8 rounded-sm flex items-center mb-8'>
              <div class='w-[200px]'>
                <StandartImageComponent
                  src={overtimeDetails?.data?.user?.profile_picture}
                  className='w-40 h-40 rounded-full border-8 border-solid border-green-2 object-cover'
                />
              </div>
              <div class='w-[calc(100%-200px)]'>
                <div class='flex items-center'>
                  <div class='w-1/3 border-r border-solid pr-5 border-green'>
                    <div class='text-2xl font-semibold text-green'>{overtimeDetails?.data?.user?.name}</div>
                    <div class='text-sm text-gray font-medium mb-3'>{overtimeDetails?.data?.user?.email}</div>
                    <div class='text-2xl font-semibold text-green'>{overtimeDetails?.data?.store?.name}</div>
                    <div class='text-sm text-gray font-medium mb-3'>{overtimeDetails?.data?.store?.city?.name}</div>
                  </div>
                  <div class='w-2/3 pl-20 flex flex-col justify-center items-center'>
                    <div className='flex flex-row items-center w-full justify-start'>
                      <div className='flex flex-row items-center mt-5  w-5/12'>
                        <div className='icon-ico-calendar text-2xl text-green-3 font-normal mr-3'></div>
                        <div className='flex flex-col px-[2%]'>
                          <p class='text-sm text-green font-bold mb-2'>Tanggal Lembur</p>
                          <p class='text-sm text-gray font-medium mb-2'>{overtimeDetails?.data?.date_formatted}</p>
                        </div>
                      </div>

                      <div className='flex flex-row items-center mt-5 w-5/12'>
                        <div className='icon-ico-info text-2xl text-green-3 font-normal mr-3'></div>
                        <div className='flex flex-col px-[2%]'>
                          <p class='text-sm text-green font-bold mb-2'>Status</p>
                          <p class={STATUS_CLASSNAME?.[overtimeDetails?.data?.status?.toUpperCase()]}>
                            {STATUS_LABEL?.[overtimeDetails?.data?.status?.toUpperCase()]}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col  w-full justify-start'>
                      <div className='flex flex-row items-center mt-5 w-5/12'>
                        <div className='icon-ico-clock text-2xl text-green-3 font-normal mr-3'></div>
                        <div className='flex flex-col px-[2%]'>
                          <p class='text-sm text-green font-bold mb-2'>Waktu Mulai</p>
                          <p class='text-sm text-gray font-medium mb-2'>
                            {overtimeDetails?.data?.start_hour_formatted
                              ? `${overtimeDetails?.data?.start_hour_formatted}`
                              : '-'}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-row w-full justify-start items-center'>
                      <div className='flex flex-row items-center mt-5 w-5/12'>
                        <div className='icon-ico-clock text-2xl text-green-3 font-normal mr-3'></div>
                        <div className='flex flex-col px-[2%]'>
                          <p class='text-sm text-green font-bold mb-2'>Waktu Selesai</p>
                          <p class='text-sm text-gray font-medium mb-2'>
                            {overtimeDetails?.data?.end_hour_formatted
                              ? `${overtimeDetails?.data?.end_hour_formatted}`
                              : '-'}
                          </p>
                        </div>
                      </div>
                      <div className='flex flex-row w-2/4 justify-start gap-3 mt-4'>
                        <Select
                          onChange={handleOnChangeAction}
                          name='type'
                          withNativeDownIcon
                          containerClassName=' w-[60%]  relative '
                          placeholder='Select Action'
                          options={APPROVE_OPTIONS}
                          value={selectedAction}
                        />
                        <ButtonSubmit
                          className='bg-green h-10 hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white font-semibold text-sm px-7 '
                          title='Apply'
                          disabled={!selectedAction}
                          onClick={() => {
                            setModalConfirmUpdateStatus((prev) => ({
                              ...prev,
                              open: true,
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className='h-[70vh] flex w-full items-center text-center'>
                <BounceLoading color='#5E755A' />
              </div>
            ) : (
              <>
                <div className='mb-5'>
                  <div className='flex flex-row justify-between gap-2 mb-5'>
                    <div class='text-2xl font-semibold text-green mr-10 '>Note & Attachment</div>
                  </div>
                  <div className='border border-gray-1 flex flex-row'>
                    <div className='w-1/2 border-r border-solid border-gray-1'>
                      <div className='p-5'>
                        <div className='flex flex-row justify-between gap-10' id='clock-in-info-time'>
                          <div className='w-full'>
                            <div className='text-md text-green  font-medium mb-3'>Notes</div>
                            <div className='text-sm text-gray font-medium mb-3'>{overtimeDetails?.data?.note}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='w-1/2 border-r border-solid border-gray-1'>
                      <div className='p-5'>
                        <div className='flex flex-row justify-between gap-10' id='clock-in-info-time'>
                          <div className='w-full'>
                            <div className='text-md text-green  font-medium mb-3'>Gambar & File Attachment</div>
                            <div className='flex flex-row flex-wrap gap-5 mb-2'>
                              {overtimeDetails?.data?.images.length === 0 && '-'}
                              {overtimeDetails?.data?.images?.map((imageItem) => (
                                <StandartImageComponent
                                  key={imageItem.image_url}
                                  src={imageItem.image_url}
                                  className='h-56 w-56 '
                                  withZoom
                                />
                              ))}
                            </div>
                            <div className='mt-3'>
                              {overtimeDetails?.data?.attachments?.map((attachmentItem, index) => (
                                <FileAttachmentComponent
                                  url={attachmentItem.attachment_url}
                                  title={`Attachment ${index + 1} - `}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>

      <ModalConfirmation
        description='Apakah anda yakin ingin mengubah status overtime yang anda pilih?'
        title='Update Data'
        textConfirm='Update'
        loading={isSubmitting}
        visible={modalConfirmUpdateStatus.open}
        onConfirm={handleOnUpdateSchedule}
        onClose={() =>
          setModalConfirmUpdateStatus({
            open: false,
          })
        }
      />
    </MainDashboardLayoutComponent>
  );
};
