import _ from 'lodash';
import { FieldArray, Formik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';

import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { InputComponent } from 'components/form/input.component';
import { generateUUID } from 'ui-utils/string.utils';
import { Select } from 'components/form/select.component';
import { useAllStoreList } from 'api/store/store-list/store-list.query.api';
import { BounceLoading } from 'components/loader/bounce.loading';
import { useNavigate, useParams } from 'react-router-dom';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { PATH_CONSTANT } from 'config/path.constant';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { useAllUserList } from 'api/users/user-list/user-list.query.api';
import { useAllProductList } from 'api/product/product-list/product-list.query.api';
import { CreatePOValidationSchema } from 'config/form/stock-movement/create-po.validation.schema';
import { useGetListPODetails, useGetListWarehouse } from 'api/stock-movement/pre-order/pre-order.query.api';
import { useUpdatePreOrder } from 'api/stock-movement/pre-order/pre-order.mutation.api';
import { toast } from 'react-toastify';

const ORDER_TYPE_OPTIONS = [
  { id: 'beli_putus', name: 'Beli Putus' },
  { id: 'konsinyasi', name: 'Konsinyasi' },
];

const BREAD_CUMB_ITEMS = [
  {
    title: 'Stock Movement',
    path: PATH_CONSTANT.STOCK_MOVEMENT.PO_LIST,
  },
  {
    title: 'Edit PO',
    path: PATH_CONSTANT.STOCK_MOVEMENT.PO_LIST,
  },
];

const formInitialValue = {
  type: '',
  user: '',
  store: '',
  warehouse: '',
  user_phone: '',
  products: [
    {
      name: '',
      price: null,
      qty: null,
      key: generateUUID(),
    },
  ],
};

export const EditPOComponent = () => {
  const { poId } = useParams();
  const formikRef = useRef();
  const navigate = useNavigate();

  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [initialFormValues, setInitialFormValues] = useState(formInitialValue);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectedStoreDetails, setSelectedStoreDetails] = useState(null);

  const { data: storeData, isLoading: isLoadingStore } = useAllStoreList();
  const { data: usersData, isLoading: isLoadingUser } = useAllUserList();
  const { data: productsData, isLoading: isLoadingProducts } = useAllProductList();
  const { data: warehouseListData, isLoading: isLoadingWarehouse } = useGetListWarehouse();
  const { data: detailsPO, isLoading: isLoadingDetails } = useGetListPODetails(poId);
  const { mutateAsync: updatePreOrder, isLoading: isCreating } = useUpdatePreOrder();

  const storeOptions = useMemo(() => {
    if (storeData) {
      return storeData?.data?.map((item) => ({
        id: item.id,
        name: item.name,
      }));
    }
    return [];
  }, [storeData]);

  const userOptions = useMemo(() => {
    if (usersData && selectedStoreDetails?.id) {
      return usersData?.data
        ?.filter((userItem) => userItem.store_id === selectedStoreDetails?.id)
        .map((item) => ({
          id: item.id,
          name: item.name,
        }));
    }
    if (usersData) {
      return usersData?.data?.map((item) => ({
        id: item.id,
        name: item.name,
      }));
    }

    return [];
  }, [usersData, selectedStoreDetails]);

  const productOptions = useMemo(() => {
    if (productsData) {
      return productsData?.data
        ?.map((item) => ({
          id: item.id,
          name: item.name,
        }))
        .filter((item) => !selectedProductIds.includes(item.id));
    }
    return [];
  }, [productsData, selectedProductIds]);

  const handleOnFormSubmit = async (formValues) => {
    if (_.isEqual(formValues, initialFormValues)) {
      navigate(`${PATH_CONSTANT.STOCK_MOVEMENT.PO_LIST}?tab=submitted`);
      return;
    }
    const requestPayload = {
      type: formValues.type,
      user_id: formValues.user || null,
      store_id: formValues.store,
      warehouse_id: formValues.warehouse,
      user_phone: formValues.user_phone ? `0${formValues.user_phone}` : ``,
      user_name: userOptions.find((item) => item.id === formValues.user)?.name,
      store_name: storeOptions.find((item) => item.id === formValues.store)?.name,
      store_address: storeData?.data?.find((item) => item.id === formValues.store)?.address,
      store_image: storeData?.data?.find((item) => item.id === formValues.store)?.image,
      store_city: storeData?.data?.find((item) => item.id === formValues.store)?.city?.name,
      warehouse_name: warehouseListData?.data?.find((item) => item.id === formValues.warehouse)?.name,
      warehouse_address: warehouseListData?.data?.find((item) => item.id === formValues.warehouse)?.address,
      products: formValues.products.map((item) => ({
        id: item.name,
        name: productsData?.data?.find((product) => product.id === item.name)?.name,
        price: item.price.replaceAll('.', ''),
        qty: item.qty,
      })),
    };

    await updatePreOrder({
      formValues: requestPayload,
      id: poId,
    });
    toast.success('Pre-Order berhasil diupdate!');
    navigate(`${PATH_CONSTANT.STOCK_MOVEMENT.PO_LIST}?tab=submitted`);
  };

  useEffect(() => {
    if (detailsPO?.data) {
      const initialProducts = detailsPO?.data?.requested_products?.map((item) => ({
        name: item.product_id,
        price: item.price && new Intl.NumberFormat('id-ID').format(item.price),
        qty: item.qty,
        key: generateUUID(),
      }));
      const initialValues = {
        type: detailsPO?.data?.type,
        user: detailsPO?.data?.user_id,
        store: detailsPO?.data?.store_id,
        warehouse: detailsPO?.data?.warehouse_id,
        user_phone: detailsPO?.data?.user_phone.replace(/^0/, '').replace(/^\+62/, '').replace(/^62/, ''),
        products: initialProducts,
      };

      const initalSelectedStoreDetails = storeData?.data?.find((item) => item.id === detailsPO?.data?.store_id);
      setSelectedStoreDetails(initalSelectedStoreDetails);
      setInitialFormValues(initialValues);
      formikRef?.current?.setValues(initialValues);

      setTimeout(() => {
        setSelectedProductIds(detailsPO?.data?.requested_products?.map((item) => item.product_id));
      }, 1000);
    }
  }, [detailsPO, storeData?.data]);

  const isDisabledAllForm = isCreating;
  const isFetchingData = isLoadingStore || isLoadingUser || isLoadingProducts || isLoadingWarehouse || isLoadingDetails;

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      <div class='text-base text-dark font-semibold mb-4'>General Data</div>

      {isFetchingData ? (
        <div className='h-[70vh] flex w-full items-center text-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <Formik
          validationSchema={CreatePOValidationSchema}
          innerRef={formikRef}
          initialValues={formInitialValue}
          onSubmit={handleOnFormSubmit}
        >
          {({ errors, values, handleChange, handleBlur, touched, setFieldValue, handleSubmit }) => {
            return (
              <>
                <div className='w-[80%] md:w-[90%] flex flex-wrap mb-10'>
                  <div class='w-1/2 pr-8'>
                    <Select
                      label='Tipe Pre-Order'
                      disabled={isFetchingData}
                      onChange={handleChange}
                      name='type'
                      containerClassName='form-input btn-arrow-create z-1 relative mb-3'
                      placeholder='Pilih Tipe Pre-Order'
                      error={touched.type && errors.type}
                      options={ORDER_TYPE_OPTIONS}
                      value={values.type}
                    />
                  </div>
                  <div class='w-1/2 pr-8'>
                    <Select
                      label='Nama BA'
                      disabled={isFetchingData || !values?.store}
                      onChange={(e) => {
                        handleChange(e);

                        const selectedUser = usersData?.data?.find((item) => item.id === e.target.value);
                        if (selectedUser) {
                          // if phone number start with 0, remove it
                          // if phone number start with +62, remove it
                          // if phone number start with 62, remove it
                          const phone = selectedUser.phone.replace(/^0/, '').replace(/^\+62/, '').replace(/^62/, '');
                          setFieldValue('user_phone', phone);
                        }
                        if (!e.target.value && values.user_phone) {
                          setFieldValue('user_phone', '');
                        }
                      }}
                      name='user'
                      containerClassName='form-input btn-arrow-create  z-1 relative mb-3'
                      placeholder='Pilih BA'
                      error={touched.user && errors.user}
                      options={userOptions}
                      value={values.user}
                    />
                  </div>

                  <div class='w-1/2 pr-8'>
                    <Select
                      label='Nama Store'
                      disabled={isFetchingData}
                      showListOnChange={false}
                      onChange={(e) => {
                        handleChange(e);
                        setSelectedStoreDetails(storeData?.data.find((item) => item.id === e.target.value));
                      }}
                      name='store'
                      containerClassName='form-input btn-arrow-create z-1 relative mb-3'
                      placeholder='Pilih Nama Store'
                      error={touched.store && errors.store}
                      options={storeOptions}
                      value={values.store}
                    />
                    <div class='relative mb-3'>
                      {!selectedStoreDetails ? (
                        <div class='w-full min-h-[150px] border border-solid border-gray-1 rounded-sm flex justify-center items-center'>
                          <div class='text-sm text-dark'>Tidak Ada Data Terpilih</div>
                        </div>
                      ) : (
                        <div class='w-full border border-solid border-gray-1 rounded-sm p-4'>
                          <div class='flex items-center justify-between mb-3'>
                            <div class='text-sm font-semibold text-green'>{selectedStoreDetails?.name}</div>
                            <div class='text-sm font-medium italic text-dark'>{selectedStoreDetails?.city?.name}</div>
                          </div>
                          <p class='text-sm'>{selectedStoreDetails?.address}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div class='w-1/2 pr-8'>
                    <InputComponent
                      disabled
                      numberOnly={true}
                      key='user_phone'
                      name='user_phone'
                      value={values.user_phone}
                      error={touched.user_phone && errors.user_phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      containerClass='form-input relative mb-3'
                      label='Telepon BA'
                      prefix='+62'
                      inputClass='w-full border rounded-sm outline-none border-solid border-gray-1 py-2.5 pl-16 pr-4 bg-white text-sm text-dark placeholder:text-gray-2 focus:border-green focus:text-green focus:bg-gray-focus transition duration-100 ease-in-out'
                      type='tel'
                      placeholder='Nomor Telepon'
                    />
                  </div>
                  <div class='w-1/2 pr-8'>
                    <Select
                      label='Warehouse'
                      disabled={isFetchingData}
                      onChange={handleChange}
                      name='warehouse'
                      containerClassName='form-input btn-arrow-create  z-1 relative mb-3'
                      placeholder='Pilih Warehouse'
                      error={touched.warehouse && errors.warehouse}
                      options={warehouseListData?.data || []}
                      value={values.warehouse}
                    />
                  </div>
                </div>
                <div class='text-base text-dark font-semibold mb-4'>Item Produk</div>
                <div class='w-[80%] md:w-[90%] flex flex-wrap mb-3'>
                  <div class='w-5/12 pr-8'>
                    <label for='product' class='text-sm font-semibold text-gray mb-2'>
                      Nama Product
                    </label>
                  </div>
                  <div class='w-3/12 pr-8'>
                    <label for='harga' class='text-sm font-semibold text-gray mb-2'>
                      Harga Product
                    </label>
                  </div>
                  <div class='w-3/12 pr-8'>
                    <label for='Quantity' class='text-sm font-semibold text-gray mb-2'>
                      Quantity
                    </label>
                  </div>
                </div>
                <FieldArray
                  name='products'
                  render={(arrayHelpers) => (
                    <>
                      {values.products.map((product, index) => {
                        return (
                          <div class='w-[80%] md:w-[90%] flex flex-wrap mb-2 ' key={product.key}>
                            <div class='w-5/12 pr-8'>
                              <Select
                                disabled={isFetchingData}
                                key={`product-name-${product.key}`}
                                onChange={(e) => {
                                  handleChange(e);
                                  const selectedProduct = productsData?.data?.find(
                                    (product) => product.id === e.target.value
                                  );
                                  const formattedPrice = new Intl.NumberFormat('id-ID').format(selectedProduct?.price);

                                  // if formattedPrice is not a number, set it to 0
                                  const price = isNaN(formattedPrice) ? '' : formattedPrice;
                                  setFieldValue(`products[${index}].price`, price);

                                  const selectedItemIds = values.products
                                    .map((product) => product.name)
                                    .filter(Boolean);
                                  setSelectedProductIds([...selectedItemIds, selectedProduct?.id]);
                                }}
                                name={`products[${index}].name`}
                                inputClassName='appearance-none block w-full cursor-pointer px-4 py-2.5 text-sm text-dark bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-1 rounded-sm transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green focus:outline-none'
                                containerClassName={undefined}
                                placeholder='Pilih Product'
                                error={touched[`products`]?.[index]?.name && errors[`products`]?.[index]?.name}
                                options={productOptions}
                                value={product.name}
                              />
                            </div>
                            <div class='w-3/12 pr-8'>
                              <InputComponent
                                disabled
                                numberOnly={true}
                                name={`products[${index}].price`}
                                value={product.price}
                                error={touched[`products`]?.[index]?.price && errors[`products`]?.[index]?.price}
                                onBlur={handleBlur}
                                placeholder='Input Harga'
                              />
                            </div>
                            <div class='w-3/12 pr-8'>
                              <InputComponent
                                disabled={isDisabledAllForm}
                                numberOnly={true}
                                name={`products[${index}].qty`}
                                key={`products[${index}].qty`}
                                value={product.qty}
                                error={touched[`products`]?.[index]?.qty && errors[`products`]?.[index]?.qty}
                                onChange={(e) => {
                                  setFieldValue(`products[${index}].qty`, e.target.value.replace(/[^0-9]+/g, ''));
                                }}
                                onBlur={handleBlur}
                                placeholder='Input Quantity'
                              />
                            </div>
                            {values.products.length > 1 && (
                              <div class='w-1/12 mt-1'>
                                <button
                                  onClick={async () => {
                                    const removedItemId = values.products[index].name;
                                    const selectedItemIds = values.products
                                      .map((product) => product.name)
                                      .filter((item) => item !== removedItemId);
                                    setSelectedProductIds(selectedItemIds);
                                    arrayHelpers.remove(index);
                                  }}
                                >
                                  <span class='icon-ico-delete text-red-1 text-xl'></span>
                                </button>
                              </div>
                            )}
                          </div>
                        );
                      })}
                      {/* If the last item field still invalid, dont show add new field button */}
                      {values.products.length > 0 &&
                      values.products[values.products.length - 1].qty &&
                      values.products[values.products.length - 1].name &&
                      values.products[values.products.length - 1].price &&
                      !errors[`products`]?.[values.products.length - 1]?.name &&
                      !errors[`products`]?.[values.products.length - 1]?.price &&
                      !errors[`products`]?.[values.products.length - 1]?.qty ? (
                        <ButtonSubmit
                          onClick={() => {
                            arrayHelpers.push({
                              name: '',
                              price: '',
                              qty: '',
                              key: generateUUID(),
                            });
                          }}
                          className='text-sm px-3 py-2 border border-solid border-green-3 bg-light-green inline-block text-dark font-semibold rounded-sm hover:bg-green-2 transition duration-100 ease-in-out'
                        >
                          <span class='icon-ico-plus-circle text-green mr-2'></span> Tambah Produk
                        </ButtonSubmit>
                      ) : null}
                    </>
                  )}
                />

                <div class='w-[80%] md:w-[90%]'>
                  <div class='py-14 relative flex justify-center'>
                    <ButtonSubmit
                      loading={isCreating}
                      disabled={!CreatePOValidationSchema.isValidSync(values)}
                      onClick={() => {
                        setModalConfirmation(true);
                      }}
                      className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-8'
                      type='button'
                    >
                      <StandartImageComponent src='/img/icon/arrow-up.svg' class='max-w-full mr-3' /> Update Pre Order
                    </ButtonSubmit>
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      )}

      <ModalConfirmation
        description='Apakah anda yakin akan merubah request pre order?'
        title='Update Pre Order'
        imageIcon='/img/info.svg'
        textConfirm='Update'
        loading={isCreating}
        visible={modalConfirmation}
        onConfirm={() => {
          formikRef?.current?.handleSubmit();
        }}
        onClose={() => setModalConfirmation(false)}
      />
    </MainDashboardLayoutComponent>
  );
};
