import clsx from 'clsx';
import { BounceLoading } from 'components/loader/bounce.loading';

export function ButtonSubmit({
  className = 'bg-green hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7',
  onClick = () => {},
  disabled = false,
  title = 'Submit',
  loading = false,
  type = 'submit',
  children,
  loadingColor = '#fff',
}) {
  return (
    <button
      onClick={onClick}
      className={clsx('disabled:cursor-not-allowed disabled:opacity-50', className)}
      disabled={disabled || loading}
      type={type}
    >
      {loading ? <BounceLoading color={loadingColor} /> : children ? children : title}
    </button>
  );
}
