import { useMemo, useState } from 'react';
import { BasicTableComponent } from 'components/table/table-basic.component';
import { TableButtonSortComponent } from 'components/table/table-button-sort.component';
import { TableSearchInputComponent } from 'components/table/table-search.component';
import { ModalTableSortComponent } from 'components/modal/modal-table-sort.component';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { useGetAnalyticsListProduct } from 'api/analytics/product/analytic-product.query.api';
import { catchErrorMessage } from 'ui-utils/string.utils';
import { EmptyStateComponent } from 'components/empty-data';
import { Pagination } from 'components/table/table-pagination.component';
import { HighlightedText } from 'components/highlighted-text';

const SORT_OPTIONS = [
  { label: 'Total Penjualan Tertinggi', sortType: 'desc', sortBy: 'total_product_sold' },
  { label: 'Total Penjualan Terendah', sortType: 'asc', sortBy: 'total_product_sold' },
];

export function AnalyticsProductTable({ bodyFilter, dateFilter, setQueryFilter, queryFilter }) {
  const [modals, setModals] = useState({
    sort: false,
  });

  const {
    data: listProducts,
    isLoading,
    isError,
    error,
  } = useGetAnalyticsListProduct({
    body: bodyFilter,
    filter: {
      ...queryFilter,
      ...dateFilter,
    },
  });

  const columns = useMemo(
    () => [
      {
        name: 'Product Name',
        className: 'w-[55%] text-sm font-semibold text-green pl-6',
        dataIndex: 'name',
        render: (record) => (
          <div class='w-[55%] text-sm font-semibold text-green pl-6'>
            <div class='flex items-center'>
              <div class='w-12'>
                <StandartImageComponent src={record?.product_image || '/img/default-product.jpg'} class='max-w-full' />
              </div>
              <div class='w-[calc(100%-3rem)] pl-5 xl:pl-5 pr-9'>
                <div class='text-sm font-semibold text-dark hover:text-green'>
                  <HighlightedText text={record?.name} highlight={queryFilter?.search} />
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: 'Product SKU',
        className: 'w-[20%] text-sm font-semibold text-green',
        dataIndex: 'sku_code',
        render: (record, sku_code) => (
          <div class='w-[20%]'>
            <div class='text-sm font-normal text-gray mb-1'>
              <HighlightedText text={sku_code} highlight={queryFilter?.search} />
            </div>
          </div>
        ),
      },
      {
        name: 'Total Product Sold',
        className: 'w-[25%] text-sm font-semibold text-green text-center',
        dataIndex: 'total_product_sold',
        render: (record, total_product_sold) => (
          <div class='w-[25%]'>
            <div class='text-sm font-medium text-gray text-center'>{total_product_sold.toLocaleString()}</div>
          </div>
        ),
      },
    ],
    [queryFilter?.search]
  );
  return (
    <>
      <div class='flex items-center mb-5'>
        <TableSearchInputComponent
          placeholder='Cari nama product...'
          inputProps={{
            disabled: isLoading,
          }}
          onSearch={(value) => {
            setQueryFilter({ ...queryFilter, search: value.keyword, page: 1 });
          }}
        />
        <TableButtonSortComponent disabled={isLoading} onClick={() => setModals({ ...modals, sort: true })} />
      </div>

      <BasicTableComponent
        error={isError}
        loading={isLoading}
        errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
        errorMessage={catchErrorMessage(error?.response?.data?.message)}
        rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
        columns={columns}
        dataSource={listProducts?.data?.rows || []}
      />

      {listProducts?.data?.total_result === 0 && (
        <EmptyStateComponent
          className='flex flex-col items-center justify-center h-44'
          message={queryFilter.search ? `No result for query "${queryFilter.search}"` : 'No data available'}
        />
      )}

      <Pagination
        currentPage={queryFilter.page}
        pageSize={queryFilter.limit}
        siblingCount={1}
        totalCount={listProducts?.data?.total_result || 0}
        onPageChange={(page) => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          setQueryFilter({ ...queryFilter, page });
        }}
      />
      <ModalTableSortComponent
        options={SORT_OPTIONS}
        initialValue={SORT_OPTIONS.find(
          (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
        )}
        onChange={({ selected }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            orderBy: selected.sortBy,
            orderType: selected.sortType,
          }));
          setModals({ ...modals, sort: false });
        }}
        visible={modals.sort}
        onClose={() => setModals({ ...modals, sort: false })}
      />
    </>
  );
}
