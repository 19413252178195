import moment from 'moment';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { BounceLoading } from 'components/loader/bounce.loading';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { useUserDetail } from 'api/users/user-detail/user-detail.api';
import { PATH_CONSTANT } from 'config/path.constant';
import { catchErrorMessage } from 'ui-utils/string.utils';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Users',
    path: PATH_CONSTANT.USER.USER_LIST,
  },
  {
    title: 'User Detail',
    path: PATH_CONSTANT.USER.USER_LIST,
  },
];

export const UserDetailComponent = () => {
  const { userId } = useParams();
  const { data: userDetails, isLoading, isSuccess, isError, error } = useUserDetail(userId);

  return (
    <MainDashboardLayoutComponent
      breadCumbs={BREAD_CUMB_ITEMS}
      isError={isError}
      errorTitle={error?.response?.data?.status}
      erroMessage={catchErrorMessage(error?.response?.data?.message)}
    >
      <>
        {isLoading && (
          <div className='h-[70vh] flex w-full items-center text-center'>
            <BounceLoading color='#5E755A' />
          </div>
        )}
        {isSuccess && (
          <>
            <div class='bg-gray-3 p-5 xl:p-8 rounded-sm flex items-center mb-8'>
              <div class='w-[200px]'>
                <StandartImageComponent
                  src={userDetails.data?.profile_picture}
                  withZoom
                  className='w-40 h-40 rounded-full border-8 border-solid border-green-2 object-cover'
                />
              </div>
              <div class='w-[calc(100%-200px)]'>
                <div class='flex justify-between items-center mb-6'>
                  <div class='text-2xl font-semibold text-green'>{userDetails?.data?.name}</div>
                  <Link
                    class='xl:mr-2 outline-none relative flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
                    to={`${PATH_CONSTANT.USER.USER_EDIT}/${userDetails?.data?.id}`}
                  >
                    <span class='icon-ico-edit text-xl text-green'></span>
                    <span class='pl-2 font-semibold text-dark text-sm'>Edit</span>
                  </Link>
                </div>
                <div class='flex'>
                  <div class='w-1/3 border-r border-solid border-green'>
                    <div class='text-sm text-gray font-medium mb-3'>{userDetails.data?.email}</div>
                    <div class='text-sm text-gray font-medium mb-3'>{userDetails.data?.phone}</div>

                    <div class='text-sm text-gray font-medium'>
                      {userDetails?.data?.date_of_birth
                        ? `${userDetails?.data?.place_of_birth}, ${moment(userDetails?.data?.date_of_birth).format(
                            'DD MMMM YYYY'
                          )}`
                        : 'Date of birth is not available'}
                    </div>
                  </div>
                  <div class='w-2/3'>
                    <p class='text-sm text-gray font-medium px-[10%]'>
                      {userDetails.data?.address || 'User address is not available'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class='text-2xl font-semibold text-green mb-5'>Store Penempatan</div>
            <div class='border-t border-solid border-gray-1 flex'>
              <div class='w-[44%] border-r border-solid border-gray-1 p-8'>
                <div class='text-base text-green font-semibold mb-4'>{userDetails.data.store?.name}</div>
                <div class='text-sm text-gray italic mb-5 font-medium'>{userDetails.data.store?.city?.name}</div>
                <p class='text-sm text-gray  mb-5'>{userDetails.data.store?.address}</p>
                <StandartImageComponent src={userDetails.data?.store?.image} className='max-w-full' />
              </div>
              <div class='w-[56%] p-8'>
                <div class='text-base text-green font-semibold mb-4'>Info Darurat</div>
                <div class='bg-gray-3 p-5 xl:p-8 rounded-sm flex items-center mb-8'>
                  <span class='icon-ico-phone text-xl text-green mr-6'></span>
                  <div class='text-sm text-gray font-medium'>
                    {userDetails.data.emergency_information || 'Emergency Contact is not available'}
                  </div>
                </div>
                <div class='text-base text-green font-semibold mb-2'>Tanggal Bergabung</div>
                <div class='text-sm text-gray font-medium'>
                  {userDetails?.data?.created_at && moment(userDetails?.data?.join_date).format('DD MMMM YYYY')}{' '}
                </div>
              </div>
            </div>
          </>
        )}
      </>
    </MainDashboardLayoutComponent>
  );
};
