import React from 'react';
import { Header } from './header.component';
import { SidebarComponent } from './sidebar.component';
import { ErrorComponentMessage } from 'components/error.component';

export const MainDashboardLayoutComponent = (props) => {
  const {
    children,
    breadCumbs,
    className = 'w-[calc(100%-256px)] p-8 z-10',
    headerContainerClassName = null,
    isError,
    errorTitle,
    erroMessage,
  } = props;
  return (
    <div className='flex'>
      <SidebarComponent />

      <div className={className}>
        <div className={headerContainerClassName}>
          <Header breadCumbs={breadCumbs} />
        </div>
        {isError && <ErrorComponentMessage errorMessage={erroMessage} errorTitle={errorTitle} />}
        {!isError && children}
      </div>
    </div>
  );
};
