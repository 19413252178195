import qs from 'query-string';
import clsx from 'clsx';
import { Tooltip } from 'flowbite-react';
import { capitalize } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { TableSearchInputComponent } from 'components/table/table-search.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { TableButtonLimitComponent } from 'components/table/table-button-limit.component';
import { TableButtonSortComponent } from 'components/table/table-button-sort.component';
import { BasicTableComponent } from 'components/table/table-basic.component';
import { Pagination } from 'components/table/table-pagination.component';
import { EmptyStateComponent } from 'components/empty-data';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ModalTableSortComponent } from 'components/modal/modal-table-sort.component';
import { ModalTableLimitComponent } from 'components/modal/modal-table-limit.component';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { setToolsReducer } from 'reducers/tools.reducer';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PATH_CONSTANT } from 'config/path.constant';
import { catchErrorMessage } from 'ui-utils/string.utils';
import { useListStockTransfer } from 'api/stock-transfer/stock-transfer.query.api';
import { getStatuClassNameByStockTransferStatus } from '../stock-transfer.constant';
import { useExportStockTransferList } from 'api/stock-transfer/stock-transfer.mutation';
import { ModalTableFilter } from 'components/modal/modal-table-filter.component';
import { TableButtonFilterComponent } from 'components/table/table-button-filter.component';
import { HighlightedText } from 'components/highlighted-text';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Stock Transfer',
    path: PATH_CONSTANT.STORE.STORE_LIST,
  },
  {
    title: 'Transfer List',
    path: PATH_CONSTANT.STORE.STORE_LIST,
  },
];

const SORT_OPTIONS = [
  { label: 'Tanggal Buat Terbaru - Terdahulu', sortType: 'desc', sortBy: 'created_at' },
  { label: 'Tanggal Buat Terdahulu - Terbaru', sortType: 'asc', sortBy: 'created_at' },
];

const INITIAL_VALUE_FILTER_BODY = {
  status: [],
};

const FILTER_OPTIONS = [
  {
    key: 'status',
    type: 'checkbox',
    multiple: true,
    searchFilter: false,
    options: [
      {
        label: 'Submitted',
        value: 'submitted',
      },
      {
        label: 'Completed',
        value: 'completed',
      },
      {
        label: 'Approved',
        value: 'approved',
      },
      {
        label: 'Rejected',
        value: 'rejected',
      },
    ],
  },
];

export const StockTransferListComponent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const INITIAL_VALUE_FILTER_QUERY = {
    limit: searchParams.get('limit')?.replace(/[^0-9]/g, '') || 20,
    page: searchParams.get('page')?.replace(/[^0-9]/g, '') || 1,
    orderBy: searchParams.get('orderBy')?.replace(/[^a-zA-Z_]/g, '') || 'created_at',
    orderType: searchParams.get('orderType')?.replace(/[^a-zA-Z_]/g, '') || 'desc',
    search: searchParams.get('search')?.replace(/[^a-zA-Z0-9 ]/g, '') || undefined,
  };
  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);
  const [bodyFilter, setBodyFilter] = useState(INITIAL_VALUE_FILTER_BODY);
  const { selectedRowKeys: selectedIds } = useSelector((state) => state.tools);

  const [modals, setModals] = useState({
    sort: false,
    limit: false,
    export: false,
    delete: false,
  });

  const { mutate: exportStockTransfer, isLoading: isExporting } = useExportStockTransferList();

  const {
    data: stockTransferList,
    isLoading,
    isError,
    error,
  } = useListStockTransfer({
    filter: queryFilter,
    bodyFilter,
  });

  const columns = useMemo(
    () => [
      {
        type: 'checkbox',
        isRightBorderAvailable: true,
        render: (record) => {
          return (
            <div className='form-check px-2'>
              <input
                className='form-check-input appearance-none h-4 w-4 border border-green rounded-sm bg-white checked:bg-green checked:border-green focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                type='checkbox'
                readOnly
              />
            </div>
          );
        },
      },
      {
        name: 'Store Pengirim',
        className: 'w-[28%] text-sm font-semibold text-green ',
        dataIndex: 'name',
        render: (record) => (
          <div class='w-[28%] text-sm font-semibold text-green '>
            <div class='flex items-center'>
              <div class='w-12'>
                <StandartImageComponent
                  src={record?.store_origin_image || '/img/default-store.jpg'}
                  class='w-10 h-10 rounded-sm overflow-hidden object-cover object-center'
                />
              </div>
              <div class='w-9/12 pl-3 xl:pl-5'>
                <div
                  onClick={() => {
                    navigate(PATH_CONSTANT.STOCK_TRANSFER.DETAILS + '/' + record?.id);
                  }}
                  class='overflow-hidden block text-ellipsis whitespace-normal line-clamp-3 text-sm font-semibold text-dark hover:text-green mb-1'
                >
                  <Tooltip className='max-w-[30%]' content={record?.store_origin_name}>
                    <HighlightedText text={record?.store_origin_name} highlight={queryFilter?.search} />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: 'Store Penerima',
        className: 'w-[28%] text-sm font-semibold text-green ',
        dataIndex: 'name',
        render: (record) => (
          <div class='w-[28%] text-sm font-semibold text-green '>
            <div class='flex items-center'>
              <div class='w-12'>
                <StandartImageComponent
                  src={record?.store_destination_image || '/img/default-store.jpg'}
                  class='w-10 h-10 rounded-sm overflow-hidden object-cover object-center'
                />
              </div>
              <div class='w-9/12 pl-3 xl:pl-5'>
                <div class='overflow-hidden block text-ellipsis whitespace-normal line-clamp-3 text-sm font-semibold text-dark hover:text-green mb-1'>
                  <Tooltip className='max-w-[30%]' content={record?.store_destination_name}>
                    <HighlightedText text={record?.store_destination_name} highlight={queryFilter?.search} />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: 'Tanggal Request',
        className: 'w-[15%] text-sm font-semibold text-green',
        dataIndex: 'location',
        render: (record) => (
          <div class='w-[15%]'>
            <div class='text-sm text-gray'>{record?.request_date_formatted}</div>
          </div>
        ),
      },
      {
        name: 'Total Product',
        className: 'w-[15%] text-sm font-semibold text-green',
        dataIndex: 'location',
        render: (record) => (
          <div class='w-[15%]'>
            <div class='text-sm text-gray'>
              {record?.total_qty ? `${record?.total_qty.toLocaleString()} product` : `-`}
            </div>
          </div>
        ),
      },
      {
        name: 'Status',
        className: 'w-[15%] text-sm font-semibold text-green',
        dataIndex: 'location',
        render: (record) => (
          <div class='w-[15%] flex'>
            <div
              class={clsx(
                'inline-block py-1 px-1 rounded-sm  text-sm font-bold',

                getStatuClassNameByStockTransferStatus(record?.status)
              )}
            >
              {capitalize(record?.status)}
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryFilter?.search]
  );

  const isFilterActive = bodyFilter.status.length > 0;

  const resetCheckList = () => {
    if (selectedIds.length > 0) {
      dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
    }
  };

  const handleExport = () => {
    exportStockTransfer(
      {
        queryFilter: queryFilter,
        bodyFilter: { stock_transfer_ids: selectedIds, status: bodyFilter.status },
      },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  useEffect(() => {
    resetCheckList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockTransferList]);

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      <div className='flex w-full  items-center mb-5'>
        <div className='w-full flex items-center justify-between'>
          <div className='w-4/12 '>
            <TableSearchInputComponent
              initialValue={INITIAL_VALUE_FILTER_QUERY.search}
              inputProps={{
                disabled: isLoading,
              }}
              containerClassName='relative  w-full mr-2'
              placeholder='Cari nama store...'
              onSearch={(value) => {
                setQueryFilter({ ...queryFilter, search: value.keyword, page: 1 });
                navigate({
                  search: qs.stringify({
                    ...queryFilter,
                    search: value.keyword || undefined,
                    page: 1,
                  }),
                });
              }}
            />
          </div>

          <div className='w-8/12 ml-5 flex justify-start items-center gap-2 lg:gap-1'>
            <TableButtonSortComponent
              disabled={isLoading}
              onClick={() => {
                setModals((prev) => ({ ...prev, sort: true }));
              }}
            />
            <TableButtonFilterComponent
              active={isFilterActive}
              disabled={isLoading}
              onClick={() => {
                setModals((prev) => ({ ...prev, filter: true }));
              }}
            />

            <TableButtonLimitComponent
              disabled={isLoading}
              value={queryFilter.limit}
              onClick={() => setModals({ ...modals, limit: true })}
            />

            <TableButtonExport
              disabled={isLoading}
              loading={isExporting}
              onClick={() => {
                setModals({ ...modals, export: true });
              }}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          <div className='italic text-dark text-sm mb-5'>{`Total ${stockTransferList?.data?.total_result} Stock Transfers`}</div>

          <BasicTableComponent
            rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
            columns={columns}
            dataSource={stockTransferList?.data?.rows || []}
            loading={isLoading}
            error={isError}
            errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
            errorMessage={catchErrorMessage(error?.response?.data?.message)}
          />

          {stockTransferList?.data?.total_result === 0 && (
            <EmptyStateComponent
              message={queryFilter.search ? `No result for query "${queryFilter.search}"` : 'No data available'}
            />
          )}

          <Pagination
            currentPage={queryFilter.page}
            pageSize={queryFilter.limit}
            siblingCount={1}
            totalCount={stockTransferList?.data?.total_result || 0}
            onPageChange={(page) => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
              setQueryFilter({ ...queryFilter, page });
              navigate({
                search: qs.stringify({
                  ...queryFilter,
                  page,
                }),
              });
            }}
          />
        </>
      )}
      <ModalTableSortComponent
        options={SORT_OPTIONS}
        initialValue={SORT_OPTIONS.find(
          (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
        )}
        onChange={({ selected }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            orderBy: selected.sortBy,
            orderType: selected.sortType,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              orderBy: selected.sortBy,
              orderType: selected.sortType,
            }),
          });
          setModals({ ...modals, sort: false });
        }}
        visible={modals.sort}
        onClose={() => setModals({ ...modals, sort: false })}
      />

      <ModalTableLimitComponent
        onChange={({ limit }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            limit,
            page: 1,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              limit,
              page: 1,
            }),
          });
          setModals({ ...modals, limit: false });
        }}
        initialValue={queryFilter.limit}
        visible={modals.limit}
        onClose={() => setModals({ ...modals, limit: false })}
      />
      <ModalConfirmation
        description='Apakah anda yakin ingin eksport data yang anda pilih ?'
        title='Export Data'
        visible={modals.export}
        onConfirm={handleExport}
        onClose={() => setModals({ ...modals, export: false })}
      />
      <ModalTableFilter
        initialValue={bodyFilter}
        visible={modals.filter}
        filterOptionsProps={FILTER_OPTIONS}
        onChange={(selectedFilter) => {
          setBodyFilter(selectedFilter);
          setQueryFilter((prevState) => ({
            ...prevState,
            page: 1,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              page: 1,
            }),
          });

          setModals({ ...modals, filter: false });
        }}
        onClose={() => setModals({ ...modals, filter: false })}
      />
    </MainDashboardLayoutComponent>
  );
};
