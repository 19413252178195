import { StandartImageComponent } from './image/standart-image.component';

export function ErrorComponentMessage({ errorTitle, errorMessage }) {
  return (
    <div className='h-[40vh] flex items-center justify-center flex-col'>
      <StandartImageComponent class='max-w-full' src='/img/info.svg' />
      <div className='text-center mt-2 w-3/4'>
        <p className='text-base font-semibold mb-3 text-gray'>{errorTitle}</p>
        <span className='text-sm  text-red-700'>{errorMessage}</span>
      </div>
    </div>
  );
}
