import { useAllStoreList } from 'api/store/store-list/store-list.query.api';
import clsx from 'clsx';
import { matchSorter } from 'match-sorter';
import { useEffect, useMemo, useState } from 'react';
import { ModalContainer } from './modal-container.component';

const DEFAULT_STATUS_FILTER_OPTIONS = {
  title: 'Status',
  key: 'status',
  type: 'checkbox',
  multiple: false,
  searchFilter: false,
  options: [
    {
      label: 'Active',
      value: 'active',
    },
    {
      label: 'Inactive',
      value: 'inactive',
    },
  ],
};

export function ModalTableFilter({
  visible,
  initialValue,
  onClose,
  onChange,
  filterStatusOptions = DEFAULT_STATUS_FILTER_OPTIONS,
  filterOptionsProps = null,
}) {
  const [selected, setSelected] = useState({});
  const [searchKeyword, setSearchkeyword] = useState('');

  const { data: allStoreList } = useAllStoreList();

  useEffect(() => {
    if (initialValue) setSelected(initialValue);
    if (!visible) setSearchkeyword('');
  }, [visible, initialValue]);

  const allStoreOptions = useMemo(() => {
    if (allStoreList?.data) {
      return matchSorter(allStoreList.data, searchKeyword, { keys: ['name'] }).map((store) => ({
        label: store.name,
        value: store.id,
      }));
    }
    return [];
  }, [allStoreList, searchKeyword]);

  const filterOptions = useMemo(() => {
    if (filterOptionsProps) return filterOptionsProps;

    if (filterStatusOptions) {
      return [
        filterStatusOptions,
        {
          title: 'Store',
          key: 'store_ids',
          searchFilter: true,
          type: 'checkbox',
          multiple: true,
          options: allStoreOptions,
        },
      ];
    }
    return [
      {
        title: 'Store',
        key: 'store_ids',
        searchFilter: true,
        type: 'checkbox',
        multiple: true,
        options: allStoreOptions,
      },
    ];
  }, [allStoreOptions, filterStatusOptions, filterOptionsProps]);

  useEffect(() => {
    setSelected(initialValue);
  }, [initialValue]);

  const handleOnApplyFilter = () => {
    onChange(selected);
    onClose();
  };

  return (
    <ModalContainer visible={visible}>
      <div id='show-item' class={clsx('w-[330px] bg-white rounded-sm p-5 relative', !visible && 'hidden')}>
        <div class='relative text-center py-4'>
          <div class='mb-5 text-center font-bold text-sm'>Filter</div>
          <button type='button' class='absolute top-0 right-0 border-0' onClick={onClose}>
            <span class='icon-ico-close'></span>
          </button>
        </div>
        <div class='max-h-[45vh] scroll mb-3'>
          {filterOptions.map((filterCategory) => {
            if (filterCategory.type === 'checkbox') {
              return (
                <>
                  {filterCategory.title && <div class='p-3 font-bold text-sm'>{filterCategory.title}</div>}
                  {filterCategory.searchFilter && (
                    <div class='mb-2 relative'>
                      <input
                        type='text'
                        class='form-control block focus:ring-0 w-full text-sm pr-3 pl-9 py-2 placeholder:italic font-normal text-gray-700 bg-white bg-clip-padding outline-none border border-solid border-gray-1 rounded-sm transition ease-in-out m-0 focus:text-green focus:bg-white focus:shadow-none focus:border-green focus:outline-none'
                        id='search'
                        value={searchKeyword}
                        onChange={(e) => {
                          setSearchkeyword(e.target.value);
                        }}
                        placeholder='cari store...'
                      />
                      <button class='absolute left-3 top-1' type='button'>
                        <span class='icon-ico-search text-gray text-lg'></span>
                      </button>
                    </div>
                  )}
                  {filterCategory.options.map((filterOption) => (
                    <div class='flex items-center p-3'>
                      <div class='form-check'>
                        <input
                          class='form-check-input appearance-none h-4 w-4 border border-green rounded-sm bg-white checked:bg-green checked:border-green focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                          type='checkbox'
                          onChange={(e) => {
                            // If the category is multiple, add or remove the selected value from the selected options
                            if (filterCategory.multiple) {
                              // If the value is already selected, remove it from the existing selected options
                              if (selected[filterCategory.key]?.includes(filterOption.value)) {
                                setSelected({
                                  ...selected,
                                  [filterCategory.key]: selected[filterCategory.key].filter(
                                    (item) => item !== filterOption.value
                                  ),
                                });
                              } else {
                                // Otherwise, add the value to the existing selected options
                                setSelected({
                                  ...selected,
                                  [filterCategory.key]: [...selected[filterCategory.key], filterOption.value],
                                });
                              }
                            } else {
                              // Otherwise, set the value to the selected option
                              setSelected({
                                ...selected,
                                [filterCategory.key]: e.target.checked ? filterOption.value : null,
                              });
                            }
                          }}
                          checked={
                            filterCategory.multiple
                              ? selected[filterCategory.key]?.includes(filterOption.value)
                              : selected[filterCategory.key] === filterOption.value
                          }
                          id={`${filterCategory.key}-${filterOption.value}`}
                        />
                      </div>
                      <label
                        htmlFor={`${filterCategory.key}-${filterOption.value}`}
                        class='text-sm text-gray font-medium ml-4 cursor-pointer'
                      >
                        {filterOption.label}
                      </label>
                    </div>
                  ))}
                </>
              );
            }
            return null;
          })}
        </div>
        <div class='flex justify-between items-center pb-2'>
          <div class='w-[48%]'>
            <button
              type='button'
              onClick={onClose}
              class='block w-full rounded-sm border border-solid border-gray-1 py-2 px-2 text-sm font-semibold text-dark hover:bg-green-status transition duration-75 ease-in-out'
            >
              Cancel
            </button>
          </div>
          <div class='w-[48%]'>
            <button
              class='bg-green w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
              type='button'
              onClick={handleOnApplyFilter}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}
