import { useState } from 'react';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { useNavigate, useParams } from 'react-router';

import { PATH_CONSTANT } from 'config/path.constant';
import { useGetListPODetails } from 'api/stock-movement/pre-order/pre-order.query.api';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { useApprovePORequest } from 'api/stock-movement/approvement/approvement.mutation.api';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { toast } from 'react-toastify';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Stock Movement',
    path: PATH_CONSTANT.STOCK_MOVEMENT.APPROMENT_DETAILS,
  },
  {
    title: 'Approvement',
    path: PATH_CONSTANT.STOCK_MOVEMENT.APPROMENT_DETAILS,
  },
  {
    title: 'Approvement Detail',
    path: PATH_CONSTANT.STOCK_MOVEMENT.APPROMENT_DETAILS,
  },
];
export function ApprovementDetailComponent() {
  const navigate = useNavigate();
  const { poId } = useParams();

  const { data: poDetails, isLoading } = useGetListPODetails(poId);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { mutate: approvePORequest, isLoading: isSubmitting } = useApprovePORequest();
  const [modalConfirmation, setModalConfirmation] = useState({
    visible: false,
    title: '',
    message: '',
    confirmText: '',
    type: '',
  });

  const handleOnSubmit = () => {
    approvePORequest(
      {
        id: poId,
        formPayload: {
          requested_product_ids: selectedProducts,
        },
      },
      {
        onSuccess: () => {
          navigate(PATH_CONSTANT.STOCK_MOVEMENT.APPROMENT_LIST);
          toast.success('Berhasil menyetujui Request PO!');
          setModalConfirmation({
            visible: false,
          });
        },
      }
    );
  };

  const handleOnShowModalConfirmation = () => {
    setModalConfirmation({
      visible: true,
      title: 'Approve PO Request',
      message: 'Apakah anda yakin ingin mengubah status PO menjadi Approved?',
      confirmText: 'Approve',
    });
  };

  const handleOnSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedProducts(poDetails?.data?.requested_products?.map((item) => item.id));
    } else {
      setSelectedProducts([]);
    }
  };

  return (
    <>
      <MainDashboardLayoutComponent
        headerContainerClassName='pt-8 pl-8 pr-8 z-10'
        breadCumbs={BREAD_CUMB_ITEMS}
        className='w-[calc(100%-256px)] h-screen z-10'
      >
        <div className='px-8 pb-0'>
          {isLoading ? (
            <div className='h-[70vh] flex w-full items-center text-center'>
              <BounceLoading color='#5E755A' />
            </div>
          ) : (
            <>
              <div class='bg-gray-3 mb-5'>
                <div class='py-4 px-6 flex justify-between items-center'>
                  <div class='w-auto flex items-center'>
                    <div class='text-sm text-green font-bold mr-3'>{poDetails?.data?.po_number}</div>
                    <div class='inline-block bg-yellow-light text-center w-28 py-1 px-1 rounded-sm text-yellow text-sm font-bold'>
                      Submitted
                    </div>
                  </div>
                  <div class='text-sm text-gray font-medium'>{poDetails?.data?.request_date}</div>
                </div>
                <div class='border-t border-solid border-gray-1 flex'>
                  <div class='w-1/2 border-r border-solid border-gray-1 p-6'>
                    <div class='text-sm text-dark mb-4 font-semibold'>{poDetails?.data?.store_name}</div>
                    <p class='text-sm text-gray  mb-4'>{poDetails?.data?.store_address}</p>
                    <div class='text-sm text-gray italic mb-4 font-medium'>{poDetails?.data?.store_city}</div>
                  </div>
                  <div class='w-1/2 p-6'>
                    <div class='text-sm text-dark mb-4 font-semibold'>{poDetails?.data?.user_name}</div>
                    <p class='text-sm text-gray italic mb-4'>{poDetails?.data?.user_phone}</p>
                    <p class='text-sm text-gray italic mb-4'>Beauty Advisor</p>
                  </div>
                </div>
              </div>

              <div class='bg-green-1 rounded-sm p-2 flex items-center mb-4'>
                <div class='w-[50%] text-sm font-semibold text-green pl-4'>Nama Produk</div>
                <div class='w-[30%] text-sm font-semibold text-green text-center'>Qty</div>
                <div class='w-[20%] text-sm font-semibold text-green justify-center flex flex-row'>
                  Available
                  <div className='form-check px-2'>
                    <input
                      className='form-check-input appearance-none h-4 w-4 border border-green rounded-sm bg-white checked:bg-green checked:border-green focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                      type='checkbox'
                      onChange={handleOnSelectAll}
                      checked={selectedProducts.length === poDetails?.data?.requested_products?.length}
                    />
                  </div>
                </div>
              </div>
              {poDetails?.data?.requested_products?.map((item, index) => {
                return (
                  <div class='flex items-center rounded-sm py-2 border-b border-solid border-gray-1 px-2 bg-white w-full'>
                    <div class='w-[50%]'>
                      <div class='block text-sm font-semibold text-dark pl-4 pr-5'>{item?.name}</div>
                    </div>

                    <div class='w-[30%]'>
                      <div class='text-sm font-bold text-dark text-center'>{item?.qty}</div>
                    </div>

                    <div class='w-[20%] flex items-center justify-center'>
                      <div class='form-check'>
                        <input
                          class='form-check-input appearance-none h-4 w-4 border border-green rounded-sm bg-white checked:bg-green checked:border-green focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                          type='checkbox'
                          disabled={isSubmitting}
                          checked={selectedProducts.includes(item?.id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedProducts([...selectedProducts, item?.id]);
                            } else {
                              setSelectedProducts(selectedProducts.filter((id) => id !== item?.id));
                            }
                          }}
                          value={selectedProducts.includes(item?.id)}
                          id={`${item?.id}-${item?.name}`}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

              <div class='py-14 relative flex justify-center'>
                <ButtonSubmit
                  disabled={selectedProducts.length === 0}
                  loading={isSubmitting}
                  onClick={handleOnShowModalConfirmation}
                >
                  <span class='icon-ico-apply mr-3'></span> Approve Request
                </ButtonSubmit>
              </div>
            </>
          )}
        </div>
        <ModalConfirmation
          description={modalConfirmation.message}
          title={modalConfirmation.title}
          visible={modalConfirmation.visible}
          onConfirm={handleOnSubmit}
          textConfirm={modalConfirmation.confirmText}
          onClose={() => setModalConfirmation({ visible: false })}
        />
      </MainDashboardLayoutComponent>
    </>
  );
}
