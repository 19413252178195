import qs from 'query-string';
import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const ANALYTIC_SALES_QUERY = {
  TOTAL_SALES: 'TOTAL_SALES',
  SALES_GRAPH: 'SALES_GRAPH',
  LIST_SALES_BA: 'LIST_SALES_BA',
};

export function useGetAnalyticsTotalSales({ filter, body }) {
  return useQuery([ANALYTIC_SALES_QUERY.TOTAL_SALES, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/analytic-sales/total?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}

export function useGetAnalyticsSalesGraph({ filter, body }) {
  return useQuery([ANALYTIC_SALES_QUERY.SALES_GRAPH, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/analytic-sales/graph?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}
export function useGetAnalyticsListSalesBA({ filter, body }) {
  return useQuery([ANALYTIC_SALES_QUERY.LIST_SALES_BA, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/analytic-sales/list?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}
