import { useState, forwardRef } from 'react';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';

export function RangeDatePicker({
  onChange,
  initialStartDate = new Date(),
  initialEndDate = new Date(),
  containerClassName = 'relative w-56 mr-2',
  iconInputClassName = 'icon-ico-calendar text-xl text-white absolute left-4 top-1',
  inputClassName = 'mx-1 w-full outline-none text-sm font-semibold text-center border border-green cursor-pointer bg-green text-white rounded-sm hover:bg-green transition hover:border-green duration-150 ease-in-out border-solid py-2 px-3',
  disabled,
  ...otherProps
}) {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const handleChange = (dates) => {
    if (!Array.isArray(dates)) {
      onChange(dates);
      setStartDate(dates);
      return;
    }
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    start && end && onChange({ start, end });
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className={containerClassName} ref={ref}>
      <input onClick={onClick} className={inputClassName} value={value} />
      <span className={iconInputClassName}></span>
    </div>
  ));

  return (
    <div className={clsx('flex items-center', disabled && 'opacity-50')}>
      <DatePicker
        selected={startDate}
        onChange={handleChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        customInput={<CustomInput />}
        monthsShown={2}
        dateFormat='dd MMM yy'
        disabledKeyboardNavigation
        disabled={disabled}
        {...otherProps}
      />
    </div>
  );
}
