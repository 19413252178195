import * as yup from 'yup';

/**
 * Form validation schema for /stock-movement/pre-order/create
 */

export const CreatePOValidationSchema = yup.object().shape({
  type: yup.string().required('Type is required'),
  user: yup.string().when('type', {
    is: 'konsinyasi',
    then: yup.string().required('User is required'),
    otherwise: yup.string().nullable().notRequired(),
  }),
  store: yup.string().required('Store is required'),
  warehouse: yup.string().required('Warehouse is required'),
  user_phone: yup.string().when('type', {
    is: 'konsinyasi',
    then: yup.string().required('User Phone is required'),
    otherwise: yup.string().nullable().notRequired(),
  }),
  products: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('Product name is required'),
        price: yup.string().required('Product price is required').nullable(),
        qty: yup.string().required('Product quantity is required').nullable(),
      })
    )
    .required('At least one product is required'),
});
