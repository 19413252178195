import moment from 'moment';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import qs from 'query-string';
import { EmptyStateComponent } from 'components/empty-data';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { BounceLoading } from 'components/loader/bounce.loading';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { TableButtonImport } from 'components/table/table-button-import.component';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { PATH_CONSTANT } from 'config/path.constant';
import { BasicTableComponent } from 'components/table/table-basic.component';
import { useGetDetailSchedulesUser, useGetDetailSchedulesUserInfo } from 'api/schedules/jadwal/jadwal.query.api';
import { catchErrorMessage } from 'ui-utils/string.utils';
import { RangeDatePicker } from 'components/date-picker/range-date-picker.component';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { ModalCreateScheduleComponent, MODAL_CREATE_SCHEDULE_TYPE } from './create-schedule.modal.component';
import { useDeleteScheduleUser, useExportScheduleUser } from 'api/schedules/jadwal/jadwai.mutation.api';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { Pagination } from 'components/table/table-pagination.component';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Jadwal & Presensi',
    path: PATH_CONSTANT.SCHEDULE.LIST,
  },
  {
    title: 'Jadwal BA',
    path: PATH_CONSTANT.SCHEDULE.LIST,
  },
  {
    title: 'Detail Jadwal',
    path: null,
  },
];

export const ScheduleDetailsComponent = () => {
  const { userId } = useParams();
  const navigate = useNavigate();

  const [showModalExport, setShowModalExport] = useState(false);
  const [modalAddSchedule, setModalAddSchedule] = useState({
    open: false,
    operationType: MODAL_CREATE_SCHEDULE_TYPE.CREATE,
    scheduleId: null,
  });

  const [modalDeleteSchedule, setModalDeleteSchedule] = useState({
    open: false,
    scheduleId: null,
  });

  const [searchParams] = useSearchParams();

  const { data: userDetails, isLoading, isSuccess, isError, error } = useGetDetailSchedulesUserInfo(userId);
  const { mutate: deleteSchedule, isLoading: isDeleting } = useDeleteScheduleUser();
  const { mutate: exportScheduleUser, isLoading: isExporting } = useExportScheduleUser();

  const INITIAL_VALUE_FILTER_QUERY = {
    limit: searchParams.get('limit')?.replace(/[^0-9]/g, '') || 10,
    page: searchParams.get('page')?.replace(/[^0-9]/g, '') || 1,
    orderBy: searchParams.get('orderBy')?.replace(/[^a-zA-Z_]/g, '') || 'date',
    orderType: searchParams.get('orderType')?.replace(/[^a-zA-Z]/g, '') || 'desc',
    search: searchParams.get('search')?.replace(/[^a-zA-Z0-9 ]/g, '') || undefined,
    yearMonth: searchParams.get('yearMonth') || moment().format('YYYY-MM'),
  };

  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);

  const {
    data: userScheduleList,
    isLoading: isLoadingSchedule,
    isError: isErrorScheduleList,
    error: errorScheduleList,
  } = useGetDetailSchedulesUser(userId, queryFilter);

  const columns = useMemo(
    () => [
      {
        name: 'Tanggal',
        className: 'w-[40%] text-sm font-semibold text-green pl-8',
        dataIndex: 'date_formatted',
        render: (record, date_formatted) => (
          <div className='w-[40%] text-grey-70 text-sm font-medium pl-8'>{date_formatted}</div>
        ),
      },

      {
        name: 'Mulai',
        className: 'w-[15%] text-sm font-semibold text-green text-center',
        dataIndex: 'clock_in_formatted',
        render: (record, clock_in_formatted) => (
          <div class='w-[15%]'>
            <div class='text-sm font-medium text-gray text-center'>{`${clock_in_formatted}`}</div>
          </div>
        ),
      },
      {
        name: 'Selesai',
        className:
          'w-[15%] text-sm font-semibold text-green overflow-hidden text-center text-ellipsis whitespace-nowrap',
        dataIndex: 'clock_out_formatted',
        render: (record, clock_out_formatted) => (
          <div class='w-[15%]'>
            <div class='text-sm font-medium text-gray text-center'>{`${clock_out_formatted}`}</div>
          </div>
        ),
      },
      {
        name: 'Edit Jadwal',
        className: 'w-[30%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record) => {
          const isDatePast = moment(record.date).isBefore(moment(), 'day');

          if (!isDatePast) {
            return (
              <div class='w-[30%] flex justify-center gap-14 '>
                <span class='text-center block cursor-pointer'>
                  <span
                    class='icon-ico-pencil-edit text-yellow text-xl'
                    onClick={() => {
                      setModalAddSchedule({
                        open: true,
                        operationType: MODAL_CREATE_SCHEDULE_TYPE.UPDATE,
                        scheduleId: record.id,
                      });
                    }}
                  ></span>
                </span>
                <span
                  class='text-center block cursor-pointer'
                  onClick={() => {
                    setModalDeleteSchedule({
                      open: true,
                      scheduleId: record.id,
                    });
                  }}
                >
                  <span class='icon-ico-delete text-red-1 text-xl'></span>
                </span>
              </div>
            );
          }
        },
      },
    ],
    []
  );

  const handleOnDeleteSchedule = () => {
    deleteSchedule(modalDeleteSchedule.scheduleId, {
      onSuccess: () => {
        setModalDeleteSchedule({
          open: false,
          scheduleId: null,
        });
      },
    });
  };

  const handleExport = () => {
    exportScheduleUser(
      {
        queryFilter: queryFilter,
        userId,
      },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setShowModalExport(false);
        },
      }
    );
  };

  return (
    <MainDashboardLayoutComponent
      breadCumbs={BREAD_CUMB_ITEMS}
      isError={isError}
      errorTitle={error?.response?.data?.status}
      erroMessage={catchErrorMessage(error?.response?.data?.message)}
    >
      <>
        {isLoading && (
          <div className='h-[70vh] flex w-full items-center text-center'>
            <BounceLoading color='#5E755A' />
          </div>
        )}

        {isSuccess && (
          <>
            <div class='bg-gray-3 p-5 xl:p-8 rounded-sm flex items-center mb-8'>
              <div class='w-[200px]'>
                <StandartImageComponent
                  src={userDetails.data?.[0]?.profile_picture}
                  withZoom
                  className='w-40 h-40 rounded-full border-8 border-solid border-green-2 object-cover'
                />
              </div>
              <div class='w-[calc(100%-200px)]'>
                <div class='flex justify-between items-center mb-2'>
                  <div class='text-2xl font-semibold text-green'>{userDetails.data?.[0]?.name}</div>
                </div>
                <div class='flex'>
                  <div class='w-1/3 border-r border-solid pr-5 border-green'>
                    <div class='text-sm text-gray font-medium mb-3'>{userDetails.data?.[0]?.email}</div>
                    <div class='text-sm text-gray font-medium mb-3'>{userDetails.data?.[0]?.phone}</div>

                    <div class='text-lg  font-medium text-green'>{userDetails.data?.[0]?.store?.name}</div>
                    <div class='text-sm text-gray font-medium'>{userDetails.data?.[0]?.store?.city?.name}</div>
                  </div>
                  <div class='w-2/3 pl-28 flex flex-col justify-center'>
                    <div className='flex flex-row items-center w-4/5 gap-3'>
                      <div className='icon-ico-calendar text-2xl text-green-3 font-normal '></div>
                      <div className='flex flex-col px-[2%] '>
                        <p class='text-sm text-green font-medium mb-2'>Jadwal Terdekat</p>
                        <p class='text-sm text-gray font-medium '>{userDetails.data?.[0]?.date_formatted || '-'}</p>
                      </div>
                    </div>
                    <div className='flex flex-row items-center w-4/5 mt-5 gap-3'>
                      <div className='icon-ico-clock text-2xl  text-green-3 font-normal '></div>
                      <div className='flex flex-col  px-[2%] '>
                        <p class='text-sm text-green font-medium mb-2'>Mulai</p>
                        <p class='text-sm text-gray font-medium '>{userDetails.data?.[0]?.clock_in_formatted || '-'}</p>
                      </div>
                      <span class='icon-ico-chev-right text-2xl text-center  text-green w-8 h-8 text-cente bg-green-2 rounded-full'></span>
                      <div className='icon-ico-clock text-2xl text-green-3 font-normal '></div>
                      <div className='flex flex-col  px-[2%] '>
                        <p class='text-sm text-green font-medium mb-2'>Selesai</p>
                        <p class='text-sm text-gray font-medium '>
                          {userDetails.data?.[0]?.clock_out_formatted || '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isLoadingSchedule ? (
              <div className='h-[70vh] flex w-full items-center text-center'>
                <BounceLoading color='#5E755A' />
              </div>
            ) : (
              <>
                <div className='flex flex-row items-center justify-between mb-5'>
                  <div className='flex flex-row justify-between gap-2'>
                    <div class='text-2xl font-semibold text-green mr-10 '>Jadwal BA</div>

                    <RangeDatePicker
                      disabled={isLoading}
                      iconInputClassName='icon-ico-calendar text-xl text-green absolute left-4 top-1'
                      inputClassName='mx-1 w-full outline-none text-sm font-semibold text-center border border-gray-1 cursor-pointer  rounded-sm transition hover:border-green duration-150 ease-in-out border-solid py-2 px-3'
                      initialStartDate={moment(queryFilter.yearMonth).toDate()}
                      initialEndDate={null}
                      adjustDateOnChange={false}
                      dateFormat='MMMM/yyyy'
                      selectsRange={false}
                      monthsShown={1}
                      showMonthYearPicker
                      onChange={(selectedMonth) => {
                        const selectedMonthFormat = moment(selectedMonth).format('YYYY-MM');
                        navigate({
                          search: qs.stringify({
                            ...queryFilter,
                            yearMonth: selectedMonthFormat,
                          }),
                        });
                        setQueryFilter((prevState) => ({
                          ...prevState,
                          yearMonth: selectedMonthFormat,
                        }));
                      }}
                    />
                    <TableButtonExport disabled={isLoading} onClick={() => setShowModalExport(true)} />
                  </div>
                  <div className='flex flex-row justify-between gap-5'>
                    <TableButtonImport
                      title='Import Jadwal'
                      onClick={() => {
                        navigate(PATH_CONSTANT.SCHEDULE.IMPORT + `/${userId}`);
                      }}
                    />
                    <div className='w-[300px]'>
                      <ButtonSubmit
                        disabled={isLoading}
                        onClick={() => {
                          setModalAddSchedule((prev) => ({
                            ...prev,
                            open: true,
                          }));
                        }}
                        className='bg-green w-full flex items-center justify-center hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                      >
                        <span class='icon-ico-plus-circle text-[20px] leading-[21px] mr-3'></span>
                        Tambah Jadwal
                      </ButtonSubmit>
                    </div>
                  </div>
                </div>
                {userScheduleList?.data?.total_result === 0 ? (
                  <EmptyStateComponent message='No data available' />
                ) : (
                  <div>
                    <BasicTableComponent
                      rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
                      columns={columns}
                      dataSource={userScheduleList?.data?.rows || []}
                      loading={isLoading}
                      error={isErrorScheduleList}
                      errorTitle={`${errorScheduleList?.response?.data?.status} - ${errorScheduleList?.response?.data?.code}`}
                      errorMessage={catchErrorMessage(errorScheduleList?.response?.data?.message)}
                    />
                    <Pagination
                      currentPage={queryFilter.page}
                      pageSize={queryFilter.limit}
                      siblingCount={1}
                      totalCount={userScheduleList?.data?.total_result || 0}
                      onPageChange={(page) => {
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        });
                        setQueryFilter({ ...queryFilter, page });
                        navigate({
                          search: qs.stringify({
                            ...queryFilter,
                            page,
                          }),
                        });
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}
      </>
      <ModalCreateScheduleComponent
        visible={modalAddSchedule.open}
        onClose={() => setModalAddSchedule({ open: false })}
        type={modalAddSchedule.operationType}
        scheduleId={modalAddSchedule.scheduleId}
      />
      <ModalConfirmation
        description='Apakah anda yakin ingin eksport data detail jadwal user yang anda pilih?'
        title='Export Data'
        visible={showModalExport}
        loading={isExporting}
        onConfirm={handleExport}
        onClose={() => setShowModalExport(false)}
      />
      <ModalConfirmation
        description='Apakah anda yakin ingin menghapus data ini?'
        title='Delete Data'
        imageIcon='/img/Delete.png'
        textConfirm='Delete'
        loading={isDeleting}
        visible={modalDeleteSchedule.open}
        onConfirm={handleOnDeleteSchedule}
        onClose={() =>
          setModalDeleteSchedule({
            open: false,
          })
        }
      />
    </MainDashboardLayoutComponent>
  );
};
