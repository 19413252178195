import qs from 'query-string';
import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const ANALYTIC_TRAFFIC_QUERY_KEY = {
  TOTAL_TRAFFIC: 'TOTAL_Traffic',
  GRAPH_TRAFFIC: 'GRAPH_TRAFFIC',
  LIST_TRAFFIC: 'LIST_TRAFFIC',
};

export function useGetAnalyticsTotalTraffic({ filter, body }) {
  return useQuery([ANALYTIC_TRAFFIC_QUERY_KEY.TOTAL_TRAFFIC, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/analytic-store-traffic/total?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}

export function useGetAnalyticsTrafficGraph({ filter, body, type }) {
  return useQuery([ANALYTIC_TRAFFIC_QUERY_KEY.GRAPH_TRAFFIC, { filter, body, type }, body], () =>
    axios
      .post(`/api/v1/admin/analytic-store-traffic/graph/${type}?${qs.stringify(filter)}`, body)
      .then((res) => res.data)
  );
}
export function useGetAnalyticsListTrafficBA({ filter, body }) {
  return useQuery([ANALYTIC_TRAFFIC_QUERY_KEY.LIST_TRAFFIC, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/analytic-store-traffic/list?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}
