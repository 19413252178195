import { StandartImageComponent } from 'components/image/standart-image.component';
import moment from 'moment';

import { useRef, forwardRef, useImperativeHandle } from 'react';
import { useReactToPrint } from 'react-to-print';
export const PrintAWBPoComponent = forwardRef(({ data, onFinish }, ref) => {
  const componentRef = useRef(null);
  const handleDownloadPDF = useReactToPrint({
    onPrintError: (error) => {
      alert(JSON.stringify(error));
    },
    content: () => componentRef.current,
    onAfterPrint: () => {
      onFinish && onFinish();
    },
    documentTitle: `AVO Beauty Advisor - Surat Jalan_${new Date().getTime()}`,
  });

  useImperativeHandle(ref, () => ({
    handleDownload: handleDownloadPDF,
  }));

  return (
    <div className='flex flex-col w-[100%] h-full  '>
      <style type='text/css' media='print'>
        {
          '\
            @page { size: A4 landscape; }\
          '
        }
      </style>

      <div className='w-[100%] h-full' ref={componentRef}>
        <div id='print' class='w-full h-[100vh] bg-white rounded-sm p-8 relative flex flex-col '>
          <div class='flex items-center mb-8'>
            <div class='w-[55%] flex items-center'>
              <div class='w-1/3 '>
                <StandartImageComponent src='/img/logo.png' class='w-20' />
              </div>
            </div>
          </div>
          <div class='flex items-center mb-8'>
            <div class='w-1/4 pr-3 border-r border-solid border-gray-1'>
              <div class='text-base font-bold text-green mb-1'>Manifest ID</div>
              <div class='text-sm font-semibold text-dark mb-2'>{data?.manifest_id}</div>
              <div class='text-base font-bold text-green mb-1'>Tanggal Manifest</div>
              <div class='text-sm font-medium text-gray'>
                {data?.manifest_date && moment(data?.manifest_date).format('DD MMMM YYYY')}
              </div>
            </div>
            <div class='w-3/4 pl-5'>
              <div class='text-base font-bold text-green mb-1'>Warehouse</div>
              <div class='text-sm font-medium text-dark mb-2'>{data?.warehouse?.name}</div>
              <div class='text-sm font-medium text-gray'>{data?.warehouse?.address}</div>
            </div>
          </div>
          <div class='mb-5'>
            <div class='bg-green-1 rounded-sm py-2 px-8 flex items-center mb-4'>
              <div class='w-[20%] text-xs font-semibold text-green'>No. PO</div>
              <div class='w-[20%] text-xs font-semibold text-green'>Nama Store</div>
              <div class='w-[48%] text-xs font-semibold text-green'>Alamat Store</div>
              <div class='w-[10%] text-xs font-semibold text-green'>Expedisi</div>
            </div>

            {data?.pre_orders?.map((item, index) => (
              <div class='bg-white rounded-sm py-2 px-8 flex items-center mb-4'>
                <div class='w-[20%] text-xs font-bold text-green overflow-hidden text-ellipsis whitespace-nowrap'>
                  {item?.po_number}
                </div>
                <div class='w-[20%] text-xs font-medium text-dark overflow-hidden text-ellipsis whitespace-nowrap'>
                  {item?.store_name}
                </div>
                <div class='w-[48%] text-xs font-medium text-dark '>{item?.store_address}</div>
                <div class='w-[10%] text-xs font-medium text-gray'></div>
              </div>
            ))}
          </div>

          <div className='flex justify-center mt-[auto]'>
            <div id='signature-box' class=' w-[70%] flex items-center justify-center  '>
              <div class='w-[50%] flex items-center justify-center'>
                <div className='w-[50%] flex items-center justify-center border-dashed  border-b'>
                  <div className='text-sm font-semibold text-dark pb-20'>Kurir</div>
                </div>
              </div>
              <div class='w-[50%] flex items-center justify-center '>
                <div className='w-[50%] flex items-center justify-center border-dashed  border-b'>
                  <div className='text-sm font-semibold text-dark pb-20'>Admin</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
