import clsx from 'clsx';
import { useRef } from 'react';
import { PrintRequestPOComponent } from './print-request-po-component';
import { useGetPrintDataPicking } from 'api/stock-movement/picking/picking.query.api';
import { ModalContainer } from 'components/modal/modal-container.component';

export function ModalPrintRequestPo({
  title = 'Print Request PO',
  onClose = () => {},
  poIds = [],
  visible = false,
  onFinish = () => {},
}) {
  const printComponentRef = useRef(null);
  const { data, isLoading } = useGetPrintDataPicking(poIds);

  const handleDownload = () => {
    printComponentRef.current && printComponentRef.current.handleDownload();
  };

  if (isLoading) return <div>Loading...</div>;
  return (
    <ModalContainer visible={visible}>
      <div id='show-item' class={clsx('w-[830px] bg-white rounded-sm p-5 relative', !visible && 'hidden')}>
        <div class='mb-5 text-center font-bold text-sm'>{title}</div>
        <button type='button' class='absolute top-2 right-3' disabled={isLoading} onClick={onClose}>
          <span class='icon-ico-close text-xl text-gray-2'></span>
        </button>
        <div className=' w-full  max-h-[80vh] overflow-auto border border-solid border-gray-1 justify-center items-center'>
          <PrintRequestPOComponent data={data?.data} onFinish={onFinish} ref={printComponentRef} />
        </div>
        <div class='flex mt-3 justify-between items-center pb-2 px-[10%]'>
          <div class='w-[48%]'>
            <button
              onClick={onClose}
              type='button'
              class='block w-full rounded-sm border border-solid border-gray-1 py-2 px-2 text-sm font-semibold text-dark hover:bg-green-status transition duration-75 ease-in-out'
            >
              Cancel
            </button>
          </div>
          <div class='w-[48%]'>
            <button
              onClick={handleDownload}
              class='bg-green w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
              type='button'
            >
              <span class='icon-ico-print mr-2'></span> Print Request PO
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}
