import qs from 'query-string';

import axios from 'api/api.config';
import { useMutation, useQueryClient } from 'react-query';
import { STOCK_TRANSFER_QUERY_KEY } from './stock-transfer.query.api';

export function useExportStockTransferList() {
  return useMutation(({ queryFilter, bodyFilter }) =>
    axios.post(`/api/v1/admin/stock-transfer/export?${qs.stringify(queryFilter)}`, bodyFilter).then((res) => res.data)
  );
}
export function useExportStockTransferListDetails() {
  return useMutation(({ recordId }) =>
    axios.get(`/api/v1/admin/stock-transfer/export/${recordId}`).then((res) => res.data)
  );
}

export function useApproveTransferStock() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ recordId, status }) =>
      axios
        .put(`/api/v1/admin/stock-transfer/approve/${recordId}`, {
          status,
        })
        .then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.refetchQueries([STOCK_TRANSFER_QUERY_KEY.STOCK_TRANSFER_QUERY_LIST]);
        queryClient.refetchQueries([STOCK_TRANSFER_QUERY_KEY.STOCK_TRANSFER_QUERY_LIST_DETAILS]);
        queryClient.refetchQueries([STOCK_TRANSFER_QUERY_KEY.STOCK_TRANSFER_QUERY_LIST_ACTION_LOG]);
      },
    }
  );
}
