import { useMemo, useState } from 'react';
import { Tooltip } from 'flowbite-react';
import { useParams } from 'react-router-dom';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';

import { BasicTableComponent } from 'components/table/table-basic.component';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { PATH_CONSTANT } from 'config/path.constant';
import { ModalResult } from 'components/modal/modal.result.component';
import { catchErrorMessage } from 'ui-utils/string.utils';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { ModalStockAudit } from 'pages/report-ba/cycle-count/cycle-count-details/modal-stock-audit.component';
import { AUDIT_STOCK_TYPE, useStockAuditData } from 'api/audit/audit-stock.query';
import { useReturnProductDetails } from 'api/return-product/return-product.query.api';
import {
  RETURN_PRODUCT_DETAILS_STATUS_COLOR_CLASSNAME,
  RETURN_PRODUCT_STATUS,
  RETURN_PRODUCT_STATUS_LABEL,
} from 'pages/stock-movement/pre-order/status-color.util';
import clsx from 'clsx';
import {
  useApproveReturnProduct,
  useCompleteReturnProduct,
  useExportReturnProductDetails,
} from 'api/return-product/return-produict.mutation.api';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Return Product',
    path: PATH_CONSTANT.RETURN_PRODUCT.LIST,
  },
  {
    title: 'List Return Product',
    path: PATH_CONSTANT.RETURN_PRODUCT.LIST,
  },
  {
    title: 'Detail',
    path: null,
  },
];

const MODAL_CONFIRMATION_TEXT = {
  [RETURN_PRODUCT_STATUS.SUBMITTED]: {
    title: 'Approve Return Product',
    description: 'Apakah anda yakin ingin menyetujui data report ini?',
    textConfirm: 'Approve',
  },
  [RETURN_PRODUCT_STATUS.DELIVERY]: {
    title: 'Complete Return Product',
    description: 'Apakah anda yakin ingin mengubah status Return Produk menjadi Completed?',
    textConfirm: 'Complete',
  },
};

const MODAL_RESULT_TEXT = {
  [RETURN_PRODUCT_STATUS.SUBMITTED]: {
    title: 'Return Product Approved',
    description: 'Status report berhasil diubah menjadi Approved!',
  },
  [RETURN_PRODUCT_STATUS.APPROVED]: {
    title: 'Return Product Approved',
    description: 'Status report berhasil diubah menjadi Approved!',
  },
  [RETURN_PRODUCT_STATUS.DELIVERY]: {
    title: 'Return Product Completed',
    description: 'Status report berhasil diubah menjadi Completed!',
  },
  [RETURN_PRODUCT_STATUS.COMPLETE]: {
    title: 'Return Product Completed',
    description: 'Status report berhasil diubah menjadi Completed!',
  },
};

export const ReturnProductDetailsComponent = () => {
  const { recordId } = useParams();

  const [modals, setModals] = useState({
    export: false,
    updateStatus: false,
    result: false,
    attachment: false,
    stockAudit: false,
  });

  const { mutate: exportReturnProductDetails, isLoading: isExporting } = useExportReturnProductDetails();

  const { mutate: approveReturnProduct, isLoading: isApproving } = useApproveReturnProduct();
  const { mutate: completeReturnProduct, isLoading: isCompleting } = useCompleteReturnProduct();
  const { data: returnProductDetails, isLoading, isError, error } = useReturnProductDetails(recordId);
  const { data: stockAuditData } = useStockAuditData({
    recordId,
    type: AUDIT_STOCK_TYPE.RETURN_PRODUCT,
  });

  const columns = useMemo(
    () => [
      {
        name: 'Product',
        className: 'w-[60%] text-sm font-semibold text-green text-center pr-3',
        dataIndex: 'user',
        render: (record) => (
          <div className='w-[60%] text-sm font-semibold text-green md:pr-1 pr-3'>
            <div class='flex items-center pl-2 xl:pl-5'>
              <div class='w-12'>
                <StandartImageComponent
                  src={record?.product_image || '/img/default-product.jpg'}
                  class='w-12 h-12 object-cover'
                  alt='Product'
                />
              </div>
              <div class='w-[calc(100%-3rem)] pl-3 xl:pl-5 line-clamp-3'>
                <Tooltip className='max-w-[30%]' content={record?.product_name}>
                  <div class='block text-xs font-semibold text-dark'>{record?.product_name}</div>
                </Tooltip>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: 'Expired Date',
        className:
          'w-[20%] text-center text-sm font-semibold text-green overflow-hidden text-ellipsis whitespace-nowrap',
        dataIndex: 'expired_date_formatted',
        render: (record, expired_date_formatted) => (
          <div class='w-[20%]'>
            <div class='text-sm text-center font-medium  text-gray'>{expired_date_formatted}</div>
          </div>
        ),
      },
      {
        name: 'QTY',
        className:
          'w-[20%] text-sm font-semibold text-green overflow-hidden text-ellipsis whitespace-nowrap text-center',
        dataIndex: 'qty_sold',
        render: (record) => (
          <div class='w-[20%]'>
            <div class='text-sm font-medium text-center text-gray'>{record?.qty?.toLocaleString()}</div>
          </div>
        ),
      },
    ],
    []
  );

  const handleOnApproveReport = (comment) => {
    if (returnProductDetails?.data?.status === RETURN_PRODUCT_STATUS.DELIVERY) {
      completeReturnProduct(
        {
          recordId,
          feedback: comment,
        },
        {
          onSuccess: () => {
            setModals({ ...modals, updateStatus: false, result: true });
          },
        }
      );
    }
    if (returnProductDetails?.data?.status === RETURN_PRODUCT_STATUS.SUBMITTED) {
      approveReturnProduct(
        {
          recordId,
        },
        {
          onSuccess: () => {
            setModals({ ...modals, updateStatus: false, result: true });
          },
        }
      );
    }
  };
  const handleExport = () => {
    exportReturnProductDetails(
      { recordId },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  return (
    <MainDashboardLayoutComponent
      breadCumbs={BREAD_CUMB_ITEMS}
      isError={isError}
      errorTitle={error?.response?.data?.status}
      erroMessage={catchErrorMessage(error?.response?.data?.message)}
    >
      {isLoading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          <div class='w-full flex items-center bg-gray-3 px-4 gap-x-3  py-8 mb-5'>
            <div class='w-[30%] flex items-center'>
              <StandartImageComponent
                withZoom
                src={returnProductDetails?.data?.user_image}
                class='overflow-hidden rounded-full w-[60px] h-[60px] border-2 border-solid border-green-3 object-cover'
              />
              <div class='w-[calc(100%-3rem)] pl-3'>
                <div class='text-sm font-semibold text-green mb-2'>{returnProductDetails?.data?.user_name}</div>
                <div class='overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-gray'>
                  {returnProductDetails?.data?.user_phone}
                </div>
              </div>
            </div>
            <div class='w-28%] flex items-center'>
              <StandartImageComponent
                defaultImage='/img/default-store.jpg'
                src={returnProductDetails?.data?.store_image}
                class='overflow-hidden w-[60px] h-[60px] border-2 border-solid border-green-3'
              />
              <div class='w-[calc(100%-3rem)] pl-3'>
                <div class='text-sm font-semibold text-green mb-2'>{returnProductDetails?.data?.store_name}</div>
                <div class='text-sm font-medium text-gray'>{returnProductDetails?.data?.city_name}</div>
              </div>
            </div>
            <div class='w-[22%] flex items-center'>
              <div class='flex items-center'>
                <span class='icon-ico-calendar text-[40px] text-green-3 font-normal'></span>
                <div class='w-[calc(100%-2rem)] pl-3'>
                  <div class='overflow-hidden block text-ellipsis whitespace-nowrap text-sm font-bold text-black mb-2'>
                    Tanggal Request
                  </div>
                  <div class='overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium text-gray'>
                    {returnProductDetails?.data?.request_date}
                  </div>
                </div>
              </div>
            </div>
            <div class='w-[20%] flex items-center'>
              <div
                class={clsx(
                  'block text-center w-full h-8 py-1 px-1 rounded-sm  text-sm font-bold',
                  RETURN_PRODUCT_DETAILS_STATUS_COLOR_CLASSNAME[returnProductDetails?.data?.status]
                )}
              >
                {RETURN_PRODUCT_STATUS_LABEL[returnProductDetails?.data?.status]}
              </div>
            </div>
          </div>
          <div className='italic text-dark text-sm mb-5'>{`Total ${returnProductDetails?.data?.items?.length} items`}</div>
          <div className='flex w-full justify-between pb-8'>
            <div className='w-[calc(100%-290px)]'>
              <BasicTableComponent
                rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
                columns={columns}
                dataSource={returnProductDetails?.data?.items || []}
              />
              <div class='bg-gray-1 mt-3 rounded-sm py-2.5 px-2 flex items-center'>
                <div class='w-[80%]'>
                  <div class='block text-sm font-bold text-dark uppercase pl-[10%] xl:pl-[8%]'>Total</div>
                </div>
                <div class='w-[20%]'>
                  <div class='block text-sm font-bold text-dark text-center uppercase'>
                    {returnProductDetails?.data?.total_product?.toLocaleString()}
                  </div>
                </div>
              </div>
            </div>
            <div class='w-[270px]'>
              <TableButtonExport
                onClick={handleExport}
                loading={isExporting}
                title='Export Data'
                className='outline-none w-full flex items-center border justify-center border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3 mb-3'
              />

              {returnProductDetails?.data?.status === RETURN_PRODUCT_STATUS.COMPLETE && (
                <div class='border border-solid border-gray-1 rounded-sm p-2 mb-3'>
                  <div className='text-green font-semibold'>
                    <span class='icon-ico-apply  text-sm text-center'></span> Completed
                  </div>
                </div>
              )}

              {returnProductDetails?.data?.status === RETURN_PRODUCT_STATUS.SUBMITTED && (
                <div class='border border-solid border-gray-1 rounded-sm p-2 mb-3'>
                  <div class='block text-sm font-semibold text-dark mb-3'>Approve Request</div>
                  <button
                    class=' bg-green flex justify-center items-center w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                    type='button'
                    onClick={() => {
                      setModals((prev) => ({
                        ...prev,
                        updateStatus: true,
                      }));
                    }}
                  >
                    <span class='icon-ico-apply mr-3 text-lg'></span> Approve
                  </button>
                </div>
              )}

              {returnProductDetails?.data?.status === RETURN_PRODUCT_STATUS.DELIVERY && (
                <div class='border border-solid border-gray-1 rounded-sm p-2 mb-3'>
                  <div class='block text-sm font-semibold text-dark mb-3'>Complete Request</div>
                  <button
                    class='disabled:opacity-40 disabled:cursor-not-allowed bg-green flex justify-center items-center w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                    type='button'
                    onClick={() => {
                      setModals((prev) => ({
                        ...prev,
                        updateStatus: true,
                      }));
                    }}
                  >
                    <span class='icon-ico-apply mr-3 text-lg'></span> Complete
                  </button>
                </div>
              )}

              <div class='bg-gray-3 py-5 px-4 mb-3'>
                <div class='block text-sm font-semibold text-dark mb-6'>Log Action</div>

                <ul class='step max-h-[45vh] scroll'>
                  {returnProductDetails?.data?.logs?.map((actionLogItem) => (
                    <li>
                      <div class='bullet'></div>
                      <div class='text-sm font-semibold text-gray '>{actionLogItem?.description}</div>
                      <div class='text-xs font-semibold italic text-gray mb-2'>{`Modified by ${actionLogItem?.actor_email}`}</div>
                      <div class='text-sm font-medium italic text-gray'>{actionLogItem?.created_at_formatted}</div>
                    </li>
                  ))}
                </ul>
              </div>
              {returnProductDetails?.data?.awb_url && (
                <button
                  class='outline-none w-full flex items-center border  border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
                  type='button'
                  onClick={() => {
                    window.open(returnProductDetails?.data?.awb_url, '_blank');
                  }}
                >
                  <span class='icon-ico-attachment text-xl text-green'></span>
                  <span class='pl-2 font-semibold text-dark text-sm'>Surat Jalan</span>
                </button>
              )}

              <div class='bg-gray-3 py-5 px-4 max-h-[45vh] scroll mt-3'>
                <div class='block text-sm font-semibold text-dark mb-3'>Notes BA</div>
                <p class='text-gray text-sm mb-4 whitespace-pre-line'>{returnProductDetails?.data?.note}</p>
              </div>
              <div class='bg-gray-3 py-5 px-4 max-h-[45vh] scroll mt-3'>
                <div class='block text-sm font-semibold text-dark mb-3'>Notes Admin</div>
                <p class='text-gray text-sm mb-4 whitespace-pre-line'>{returnProductDetails?.data?.feedback}</p>
              </div>
              <button
                class='bg-green flex mt-4  my-2 justify-center items-center w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                type='button'
                onClick={() => {
                  setModals((prev) => ({
                    ...prev,
                    stockAudit: true,
                  }));
                }}
              >
                <span class='icon-ico-info mr-3 text-lg'></span> Stock Audit
              </button>
            </div>
          </div>
        </>
      )}

      <ModalStockAudit
        visible={modals.stockAudit}
        onClose={() => setModals({ ...modals, stockAudit: false })}
        data={stockAuditData?.data}
      />

      <ModalConfirmation
        description={MODAL_CONFIRMATION_TEXT?.[returnProductDetails?.data?.status]?.description}
        title={MODAL_CONFIRMATION_TEXT?.[returnProductDetails?.data?.status]?.title}
        imageIcon='/img/info.svg'
        visible={modals.updateStatus}
        withComment={returnProductDetails?.data?.status === RETURN_PRODUCT_STATUS.DELIVERY}
        loading={isApproving || isCompleting}
        textConfirm={MODAL_CONFIRMATION_TEXT?.[returnProductDetails?.data?.status]?.textConfirm}
        onConfirm={handleOnApproveReport}
        onClose={() => setModals({ ...modals, updateStatus: false })}
      />

      <ModalResult
        description={MODAL_RESULT_TEXT?.[returnProductDetails?.data?.status]?.description}
        title={MODAL_RESULT_TEXT?.[returnProductDetails?.data?.status]?.title}
        visible={modals.result}
        textConfirm='OK'
        onConfirm={() => setModals({ ...modals, result: false })}
        onClose={() => setModals({ ...modals, result: false })}
      />
    </MainDashboardLayoutComponent>
  );
};
