import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from 'chart.js';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
defaults.font.family = 'Montserrat, sans-serif';

export const options = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: () => 'Product Sold',
        label: (context) => {
          return `${context.label} - ${context.formattedValue} Unit`;
        },
      },
      backgroundColor: '#fff',
      titleColor: '#5E755A',
      bodyColor: '#5E755A',
      bodyFont: {
        weight: 500,
        size: 16,
      },
      footerFont: {
        size: 20,
      },
      bodyAlign: 'center',
      displayColors: false,
    },
  },
  scales: {
    x: {
      display: true,
      gridLines: {
        borderDash: [8, 4],
        color: '#348632',
      },
    },
    y: {
      display: true,
      gridLines: {
        borderDash: [8, 4],
        color: '#348632',
      },
      ticks: {
        callback: function (value, index, ticks) {
          return `${value.toLocaleString()}`;
        },
      },
    },
  },
  layout: {
    padding: {
      left: 80,
      right: 0,
      bottom: 5,
    },
  },
};

export function AnalyticsProductChart({ labels, data, containerClassName = 'bg-gray-3 flex w-full relative mb-8' }) {
  const chartData = useMemo(() => {
    return {
      labels: labels,
      datasets: [
        {
          label: 'Unit',
          backgroundColor: 'rgba(116, 141, 111, 1)',
          borderColor: 'rgba(116, 141, 111, 1)',
          data: data,
          borderWidth: 1,
          pointRadius: 5,
          pointHoverRadius: 8,
        },
      ],
    };
  }, [labels, data]);

  return (
    <div class={containerClassName}>
      {data.length === 0 ? (
        <div className='h-44 w-full flex flex-col justify-center items-center bg-gray-3'>
          <StandartImageComponent class='max-w-full' src='/img/info.svg' />
          <p className='text-base font-semibold mb-3 text-gray'>Data for chart is not available</p>
        </div>
      ) : (
        <>
          <div class='h-100 w-[50px] flex justify-center items-center bg-green-status'>
            <div class='text-base font-bold w-32 whitespace-nowrap text-green rotate-[-90deg]'>Quantity (Qty)</div>
          </div>
          <div class='w-[calc(100%-50px)] p-4'>
            <Line options={options} data={chartData} />
          </div>
          <div class='w-32 h-12 absolute left-0 bottom-0 bg-green-status flex justify-center items-center'>
            <div class='text-base font-bold text-green'>Tanggal</div>
          </div>
        </>
      )}
    </div>
  );
}
