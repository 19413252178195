import clsx from 'clsx';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { ModalContainer } from './modal-container.component';

export const ModalResult = (props) => {
  const {
    title,
    description,
    onConfirm,
    children,
    visible,
    loading = false,
    onClose,
    textConfirm = 'Export',
    imageIcon = '/img/approve.svg',
    containerClassName = 'w-[330px] bg-white rounded-sm p-5 relative',
  } = props;
  return (
    <ModalContainer visible={visible}>
      <div id='show-item' class={clsx(containerClassName, !visible && 'hidden')}>
        <div class='mb-5 text-center font-bold text-sm'>{title}</div>
        <button type='button' class='absolute top-2 right-3' disabled={loading} onClick={onClose}>
          <span class='icon-ico-close text-xl text-gray-2'></span>
        </button>
        {!children && (
          <>
            <div class='max-h-[45vh] text-center scroll mb-5'>
              <img src={imageIcon} class='max-w-full inline-block' alt='Icon' />
              <p class='text-sm my-3'>{description}</p>
            </div>
            <div class='flex justify-between items-center pb-2'>
              <div class='w-full'>
                <ButtonSubmit
                  loading={loading}
                  onClick={onConfirm}
                  title={textConfirm}
                  className='bg-green w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                />
              </div>
            </div>
          </>
        )}
        {children && typeof children === 'function' ? children(props) : children}
      </div>
    </ModalContainer>
  );
};
