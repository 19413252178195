import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  defaults,
} from 'chart.js';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { abbreviateCurrency } from 'ui-utils/string.utils';
import { DATA_GRAPH_TYPE } from './analytics-traffic.component';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

defaults.font.family = 'Montserrat, sans-serif';

const INITIAL_OPTIONS = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: () => 'Traffic',
        label: (context) => {
          return `${context.formattedValue} Orang`;
        },
      },
      backgroundColor: '#fff',
      titleColor: '#5E755A',
      bodyColor: '#5E755A',
      bodyFont: {
        weight: 500,
        size: 16,
      },
      footerFont: {
        size: 20,
      },
      bodyAlign: 'center',
      displayColors: false,
    },
  },
  scales: {
    x: {
      display: true,
      gridLines: {
        borderDash: [8, 4],
        color: '#348632',
      },
    },
    y: {
      display: true,
      gridLines: {
        borderDash: [8, 4],
        color: '#348632',
      },
      ticks: {
        callback: function (value, index, ticks) {
          return abbreviateCurrency(value);
        },
      },
    },
  },
  layout: {
    padding: {
      left: 80,
      right: 0,
      bottom: 5,
    },
  },
};

export function AnalyticsTrafficChart({
  type = 'buyer',
  labels,
  data,
  containerClassName = 'bg-gray-3 flex w-full relative mb-8',
}) {
  const CHART_TEXT_LABEL = {
    [DATA_GRAPH_TYPE.BUYER]: {
      titleY: 'Total Pembeli',
      title: 'Traffic Pembeli',
    },
    [DATA_GRAPH_TYPE.VISITOR]: {
      titleY: 'Total Pengunjung',
      title: 'Traffic Pengunjung',
    },
  };
  const options = useMemo(() => {
    return {
      ...INITIAL_OPTIONS,
      plugins: {
        ...INITIAL_OPTIONS.plugins,
        tooltip: {
          ...INITIAL_OPTIONS.plugins.tooltip,
          callbacks: {
            ...INITIAL_OPTIONS.plugins.tooltip.callbacks,
            title: () => CHART_TEXT_LABEL[type].title,
            label: (context) => {
              return `${context.formattedValue} Orang`;
            },
          },
        },
      },
    };
  }, [type]);

  const chartData = useMemo(() => {
    return {
      labels: labels,
      datasets: [
        {
          label: 'Sales (Rp)',
          backgroundColor: 'rgba(116, 141, 111, 1)',
          borderColor: 'rgba(116, 141, 111, 1)',
          data: data,
          borderWidth: 1,
          pointRadius: 5,
          pointHoverRadius: 8,
        },
      ],
    };
  }, [labels, data]);

  if (!data) return <div class={containerClassName}> </div>;

  return (
    <div class={containerClassName}>
      {data.length === 0 ? (
        <div className='h-44 w-full flex flex-col justify-center items-center bg-gray-3'>
          <StandartImageComponent class='max-w-full' src='/img/info.svg' />
          <p className='text-base font-semibold mb-3 text-gray'>Data for chart is not available</p>
        </div>
      ) : (
        <>
          <div class='h-100 w-[50px] flex justify-center items-center bg-green-status'>
            <div class='text-base font-bold text-green transform -rotate-90'>
              <div class='whitespace-nowrap'>{CHART_TEXT_LABEL[type].titleY}</div>
            </div>
          </div>

          <div class='w-[calc(100%-50px)] p-4'>
            <Line options={options} data={chartData} />
          </div>
          <div class='w-32 h-12 absolute left-0 bottom-0 bg-green-status flex justify-center items-center'>
            <div class='text-base font-bold text-green'>Tanggal</div>
          </div>
        </>
      )}
    </div>
  );
}
