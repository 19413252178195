import qs from 'query-string';
import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const RETURN_PRODUCT_QUERY_KEY = {
  RETURN_PRODUCT_LIST: 'RETURN_PRODUCT_LIST',
  RETURN_PRODUCT_DETAILS: 'RETURN_PRODUCT_DETAILS',
};

export function useReturnProductList({ filter, body }) {
  return useQuery([RETURN_PRODUCT_QUERY_KEY.RETURN_PRODUCT_LIST, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/return-product?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}

export function useReturnProductDetails(recordId) {
  return useQuery([RETURN_PRODUCT_QUERY_KEY.RETURN_PRODUCT_DETAILS, recordId], () =>
    axios.get(`/api/v1/admin/return-product/detail/${recordId}`).then((res) => res.data)
  );
}
