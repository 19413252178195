import qs from 'query-string';
import axios from 'api/api.config';
import { useMutation, useQueryClient } from 'react-query';
import { STORE_LIST_QUERY } from './store-list.query.api';
import { PRODUCT_LIST_QUERY } from 'api/product/product-list/product-list.query.api';

export function useExportStoreList() {
  return useMutation(({ queryFilter, bodyFilter }) =>
    axios.post(`/api/v1/admin/store/export?${qs.stringify(queryFilter)}`, bodyFilter).then((res) => res.data)
  );
}

export function useDeleteStore() {
  const queryClient = useQueryClient();
  return useMutation(({ storeId }) => axios.delete(`/api/v1/admin/store/delete/${storeId}`).then((res) => res.data), {
    onSuccess: () => {
      queryClient.invalidateQueries(STORE_LIST_QUERY.STORE_LIST);
      queryClient.removeQueries(STORE_LIST_QUERY.ALL_STORE_LIST);
    },
  });
}

export function useUpdateStockProductStore() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ store_id, variant_id, total_qty }) =>
      axios.put(`/api/v1/admin/variant-stock/update`, { store_id, variant_id, total_qty }).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.refetchQueries([PRODUCT_LIST_QUERY.PRODUCT_STOCK_IN_STORE]);
      },
    }
  );
}

export function useExportStockProductStore() {
  return useMutation(({ queryFilter, bodyFilter }) =>
    axios.post(`/api/v1/admin/variant-stock/export?${qs.stringify(queryFilter)}`, bodyFilter).then((res) => res.data)
  );
}
