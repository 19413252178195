import { StandartImageComponent } from 'components/image/standart-image.component';
import moment from 'moment';

import { useRef, forwardRef, useImperativeHandle } from 'react';
import { useReactToPrint } from 'react-to-print';
export const PrintLabelPoComponent = forwardRef(({ data, onFinish }, ref) => {
  const componentRef = useRef(null);
  const handleDownloadPDF = useReactToPrint({
    onPrintError: (error) => alert(JSON.stringify(error)),
    content: () => componentRef.current,
    onAfterPrint: () => {
      onFinish && onFinish();
    },
    documentTitle: `AVO Beauty Advisor - Label PO_${new Date().getTime()}`,
  });

  useImperativeHandle(ref, () => ({
    handleDownload: handleDownloadPDF,
  }));

  const getPageMargins = () => {
    const pagePadding = {
      paddingTop: '25mm',
      paddingRight: 0,
      paddingBottom: '10mm',
      paddingLeft: 0,
    };
    return `
      @page {
        margin: ${pagePadding.paddingTop} ${pagePadding.paddingRight} ${pagePadding.paddingBottom} ${pagePadding.paddingLeft} !important;
      }
      @page:first {
        margin: 0 ${pagePadding.paddingRight} ${pagePadding.paddingBottom} ${pagePadding.paddingLeft} !important;
      }
    `;
  };

  return (
    <div className='flex flex-col w-[100%] h-full  '>
      <style>{getPageMargins()}</style>
      <div className='w-[100%] h-full' ref={componentRef}>
        {data?.map((itemPo) => (
          <div id='print' class='w-full h-[100vh] bg-white rounded-sm p-8 relative'>
            <div class='flex items-center mb-8'>
              <div class='w-[55%] flex items-center'>
                <div class='w-1/3 border-r border-solid border-gray-1'>
                  <StandartImageComponent src='/img/logo.png' class='w-20' />
                </div>
                <div class='w-4/6'>
                  <div class='text-xs font-semibold text-dark pl-12 mb-1'>Nomor PO</div>
                  <div class='text-sm font-medium text-gray pl-12'>{itemPo?.po_number}</div>
                </div>
              </div>
              <div class='w-[45%]'></div>
            </div>
            <div class='flex items-center mb-8'>
              <div class='w-1/2 pr-3'>
                <div class='text-base font-bold text-dark mb-1'>Pengirim</div>
                <div class='text-sm font-semibold text-dark mb-2'>{itemPo?.warehouse_name}</div>
                <div class='text-xs font-medium text-gray'>{itemPo?.warehouse_address}</div>
              </div>
              <div class='w-1/2 pl-5'>
                <div class='text-base font-bold text-dark mb-1'>Penerima</div>
                <div class='text-sm font-semibold text-dark mb-2'>{itemPo?.store_name}</div>
                <div class='text-xs font-medium text-gray'>{itemPo?.store_address}</div>
              </div>
            </div>
            <div class=' scroll mb-5'>
              <div class='bg-green-1 rounded-sm py-2 px-8 flex items-center mb-4'>
                <div class='w-[60%] text-xs font-semibold text-green'>Product</div>
                <div class='w-[20%] text-xs font-semibold text-green'>Qty</div>
                <div class='w-[20%] text-xs font-semibold text-green'>Expired Date</div>
              </div>

              {itemPo?.expired_date_items?.map((item) => (
                <div class='bg-white rounded-sm py-2 px-8 flex items-center mb-4 justify-center'>
                  <div class='w-[60%] text-xs font-medium text-dark pr-6'>{item?.product_name}</div>
                  <div class='w-[20%] text-xs font-medium text-dark'>{item?.qty}</div>
                  <div class='w-[20%] text-xs font-medium text-gray'>
                    {item?.expired_date && moment(item?.expired_date).format('MMMM YYYY')}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});
