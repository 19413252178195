import clsx from 'clsx';

export function TimeComponent({ title = 'Time Clock In', time = '', type = 'IN', note = '' }) {
  return (
    <div className='flex flex-row justify-between gap-10' id='clock-in-info-time'>
      <div className={clsx(time ? 'w-1/4' : 'w-2/4')}>
        <div className='text-md text-green font-medium mb-3'>{title}</div>
        {time && time !== 'Invalid date' && (
          <div className={clsx('text-2xl  font-bold mb-3', type === 'IN' ? 'text-blue' : 'text-red-500')}>{time}</div>
        )}
        {!time || (time === 'Invalid date' && <p className='text-sm text-gray-2  italic'>No Time Record</p>)}
      </div>
      {time && (
        <div className='w-3/4'>
          <div className='text-md text-green  font-medium mb-3'>Notes</div>
          <div className='text-sm text-gray  mb-3'>
            {note || <p className='text-sm text-gray-2  italic'>No Note Available</p>}
          </div>
        </div>
      )}
    </div>
  );
}
