import qs from 'query-string';
import axios from 'api/api.config';
import { useQueryClient, useMutation } from 'react-query';
import { SHARE_INFO_QUERY_KEY } from './info.query';

export function useCreateNewInfo() {
  const queryClient = useQueryClient();
  return useMutation((body) => axios.post(`/api/v1/admin/share-info/create`, body).then((res) => res.data), {
    onSuccess: (data) => {
      queryClient.resetQueries([SHARE_INFO_QUERY_KEY.INFO_DETAILS]);
      queryClient.invalidateQueries(SHARE_INFO_QUERY_KEY.LIST_INFO);
    },
  });
}

export function useEditInfo() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, ...body }) => axios.put(`/api/v1/admin/share-info/update/${id}`, body).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.resetQueries([SHARE_INFO_QUERY_KEY.INFO_DETAILS]);
        queryClient.invalidateQueries(SHARE_INFO_QUERY_KEY.LIST_INFO);
      },
    }
  );
}

export function useExportInfoList() {
  return useMutation(({ queryFilter, bodyFilter }) =>
    axios.post(`/api/v1/admin/share-info/export?${qs.stringify(queryFilter)}`, bodyFilter).then((res) => res.data)
  );
}
