import { createSlice } from '@reduxjs/toolkit';

export const toolsSlice = createSlice({
  name: '@TOOLS',
  initialState: {
    pageSize: {
      showModal: false,
      value: 20,
    },
    sorting: {
      showModal: false,
      value: 'desc',
    },
    filter: {
      showModal: false,
      value: null,
    },
    searchKey: '',
    selectedRowKeys: [],
    selectedRows: [],
  },
  reducers: {
    setToolsReducer: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setToolsReducer } = toolsSlice.actions;
const toolsReducer = toolsSlice.reducer;

export default toolsReducer;
