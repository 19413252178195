import moment from 'moment';
import { useMemo, useState } from 'react';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';

import { BounceLoading } from 'components/loader/bounce.loading';
import { PATH_CONSTANT } from 'config/path.constant';
import { AnalyticsSalesChart } from './analytics-sales.chart';
import { RangeDatePicker } from 'components/date-picker/range-date-picker.component';
import { useGetAnalyticsSalesGraph, useGetAnalyticsTotalSales } from 'api/analytics/sales/analytic-sales.query.api';
import { convertToRupiah } from 'ui-utils/string.utils';
import { AnalyticsSummaryCardInfo } from './summary-card.component';
import { useGetAnalyticsTotalProduct } from 'api/analytics/product/analytic-product.query.api';
import { AnalyticSalesTable } from './sales-table.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { TableButtonFilterComponent } from 'components/table/table-button-filter.component';
import { ModalTableFilter } from 'components/modal/modal-table-filter.component';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { useExportAnalyticSales } from 'api/analytics/sales/analytic-sales.mutation.api';

const INITIAL_PREVIOUS_DAY_RANGE = 6;

const BREAD_CUMB_ITEMS = [
  {
    title: 'Analytics',
    path: PATH_CONSTANT.ANALYTICS.SALES,
  },
  {
    title: 'Sales Analytic',
    path: PATH_CONSTANT.ANALYTICS.SALES,
  },
];

const INITIAL_DATE_FILTER = {
  startDate: moment().subtract(INITIAL_PREVIOUS_DAY_RANGE, 'day').toDate(),
  endDate: moment().toDate(),
};

const INITIAL_VALUE_FILTER_QUERY = {
  limit: 20,
  page: 1,
  orderBy: 'total_sales',
  orderType: 'desc',
  search: undefined,
};
const INITIAL_VALUE_FILTER_BODY = {
  store_ids: [],
};

export const AnalyticsSalesComponent = () => {
  const [dateFilter, setDateFilter] = useState({
    startDate: moment(INITIAL_DATE_FILTER.startDate).format('YYYY-MM-DD'),
    endDate: moment(INITIAL_DATE_FILTER.endDate).format('YYYY-MM-DD'),
  });
  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);
  const [bodyFilter, setBodyFilter] = useState(INITIAL_VALUE_FILTER_BODY);

  const [modals, setModals] = useState({
    export: false,
    filter: false,
    result: false,
  });

  const { data: totalSales, isLoading: isLoadingTotalSales } = useGetAnalyticsTotalSales({
    filter: dateFilter,
    body: bodyFilter,
  });

  const { data: totalProducts, isLoading: isLoadingProducts } = useGetAnalyticsTotalProduct({
    filter: dateFilter,
    body: bodyFilter,
  });

  const { data: salesGraph } = useGetAnalyticsSalesGraph({
    filter: dateFilter,
    body: bodyFilter,
  });

  const { mutate: exportData, isLoading: isExporting } = useExportAnalyticSales();

  // format data for chart
  const salesGraphData = useMemo(() => {
    if (salesGraph) {
      return {
        label: salesGraph.data.map((item) => item.label && moment(item.label).format('D MMM YYYY')),
        data: salesGraph.data.map((item) => item.value),
      };
    }
    return {
      label: [],
      data: [],
    };
    // ENABLE THIS FOR MOCK DATA
    // return {
    //   label: generateRandomChartItems().map((item) => item.label && moment(item.label).format('D MMM YYYY')),
    //   data: generateRandomChartItems().map((item) => item.value),
    // };
  }, [salesGraph]);

  const isFilterActive = useMemo(() => {
    if (bodyFilter.store_ids.length > 0) {
      return true;
    }
  }, [bodyFilter]);

  const handleExport = () => {
    exportData(
      {
        queryFilter: {
          ...queryFilter,
          ...dateFilter,
        },
        bodyFilter,
      },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      {isLoadingTotalSales && isLoadingProducts ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          <div class='flex items-center justify-center w-full gap-x-2 mb-5'>
            <RangeDatePicker
              initialStartDate={moment(dateFilter.startDate).toDate()}
              initialEndDate={moment(dateFilter.endDate).toDate()}
              maxDate={moment().toDate()}
              onChange={({ start, end }) => {
                setDateFilter({
                  startDate: moment(start).format('YYYY-MM-DD'),
                  endDate: moment(end).format('YYYY-MM-DD'),
                });
              }}
            />

            <TableButtonFilterComponent
              active={isFilterActive}
              onClick={() => setModals({ ...modals, filter: true })}
            />
            <TableButtonExport onClick={() => setModals({ ...modals, export: true })} loading={isExporting} />
          </div>
          <div class='flex justify-between mb-5'>
            <AnalyticsSummaryCardInfo
              title='Total Sales'
              tooltipText='Total revenue yang di dapatkan berdasarkan hasil report yang dilakukan oleh BA'
              percentageSummaryValue={totalSales?.data?.value_sales}
              currentPeriodeDate={`${moment(totalSales?.data?.current_period?.[0]).format('DD MMM YY')} - ${moment(
                totalSales?.data?.current_period?.[1]
              ).format('DD MMM YY')}`}
              currentPeriodeValue={convertToRupiah(totalSales?.data?.total_sales)}
              previousPeriodeDate={`${moment(totalSales?.data?.prev_period?.[0]).format('DD MMM YY')} - ${moment(
                totalSales?.data?.prev_period?.[1]
              ).format('DD MMM YY')}`}
              previousPeriodeValue={convertToRupiah(totalSales?.data?.prev_total_sales)}
            />
            <AnalyticsSummaryCardInfo
              title='Total Produk Terjual'
              tooltipText='Total produk yang terjual berdasarkan hasil report yang dilakukan oleh BA'
              percentageSummaryValue={totalProducts?.data?.value}
              currentPeriodeDate={`${moment(totalProducts?.data?.current_period?.[0]).format('DD MMM YY')} - ${moment(
                totalProducts?.data?.current_period?.[1]
              ).format('DD MMM YY')}`}
              currentPeriodeValue={`${totalProducts?.data?.total_product_sold.toLocaleString()} Unit`}
              previousPeriodeDate={`${moment(totalProducts?.data?.prev_period?.[0]).format('DD MMM YY')} - ${moment(
                totalProducts?.data?.prev_period?.[1]
              ).format('DD MMM YY')}`}
              previousPeriodeValue={`${totalProducts?.data?.prev_total_product_sold.toLocaleString()} Unit`}
            />
          </div>
          <AnalyticsSalesChart labels={salesGraphData.label} data={salesGraphData.data} />
          <AnalyticSalesTable
            queryFilter={queryFilter}
            bodyFilter={bodyFilter}
            setQueryFilter={setQueryFilter}
            dateFilter={dateFilter}
          />
        </>
      )}
      <ModalTableFilter
        initialValue={bodyFilter}
        filterStatusOptions={null}
        visible={modals.filter}
        onChange={(selectedFilter) => {
          setBodyFilter(selectedFilter);

          setModals({ ...modals, filter: false });
        }}
        onClose={() => setModals({ ...modals, filter: false })}
      />
      <ModalConfirmation
        description='Apakah anda yakin ingin export data?'
        title='Export Data'
        visible={modals.export}
        onConfirm={handleExport}
        onClose={() => setModals({ ...modals, export: false })}
      />
    </MainDashboardLayoutComponent>
  );
};
