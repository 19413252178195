import axios from 'api/api.config';
import { useMutation } from 'react-query';

export function useLogin() {
  return useMutation((values) => axios.post('/api/v1/auth/login-admin', values).then((res) => res.data));
}

export function useForgotPassword() {
  return useMutation((values) => axios.post('/api/v1/auth/forgot-password-admin', values).then((res) => res.data));
}
