import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const USER_DETAIL_QUERY_KEY = {
  USER_DETAIL: 'USER_DETAIL',
};

export function useUserDetail(userId) {
  return useQuery(
    [USER_DETAIL_QUERY_KEY.USER_DETAIL, userId],
    () => axios.get(`/api/v1/admin/user/detail/${userId}`).then((res) => res.data),
    {
      enabled: !!userId,
    }
  );
}
