import qs from 'query-string';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { TableSearchInputComponent } from 'components/table/table-search.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { TableButtonLimitComponent } from 'components/table/table-button-limit.component';
import { TableButtonSortComponent } from 'components/table/table-button-sort.component';
import { BasicTableComponent } from 'components/table/table-basic.component';
import { Pagination } from 'components/table/table-pagination.component';
import { EmptyStateComponent } from 'components/empty-data';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ModalTableSortComponent } from 'components/modal/modal-table-sort.component';
import { ModalTableLimitComponent } from 'components/modal/modal-table-limit.component';
import { ModalTableFilter } from 'components/modal/modal-table-filter.component';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { setToolsReducer } from 'reducers/tools.reducer';
import { Tooltip } from 'flowbite-react';
import { PATH_CONSTANT } from 'config/path.constant';
import { ModalResult } from 'components/modal/modal.result.component';
import { catchErrorMessage } from 'ui-utils/string.utils';
import { useAttendanceList } from 'api/schedules/attendance/attendance.query.api.';
import { RangeDatePicker } from 'components/date-picker/range-date-picker.component';
import { TableButtonFilterComponent } from 'components/table/table-button-filter.component';
import { useExportAttendanceList } from 'api/schedules/attendance/attendance.mutation.api';
import { HighlightedText } from 'components/highlighted-text';

const INITIAL_PREVIOUS_DAY_RANGE = 6;
const BREAD_CUMB_ITEMS = [
  {
    title: 'Jadwal & Presensi',
    path: PATH_CONSTANT.PRESENSI.LIST,
  },
  {
    title: 'Presensi',
    path: PATH_CONSTANT.PRESENSI.LIST,
  },
];

const INITIAL_VALUE_FILTER_BODY = {
  status: [],
};

const SORT_OPTIONS = [
  { label: 'Tanggal Terlama', sortType: 'asc', sortBy: 'date' },
  { label: 'Tanggal Terbaru', sortType: 'desc', sortBy: 'date' },
];

export const PresensiListComponent = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const startDateParams = searchParams.get('startDate') || null;
  const endDateParams = searchParams.get('endDate') || null;

  const INITIAL_DATE_FILTER = {
    startDate: startDateParams
      ? moment(startDateParams).toDate()
      : moment().subtract(INITIAL_PREVIOUS_DAY_RANGE, 'day').toDate(),
    endDate: endDateParams ? moment(endDateParams).toDate() : moment().toDate(),
  };

  const INITIAL_VALUE_FILTER_QUERY = {
    limit: searchParams.get('limit')?.replace(/[^0-9]/g, '') || 20,
    page: searchParams.get('page')?.replace(/[^0-9]/g, '') || 1,
    orderBy: searchParams.get('orderBy')?.replace(/[^a-zA-Z_]/g, '') || 'date',
    orderType: searchParams.get('orderType')?.replace(/[^a-zA-Z]/g, '') || 'desc',
    search: searchParams.get('search')?.replace(/[^a-zA-Z0-9 ]/g, '') || undefined,
  };

  const [dateFilter, setDateFilter] = useState({
    startDate: moment(INITIAL_DATE_FILTER.startDate).format('YYYY-MM-DD'),
    endDate: moment(INITIAL_DATE_FILTER.endDate).format('YYYY-MM-DD'),
  });

  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);
  const [bodyFilter, setBodyFilter] = useState(INITIAL_VALUE_FILTER_BODY);

  const { selectedRowKeys: selectedIds } = useSelector((state) => state.tools);

  const [modals, setModals] = useState({
    sort: false,
    limit: false,
    filter: false,
    export: false,
    updateStatus: false,
    result: false,
  });

  const { mutate: exportAttendanceList, isLoading: isExporting } = useExportAttendanceList();
  const {
    data: userList,
    isLoading,
    isError,
    error,
  } = useAttendanceList({
    filter: { ...queryFilter, ...dateFilter },
    bodyFilter,
  });

  const FILTER_OPTIONS = useMemo(() => {
    return [
      {
        key: 'status',
        type: 'checkbox',
        multiple: true,
        searchFilter: false,
        options: [
          {
            label: 'User Terlambat',
            value: 'late',
          },
          {
            label: 'User Tepat Waktu',
            value: 'ontime',
          },
        ],
      },
    ];
  }, []);

  const columns = useMemo(
    () => [
      {
        type: 'checkbox',
        isRightBorderAvailable: true,
        render: (record) => {
          return (
            <div className='form-check px-2'>
              <input
                className='form-check-input appearance-none h-4 w-4 border border-green rounded-sm bg-white checked:bg-green checked:border-green focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                type='checkbox'
                readOnly
              />
            </div>
          );
        },
      },
      {
        name: 'User',
        className: 'w-[36%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record) => (
          <div className='w-[36%] text-grey-70 text-sm font-medium'>
            <div class='flex items-center pl-2 xl:pl-5'>
              <div class='w-12'>
                <img
                  src={record?.user?.profile_picture || '/img/default-user.jpg'}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/img/default-user.jpg';
                  }}
                  class='w-12 h-12 object-cover'
                  alt='User'
                />
              </div>
              <div class='w-auto pl-3 xl:pl-5'>
                <Link
                  to={`${PATH_CONSTANT.PRESENSI.DETAILS}/${record?.id}`}
                  class='w-48 text-ellipsis line-clamp-2 whitespace-normal text-sm font-semibold text-dark hover:text-green mb-1'
                >
                  <Tooltip className='max-w-[40%]' content={record?.user?.name}>
                    <HighlightedText text={record?.user?.name} highlight={queryFilter?.search} />
                  </Tooltip>
                </Link>
                <div class='text-sm font-medium text-gray'>
                  <HighlightedText text={record?.user?.email} highlight={queryFilter?.search} />
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: 'Store Penempatan',
        className: 'w-[20%] text-sm font-semibold text-green',
        dataIndex: 'user',
        render: (record) => (
          <div className='w-[20%] text-grey-70 text-sm font-medium px-2'>
            <div class='text-sm font-semibold text-green mb-1 line-clamp-2'>
              <Tooltip className='max-w-[40%]' content={record?.store?.name}>
                <HighlightedText text={record?.store?.name} highlight={queryFilter?.search} />
              </Tooltip>
            </div>
            <div class='text-sm font-medium italic text-gray'>{record?.store?.city?.name}</div>
          </div>
        ),
      },
      {
        name: 'Clock In',
        className: 'w-[15%] text-sm font-semibold text-green text-center',
        dataIndex: 'clock_in_formatted',
        render: (record) => (
          <div class='w-[15%]'>
            <div class='text-sm font-medium text-gray text-left'>{record?.date_formatted}</div>
            <div class='text-sm font-medium text-black text-left'>{record?.attendance?.clock_in_formatted || '-'}</div>

            {record?.attendance?.is_clock_in_late === 1 && (
              <div class='text-sm font-medium text-red-500 text-left'>Terlambat</div>
            )}
          </div>
        ),
      },
      {
        name: 'Clock Out',
        className:
          'w-[15%] text-sm font-semibold text-green overflow-hidden text-center text-ellipsis whitespace-nowrap',
        dataIndex: 'clock_out_formatted',
        render: (record, clock_out_formatted) => (
          <div class='w-[15%]'>
            <div class='text-sm font-medium text-gray text-left'>{record?.date_formatted}</div>
            <div class='text-sm font-medium text-black text-left'>{record?.attendance?.clock_out_formatted || '-'}</div>
            {record?.attendance?.is_clock_out_early === 1 && (
              <div class='text-sm font-medium text-red-500 text-left'>Terlambat</div>
            )}
          </div>
        ),
      },
      {
        name: '',
        className: 'w-[10%] text-sm font-semibold text-green',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[10%] flex justify-center'>
            <Link
              to={`${PATH_CONSTANT.PRESENSI.DETAILS}/${record?.id}`}
              class='w-48 text-center text-ellipsis line-clamp-2 whitespace-normal text-sm font-semibold text-green mb-1'
            >
              LIHAT DETAIL
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  const resetCheckList = () => {
    if (selectedIds.length > 0) {
      dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
    }
  };

  const handleExport = () => {
    exportAttendanceList(
      {
        queryFilter: { ...queryFilter, ...dateFilter },
        bodyFilter: { ...bodyFilter, attendance_ids: selectedIds },
      },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  useEffect(() => {
    resetCheckList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      <div className='flex items-center mb-5'>
        <div className='w-full flex items-center justify-between'>
          <TableSearchInputComponent
            initialValue={INITIAL_VALUE_FILTER_QUERY.search}
            inputProps={{
              disabled: isLoading,
            }}
            onSearch={(value) => {
              setQueryFilter({ ...queryFilter, search: value.keyword, page: 1 });
              navigate({
                search: qs.stringify({
                  ...queryFilter,
                  search: value.keyword || undefined,
                  page: 1,
                }),
              });
            }}
          />

          <div className='w-full flex justify-start items-center gap-2'>
            <TableButtonSortComponent
              disabled={isLoading}
              onClick={() => {
                setModals((prev) => ({ ...prev, sort: true }));
              }}
            />

            <TableButtonLimitComponent
              disabled={isLoading}
              value={queryFilter.limit}
              onClick={() => setModals({ ...modals, limit: true })}
            />

            <RangeDatePicker
              disabled={isLoading}
              initialStartDate={moment(dateFilter.startDate).toDate()}
              initialEndDate={moment(dateFilter.endDate).toDate()}
              maxDate={moment().toDate()}
              onChange={({ start, end }) => {
                navigate({
                  search: qs.stringify({
                    ...queryFilter,
                    startDate: moment(start).format('YYYY-MM-DD'),
                    endDate: moment(end).format('YYYY-MM-DD'),
                  }),
                });
                setQueryFilter((prev) => ({
                  ...prev,
                  page: 1,
                }));
                setDateFilter({
                  startDate: moment(start).format('YYYY-MM-DD'),
                  endDate: moment(end).format('YYYY-MM-DD'),
                });
              }}
            />
            <TableButtonFilterComponent
              disabled={isLoading}
              active={bodyFilter.status.length > 0}
              onClick={() => {
                setModals({ ...modals, filter: true });
              }}
            />

            <TableButtonExport
              disabled={isLoading}
              loading={isExporting}
              onClick={() => {
                setModals({ ...modals, export: true });
              }}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          <div className='italic text-dark text-sm mb-5'>{`Total ${userList?.data?.total_result} data`}</div>

          <BasicTableComponent
            rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
            columns={columns}
            dataSource={userList?.data?.rows || []}
            loading={isLoading}
            error={isError}
            errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
            errorMessage={catchErrorMessage(error?.response?.data?.message)}
          />

          {userList?.data?.total_result === 0 && (
            <EmptyStateComponent
              message={queryFilter.search ? `No result for query "${queryFilter.search}"` : 'No data available'}
            />
          )}

          <Pagination
            currentPage={queryFilter.page}
            pageSize={queryFilter.limit}
            siblingCount={1}
            totalCount={userList?.data?.total_result || 0}
            onPageChange={(page) => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
              setQueryFilter({ ...queryFilter, page });
              navigate({
                search: qs.stringify({
                  ...queryFilter,
                  ...dateFilter,
                  page,
                }),
              });
            }}
          />
        </>
      )}
      <ModalTableSortComponent
        options={SORT_OPTIONS}
        initialValue={SORT_OPTIONS.find(
          (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
        )}
        onChange={({ selected }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            orderBy: selected.sortBy,
            orderType: selected.sortType,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              ...dateFilter,
              orderBy: selected.sortBy,
              orderType: selected.sortType,
            }),
          });
          setModals({ ...modals, sort: false });
        }}
        visible={modals.sort}
        onClose={() => setModals({ ...modals, sort: false })}
      />

      <ModalTableLimitComponent
        onChange={({ limit }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            limit,
            page: 1,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              ...dateFilter,
              limit,
              page: 1,
            }),
          });
          setModals({ ...modals, limit: false });
        }}
        initialValue={queryFilter.limit}
        visible={modals.limit}
        onClose={() => setModals({ ...modals, limit: false })}
      />
      <ModalConfirmation
        description='Apakah anda yakin ingin eksport data yang anda pilih ?'
        title='Export Data'
        loading={isExporting}
        visible={modals.export}
        onConfirm={handleExport}
        onClose={() => setModals({ ...modals, export: false })}
      />

      <ModalResult
        description='Update status user berhasil dilakukan!'
        title='Update Status User'
        visible={modals.result}
        textConfirm='OK'
        onConfirm={() => setModals({ ...modals, result: false })}
        onClose={() => setModals({ ...modals, result: false })}
      />

      <ModalTableFilter
        initialValue={bodyFilter}
        visible={modals.filter}
        filterOptionsProps={FILTER_OPTIONS}
        onChange={(selectedFilter) => {
          if (!selectedFilter.status) return setBodyFilter({ status: [] });

          setBodyFilter({
            status: selectedFilter.status,
          });
          setQueryFilter((prevState) => ({
            ...prevState,
            page: 1,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              page: 1,
            }),
          });
          setModals({ ...modals, filter: false });
        }}
        onClose={() => setModals({ ...modals, filter: false })}
      />
    </MainDashboardLayoutComponent>
  );
};
