import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { isTokenExpired } from 'api/api.utils';
import { setAuthHeader, resetAuthHeader } from 'api/api.config';
import authReducer, { logout } from './auth.reducer';
import toolsReducer from './tools.reducer';

const rootReducers = combineReducers({
  auth: authReducer,
  tools: toolsReducer,
});

const persistConfig = {
  key: 'APPLICATION_STATE',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore(
  {
    reducer: persistedReducer,
  },
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const persistor = persistStore(store, {}, () => {
  const state = store.getState();
  if (isTokenExpired(state)) {
    store.dispatch(logout());
    resetAuthHeader();
    localStorage.clear();
  } else {
    setAuthHeader(state.auth.accessToken);
  }
});
