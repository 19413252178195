import moment from 'moment';

export function isTokenExpired(state) {
  const { accessToken, expiredDate } = state.auth;

  if (expiredDate === 'NEVER') return false;

  let expired = true;

  if (moment(expiredDate).isValid()) {
    expired = moment().isAfter(expiredDate);
  }
  return accessToken && expired;
}
