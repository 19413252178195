import qs from 'query-string';
import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const REPORT_BA_SALES_QUERY = {
  SALES_LIST: 'SALES_LIST',
  SALES_DETAILS_REPORT: 'SALES_DETAILS_REPORT',
  SALES_DETAILS_REPORT_ACTION_LOG: 'SALES_DETAILS_REPORT_ACTION_LOG',
};

export function useReportBASalesList({ filter, body }) {
  return useQuery([REPORT_BA_SALES_QUERY.SALES_LIST, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/report?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}

export function useReportBASalesDetails(recordId) {
  return useQuery([REPORT_BA_SALES_QUERY.SALES_DETAILS_REPORT, recordId], () =>
    axios.get(`/api/v1/admin/report/detail/${recordId}`).then((res) => res.data)
  );
}

export function useReportBASalesActionLog(recordId) {
  return useQuery([REPORT_BA_SALES_QUERY.SALES_DETAILS_REPORT_ACTION_LOG, recordId], () =>
    axios.get(`/api/v1/admin/report/log/${recordId}`).then((res) => res.data)
  );
}
