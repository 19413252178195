import * as Yup from 'yup';

/**
 * Form validation schema for /store/create
 */

export const CreateProductValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required').max(255, 'Name is too long'),
  gs_one_barcode: Yup.string().required('GS1 Barcode is required'),
  sku_code: Yup.string().required('SKU Code is required'),
  price: Yup.string().required('Price is required'),

  variants: Yup.array()
    .of(Yup.string().required('Variant is required'))
    .min(1, 'At least one variant is required')
    .nullable(),
});
