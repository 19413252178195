import { useMemo, useState } from 'react';
import { BasicTableComponent } from 'components/table/table-basic.component';
import { TableButtonSortComponent } from 'components/table/table-button-sort.component';
import { TableSearchInputComponent } from 'components/table/table-search.component';
import { ModalTableSortComponent } from 'components/modal/modal-table-sort.component';
import { catchErrorMessage } from 'ui-utils/string.utils';
import { EmptyStateComponent } from 'components/empty-data';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { Pagination } from 'components/table/table-pagination.component';
import { useNavigate } from 'react-router-dom';
import { useGetAnalyticsListPresensi } from 'api/analytics/presensi/analytic-presensi.query.api';
import clsx from 'clsx';
import { TableButtonFilterComponent } from 'components/table/table-button-filter.component';
import { ModalTableFilter } from 'components/modal/modal-table-filter.component';
import { HighlightedText } from 'components/highlighted-text';

const SORT_OPTIONS = [
  { label: 'Tanggal Terbaru', sortType: 'desc', sortBy: 'date' },
  { label: 'Tanggal Terdahulu', sortType: 'asc', sortBy: 'date' },
];

const FILTER_OPTIONS = [
  {
    key: 'status',
    type: 'checkbox',
    multiple: true,
    searchFilter: false,
    options: [
      {
        label: 'User Terlambat',
        value: 'late',
      },
      {
        label: 'User Tepat Waktu',
        value: 'ontime',
      },
    ],
  },
];

const getStatusColorClassName = (status) => {
  switch (status) {
    case 'Terlambat':
      return 'text-red-500';

    case 'Tepat Waktu':
      return 'text-green';

    default:
      return 'bg-dark text-black';
  }
};

export function AnalyticPresensiTable({ bodyFilter, dateFilter, queryFilter, setQueryFilter, setBodyFilter }) {
  const navigate = useNavigate();
  const [modals, setModals] = useState({
    sort: false,
  });

  const { data, isLoading, isError, error } = useGetAnalyticsListPresensi({
    body: bodyFilter,
    filter: {
      ...queryFilter,
      ...dateFilter,
    },
  });

  const isFilterActive = useMemo(() => {
    if (bodyFilter.status.length > 0) {
      return true;
    }
  }, [bodyFilter]);

  const columns = useMemo(
    () => [
      {
        name: 'Tanggal',
        className: 'w-[15%] text-sm font-semibold text-green',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[15%]'>
            <div class='text-sm font-semibold text-dark mb-1'>{record?.date_formatted}</div>
          </div>
        ),
      },
      {
        name: 'BA Name',
        className: 'w-[30%] text-sm font-semibold text-green pl-6',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[30%] text-sm font-semibold text-green pl-6'>
            <div class='flex items-center'>
              <div class='w-12'>
                <StandartImageComponent
                  src={record?.user?.profile_picture || '/img/default-user.jpg'}
                  class='w-12 h-12 object-cover'
                />
              </div>
              <div class='w-[70%] pl-5 xl:pl-5'>
                <div
                  onClick={() => {
                    navigate(`/users/list/details/${record?.user_id}`);
                  }}
                  class='text-sm font-semibold text-dark hover:text-green mb-1'
                >
                  <HighlightedText text={record?.user?.name} highlight={queryFilter?.search} />
                </div>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: 'Store Name',
        className: 'w-[20%] text-sm font-semibold text-green',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[20%]'>
            <div class='text-sm font-semibold text-dark mb-1'>
              <HighlightedText text={record?.store?.name} highlight={queryFilter?.search} />
            </div>
          </div>
        ),
      },
      {
        name: 'Clock In',
        className: 'w-[10%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[10%]'>
            <div class='text-sm font-medium text-gray text-center'>
              {record?.attendance?.clock_in_formatted ? `${record?.attendance?.clock_in_formatted}` : `-`}
            </div>
          </div>
        ),
      },
      {
        name: 'Clock Out',
        className: 'w-[10%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[10%]'>
            <div class='text-sm font-medium text-gray text-center'>
              {record?.attendance?.clock_out_formatted ? `${record?.attendance?.clock_out_formatted}` : `-`}
            </div>
          </div>
        ),
      },
      {
        name: 'Status',
        className: 'w-[10%] text-sm font-semibold text-green text-center',
        dataIndex: 'user',
        render: (record) => (
          <div class='w-[10%]'>
            <div
              class={clsx(
                'text-sm font-medium  text-center',
                getStatusColorClassName(record?.attendance?.clock_in_status)
              )}
            >
              {record?.attendance?.clock_in_status}
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryFilter?.search]
  );

  return (
    <>
      <div class='flex items-center mb-5 gap-3'>
        <TableSearchInputComponent
          placeholder='Cari nama BA atau nama toko'
          inputProps={{
            disabled: isLoading,
          }}
          onSearch={(value) => {
            setQueryFilter({ ...queryFilter, search: value.keyword, page: 1 });
          }}
        />
        <TableButtonSortComponent disabled={isLoading} onClick={() => setModals({ ...modals, sort: true })} />
        <TableButtonFilterComponent active={isFilterActive} onClick={() => setModals({ ...modals, filter: true })} />
      </div>

      <BasicTableComponent
        error={isError}
        loading={isLoading}
        errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
        errorMessage={catchErrorMessage(error?.response?.data?.message)}
        rowClassName='flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
        columns={columns}
        dataSource={data?.data?.rows || []}
      />

      {data?.data?.total_result === 0 && (
        <EmptyStateComponent
          className='flex flex-col items-center justify-center h-44'
          message={queryFilter.search ? `No result for query "${queryFilter.search}"` : 'No data available'}
        />
      )}

      <Pagination
        currentPage={queryFilter.page}
        pageSize={queryFilter.limit}
        siblingCount={1}
        totalCount={data?.data?.total_result || 0}
        onPageChange={(page) => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          setQueryFilter({ ...queryFilter, page });
        }}
      />
      <ModalTableSortComponent
        options={SORT_OPTIONS}
        initialValue={SORT_OPTIONS.find(
          (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
        )}
        onChange={({ selected }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            orderBy: selected.sortBy,
            orderType: selected.sortType,
          }));
          setModals({ ...modals, sort: false });
        }}
        visible={modals.sort}
        onClose={() => setModals({ ...modals, sort: false })}
      />
      <ModalTableFilter
        filterOptionsProps={FILTER_OPTIONS}
        initialValue={bodyFilter}
        filterStatusOptions={null}
        visible={modals.filter}
        onChange={(selectedFilter) => {
          setBodyFilter(selectedFilter);
          setQueryFilter((prevState) => ({
            ...prevState,
            page: 1,
          }));

          setModals({ ...modals, filter: false });
        }}
        onClose={() => setModals({ ...modals, filter: false })}
      />
    </>
  );
}
