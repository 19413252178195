import { useState, useImperativeHandle, forwardRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { getReactDropZoneErrorMessage } from 'ui-utils/string.utils';
import { Tooltip } from 'flowbite-react';
import { InputComponent } from 'components/form/input.component';
import { ButtonSubmit } from 'components/button/button-submit.component';

// max file size is 10MB
const MAX_FILE_SIZE = 10 * 1024 * 1024;
const URL_REGEX = /^(ftp|http|https):\/\/[^ "]+$/;

export const SHARE_INFO_ATTACHMENT_TYPE = {
  FILE: 'file',
  LINK: 'link',
};
export const UploadAttachmentInfo = forwardRef((props, ref) => {
  const { initialAttacthments = null, withInputLink = false } = props;
  const [fileBuffer, setFileBuffer] = useState([]);
  const [filesMetaData, setFilesMetaData] = useState([]); // [{ name: 'file1.pdf', size: 12345 }, { name: 'file2.pdf', size: 12345 }

  const [link, setLink] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState({
    link: null,
    name: null,
  });
  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    maxSize: MAX_FILE_SIZE,
    accept: {
      'image/*': ['.png', '.jpg', '.jpeg', '.gif', '.webp'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc', '.docx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'application/vnd.ms-powerpoint': ['.ppt', '.pptx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
      'video/mp4': ['.mp4'],
    }, // Accepts PDF files and all image types
    onDropAccepted: (files) => {
      if (files.length === 0) return;
      setFileBuffer((prevData) => [...prevData, ...files]);
      setFilesMetaData((prevData) => {
        return [
          ...prevData,
          ...files.map((file) => ({
            name: file.name,
            type: SHARE_INFO_ATTACHMENT_TYPE.FILE,
            size: file.size,
            url: URL.createObjectURL(file),
            isNew: true,
          })),
        ];
      });
    },
    onDropRejected: (files) => {
      toast.error(getReactDropZoneErrorMessage(files[0].errors[0]));
    },
  });

  useImperativeHandle(ref, () => ({
    getFiles: () => {
      return fileBuffer;
    },
    getFilesMetaData: () => {
      return filesMetaData;
    },
  }));

  useEffect(() => {
    if (initialAttacthments) {
      setFilesMetaData(initialAttacthments);
    }
  }, [initialAttacthments]);

  const handleOnRemoveItem = (index) => {
    const newFilesMetaData = [...filesMetaData];
    newFilesMetaData.splice(index, 1);
    setFilesMetaData(newFilesMetaData);

    const newFileBuffer = [...fileBuffer];
    newFileBuffer.splice(index, 1);
    setFileBuffer(newFileBuffer);
  };

  const handleSubmit = () => {
    if (!URL_REGEX.test(link)) {
      setError((prevState) => ({ ...prevState, link: 'Invalid URL' }));
      return;
    }

    if (!name) {
      setError((prevState) => ({ ...prevState, name: 'Name is required' }));
      return;
    }

    const newFilesMetaData = [
      ...filesMetaData,
      { name, url: link, size: 0, isNew: true, type: SHARE_INFO_ATTACHMENT_TYPE.LINK },
    ];

    setFilesMetaData(newFilesMetaData);

    setLink('');
    setName('');
    setError({
      link: null,
      name: null,
    });
  };
  return (
    <div class='relative mt-[-1px] mb-3 bg-gray-3 border border-solid border-green-1'>
      <div class='drag-upload' {...getRootProps({ className: 'dropzone' })}>
        <input type='file' id='upload-drag' class='hidden' {...getInputProps()} />
        <div htmlFor='upload-drag' class='flex justify-center items-center w-full h-40 cursor-pointer'>
          <div class='w-auto'>
            <div className='icon-ico-upload text-4xl text-green text-center'></div>
            <p class='text-center text-green block text-base mt-4 font-semibold'>Drag File Here</p>
            <p class='text-center text-gray block text-sm '>
              Or <span class='text-green font-bold cursor-pointer'>Browse</span> to choose a file
            </p>
          </div>
        </div>
      </div>
      {withInputLink && (
        <div className='p-3 border-t border-solid border-t-gray-1'>
          <InputComponent
            label='URL Link'
            name='link'
            error={error.link}
            value={link}
            onChange={(e) => setLink(e.target.value)}
          />
          <InputComponent
            label='URL Name'
            name='name'
            error={error.name}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <div className='flex justify-end'>
            <ButtonSubmit
              onClick={handleSubmit}
              className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
            >
              <span class='icon-ico-plus-circle mr-3'></span> Add Link
            </ButtonSubmit>
          </div>
        </div>
      )}

      {filesMetaData.length > 0 && (
        <div class='flex justify-between items-center border-t border-solid border-t-gray-1 p-3'>
          <div class='w-[100%]'>
            <div class='flex items-center'>
              <div class='mr-5'>
                <ul class='flex flex-wrap flex-row'>
                  {filesMetaData.map((file, index) => (
                    <li class='px-3 mb-1 py-2 text-sm flex items-center bg-green-1 text-green font-semibold mr-2 border border-green-3 border-solid rounded-sm'>
                      <button onClick={() => window.open(file.url, '_blank')}>
                        <Tooltip content='View attachment'>{file.name}</Tooltip>
                      </button>
                      <button class='text-red-1 outline-none text-lg ml-2' onClick={() => handleOnRemoveItem(index)}>
                        <span class='icon-ico-close-circle'></span>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
