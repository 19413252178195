import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

const adminBlackListRoute = ['/auth/login', '/auth/forgot-password'];
const homePath = '/users/list';

export function RouteWrapper({ children, isPrivate }) {
  const authReducer = useSelector((state) => state.auth);
  const persistedUser = { ...authReducer, token: authReducer.accessToken };

  const signed = persistedUser.token || undefined;
  const location = useLocation();
  const redirect = new URLSearchParams(location.search).get('redirect');
  const { pathname } = location;

  /**
   * Redirect user to signIn pages if he tries to access a private      route
   * without emailAuth.
   */

  if (isPrivate && !signed) {
    return <Navigate to={`/auth/login?redirect=${pathname}`} />;
  }

  if (signed && redirect) {
    return <Navigate to={redirect} />;
  }

  /**
   * Redirect user to Main pages if he tries to access a non private route
   * (signIn or SignUp) after being authenticated.
   */
  if (adminBlackListRoute.includes(pathname) && signed) {
    return <Navigate to={homePath} />;
  }

  if (pathname === '/' && signed) {
    return <Navigate to={homePath} />;
  }

  /**
   * If not included on both previous cases, redirect user to the desired route.
   */
  return children;
}
