import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { ModalContainer } from './modal-container.component';
import { InputComponent } from 'components/form/input.component';

export const ModalConfirmation = (props) => {
  const {
    title,
    description,
    onConfirm,
    children,
    visible,
    loading = false,
    onClose,
    textConfirm = 'Export',
    textCancel = 'Cancel',
    imageIcon = '/img/export.png',
    withComment = false,
  } = props;

  const [comment, setComment] = useState('');

  useEffect(() => {
    if (visible) {
      setComment('');
    }
  }, [visible]);

  return (
    <ModalContainer visible={visible}>
      <div id='show-item' class={clsx('w-[330px] bg-white rounded-sm p-5 relative z-50 ', !visible && 'hidden')}>
        <div class='mb-5 text-center font-bold text-sm'>{title}</div>
        <button type='button' class='absolute top-2 right-3' disabled={loading} onClick={onClose}>
          <span class='icon-ico-close text-xl text-gray-2'></span>
        </button>
        {!children && (
          <>
            <div class='max-h-[45vh] text-center scroll mb-5'>
              <img src={imageIcon} class='max-w-full inline-block' alt='Icon' />
              <p class='text-sm my-3'>{description}</p>
            </div>

            {withComment && (
              <div className='my-2'>
                <InputComponent
                  disabled={loading}
                  label='Notes (optional)'
                  element='textarea'
                  rows={5}
                  placeholder='Enter some notes here...'
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                />
              </div>
            )}
            <div class='flex justify-between items-center pb-2'>
              <div class='w-[48%]'>
                <ButtonSubmit
                  disabled={loading}
                  onClick={onClose}
                  title={textCancel}
                  className='block w-full rounded-sm border border-solid border-gray-1 py-2 px-2 text-sm font-semibold text-dark hover:bg-green-status transition duration-75 ease-in-out'
                />
              </div>
              <div class='w-[48%]'>
                <ButtonSubmit
                  loading={loading}
                  onClick={() => onConfirm(comment)}
                  title={textConfirm}
                  className='bg-green w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                />
              </div>
            </div>
          </>
        )}
      </div>
      {children && typeof children === 'function' ? children(props) : children}
    </ModalContainer>
  );
};
