import UAParser from 'ua-parser-js';

export const getBrowserInfo = () => {
  const parser = new UAParser();
  const result = parser.getResult();

  const browserInfo = {
    browserType: result.browser.name,
    osType: result.os.name,
    deviceType: result.device.type ? result.device.type : 'desktop',
    browserVersion: result.browser.version,
    osVersion: result.os.version,
    deviceName: result.device.vendor ? `${result.device.vendor} ${result.device.model}` : 'unknown',
  };

  return browserInfo;
};
export const OS_TYPE = {
  AIX: 'AIX',
  AMIGA_OS: 'Amiga OS',
  ANDROID_X86: 'Android-x86',
  ARCH: 'Arch',
  BADA: 'Bada',
  BEOS: 'BeOS',
  BLACKBERRY: 'BlackBerry',
  CENTOS: 'CentOS',
  CHROMIUM_OS: 'Chromium OS',
  CONTIKI: 'Contiki',
  FEDORA: 'Fedora',
  FIREFOX_OS: 'Firefox OS',
  FREEBSD: 'FreeBSD',
  DEBIAN: 'Debian',
  DEEPIN: 'Deepin',
  DRAGONFLY: 'DragonFly',
  ELEMENTARY_OS: 'elementary OS',
  FUCHSIA: 'Fuchsia',
  GENTOO: 'Gentoo',
  GHOSTBSD: 'GhostBSD',
  GNU: 'GNU',
  HAIKU: 'Haiku',
  HARMONYOS: 'HarmonyOS',
  HP_UX: 'HP-UX',
  HURD: 'Hurd',
  IOS: 'iOS',
  JOLI: 'Joli',
  KAIOS: 'KaiOS',
  LINPUS: 'Linpus',
  LINSPIRE: 'Linspire',
  LINUX: 'Linux',
  MAC_OS: 'Mac OS',
  MAEMO: 'Maemo',
  MAGEIA: 'Mageia',
  MANDRIVA: 'Mandriva',
  MANJARO: 'Manjaro',
  MEEGO: 'MeeGo',
  MINIX: 'Minix',
  MINT: 'Mint',
  MORPH_OS: 'Morph OS',
  NETBSD: 'NetBSD',
  NETRANGE: 'NetRange',
  NETTV: 'NetTV',
  NINTENDO: 'Nintendo',
  OPENBSD: 'OpenBSD',
  OPENVMS: 'OpenVMS',
  OS2: 'OS/2',
  PALM: 'Palm',
  PCBSD: 'PC-BSD',
  PCLINUXOS: 'PCLinuxOS',
  PLAN9: 'Plan9',
  PLAYSTATION: 'PlayStation',
  QNX: 'QNX',
  RASPBIAN: 'Raspbian',
  REDHAT: 'RedHat',
  RIM_TABLET_OS: 'RIM Tablet OS',
  RISC_OS: 'RISC OS',
  SABAYON: 'Sabayon',
  SAILFISH: 'Sailfish',
  SERENITYOS: 'SerenityOS',
  SERIES40: 'Series40',
  SLACKWARE: 'Slackware',
  SOLARIS: 'Solaris',
  SUSE: 'SUSE',
  SYMBIAN: 'Symbian',
  TIZEN: 'Tizen',
  UBUNTU: 'Ubuntu',
  UNIX: 'Unix',
  VECTORLINUX: 'VectorLinux',
  VIERA: 'Viera',
  WATCHOS: 'watchOS',
  WEBOS: 'WebOS',
  WINDOWS_PHONE: 'Windows Phone',
  WINDOWS_MOBILE: 'Windows Mobile',
  ZENWALK: 'Zenwalk',
};

export const DEVICE_TYPE = {
  CONSOLE: 'console',
  MOBILE: 'mobile',
  TABLET: 'tablet',
  SMARTTV: 'smarttv',
  WEARABLE: 'wearable',
  EMBEDDED: 'embedded',
  DESKTOP: 'desktop',
};
export const BROWSER_NAME = {
  '2345EXPLORER': '2345Explorer',
  '360_BROWSER': '360 Browser',
  AMAYA: 'Amaya',
  ANDROID_BROWSER: 'Android Browser',
  ARORA: 'Arora',
  AVANT: 'Avant',
  AVAST: 'Avast',
  AVG: 'AVG',
  BIDUBROWSER: 'BIDUBrowser',
  BAIDU: 'Baidu',
  BASILISK: 'Basilisk',
  BLAZER: 'Blazer',
  BOLT: 'Bolt',
  BRAVE: 'Brave',
  BOWSER: 'Bowser',
  CAMINO: 'Camino',
  CHIMERA: 'Chimera',
  CHROME_HEADLESS: 'Chrome Headless',
  CHROME_WEBVIEW: 'Chrome WebView',
  CHROME: 'Chrome',
  CHROMIUM: 'Chromium',
  COBALT: 'Cobalt',
  COMODO_DRAGON: 'Comodo Dragon',
  DILLO: 'Dillo',
  DOLPHIN: 'Dolphin',
  DORIS: 'Doris',
  DUCKDUCKGO: 'DuckDuckGo',
  EDGE: 'Edge',
  ELECTRON: 'Electron',
  EPIPHANY: 'Epiphany',
  FACEBOOK: 'Facebook',
  FALKON: 'Falkon',
  FENNEC: 'Fennec',
  FIREBIRD: 'Firebird',
  FIREFOX: 'Firefox',
  FLOCK: 'Flock',
  FLOW: 'Flow',
  GSA: 'GSA',
  GOBROWSER: 'GoBrowser',
  HEYTAP: 'Heytap',
  HUAWEI_BROWSER: 'Huawei Browser',
  ICE_BROWSER: 'ICE Browser',
  IE: 'IE',
  IEMOBILE: 'IEMobile',
  ICEAPE: 'IceApe',
  ICECAT: 'IceCat',
  ICEDRAGON: 'IceDragon',
  ICEWEASEL: 'Iceweasel',
  INSTAGRAM: 'Instagram',
  IRIDIUM: 'Iridium',
  IRON: 'Iron',
  JASMINE: 'Jasmine',
  KAKAOSTORY: 'KakaoStory',
  KAKAOTALK: 'KakaoTalk',
  KMELEON: 'K-Meleon',
  KINDLE: 'Kindle',
  KLAR: 'Klar',
  KONQUEROR: 'Konqueror',
  LBBROWSER: 'LBBROWSER',
  LINE: 'Line',
  LINKEDIN: 'LinkedIn',
  LINKS: 'Links',
  LUNASCAPE: 'Lunascape',
  LYNX: 'Lynx',
  MIUI_BROWSER: 'MIUI Browser',
  MAEMO: 'Maemo',
  MAXTHON: 'Maxthon',
  METASR: 'MetaSr',
  MIDORI: 'Midori',
  MINIMO: 'Minimo',
  MOBILE_SAFARI: 'Mobile Safari',
  MOSAIC: 'Mosaic',
  MOZILLA: 'Mozilla',
  NETFRONT: 'NetFront',
  NETSURF: 'NetSurf',
  NETFRONT: 'Netfront',
  NETSCAPE: 'Netscape',
  NOKIABROWSER: 'NokiaBrowser',
  OBIGO: 'Obigo',
  OCULUS_BROWSER: 'Oculus Browser',
  OMNIWEB: 'OmniWeb',
  OPERA_COAST: 'Opera Coast',
  OPERA_MINI: 'Opera Mini',
  OPERA_MOBI: 'Opera Mobi',
  OPERA_TABLET: 'Opera Tablet',
  PALEMOON: 'PaleMoon',
  PHANTOMJS: 'PhantomJS',
  PHOENIX: 'Phoenix',
  POLARIS: 'Polaris',
  PUFFIN: 'Puffin',
  QQ: 'QQ',
  QQBROWSER: 'QQBrowser',
  QQBROWSERLITE: 'QQBrowserLite',
  QUARK: 'Quark',
  QUPZILLA: 'QupZilla',
  ROCKMELT: 'RockMelt',
  SAFARI: 'Safari',
  SAILFISH_BROWSER: 'Sailfish Browser',
  SAMSUNG_BROWSER: 'Samsung Browser',
  SEAMONKEY: 'SeaMonkey',
  SILK: 'Silk',
  SKYFIRE: 'Skyfire',
  SLEIPNIR: 'Sleipnir',
  SLIM: 'Slim',
  SLIMBROWSER: 'SlimBrowser',
  SWIFTFOX: 'Swiftfox',
  TESLA: 'Tesla',
  TIKTOK: 'TikTok',
  TIZEN_BROWSER: 'Tizen Browser',
  UCBROWSER: 'UCBrowser',
  UP_BROWSER: 'UP.Browser',
  VIERA: 'Viera',
  VIVALDI: 'Vivaldi',
  WATERFOX: 'Waterfox',
  WECHAT: 'WeChat',
  WEIBO: 'Weibo',
  YANDEX: 'Yandex',
  BAIDU: 'baidu',
  ICAB: 'iCab',
  W3M: 'w3m',
  WHALE_BROWSER: 'Whale Browser',
};
