import axios from 'api/api.config';
import qs from 'query-string';
import { useQuery } from 'react-query';

export const SHARE_INFO_QUERY_KEY = {
  LIST_INFO: 'LIST_INFO',
  INFO_DETAILS: 'INFO_DETAILS',
};

export function useShareInfoList({ filter, body }) {
  return useQuery([SHARE_INFO_QUERY_KEY.LIST_INFO, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/share-info?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}

export function useShareInfoDetails(recordId) {
  return useQuery(
    [SHARE_INFO_QUERY_KEY.INFO_DETAILS, recordId],
    () => axios.get(`/api/v1/admin/share-info/detail/${recordId}`).then((res) => res.data),
    {
      enabled: !!recordId,
    }
  );
}
