import qs from 'query-string';
import axios from 'api/api.config';
import { useMutation, useQuery } from 'react-query';

export const PRODUCT_LIST_QUERY = {
  PRODUCT_LIST: 'PRODUCT_LIST',
  PRODUCT_DETAILS: 'PRODUCT_DETAILS',
  PRODUCT_STOCK_IN_STORE: 'PRODUCT_STOCK_IN_STORE',
  ALL_PRODUCT_LIST: 'ALL_PRODUCT_LIST',
};

export function useProductList({ filter }) {
  return useQuery([PRODUCT_LIST_QUERY.PRODUCT_LIST, { filter }], () =>
    axios.get(`/api/v1/admin/product?${qs.stringify(filter)}`).then((res) => res.data)
  );
}

export function useProductDetails(productId) {
  return useQuery([PRODUCT_LIST_QUERY.PRODUCT_DETAILS, { productId }], () =>
    axios.get(`/api/v1/admin/product/detail/${productId}`).then((res) => res.data)
  );
}

export function useProductInStore({ queryFilter, bodyFilter }) {
  return useQuery([PRODUCT_LIST_QUERY.PRODUCT_STOCK_IN_STORE, { queryFilter, bodyFilter }], () =>
    axios.post(`/api/v1/admin/variant-stock?${qs.stringify(queryFilter)}`, bodyFilter).then((res) => res.data)
  );
}

export function useAllProductList() {
  return useQuery([PRODUCT_LIST_QUERY.ALL_PRODUCT_LIST], () =>
    axios.get(`/api/v1/admin/product/all`).then((res) => res.data)
  );
}

export function useGetProductVariantsMutation() {
  return useMutation((productId) => axios.get(`/api/v1/admin/product/variants/${productId}`).then((res) => res.data));
}
