import qs from 'query-string';
import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { BasicTableComponent } from 'components/table/table-basic.component';
import { EmptyStateComponent } from 'components/empty-data';
import { Pagination } from 'components/table/table-pagination.component';
import { catchErrorMessage, convertToRupiah } from 'ui-utils/string.utils';
import { Tooltip } from 'flowbite-react';
import { StandartImageComponent } from 'components/image/standart-image.component';

import { PATH_CONSTANT } from 'config/path.constant';
import {
  STOCK_MOVEMENT_STATUS_COLOR_CLASSNAME,
  STOCK_MOVEMENT_STATUS_TEXT_LAGEL,
} from 'pages/stock-movement/pre-order/status-color.util';
import { ModalInfoStatusPO } from 'components/modal/modal-info-status-po.component';
import { STOCK_MOVEMENT_PO_LIST_DATA_STATUS } from '.';
import { HighlightedText } from 'components/highlighted-text';

export function PackingListTableComponent({
  data,
  loading,
  isError,
  error,
  queryFilter,
  onPageChange,
  status,
  onRowClick,
}) {
  const navigate = useNavigate();
  const [showModalStatus, setShowModalStatus] = useState(false);
  const columns = useMemo(
    () => {
      const cols = [
        {
          name: 'Nomor PO',
          className: 'w-[15%] text-sm font-semibold text-green pl-[2%]',
          dataIndex: 'po_number',
          render: (record, po_number) => (
            <div
              class='w-[15%] overflow-hidden cursor-pointer'
              onClick={() => {
                navigate(PATH_CONSTANT.STOCK_MOVEMENT.PACKING_DETAILS + '/' + record.id);
              }}
            >
              <div class='text-sm font-semibold text-dark overflow-hidden text-ellipsis whitespace-nowrap'>
                <HighlightedText text={po_number} highlight={queryFilter?.search} />
              </div>
            </div>
          ),
        },
        {
          name: 'Store Name',
          className: 'w-[21%] text-sm font-semibold text-green text-center',
          dataIndex: 'store_name',
          render: (record) => (
            <div class='w-[21%] text-sm font-semibold text-green'>
              <div class='flex items-center'>
                <div class='w-12'>
                  <StandartImageComponent
                    src={record?.store_image || '/img/default-store.jpg'}
                    class='w-10 h-10 overflow-hidden object-cover object-center'
                  />
                </div>
                <div class='w-[calc(100%-3rem)] pl-2 pr-4'>
                  <div class='block text-sm font-semibold text-dark hover:text-green line-clamp-3'>
                    <Tooltip className='max-w-[30%]' content={record?.store_name}>
                      <HighlightedText text={record?.store_name} highlight={queryFilter?.search} />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          ),
        },
        {
          name: 'Alamat Store',
          className: 'w-[20%] text-sm font-semibold text-green text-center',
          dataIndex: 'store_address',
          render: (record, store_address) => (
            <div class='w-[20%] overflow-hidden'>
              <div class='text-sm font-normal text-gray pr-2 line-clamp-4'>
                <Tooltip className='max-w-[40%]' content={store_address}>
                  <HighlightedText text={store_address} highlight={queryFilter?.search} />
                </Tooltip>
              </div>
            </div>
          ),
        },
        {
          name: 'Total Harga',
          className: 'w-[16%] text-sm font-semibold text-green text-center',
          dataIndex: 'total_price',
          render: (record, total_price) => (
            <div class='w-[16%]'>
              <div class='text-sm font-normal text-gray text-center'>{convertToRupiah(total_price)}</div>
            </div>
          ),
        },
        {
          name: 'Tgl. Pengajuan',
          className: 'w-[12%] text-sm font-semibold text-green overflow-hidden text-ellipsis whitespace-nowrap',
          dataIndex: 'request_date',
          render: (record, request_date) => (
            <div class='w-[12%] flex justify-center'>
              <div class='text-sm font-normal text-gray'>{request_date}</div>
            </div>
          ),
        },
        {
          name: 'Status',
          className: 'w-[15%] text-sm font-semibold text-green flex justify-center items-center',
          dataIndex: 'status',
          renderHeader: (column) => (
            <div key={`${column.name}`} class={column.className}>
              {column.name}
              <button
                onClick={() => {
                  setShowModalStatus(true);
                }}
                class='p-0 outline-none ml-2'
                type='button'
              >
                <span class='icon-ico-help'></span>
              </button>
            </div>
          ),
          render: (record, status) => (
            <div class='w-[15%] flex justify-center'>
              <div
                class={clsx(
                  'inline-block  text-center w-28 py-1 px-1 rounded-sm  text-sm font-bold',
                  STOCK_MOVEMENT_STATUS_COLOR_CLASSNAME[status.toUpperCase()]
                )}
              >
                {STOCK_MOVEMENT_STATUS_TEXT_LAGEL[status.toUpperCase()]}
              </div>
            </div>
          ),
        },
      ];
      if (STOCK_MOVEMENT_PO_LIST_DATA_STATUS.PACKED !== status) {
        return [
          {
            type: 'checkbox',
            isRightBorderAvailable: true,
            render: (record) => {
              return (
                <div className='form-check px-2'>
                  <input
                    className='form-check-input appearance-none h-4 w-4 border border-green rounded-sm bg-white checked:bg-green checked:border-green focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                    type='checkbox'
                    readOnly
                  />
                </div>
              );
            },
          },
          ...cols,
        ];
      }

      return cols;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status]
  );

  return (
    <>
      {Boolean(data?.total_result) && (
        <div className='italic text-dark text-sm mb-5'>{`Total ${data?.total_result} PO`}</div>
      )}

      <BasicTableComponent
        withExpand={true}
        rowClassName='flex mb-3 items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
        columns={columns}
        dataSource={data?.rows || []}
        onRowClick={onRowClick}
        loading={loading}
        error={isError}
        errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
        errorMessage={catchErrorMessage(error?.response?.data?.message)}
      />

      {data?.total_result === 0 && (
        <EmptyStateComponent
          message={queryFilter?.search ? `No result for query "${queryFilter?.search}"` : 'No data available'}
        />
      )}

      <Pagination
        currentPage={data?.current_page}
        pageSize={data?.limit}
        siblingCount={1}
        totalCount={data?.total_result || 0}
        onPageChange={(page) => {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
          onPageChange(page);
          navigate({
            search: qs.stringify({
              ...queryFilter,
              page,
            }),
          });
        }}
      />
      <ModalInfoStatusPO
        visible={showModalStatus}
        onClose={() => {
          setShowModalStatus(false);
        }}
      />
    </>
  );
}
