import { GoogleMap, MarkerF } from '@react-google-maps/api';
import clsx from 'clsx';

export function MapCoordinateComponent({ lat, lang, title = 'Coordinate Clock In', isLoaded }) {
  return (
    <div className='mt-10 mb-8' id='clock-in-map'>
      <div className='text-md text-green  font-medium mb-2'>{title}</div>
      <div className={clsx(' w-full relative', lang && 'h-80')}>
        {!lang && <p className='text-sm text-gray-2  italic'>No Coordinate Available</p>}
        {isLoaded && lang && lat && (
          <GoogleMap
            center={{
              lat: +lat,
              lng: +lang,
            }}
            zoom={14}
            options={{ disableDefaultUI: true, zoomControl: true }}
            mapContainerClassName='map-container'
          >
            <MarkerF
              position={{
                lat: +lat,
                lng: +lang,
              }}
            />
          </GoogleMap>
        )}
      </div>
    </div>
  );
}
