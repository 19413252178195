import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { ModalContainer } from './modal-container.component';

const limitOptions = [20, 50, 100, 250, 500];

export function ModalTableLimitComponent({ visible, initialValue, onClose, onChange }) {
  const [selected, setSelected] = useState(initialValue || limitOptions[0]);

  useEffect(() => {
    if (initialValue) {
      setSelected(+initialValue);
    }
  }, [visible, initialValue]);

  return (
    <ModalContainer visible={visible}>
      <div id='show-item' class={clsx('w-[330px] bg-white rounded-sm p-5 relative', !visible && 'hidden')}>
        <div class='relative text-center py-4'>
          <div class='mb-5 text-center font-bold text-sm'>Show Data List</div>
          <button type='button' class='absolute top-0 right-0 border-0' onClick={onClose}>
            <span class='icon-ico-close'></span>
          </button>
        </div>

        <div class='max-h-[45vh] scroll mb-5'>
          {limitOptions.map((limit) => (
            <div class='flex items-center p-3' key={limit}>
              <div class='form-check items-center justify-center'>
                <input
                  onClick={() => setSelected(limit)}
                  class='form-check-input appearance-none rounded-full h-4 w-4 border border-gray-1 bg-white checked:bg-green checked:border-green focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                  type='radio'
                  name='limit'
                  checked={limit === selected}
                />
              </div>
              <label onClick={() => setSelected(limit)} class='text-sm text-gray font-medium ml-4 cursor-pointer'>
                <span>{`Tampilkan ${limit} Item`}</span>
              </label>
            </div>
          ))}
        </div>

        <div class='flex justify-between items-center pb-2'>
          <div class='w-[48%]'>
            <button
              onClick={() => {
                onClose();
              }}
              type='button'
              class='block w-full rounded-sm border border-solid border-gray-1 py-2 px-2 text-sm font-semibold text-dark hover:bg-green-status transition duration-75 ease-in-out'
            >
              Cancel
            </button>
          </div>
          <div class='w-[48%]'>
            <button
              onClick={() => {
                onChange({ limit: selected });
              }}
              class='bg-green w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
              type='button'
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}
