import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import * as Yup from 'yup';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { useNavigate, useParams } from 'react-router';
import { FieldArray, Formik } from 'formik';
import { PATH_CONSTANT } from 'config/path.constant';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { InputComponent } from 'components/form/input.component';
import { useGetProductVariantsMutation } from 'api/product/product-list/product-list.query.api';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { useUpdateExpireDate } from 'api/stock-movement/pre-order/pre-order.mutation.api';
import { toast } from 'react-toastify';
import { useGetDetailExpiredDate } from 'api/stock-movement/picking/picking.query.api';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Stock Movement',
    path: PATH_CONSTANT.STOCK_MOVEMENT.PICKING_INPUT_EXPIRED_DATE,
  },
  {
    title: 'Picking',
    path: PATH_CONSTANT.STOCK_MOVEMENT.PICKING_INPUT_EXPIRED_DATE,
  },
  {
    title: 'Input Expired Date',
    path: PATH_CONSTANT.STOCK_MOVEMENT.PICKING_INPUT_EXPIRED_DATE,
  },
];

const SUBMIT_TYPE = {
  SAVE: 'submit',
  DRAFT: 'draft',
};

const formikInitialValues = {
  products: [],
};

function InputExpiredDateComponent() {
  const navigate = useNavigate();
  const { poId } = useParams();
  const formikRef = useRef(null);
  const [requestedProductsList, setRequestedProductsList] = useState([]);

  const { data: poDetails, isLoading } = useGetDetailExpiredDate(poId);
  const [formInitialValues, setFormInitialValues] = useState(formikInitialValues);
  const { mutateAsync: getProductVariants, isLoading: isLoadingProductVariants } = useGetProductVariantsMutation();
  const { mutate: updateExpiredData, isLoading: isUpdating } = useUpdateExpireDate();

  function ValidationSchemaProductItem(totalQty, product) {
    return Yup.object().shape({
      name: Yup.string().required('Name is required'),
      approved_product_id: Yup.string().required('Approved product ID is required'),
      expired_dates: Yup.array().of(
        Yup.object().shape({
          variant_id: Yup.string().required('Variant ID is required'),
          qty: Yup.string().test(
            `test-qty-${product.name}`,
            `The total value of qty must be ${totalQty}`,
            function (value, context) {
              const { from } = this;
              if (from[2]?.value) {
                const itemExpiredDates =
                  from[2]?.value?.products?.find((x) => x.name === product.name)?.expired_dates || [];
                const qtyValues = itemExpiredDates.map((x) => +x.qty);
                const total = qtyValues?.reduce((prev, curr) => prev + parseInt(curr), 0);
                return total === totalQty;
              }
              return false;
            }
          ),
        })
      ),
    });
  }

  const ValidationSchema = useMemo(() => {
    if (requestedProductsList.length === 0) return null;

    const validationSchema = Yup.object().shape({
      products: Yup.array().of(
        Yup.lazy((product, options) => {
          // Find the corresponding product in the requestedProductsList array
          const requestedProduct = requestedProductsList.find((x) => x.name === product.name);
          // If the product is found, return the validation schema for that product
          if (requestedProduct) {
            return ValidationSchemaProductItem(requestedProduct.qty, requestedProduct);
          }
          // Otherwise, return a default schema that does nothing
          return Yup.mixed().notRequired();
        })
      ),
    });
    return validationSchema;
  }, [requestedProductsList]);

  const handleOnFormSubmit = (data, type) => {
    const formPayload = {
      saveType: type,
      expiredDateDetail: data.products.map((product) => ({
        ...product,
        expired_dates: product.expired_dates.map((expiredDate) => ({
          ...expiredDate,
          qty: parseInt(expiredDate.qty),
        })),
      })),
    };

    updateExpiredData(
      {
        formValues: formPayload,
        id: poId,
      },
      {
        onSuccess: () => {
          navigate(`${PATH_CONSTANT.STOCK_MOVEMENT.PICKING_LIST}?tab=picking`);
          toast.success('Success update expired date!');
        },
      }
    );
  };

  useEffect(() => {
    (async () => {
      if (poDetails?.data?.approved_products) {
        const requestedProductsWithVariantPromise = poDetails?.data?.approved_products.map(async (product) => {
          const productVariants = await getProductVariants(product.product_id);
          return {
            ...product,
            product_variants: productVariants.data,
          };
        });
        const requestedProducts = await Promise.all(requestedProductsWithVariantPromise);
        setRequestedProductsList(requestedProducts);
      }
    })();

    if (poDetails?.data?.approved_products) {
      const formikInitialValues = poDetails?.data?.approved_products.map((product) => {
        return {
          name: product.name,
          approved_product_id: product.id,
          expired_dates: product.expired_dates.length ? product.expired_dates : [{ variant_id: '', qty: '' }],
        };
      });
      setFormInitialValues({ products: formikInitialValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poDetails?.data]);

  return (
    <>
      <MainDashboardLayoutComponent
        headerContainerClassName='pt-8 pl-8 pr-8 z-10'
        breadCumbs={BREAD_CUMB_ITEMS}
        className='w-[calc(100%-256px)] h-screen z-10'
      >
        <div className='px-8 pb-0'>
          {isLoading || isLoadingProductVariants ? (
            <div className='h-[70vh] flex w-full items-center text-center'>
              <BounceLoading color='#5E755A' />
            </div>
          ) : (
            <>
              <div class='bg-gray-3 mb-5'>
                <div class='py-4 px-6 flex justify-between items-center'>
                  <div class='w-auto flex items-center'>
                    <div class='text-sm text-green font-bold mr-3'>{poDetails?.data?.po_number}</div>
                    <div class='inline-block bg-olive-light text-olive text-center w-28 py-1 px-1 rounded-sm text-sm font-bold'>
                      Picking
                    </div>
                  </div>
                  <div class='text-sm text-gray font-medium'>{poDetails?.data?.request_date}</div>
                </div>
                <div class='border-t border-solid border-gray-1 flex'>
                  <div class='w-1/2 border-r border-solid border-gray-1 p-6'>
                    <div class='text-sm text-dark mb-4 font-semibold'>{poDetails?.data?.store_name}</div>
                    <p class='text-sm text-gray  mb-4'>{poDetails?.data?.store_address}</p>
                    <div class='text-sm text-gray italic mb-4 font-medium'>{poDetails?.data?.store_city}</div>
                  </div>
                  <div class='w-1/2 p-6'>
                    <div class='text-sm text-dark mb-4 font-semibold'>{poDetails?.data?.user_name}</div>
                    <p class='text-sm text-gray italic mb-4'>{poDetails?.data?.user_phone}</p>
                    <p class='text-sm text-gray italic mb-4'>Beauty Advisor</p>
                  </div>
                </div>
              </div>
              <div class='text-base font-bold mb-5 text-dark'>Product Pre Order Approved</div>
              <div class='bg-green-1 rounded-sm p-2 flex items-center mb-4'>
                <div class='w-[70%] text-sm font-semibold text-green pl-4'>Nama Produk</div>
                <div class='w-[30%] text-sm font-semibold text-green text-center'>Qty Product</div>
              </div>

              <Formik
                validationSchema={ValidationSchema}
                innerRef={formikRef}
                initialValues={formInitialValues}
                enableReinitialize
              >
                {({ values, handleChange, handleBlur, setFieldValue, touched, errors }) => {
                  return (
                    <>
                      {requestedProductsList.map((item, indexItem) => {
                        return (
                          <>
                            <div class='mb-10'>
                              <div class='border border-gray-1 border-solid mb-3'>
                                <div class='flex items-center rounded-sm py-2.5 px-2 bg-white w-full'>
                                  <div class='w-[70%]'>
                                    <div class='block text-sm font-semibold text-dark pl-4 pr-5'>{item?.name}</div>
                                  </div>
                                  <div class='w-[30%]'>
                                    <div class='text-sm font-bold text-dark text-center'>{item?.qty}</div>
                                  </div>
                                </div>
                              </div>
                              <FieldArray
                                name={`products[${indexItem}].expired_dates`}
                                key={`products[${indexItem}].expired_dates`}
                                render={(arrayHelpers) => (
                                  <>
                                    {values.products[indexItem]?.expired_dates.map((product, index) => {
                                      const selectedOptionItems = values.products[indexItem]?.expired_dates.map(
                                        (item) => +item.variant_id
                                      );

                                      return (
                                        <>
                                          <div
                                            class='flex items-center'
                                            key={`products[${indexItem}].expired_dates[${index}]`}
                                          >
                                            <div class='w-[70%] pr-7'>
                                              <div class='form-input relative mb-3'>
                                                <select
                                                  class='appearance-none block w-full cursor-pointer px-4 py-2.5 text-sm text-dark bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-1 rounded-sm transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-green focus:outline-none'
                                                  name={`products[${indexItem}].expired_dates[${index}].variant_id`}
                                                  onChange={handleChange}
                                                  value={product?.variant_id}
                                                >
                                                  <option value='' disabled={product?.variant !== ''}>
                                                    Pilih Tanggal Expired
                                                  </option>
                                                  {
                                                    // use an empty array as a fallback value for item.product_variants
                                                    (item.product_variants || []).map((variant, idxItem) => {
                                                      return (
                                                        <option
                                                          value={variant.id || 0}
                                                          key={variant.id || 0}
                                                          disabled={selectedOptionItems.includes(+variant.id)}
                                                        >
                                                          {variant.expired_date &&
                                                            moment(variant.expired_date).format('MMMM YYYY')}
                                                        </option>
                                                      );
                                                    })
                                                  }
                                                </select>
                                              </div>
                                            </div>
                                            <div class='w-[23%]'>
                                              <InputComponent
                                                numberOnly={true}
                                                name={`products[${indexItem}].expired_dates[${index}].qty`}
                                                key={`products[${indexItem}].expired_dates[${index}].qty`}
                                                error={
                                                  touched?.products?.[indexItem]?.expired_dates?.[index]?.qty &&
                                                  errors?.products?.[indexItem]?.expired_dates?.[index]?.qty
                                                }
                                                value={product.qty}
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `products[${indexItem}].expired_dates[${index}].qty`,
                                                    e.target.value.replace(/[^0-9]+/g, '')
                                                  );
                                                }}
                                                onBlur={handleBlur}
                                                placeholder='Input Quantity'
                                              />
                                            </div>
                                            {values.products[indexItem]?.expired_dates?.length > 1 && (
                                              <div class='w-[7%] text-center mb-2'>
                                                <button
                                                  onClick={async () => {
                                                    arrayHelpers.remove(index);
                                                  }}
                                                >
                                                  <span class='icon-ico-delete text-red-1 text-xl'></span>
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      );
                                    })}
                                    {values.products[indexItem]?.expired_dates?.length !==
                                      item.product_variants?.length && (
                                      <button
                                        type='button'
                                        onClick={() => {
                                          // add the expired_dates form for the corresponding product
                                          arrayHelpers.push({
                                            variant_id: '',
                                            qty: '',
                                          });
                                        }}
                                        class='text-sm px-3 py-2 border border-solid border-green-2 bg-light-green inline-block text-dark font-semibold rounded-sm hover:bg-green-2 transition duration-100 ease-in-out'
                                      >
                                        <span class='icon-ico-plus-circle text-green mr-2'></span> Tambahkan Exp. Date
                                      </button>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                          </>
                        );
                      })}
                      <div class='pb-14 relative flex justify-center'>
                        <ButtonSubmit
                          disabled={!ValidationSchema?.isValidSync(values)}
                          loading={isUpdating}
                          onClick={() => handleOnFormSubmit(values, SUBMIT_TYPE.DRAFT)}
                          className='bg-gray-1 mx-3 hover:bg-gray-2 font-semibold transition duration-75 ease-in-out rounded-sm text-dark py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-10'
                        >
                          <StandartImageComponent src='/img/icon/file.svg' class='max-w-full mr-3' /> Save As Draft
                        </ButtonSubmit>

                        <ButtonSubmit
                          disabled={!ValidationSchema?.isValidSync(values)}
                          loading={isUpdating}
                          onClick={() => handleOnFormSubmit(values, SUBMIT_TYPE.SAVE)}
                          className='bg-green mx-3 hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-10'
                        >
                          <StandartImageComponent src='/img/icon/arrow-up.svg' class='max-w-full mr-3' /> Submit Data
                        </ButtonSubmit>
                      </div>
                    </>
                  );
                }}
              </Formik>
            </>
          )}
        </div>
      </MainDashboardLayoutComponent>
    </>
  );
}

export default InputExpiredDateComponent;
