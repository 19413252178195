import qs from 'query-string';
import axios from 'api/api.config';
import { useQueryClient, useMutation } from 'react-query';
import { INFO_SKIL_DEVELOPMENT_QUERY_KEY } from './skill-development.query';

export function useCreateNewSkillDevelopment() {
  const queryClient = useQueryClient();
  return useMutation((body) => axios.post(`/api/v1/admin/skill-development/create`, body).then((res) => res.data), {
    onSuccess: (data) => {
      queryClient.resetQueries([INFO_SKIL_DEVELOPMENT_QUERY_KEY.INFO_DETAILS]);
      queryClient.refetchQueries(INFO_SKIL_DEVELOPMENT_QUERY_KEY.LIST_INFO);
    },
  });
}

export function useEditSkillDevelopment() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, ...body }) => axios.put(`/api/v1/admin/skill-development/update/${id}`, body).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.resetQueries([INFO_SKIL_DEVELOPMENT_QUERY_KEY.INFO_DETAILS]);
        queryClient.refetchQueries(INFO_SKIL_DEVELOPMENT_QUERY_KEY.LIST_INFO);
      },
    }
  );
}

export function useExportSkillDevelopment() {
  return useMutation(({ queryFilter, bodyFilter }) =>
    axios
      .post(`/api/v1/admin/skill-development/export?${qs.stringify(queryFilter)}`, bodyFilter)
      .then((res) => res.data)
  );
}
