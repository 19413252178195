import clsx from 'clsx';

export function TableButtonSortComponent({
  disabled,
  onClick,
  className = 'xl:mr-2 outline-none flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3',
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={clsx('disabled:opacity-50 disabled:cursor-not-allowed  ', className)}
      type='button'
    >
      <span className='icon-ico-sort text-xl text-green'></span>
      <span className='pl-2 font-semibold text-dark text-sm'>Sort</span>
    </button>
  );
}
