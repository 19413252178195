import clsx from 'clsx';

export function TableButtonFilterComponent({
  disabled,
  onClick,
  active,
  className = 'xl:mr-2 outline-none  relative flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3',
}) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={clsx('disabled:opacity-50', className, active && 'filtered')}
      type='button'
    >
      <span className='icon-ico-filter text-xl text-green'></span>
      <span className='pl-2 font-semibold text-dark text-s'>Filter</span>
    </button>
  );
}
