import clsx from 'clsx';
import moment from 'moment';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { ModalContainer } from 'components/modal/modal-container.component';
import { Timeline } from 'components/timeline.component';
import { EmptyStateComponent } from 'components/empty-data';

function generateTimeLineData(data) {
  if (!data || data.length === 0) {
    return [
      {
        id: 0,
        date: '',
        title: <li className={`list-item mb-1 text-sm text-gray`}>Tidak ada perubahan stok</li>,
      },
    ];
  }

  return data.map((item, index) => {
    const verb = item.type === 'addition' ? 'bertambah' : 'berkurang';
    const color = item.type === 'addition' ? 'green' : 'yellow';
    return {
      id: index + 1,
      date: moment(item.created_at).format('DD MMMM YYYY HH:mm'),
      title: (
        <li key={index} className={`list-item mb-4 leading-normal text-sm text-gray`}>
          Product {item.product_name} ({item.expired_date_formatted}){' '}
          <span className={`text-${color} font-bold`}>{verb}</span> dari{' '}
          <span className='font-bold'>{item.pre_value}</span> ke <span className='font-bold'>{item.post_value}</span>{' '}
        </li>
      ),
    };
  });
}

export const ModalStockAudit = (props) => {
  const {
    title,
    children,
    visible,
    loading = false,
    onClose,
    data = [],
    imageIcon = '/img/approve.svg',
    containerClassName = 'w-[600px] bg-white rounded-sm p-5 relative',
  } = props;
  return (
    <ModalContainer visible={visible}>
      <div id='show-item' class={clsx(containerClassName, !visible && 'hidden')}>
        <div class='mb-5 text-center font-bold text-sm'>{title}</div>
        <button type='button' class='absolute top-2 right-3' disabled={loading} onClick={onClose}>
          <span class='icon-ico-close text-xl text-gray-2'></span>
        </button>
        {!children && (
          <>
            <div class='max-h-[55vh] text-center scroll  mb-5'>
              <img src={imageIcon} class='max-w-full inline-block' alt='Icon' />
              <span class='block text-lg text-black mt-2 mb-5 font-bold'>Stock Audit</span>
              <div className='text-left px-3'>
                {!data || (data?.length === 0 && <EmptyStateComponent message='Tidak ada perubahan stock' />)}
                {data.length !== 0 && <Timeline data={generateTimeLineData(data)} />}
              </div>
            </div>
            <div class='flex justify-between items-center pb-2'>
              <div class='w-full'>
                <ButtonSubmit
                  loading={loading}
                  onClick={onClose}
                  title='OK'
                  className='bg-green w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                />
              </div>
            </div>
          </>
        )}
      </div>
    </ModalContainer>
  );
};
