// there are some extension that can't be downloaded, so we need to open it in new tab
const OPEN_NEW_TAB_EXTENSION = ['pdf', 'jpg', 'png', 'jpeg', 'gif'];

export function FileAttachmentComponent({ url = null, title = null, downloadable = true }) {
  const handleOnDownload = () => {
    if (!downloadable) return;

    if (OPEN_NEW_TAB_EXTENSION.includes(url.split('.').pop())) return window.open(url, '_blank');

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', title);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };
  return (
    <div className='flex flex-row items-center justify-between border border-solid border-gray-1 rounded-sm my-2 p-2'>
      <div className='flex flex-row items-center'>
        <img src='/img/file-text.svg' class='max-w-full inline-block mr-2' alt='Icon' />
        {title && <div className='text-sm text-gray '>{title}</div>}
      </div>
      <span onClick={handleOnDownload} className='uppercase font-bold cursor-pointer text-sm text-green'>
        Unduh
      </span>
    </div>
  );
}
