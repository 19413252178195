import qs from 'query-string';
import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const USER_LIST_QUERY_KEY = {
  USER_LIST: 'USER_LIST',
  ALL_USER: 'ALL_USER',
};

export function useUserList({ filter, body }) {
  return useQuery([USER_LIST_QUERY_KEY.USER_LIST, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/user?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}

export function useAllUserList() {
  return useQuery([USER_LIST_QUERY_KEY.ALL_USER], () => axios.get(`/api/v1/admin/user/all`).then((res) => res.data));
}
