import axios from 'api/api.config';
import qs from 'query-string';
import { useQuery } from 'react-query';

export const CHANGE_SCHEDULE_REQUEST_QUERY_KEY = {
  CHANGE_SCHEDULE_REQUEST_LIST: 'CHANGE_SCHEDULE_REQUEST_LIST',
  CHANGE_SCHEDULE_REQUEST_DETAILS: 'CHANGE_SCHEDULE_REQUEST_DETAILS',
};

export function useGetChangeScheduleRequestList({ filter, bodyFilter }) {
  return useQuery([CHANGE_SCHEDULE_REQUEST_QUERY_KEY.CHANGE_SCHEDULE_REQUEST_LIST, { filter, bodyFilter }], () =>
    axios.post(`/api/v1/admin/schedule/request?${qs.stringify(filter)}`, bodyFilter).then((res) => res.data)
  );
}

export function useGetChangeScheduleRequestDetails(recordId) {
  return useQuery([CHANGE_SCHEDULE_REQUEST_QUERY_KEY.CHANGE_SCHEDULE_REQUEST_DETAILS, recordId], () =>
    axios.get(`/api/v1/admin/schedule/request/detail/${recordId}`).then((res) => res.data)
  );
}
