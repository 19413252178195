import moment from 'moment';
import { useState } from 'react';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { BounceLoading } from 'components/loader/bounce.loading';
import { PATH_CONSTANT } from 'config/path.constant';
import { RangeDatePicker } from 'components/date-picker/range-date-picker.component';
import { AnalyticsSummaryCardInfo } from 'pages/analytics/sales/summary-card.component';
import { AnalyticPresensiTable } from './presensi-table.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { useGetAnalyticsTotalPresensi } from 'api/analytics/presensi/analytic-presensi.query.api';
import { useExportAnalyticPresensi } from 'api/analytics/presensi/analytic-presensi.mutation.api';

const INITIAL_PREVIOUS_DAY_RANGE = 6;

const BREAD_CUMB_ITEMS = [
  {
    title: 'Analytics',
    path: PATH_CONSTANT.ANALYTICS.PRESENSI,
  },
  {
    title: 'Presensi Analytic',
    path: PATH_CONSTANT.ANALYTICS.PRESENSI,
  },
];

const INITIAL_DATE_FILTER = {
  startDate: moment().subtract(INITIAL_PREVIOUS_DAY_RANGE, 'day').toDate(),
  endDate: moment().toDate(),
};

const INITIAL_VALUE_FILTER_QUERY = {
  limit: 10,
  page: 1,
  orderBy: 'date',
  orderType: 'desc',
  search: undefined,
};
const INITIAL_VALUE_FILTER_BODY = {
  status: [],
};

export const AnalyticsPresensiComponent = () => {
  const [dateFilter, setDateFilter] = useState({
    startDate: moment(INITIAL_DATE_FILTER.startDate).format('YYYY-MM-DD'),
    endDate: moment(INITIAL_DATE_FILTER.endDate).format('YYYY-MM-DD'),
  });
  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);
  const [bodyFilter, setBodyFilter] = useState(INITIAL_VALUE_FILTER_BODY);

  const [modals, setModals] = useState({
    export: false,
    filter: false,
    result: false,
  });

  const { data: totalPresensi, isLoading: isLoadingtotalPresensi } = useGetAnalyticsTotalPresensi({
    filter: dateFilter,
    body: bodyFilter,
  });

  const { mutate: exportData, isLoading: isExporting } = useExportAnalyticPresensi();

  const handleExport = () => {
    exportData(
      {
        queryFilter: {
          ...queryFilter,
          ...dateFilter,
        },
        bodyFilter,
      },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      {isLoadingtotalPresensi ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          <div class='flex items-center justify-center w-full gap-x-2 mb-5'>
            <RangeDatePicker
              initialStartDate={moment(dateFilter.startDate).toDate()}
              initialEndDate={moment(dateFilter.endDate).toDate()}
              maxDate={moment().toDate()}
              onChange={({ start, end }) => {
                setDateFilter({
                  startDate: moment(start).format('YYYY-MM-DD'),
                  endDate: moment(end).format('YYYY-MM-DD'),
                });
              }}
            />

            <TableButtonExport onClick={() => setModals({ ...modals, export: true })} loading={isExporting} />
          </div>
          <div class='flex justify-between mb-5'>
            <AnalyticsSummaryCardInfo
              tooltipText='Merupakan persentase perbandingan Presensi tepat waktu pada periode saat ini dengan periode sebelumnya '
              title='Persentase Presensi Tepat Waktu'
              percentageSummaryValue={totalPresensi?.data?.value_ontime_attendance}
              currentPeriodeDate={`${moment(totalPresensi?.data?.current_period?.[0]).format('DD MMM YY')} - ${moment(
                totalPresensi?.data?.current_period?.[1]
              ).format('DD MMM YY')}`}
              currentPeriodeValue={totalPresensi?.data?.total_ontime_attendance}
              previousPeriodeDate={`${moment(totalPresensi?.data?.prev_period?.[0]).format('DD MMM YY')} - ${moment(
                totalPresensi?.data?.prev_period?.[1]
              ).format('DD MMM YY')}`}
              previousPeriodeValue={totalPresensi?.data?.prev_total_ontime_attendance}
            />
            <AnalyticsSummaryCardInfo
              tooltipText='Merupakan persentase perbandingan Presensi tidak tepat waktu pada periode saat ini dengan periode sebelumnya '
              title='Persentase Presensi Tidak Tepat Waktu'
              percentageSummaryValue={totalPresensi?.data?.value_late_attendance}
              currentPeriodeDate={`${moment(totalPresensi?.data?.current_period?.[0]).format('DD MMM YY')} - ${moment(
                totalPresensi?.data?.current_period?.[1]
              ).format('DD MMM YY')}`}
              currentPeriodeValue={totalPresensi?.data?.total_late_attendance}
              previousPeriodeDate={`${moment(totalPresensi?.data?.prev_period?.[0]).format('DD MMM YY')} - ${moment(
                totalPresensi?.data?.prev_period?.[1]
              ).format('DD MMM YY')}`}
              previousPeriodeValue={totalPresensi?.data?.prev_total_late_attendance}
              classNameOnValueDown='text-green-3 pl-2 text-sm font-medium'
              classNameOnValueUp='text-red-1 pl-2 text-sm font-medium'
              classNameIconOnValueDown='icon-arrow-down text-green-3 text-xl'
              classNameIconOnValueUp='icon-arrow-up text-red-1 text-xl'
            />
          </div>

          <AnalyticPresensiTable
            queryFilter={queryFilter}
            bodyFilter={bodyFilter}
            setQueryFilter={setQueryFilter}
            setBodyFilter={setBodyFilter}
            dateFilter={dateFilter}
          />
        </>
      )}

      <ModalConfirmation
        description='Apakah anda yakin ingin export data?'
        title='Export Data'
        visible={modals.export}
        onConfirm={handleExport}
        onClose={() => setModals({ ...modals, export: false })}
      />
    </MainDashboardLayoutComponent>
  );
};
