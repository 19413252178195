import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const STOCK_MOVEMENT_PACKING = {
  PRINT_DATA: 'PRINT_DATA_PACKING',
};

export function useGetPrintDataPacking(poIds) {
  return useQuery(
    [STOCK_MOVEMENT_PACKING.PRINT_DATA, { poIds }],
    () =>
      axios
        .post(`/api/v1/admin/pre-order/packing/list-label-po`, {
          pre_order_ids: poIds,
        })
        .then((res) => res.data),
    {
      enabled: !!poIds?.length,
    }
  );
}
