import { StandartImageComponent } from 'components/image/standart-image.component';
import { BounceLoading } from 'components/loader/bounce.loading';
import { useDispatch, useSelector } from 'react-redux';
import { setToolsReducer } from 'reducers/tools.reducer';

export function BasicTableComponent(props) {
  const {
    error = false,
    errorMessage = undefined,
    errorTitle = "We're sorry, something went wrong",
    columns = [],
    withExpand = false,
    onRowClick = () => {},
    renderExpand = () => {},
    dataSource,
    loading,
    rowClassName = 'flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer',
    rowSelectedClassName = 'mb-3 flex items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer',
  } = props;
  const dispatch = useDispatch();
  const { selectedRowKeys, selectedRows } = useSelector((state) => state.tools);

  const onCheckedAllHandler = (e) => {
    if (e.target.checked) {
      let rowKeys = getRowKeys(dataSource);
      dispatch(setToolsReducer({ selectedRowKeys: rowKeys, selectedRows: dataSource }));
    } else {
      dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
    }
  };

  const getRowKeys = (data) => {
    let rowKeys = [];
    data.forEach((i) => {
      rowKeys.push(i.id);
    });

    return rowKeys;
  };

  const onCheckHandler = (e, value) => {
    let rows = [];

    if (e.target.checked) {
      rows = [...selectedRows, value];
    } else {
      rows = selectedRows.filter((item) => item !== value);
    }

    let newSelectedRowKeys = getRowKeys(rows);

    dispatch(setToolsReducer({ selectedRowKeys: newSelectedRowKeys, selectedRows: rows }));
  };

  return (
    <>
      <div className='bg-green-1 rounded-sm p-2 flex items-center mb-4'>
        {columns?.map((column, index) => {
          if (column.type === 'checkbox') {
            return (
              <div className='form-check px-2' key={`${column.name}-${index}`}>
                <input
                  disabled={loading}
                  className='form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-green checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                  type='checkbox'
                  checked={selectedRowKeys.length === dataSource.length && dataSource.length > 0}
                  onChange={(e) => onCheckedAllHandler(e)}
                  id='check'
                />
              </div>
            );
          }

          if (column.renderHeader !== undefined) {
            return column.renderHeader(column, index);
          }

          return (
            <div key={`${column.name}-${index}`} class={column.className}>
              {column.name}
            </div>
          );
        })}
      </div>
      {loading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : error ? (
        <div className='h-[40vh] flex items-center justify-center flex-col'>
          <StandartImageComponent class='max-w-full' src='/img/info.svg' />
          <div className='text-center mt-2 w-3/4'>
            <p className='text-base font-semibold mb-3 text-gray'>{errorTitle}</p>
            <span className='text-sm  text-red-700'>{errorMessage}</span>
          </div>
        </div>
      ) : (
        dataSource?.map((item, index) => (
          <>
            <div
              key={index.toString()}
              onClick={() => onRowClick(item)}
              className={selectedRowKeys.includes(item.id) ? rowSelectedClassName : rowClassName}
            >
              {columns?.map((columnItem, idx) => {
                if (idx === 0 && columnItem.type === 'checkbox') {
                  return (
                    <div className='form-check px-2' key={index.toString().concat(idx.toString())}>
                      <input
                        checked={selectedRowKeys.includes(item.id)}
                        className='form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-green checked:border-red-50 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                        type='checkbox'
                        onChange={(e) => onCheckHandler(e, item, index)}
                      />
                    </div>
                  );
                } else {
                  if (!!columnItem.render) {
                    return columnItem.render(item, item[columnItem.dataIndex]);
                  }
                  return (
                    <div key={index.toString().concat(idx.toString())} className={'text-center'}>
                      {item[columnItem.dataIndex]}
                    </div>
                  );
                }
              })}
            </div>
            {withExpand && renderExpand(props, item)}
          </>
        ))
      )}
    </>
  );
}
