import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { useEffect, useState } from 'react';
import { BROWSER_NAME, getBrowserInfo } from 'ui-utils/browser-info.utils';

const imageCache = new Map();

const DEFAULT_IMAGE_PATH = '/img/default-user.jpg';
export const StandartImageComponent = (props) => {
  const {
    withZoom = false,
    src = DEFAULT_IMAGE_PATH,
    alt = 'Default ',
    className = 'w-full h-auto ',
    defaultImage,
    ...rest
  } = props;

  const browserName = getBrowserInfo().browserType;

  const [imgSrc, setImgSrc] = useState(imageCache.get(src) || defaultImage || DEFAULT_IMAGE_PATH);

  useEffect(() => {
    if (!imageCache.has(src)) {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        src !== DEFAULT_IMAGE_PATH && imageCache.set(src, src);
        setImgSrc(src);
      };
      img.onerror = () => {
        setImgSrc('/img/image-not-found.png');
      };
    }
  }, [src, defaultImage]);

  const imageComponent = (
    <img
      src={src || defaultImage || '/img/image-not-found.png'}
      alt={alt}
      className={className}
      {...rest}
      onClick={() => {
        if (browserName === BROWSER_NAME.SAFARI) {
          window.open(src, '_blank');
        }
      }}
    />
  );

  if (withZoom && browserName === BROWSER_NAME.SAFARI) return imageComponent;

  if (!withZoom) return imageComponent;

  return <Zoom>{imageComponent}</Zoom>;
};
