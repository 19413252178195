import _ from 'lodash';
import qs from 'query-string';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setToolsReducer } from 'reducers/tools.reducer';
import { useEffect, useState } from 'react';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { TableSearchInputComponent } from 'components/table/table-search.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { TableButtonLimitComponent } from 'components/table/table-button-limit.component';
import { TableButtonSortComponent } from 'components/table/table-button-sort.component';
import { PATH_CONSTANT } from 'config/path.constant';
import { TabsComponent } from 'components/tab/tabs.component';
import { TabItem } from 'components/tab/tab-item.component';
import { PickingListTableComponent } from './picking-list-table.component';
import { useGetListPO } from 'api/stock-movement/pre-order/pre-order.query.api';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ModalTableLimitComponent } from 'components/modal/modal-table-limit.component';
import { ModalTableSortComponent } from 'components/modal/modal-table-sort.component';
import { useExportPreOrderList, useUpdatePreOrderStatus } from 'api/stock-movement/pre-order/pre-order.mutation.api';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { toast } from 'react-toastify';
import { ModalPrintRequestPo } from '../modal-print-request-po';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Stock Movement',
    path: PATH_CONSTANT.STOCK_MOVEMENT.PO_LIST,
  },
  {
    title: 'Picking List',
    path: PATH_CONSTANT.STOCK_MOVEMENT.PO_LIST,
  },
];

export const STOCK_MOVEMENT_PO_LIST_DATA_STATUS = {
  ALL: 'all',
  WAITING_APPROVAL: 'submitted',
  WAITING_PAYMENT: 'waiting_for_payment',
  DIKEMAS: 'packing',
  PICKING: 'picking',
  DIKIRIM: 'delivery',
  KONFIRMASI: 'validate',
  SELESAI: 'completed',
  APPROVED: 'approved',
};

const MODAL_CONFIRMATION_TYPE = {
  EXPORT: 'MODAL_CONFIRMATION_TYPE_EXPORT',
  UPDATE_STATUS: 'MODAL_CONFIRMATION_TYPE_UPDATE_STATUS',
};

const PO_LIST_STATUS = [
  {
    label: 'Perlu Diproses',
    value: STOCK_MOVEMENT_PO_LIST_DATA_STATUS.APPROVED,
  },
  {
    label: 'Input Exp. Date',
    value: STOCK_MOVEMENT_PO_LIST_DATA_STATUS.PICKING,
  },
];

const SORT_OPTIONS = [
  { label: 'Urutkan Nama Store A-Z', sortType: 'asc', sortBy: 'store_name' },
  { label: 'Urutkan Nama Store Z-A', sortType: 'desc', sortBy: 'store_name' },
  { label: 'Urutkan Alamat Store A-Z', sortType: 'asc', sortBy: 'store_address' },
  { label: 'Urutkan Alamat Store Z-A', sortType: 'desc', sortBy: 'store_address' },
  { label: 'Urutkan PO Number A-Z', sortType: 'asc', sortBy: 'po_number' },
  { label: 'Urutkan PO Number Z-A', sortType: 'desc', sortBy: 'po_number' },
  { label: 'Total Harga Tertinggi - Terendah', sortType: 'desc', sortBy: 'total_price' },
  { label: 'Total Harga Terendah - Tertinggi', sortType: 'asc', sortBy: 'total_price' },
  { label: 'Tanggal Buat Terbaru - Terdahulu', sortType: 'desc', sortBy: 'created_at' },
  { label: 'Tanggal Buat Terdahulu - Terbaru', sortType: 'asc', sortBy: 'created_at' },
];

export default function StockMovementPOList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const searchParamsObject = qs.parse(searchParams.toString());
  const { selectedRowKeys: selectedIds } = useSelector((state) => state.tools);

  const [selectedTab, setSelectedTab] = useState(
    _.get(searchParamsObject, 'tab', STOCK_MOVEMENT_PO_LIST_DATA_STATUS.APPROVED)
  );

  const INITIAL_VALUE_FILTER_QUERY = {
    limit: searchParams.get('limit')?.replace(/[^0-9]/g, '') || 20,
    page: searchParams.get('page')?.replace(/[^0-9]/g, '') || 1,
    orderBy: searchParams.get('orderBy')?.replace(/[^a-zA-Z_]/g, '') || 'created_at',
    orderType: searchParams.get('orderType')?.replace(/[^a-zA-Z]/g, '') || 'desc',
    search: searchParams.get('search')?.replace(/[^a-zA-Z0-9 ]/g, '') || undefined,
  };

  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);
  const [modalRequestPO, setModalRequestPO] = useState({
    title: '',

    open: false,
    data: [],
  });
  const [modalConfirmation, setModalConfirmation] = useState({
    type: null,
    title: '',
    message: '',
    open: false,
  });
  const [modals, setModals] = useState({
    sort: false,
    limit: false,
    export: false,
    filter: false,
  });

  const handleOnTabChange = (tab) => {
    setSelectedTab(tab);
    if (queryFilter.search) {
      setQueryFilter({
        ...queryFilter,
        page: 1,
        search: '',
      });
    } else {
      setQueryFilter({
        ...queryFilter,
        page: 1,
      });
      navigate({
        search: qs.stringify({
          ...queryFilter,
          page: 1,
        }),
      });
    }
  };

  const { mutate: exportData, isLoading: isExporting } = useExportPreOrderList();
  const { mutate: updatePreOrderStatus, isLoading: isUpdatingStatus } = useUpdatePreOrderStatus();
  const {
    data: poList,
    isLoading,
    isError,
    error,
  } = useGetListPO({ queryFilter, bodyFilter: { status: selectedTab === 'all' ? [] : [selectedTab] } });

  useEffect(() => {
    const params = {
      ...queryFilter,
      tab: selectedTab,
    };
    navigate({
      search: qs.stringify(params),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const handleExport = () => {
    exportData(
      { queryFilter, bodyFilter: { status: selectedTab === 'all' ? [] : [selectedTab] } },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setModalConfirmation((prevState) => ({ ...prevState, open: false }));
        },
      }
    );
  };

  const handleOnUpdateStatus = () => {
    updatePreOrderStatus(
      {
        status: STOCK_MOVEMENT_PO_LIST_DATA_STATUS.PICKING,
        pre_order_ids: selectedIds,
      },
      {
        onSuccess: () => {
          dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
          toast.success('Berhasil memperbarui status PO!');
          setModalConfirmation({ ...modalConfirmation, open: false });
        },
      }
    );
  };

  const handleOnShowModalPrint = () => {
    setModalRequestPO({
      title: 'Print Request PO',
      open: true,
      data: selectedIds,
    });
  };

  const resetCheckList = () => {
    dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
  };

  useEffect(() => {
    return () => {
      resetCheckList();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainDashboardLayoutComponent
      headerContainerClassName='pt-8 pl-8 pr-8 z-10'
      breadCumbs={BREAD_CUMB_ITEMS}
      className='w-[calc(100%-256px)] h-screen z-10'
    >
      <div className='px-8 pb-0'>
        <div className='flex  mb-5'>
          <div className='w-full flex items-center justify-between'>
            <TableSearchInputComponent
              containerClassName='w-[440px] mr-2 relative'
              placeholder='Cari nomor PO/nama store...'
              inputProps={{
                disabled: isLoading,
              }}
              initialValue={queryFilter.search}
              onSearch={(value) => {
                setQueryFilter({ ...queryFilter, search: value.keyword, page: 1 });
                navigate({
                  search: qs.stringify({
                    ...queryFilter,
                    search: value.keyword || undefined,
                    page: 1,
                  }),
                });
              }}
            />

            <div className='xl:w-[calc(100%-30%)] lg:w-[calc(100%-30%)] md:w-[calc(100%-30%)] flex justify-start items-center'>
              <TableButtonSortComponent
                disabled={isLoading}
                className='mr-2 outline-none flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
                onClick={() => {
                  setModals((prev) => ({ ...prev, sort: true }));
                }}
              />

              <TableButtonLimitComponent
                disabled={isLoading}
                className='mr-2 outline-none  relative flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
                value={queryFilter.limit}
                onClick={() => setModals({ ...modals, limit: true })}
              />

              <TableButtonExport
                className='mr-2 outline-none flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
                disabled={isLoading}
                loading={isExporting}
                onClick={() => {
                  setModalConfirmation({
                    open: true,
                    title: 'Export Data',
                    message: 'Apakah anda yakin ingin eksport data yang anda pilih ?',
                    type: MODAL_CONFIRMATION_TYPE.EXPORT,
                  });
                }}
              />
            </div>
            <div class='w-[380px]'>
              <div className='flex w-[100%] flex-row gap-2 justify-around items-end'>
                {selectedTab === STOCK_MOVEMENT_PO_LIST_DATA_STATUS.APPROVED && (
                  <>
                    <ButtonSubmit
                      onClick={handleOnShowModalPrint}
                      disabled={selectedIds.length === 0}
                      className='bg-green hover:bg-dark-green w-52 flex items-center justify-center transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-5 md:px-4 xl:px-7'
                    >
                      <span class='icon-ico-print mr-2'></span> Print Request PO
                    </ButtonSubmit>

                    <ButtonSubmit
                      className='bg-green hover:bg-dark-green w-40 flex items-center justify-center transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-5 md:px-4 xl:px-7'
                      onClick={() => {
                        setModalConfirmation({
                          open: true,
                          title: 'Update Status',
                          message: 'Apakah anda yakin ingin mengubah status PO menjadi Picking?',
                          type: MODAL_CONFIRMATION_TYPE.UPDATE_STATUS,
                        });
                      }}
                      disabled={selectedIds.length === 0}
                    >
                      <span class='icon-ico-arrow-circle mr-2'></span>
                      Update Status
                    </ButtonSubmit>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <TabsComponent value={selectedTab} onChange={handleOnTabChange} disabled={isLoading}>
        {PO_LIST_STATUS.map((tab) => (
          <TabItem
            label={tab.label}
            key={tab.value}
            value={tab.value}
            className='nav-item text-center'
            labelClassName='nav-link cursor-pointer w-full block font-semibold text-sm leading-tight border border-transparent rounded-tl-sm rounded-tr-sm text-gray border-solid border-b-1 px-6 py-3 hover:bg-gray-100'
          />
        ))}
      </TabsComponent>
      <div className='tab-content px-8 py-5 border-t border-solid border-gray-1 mt-[-1px]'>
        {isLoading ? (
          <div className='h-[70vh] flex w-full items-center text-center'>
            <BounceLoading color='#5E755A' />
          </div>
        ) : (
          <PickingListTableComponent
            status={selectedTab}
            isError={isError}
            error={error}
            data={poList?.data}
            queryFilter={queryFilter}
            onPageChange={(page) => {
              setQueryFilter({ ...queryFilter, page });
              navigate({
                search: qs.stringify({
                  ...queryFilter,
                  page,
                }),
              });
            }}
          />
        )}
      </div>
      <ModalTableSortComponent
        options={SORT_OPTIONS}
        initialValue={SORT_OPTIONS.find(
          (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
        )}
        onChange={({ selected }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            orderBy: selected.sortBy,
            orderType: selected.sortType,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              orderBy: selected.sortBy,
              orderType: selected.sortType,
            }),
          });
          setModals({ ...modals, sort: false });
        }}
        visible={modals.sort}
        onClose={() => setModals({ ...modals, sort: false })}
      />
      <ModalTableLimitComponent
        onChange={({ limit }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            limit,
            page: 1,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              limit,
              page: 1,
            }),
          });
          setModals({ ...modals, limit: false });
        }}
        initialValue={queryFilter.limit}
        visible={modals.limit}
        onClose={() => setModals({ ...modals, limit: false })}
      />
      <ModalConfirmation
        description={modalConfirmation.message}
        title={modalConfirmation.title}
        visible={modalConfirmation.open}
        textConfirm='Ya'
        loading={isUpdatingStatus}
        onConfirm={() => {
          if (modalConfirmation.type === MODAL_CONFIRMATION_TYPE.EXPORT) {
            handleExport();
          }
          if (modalConfirmation.type === MODAL_CONFIRMATION_TYPE.UPDATE_STATUS) {
            handleOnUpdateStatus();
          }
        }}
        onClose={() =>
          setModalConfirmation({
            open: false,
          })
        }
      />
      <ModalPrintRequestPo
        visible={modalRequestPO.open}
        title={modalRequestPO.title}
        poIds={selectedIds}
        onFinish={() => {
          resetCheckList();
          setModalRequestPO({ open: false });
        }}
        onClose={() => setModalRequestPO({ open: false })}
      />
    </MainDashboardLayoutComponent>
  );
}
