import qs from 'query-string';
import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const ANALYTIC_PRESENSI_QUERY = {
  TOTAL_PRESENSI: 'TOTAL_PRESENSI',
  LIST_PRESEBSI: 'LIST_PRESEBSI',
};

export function useGetAnalyticsTotalPresensi({ filter, body }) {
  return useQuery([ANALYTIC_PRESENSI_QUERY.TOTAL_PRESENSI, { filter, body }, body], () =>
    axios.get(`/api/v1/admin/analytic-attendance/total?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}

export function useGetAnalyticsListPresensi({ filter, body }) {
  return useQuery([ANALYTIC_PRESENSI_QUERY.LIST_PRESEBSI, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/analytic-attendance/list?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}
