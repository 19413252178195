import _ from 'lodash';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { InputComponent } from 'components/form/input.component';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { useNavigate, useParams } from 'react-router-dom';
import { UPLOAD_IMAGE_CONSTANT, useUploadSingleFile } from 'api/upload/upload-single-image.api';
import { TOOLBAR_OPTIONS, TextEditorComponent } from 'components/form/text-editor';
import { PATH_CONSTANT } from 'config/path.constant';
import { UploadAttachmentInfo } from '../components/upload-attachment.component';
import { Select } from 'components/form/select.component';
import { SelectShareUserComponent } from '../components/select-share-user.component';
import { CreateShareInfoValidationSchema } from 'config/form/share-info/create-info-validation.schema';
import { useEditInfo } from 'api/share-info/info/info.mutation';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { useShareInfoDetails } from 'api/share-info/info/info.query';
import { BounceLoading } from 'components/loader/bounce.loading';
import { getShareTypeDescription } from '../create-info/create-info.component';
import { catchErrorMessage } from 'ui-utils/string.utils';

const SUBMIT_TYPE = {
  SAVE: 'publish',
  DRAFT: 'draft',
};

const SHARE_TYPE = [
  { id: 'SPECIFIC_USER', name: 'Specific User' },
  { id: 'ALL_USERS', name: 'All Users' },
];
const DEFAULT_IMAGE = '/img/upload-image.jpg';
const BREAD_CUMB_ITEMS = [
  {
    title: 'Share Info',
    path: null,
  },
  {
    title: 'Edit Info',
    path: null,
  },
];

const formInitialValue = {
  title: '',
  description: '',
  status: '',
  broadcast_date: '',
  broadcast_time: '',
  start_date: '',
  start_time: '',
  end_date: '',
  end_time: '',
  attachments: [],
  is_all_users: 'ALL_USERS',
  users: [],
};

const minDate = new Date().toISOString().split('T')[0].split('-');

export const EditShareInfoComponent = () => {
  const navigate = useNavigate();
  const formikRef = useRef(null);

  const { recordId } = useParams();
  const { data: shareInfoDetails, isLoading, isError, error } = useShareInfoDetails(recordId);
  const uploadComponentRef = useRef(null);

  const { mutateAsync: updateInfo, isLoading: isUpdating } = useEditInfo();

  const { mutateAsync: uploadInfoBanner, isLoading: isUploading } = useUploadSingleFile({
    type: UPLOAD_IMAGE_CONSTANT.TYPE.ORIGINAL,
    path: UPLOAD_IMAGE_CONSTANT.DIRECTORY_PATH.SHARE_INFO,
  });

  const [selectedFileBuffer, setSelectedFileBuffer] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(DEFAULT_IMAGE);
  const [attachmentsMetaData, setAttachmentsMetaData] = useState([]);

  useEffect(() => {
    if (shareInfoDetails?.data) {
      const formikInitialValues = {
        title: shareInfoDetails?.data?.title,
        description: shareInfoDetails?.data?.description,
        image: shareInfoDetails?.data?.image,
        broadcast_date: shareInfoDetails?.data?.broadcast_date,
        broadcast_time: shareInfoDetails?.data?.broadcast_time,
        start_date: shareInfoDetails?.data?.start_date,
        start_time: shareInfoDetails?.data?.start_time,
        end_date: shareInfoDetails?.data?.end_date,
        end_time: shareInfoDetails?.data?.end_time,
        is_all_users: shareInfoDetails?.data?.is_all_users ? 'ALL_USERS' : 'SPECIFIC_USER',
        users: shareInfoDetails?.data?.receivers?.map((receiver) => receiver.user_id),
        attachments: shareInfoDetails?.data?.attachments?.map((attachment) => attachment.file_url),
      };

      const attachmentsMeta = shareInfoDetails?.data?.attachments?.map((attachment, index) => ({
        name: attachment.file_name || 'Untitled',
        size: 0,
        url: attachment.file_url,
        isNew: false,
      }));
      setAttachmentsMetaData(attachmentsMeta);

      setImagePreviewUrl(shareInfoDetails?.data?.image || DEFAULT_IMAGE);
      formikRef.current?.setValues(formikInitialValues);
    }
  }, [shareInfoDetails]);

  const handleOnFormSubmit = async (formValues, submitType) => {
    try {
      const formValuesCloned = _.cloneDeep(formValues);
      let infoImageBannerURL = null;
      if (selectedFileBuffer) {
        const formData = new FormData();
        formData.append('image', selectedFileBuffer);
        const response = await uploadInfoBanner(formData);
        infoImageBannerURL = response?.data?.url;
      }

      const filesBuffer = uploadComponentRef.current?.getFiles();

      const currentAttachments = uploadComponentRef.current
        ?.getFilesMetaData()
        .filter((file) => !file.isNew)
        .map((file) => ({
          file_name: file.name,
          url: file.url,
        }));

      formValuesCloned.attachments = [...currentAttachments];

      if (filesBuffer.length > 0) {
        const attachmentsURL = await Promise.all(
          filesBuffer.map(async (file) => {
            const formData = new FormData();
            formData.append('image', file);
            const response = await uploadInfoBanner(formData);
            return {
              file_name: file.name,
              url: response?.data?.url,
            };
          })
        );
        formValuesCloned.attachments = [...currentAttachments, ...attachmentsURL];
      }

      const payload = {
        ...formValuesCloned,
        status: submitType,
        image: infoImageBannerURL ? infoImageBannerURL : formValuesCloned.image,
        is_all_users: formValuesCloned.is_all_users === 'ALL_USERS',
        id: recordId,
      };

      await updateInfo(payload);
      toast.success('Info updated successfully', {
        autoClose: 2000,
      });
      navigate(PATH_CONSTANT.SHARE_INFO.LIST_INFO);
    } catch (error) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleOnUploadFileChange = (e) => {
    //chedck file size with maximum 5mb and type, only accept image
    if (e.target.files[0].size > 5000000) {
      toast('File size is too large. Maximum 5mb is allowed');
      return;
    }
    if (!e.target.files[0].type.includes('image')) {
      toast('Only image file is allowed');
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setSelectedFileBuffer(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const isDisabledAllForm = isUpdating || isUploading || isLoading;

  return (
    <MainDashboardLayoutComponent
      breadCumbs={BREAD_CUMB_ITEMS}
      isError={isError}
      errorTitle={error?.response?.data?.status}
      erroMessage={catchErrorMessage(error?.response?.data?.message)}
    >
      {isLoading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <Formik
          innerRef={formikRef}
          initialValues={formInitialValue}
          validationSchema={CreateShareInfoValidationSchema}
        >
          {({ errors, values, handleChange, handleBlur, setTouched, touched, setFieldValue, setFieldTouched }) => {
            return (
              <>
                <div className='flex justify-between mb-8'>
                  <div class='w-[40%] '>
                    <div class='w-auto'>
                      <img
                        src={imagePreviewUrl}
                        class='w-[400px] h-[400px] overflow-hidden object-cover object-center border border-solid border-gray-1'
                        alt='Preview'
                      />
                      <div class='mt-7 flex justify-center'>
                        <input
                          type='file'
                          id='upload'
                          class='hidden'
                          onChange={handleOnUploadFileChange}
                          accept='image/*'
                        />
                        <label
                          for='upload'
                          class='bg-green w-28 inline-block text-center hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-3 cursor-pointer'
                        >
                          <span class='icon-ico-upload mr-2'></span>
                          <span class='text-sm'>Upload</span>
                        </label>
                      </div>
                    </div>
                    <div class=' mb-7'>
                      <div class='w-full mb-2'>
                        <Select
                          label='Share To'
                          onBlur={handleBlur}
                          onChange={(selectedValue) => {
                            setFieldValue('is_all_users', selectedValue.target?.value);
                            setTouched({ ...touched, users: true });
                          }}
                          name='is_all_users'
                          containerClassName='form-input btn-arrow-create z-1   relative mb-3'
                          placeholder='Pilih Share To'
                          error={touched.is_all_users && errors.is_all_users}
                          options={SHARE_TYPE}
                          value={values.is_all_users}
                        />
                      </div>

                      <p className='text-sm text-gray-2 italic mb-4'>
                        {getShareTypeDescription(values.is_all_users, values.users.length, errors.users)}
                      </p>

                      {values.is_all_users === 'ALL_USERS' ? null : (
                        <SelectShareUserComponent
                          selectedUserIds={values.users}
                          onSelectUser={(selectedUsers) => {
                            setFieldValue('users', selectedUsers);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div class='w-[55%]'>
                    <InputComponent
                      disabled={isDisabledAllForm}
                      name='title'
                      value={values.title}
                      error={touched.title && errors.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      label='Title'
                      placeholder='Enter title...'
                    />
                    <div class='mb-7'>
                      <div class='text-sm block text-dark font-semibold mb-2'>Description</div>

                      <TextEditorComponent
                        toolbarOptions={[
                          TOOLBAR_OPTIONS.BOLD,
                          TOOLBAR_OPTIONS.ITALIC,
                          TOOLBAR_OPTIONS.UNDERLINE,
                          TOOLBAR_OPTIONS.CENTER_ALIGN,
                          TOOLBAR_OPTIONS.RIGHT_ALIGN,
                          TOOLBAR_OPTIONS.LEFT_ALIGN,
                        ]}
                        initialValue={values.description}
                        onChange={(nodeValue) => setFieldValue('description', nodeValue)}
                        error={touched.description && errors.description}
                        setFieldTouched={setFieldTouched}
                        name='description'
                      />
                    </div>

                    <div className='w-full flex-row flex justify-between'>
                      <div class='w-1/2 py-2 pr-6'>
                        <InputComponent
                          disabled={isDisabledAllForm}
                          name='start_date'
                          type='date'
                          value={values.start_date}
                          min={minDate.join('-')}
                          error={touched.start_date && errors.start_date}
                          onChange={(e) => {
                            setFieldValue('start_date', e.target.value);
                          }}
                          onBlur={handleBlur}
                          label='Start Date'
                          placeholder='Start Date'
                        />
                      </div>
                      <div class='w-1/2 py-2 pr-6'>
                        <InputComponent
                          disabled={isDisabledAllForm}
                          name='start_time'
                          type='time'
                          value={values.start_time}
                          error={touched.start_time && errors.start_time}
                          onChange={(e) => {
                            setFieldValue('start_time', e.target.value);
                          }}
                          onBlur={handleBlur}
                          label='Start Time'
                          placeholder='Start Time'
                        />
                      </div>
                    </div>
                    <div className='w-full flex-row flex justify-between'>
                      <div class='w-1/2 py-2 pr-6'>
                        <InputComponent
                          disabled={isDisabledAllForm}
                          name='end_date'
                          type='date'
                          min={minDate.join('-')}
                          value={values.end_date}
                          error={touched.end_date && errors.end_date}
                          onChange={(e) => {
                            setFieldValue('end_date', e.target.value);
                          }}
                          onBlur={handleBlur}
                          label='End Date'
                          placeholder='End Date'
                        />
                      </div>
                      <div class='w-1/2 py-2 pr-6'>
                        <InputComponent
                          disabled={isDisabledAllForm}
                          name='end_time'
                          type='time'
                          value={values.end_time}
                          error={touched.end_time && errors.end_time}
                          onChange={(e) => {
                            setFieldValue('end_time', e.target.value);
                          }}
                          onBlur={handleBlur}
                          label='End Time'
                          placeholder='End Time'
                        />
                      </div>
                    </div>
                    <UploadAttachmentInfo ref={uploadComponentRef} initialAttacthments={attachmentsMetaData} />
                    <p class='text-md block text-dark font-semibold mt-4'>Push Notification</p>
                    <div className='w-full flex-row flex justify-between'>
                      <div class='w-1/2 py-2 pr-6'>
                        <InputComponent
                          disabled={isDisabledAllForm}
                          name='broadcast_date'
                          type='date'
                          value={values.broadcast_date}
                          min={minDate.join('-')}
                          error={touched.broadcast_date && errors.broadcast_date}
                          onChange={(e) => {
                            setFieldValue('broadcast_date', e.target.value);
                          }}
                          onBlur={handleBlur}
                          label='Start Date'
                          placeholder='Start Date'
                        />
                      </div>
                      <div class='w-1/2 py-2 pr-6'>
                        <InputComponent
                          disabled={isDisabledAllForm}
                          name='broadcast_time'
                          value={values.broadcast_time}
                          type='time'
                          error={touched.broadcast_time && errors.broadcast_time}
                          onChange={(e) => {
                            setFieldValue('broadcast_time', e.target.value);
                          }}
                          onBlur={handleBlur}
                          label='Start Time'
                          placeholder='Start Time'
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class='py-14 relative flex justify-center'>
                  <ButtonSubmit
                    disabled={!CreateShareInfoValidationSchema?.isValidSync(values)}
                    loading={isUpdating | isUploading}
                    onClick={() => handleOnFormSubmit(values, SUBMIT_TYPE.DRAFT)}
                    className='bg-gray-1 mx-3 hover:bg-gray-2 font-semibold transition duration-75 ease-in-out rounded-sm text-dark py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-10'
                  >
                    <StandartImageComponent src='/img/icon/file.svg' class='max-w-full mr-3' /> Save As Draft
                  </ButtonSubmit>
                  <ButtonSubmit
                    loading={isUpdating | isUploading}
                    disabled={!CreateShareInfoValidationSchema.isValidSync(values)}
                    onClick={() => handleOnFormSubmit(values, SUBMIT_TYPE.SAVE)}
                    className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
                  >
                    <span class='icon-ico-plus-circle mr-3'></span> Update
                  </ButtonSubmit>
                </div>
              </>
            );
          }}
        </Formik>
      )}
    </MainDashboardLayoutComponent>
  );
};
