import axios from 'api/api.config';
import { useMutation, useQueryClient } from 'react-query';
import { ANALYTIC_PRODUCT_QUERY } from 'api/analytics/product/analytic-product.query.api';
import { REPORT_BA_SALES_QUERY } from 'api/report-ba/sales/sales.query.api';
import { PRODUCT_LIST_QUERY } from '../product-list/product-list.query.api';

export function useCreateNewProduct() {
  const queryClient = useQueryClient();
  return useMutation((body) => axios.post(`/api/v1/admin/product/create`, body).then((res) => res.data), {
    onSuccess: (data) => {
      queryClient.resetQueries([PRODUCT_LIST_QUERY.PRODUCT_LIST]);
    },
  });
}

export function useEditProduct() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ productId, ...body }) => axios.put(`/api/v1/admin/product/update/${productId}`, body).then((res) => res.data),
    {
      onSuccess: (data) => {
        queryClient.resetQueries([PRODUCT_LIST_QUERY.PRODUCT_LIST]);
        queryClient.resetQueries([PRODUCT_LIST_QUERY.PRODUCT_DETAILS]);
        queryClient.refetchQueries([ANALYTIC_PRODUCT_QUERY.LIST_PRODUCT]);
        queryClient.refetchQueries([REPORT_BA_SALES_QUERY.SALES_DETAILS_REPORT]);
        queryClient.refetchQueries([REPORT_BA_SALES_QUERY.SALES_LIST]);
      },
    }
  );
}

export function useDownloadTemplateImportProduct() {
  return useMutation(() =>
    axios
      .get(`/api/v1/admin/product/import`, {
        responseType: 'arraybuffer',
      })
      .then((res) => res.data)
  );
}

export function useImportProduct() {
  const queryClient = useQueryClient();
  return useMutation((body) => axios.put(`/api/v1/admin/product/import`, body).then((res) => res.data), {
    onSuccess: (data) => {
      queryClient.resetQueries([PRODUCT_LIST_QUERY.PRODUCT_LIST]);
      queryClient.resetQueries([PRODUCT_LIST_QUERY.PRODUCT_DETAILS]);
    },
  });
}
