import { StandartImageComponent } from 'components/image/standart-image.component';

import { useRef, useImperativeHandle, forwardRef } from 'react';
import { useReactToPrint } from 'react-to-print';

export const PrintRequestPOComponent = forwardRef(({ data, onFinish }, ref) => {
  const componentRef = useRef(null);

  const handleDownload = useReactToPrint({
    onPrintError: (error) => alert(JSON.stringify(error)),
    content: () => componentRef.current,
    onAfterPrint: () => {
      onFinish && onFinish();
    },

    documentTitle: `AVO Beauty Advisor - Request PO_${new Date().getTime()}`,
  });

  useImperativeHandle(ref, () => ({
    handleDownload,
  }));
  const getPageMargins = () => {
    const pagePadding = {
      paddingTop: '25mm',
      paddingRight: 0,
      paddingBottom: '10mm',
      paddingLeft: 0,
    };
    return `
      @page {
        margin: ${pagePadding.paddingTop} ${pagePadding.paddingRight} ${pagePadding.paddingBottom} ${pagePadding.paddingLeft} !important;
      }
      @page:first {
        margin: 0 ${pagePadding.paddingRight} ${pagePadding.paddingBottom} ${pagePadding.paddingLeft} !important;
      }
    `;
  };

  return (
    <div className='flex flex-col w-[100%] h-full '>
      <style>{getPageMargins()}</style>
      <div className='w-[100%] py-5' ref={componentRef}>
        {data?.map((itemPo) => (
          <div id='printRequestPoComponent' class='w-[100%] h-[100vh] bg-white rounded-sm p-8 '>
            <div class='flex items-center mb-8'>
              <div class='w-[55%] flex items-center'>
                <div class='w-1/3 border-r border-solid border-gray-1'>
                  <StandartImageComponent src='/img/logo.png' class='w-20' />
                </div>
                <div class='w-4/6'>
                  <div class='text-xs font-semibold text-dark pl-12 mb-1'>Nomor PO</div>
                  <div class='text-sm font-medium text-gray pl-12'>{itemPo.po_number}</div>
                </div>
              </div>
              <div class='w-[45%]'>
                <div class='text-xs font-semibold text-dark pl-12 mb-1'>Store</div>
                <div class='text-sm font-medium text-gray pl-12'>{itemPo.store_name}</div>
              </div>
            </div>
            <div class=' mb-5'>
              <div class='bg-green-1 rounded-sm py-2 px-8 flex items-center mb-4'>
                <div class='w-[65%] text-xs font-semibold text-green'>Product</div>
                <div class='w-[20%] text-xs font-semibold text-green'>Qty</div>
              </div>

              {itemPo?.approved_products?.map((item, index) => (
                <div class='bg-white rounded-sm py-2 px-8 flex items-center mb-3'>
                  <div class='w-[65%] text-xs font-medium text-dark pr-5'>{item.name}</div>
                  <div class='w-[29%] text-xs font-medium text-dark'>{item.qty}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});
