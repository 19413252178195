import React from 'react';

const ErrorBoundaryPage = (props) => {
  const { errorCode = 404, errorMessage = 'Oh snap! Please double check your URL.' } = props;

  return (
    <body className='bg-login'>
      <div className='flex justify-center h-screen items-center relative'>
        <div className='w-[600px]'>
          <div className='w-full rounded-sm p-4 pb-8'>
            <div className='text-8xl font-bold text-green text-center '>{errorCode}</div>
          </div>
          <pre className='text-xl  text-green text-center  pb-6'>{errorMessage}</pre>
          <div className='block text-center'>
            <div className='text-sm  text-green text-center  pb-6'>Please contact the administrator.</div>
          </div>
        </div>
        <div className='block text-center mb-12'>
          <img src='/img/logo.svg' className='max-w-full inline-block' alt='Logo' />
        </div>
      </div>
      <div class='absolute left-0 bottom-0'>
        <img src='/img/leaf-login.svg' class='max-w-full' alt='Background' />
      </div>
    </body>
  );
};

export default ErrorBoundaryPage;
