import axios from 'api/api.config';
import qs from 'query-string';
import { useMutation, useQueryClient } from 'react-query';
import { OVERTIME_QUERY_KEY } from './overtime.query.api';

export function useApprovalOvertime() {
  const queryClient = useQueryClient();
  return useMutation((payload) => axios.put(`/api/v1/admin/overtime/approval`, payload).then((res) => res.data), {
    onSuccess: () => {
      queryClient.removeQueries([OVERTIME_QUERY_KEY.LIST_OVERTIME]);
      queryClient.removeQueries([OVERTIME_QUERY_KEY.DETAILS_OVERTIME]);
    },
  });
}

export function useExportOverimeList() {
  return useMutation(({ queryFilter, bodyFilter }) =>
    axios.post(`/api/v1/admin/overtime/export?${qs.stringify(queryFilter)}`, bodyFilter).then((res) => res.data)
  );
}
