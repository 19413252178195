import axios from 'api/api.config';
import qs from 'query-string';
import { useMutation, useQueryClient } from 'react-query';
import { SCHEDULE_USER_QUERY_KEY } from './jadwal.query.api';

export function useCreateScheduleUser() {
  const queryClient = useQueryClient();
  return useMutation((payload) => axios.post(`/api/v1/admin/schedule/create`, payload).then((res) => res.data), {
    onSuccess: () => {
      queryClient.invalidateQueries([SCHEDULE_USER_QUERY_KEY.SCHEDULE_USER_LIST]);
      queryClient.invalidateQueries([SCHEDULE_USER_QUERY_KEY.SCHEDULE_USER_DETAILS]);
      queryClient.invalidateQueries([SCHEDULE_USER_QUERY_KEY.SCHEDULE_USER_DETAILS_INFO]);
    },
  });
}

export function useUpdateScheduleUser() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ scheduleId, ...payload }) =>
      axios.put(`/api/v1/admin/schedule/update/${scheduleId}`, payload).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SCHEDULE_USER_QUERY_KEY.SCHEDULE_USER_LIST]);
        queryClient.invalidateQueries([SCHEDULE_USER_QUERY_KEY.SCHEDULE_USER_DETAILS]);
        queryClient.invalidateQueries([SCHEDULE_USER_QUERY_KEY.SCHEDULE_USER_DETAILS_INFO]);
      },
    }
  );
}

export function useDeleteScheduleUser() {
  const queryClient = useQueryClient();
  return useMutation(
    (scheduleId) => axios.delete(`/api/v1/admin/schedule/delete/${scheduleId}`).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([SCHEDULE_USER_QUERY_KEY.SCHEDULE_USER_LIST]);
        queryClient.invalidateQueries([SCHEDULE_USER_QUERY_KEY.SCHEDULE_USER_DETAILS]);
        queryClient.invalidateQueries([SCHEDULE_USER_QUERY_KEY.SCHEDULE_USER_DETAILS_INFO]);
      },
    }
  );
}

export function useExportScheduleList() {
  return useMutation(({ queryFilter, bodyFilter }) =>
    axios.post(`/api/v1/admin/schedule/user/export?${qs.stringify(queryFilter)}`, bodyFilter).then((res) => res.data)
  );
}

export function useExportScheduleUser() {
  return useMutation(({ queryFilter, userId }) =>
    axios.post(`/api/v1/admin/schedule/user/export/${userId}?${qs.stringify(queryFilter)}`).then((res) => res.data)
  );
}

export function useDownloadTemplateImportSchedule() {
  return useMutation(() =>
    axios
      .get('/api/v1/admin/schedule/user/import', {
        responseType: 'blob',
      })
      .then((res) => res.data)
  );
}

export function useImportScheduleUser() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ userId, formData }) =>
      axios.put(`/api/v1/admin/schedule/user/import/${userId}`, formData).then((res) => res.data),
    {
      onSuccess: () => {
        queryClient.removeQueries([SCHEDULE_USER_QUERY_KEY.SCHEDULE_USER_LIST]);
        queryClient.removeQueries([SCHEDULE_USER_QUERY_KEY.SCHEDULE_USER_DETAILS]);
        queryClient.invalidateQueries([SCHEDULE_USER_QUERY_KEY.SCHEDULE_USER_DETAILS_INFO]);
      },
    }
  );
}
