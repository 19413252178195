import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useRef, useState } from 'react';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { InputComponent } from 'components/form/input.component';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { useNavigate } from 'react-router-dom';
import { UPLOAD_IMAGE_CONSTANT, useUploadSingleFile } from 'api/upload/upload-single-image.api';
import { TOOLBAR_OPTIONS, TextEditorComponent } from 'components/form/text-editor';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { PATH_CONSTANT } from 'config/path.constant';
import { UploadAttachmentInfo } from '../components/upload-attachment.component';
import { Select } from 'components/form/select.component';
import { SelectShareUserComponent } from '../components/select-share-user.component';
import { CreateShareInfoValidationSchema } from 'config/form/share-info/create-info-validation.schema';
import { useCreateNewInfo } from 'api/share-info/info/info.mutation';
import { StandartImageComponent } from 'components/image/standart-image.component';

const SUBMIT_TYPE = {
  SAVE: 'publish',
  DRAFT: 'draft',
};

const SHARE_TYPE = [
  { id: 'SPECIFIC_USER', name: 'Specific User' },
  { id: 'ALL_USERS', name: 'All Users' },
];
const DEFAULT_IMAGE = '/img/upload-image.jpg';
const BREAD_CUMB_ITEMS = [
  {
    title: 'Share Info',
    path: null,
  },
  {
    title: 'Create Info',
    path: null,
  },
];

const formInitialValue = {
  title: '',
  description: '',
  status: '',
  broadcast_date: '',
  broadcast_time: '',
  start_date: null,
  start_time: null,
  end_date: null,
  end_time: null,
  attachments: [],
  is_all_users: 'ALL_USERS',
  users: [],
};

const minDate = new Date().toISOString().split('T')[0].split('-');

export function getShareTypeDescription(shareType, totalSelectedUser, error) {
  if (shareType === 'ALL_USERS') {
    return 'This info will be shared to all users';
  }
  if (shareType === 'SPECIFIC_USER' && totalSelectedUser !== 0) {
    return `This info will be shared to ${totalSelectedUser} user${totalSelectedUser > 1 ? 's' : ''}`;
  }
  if (shareType === 'SPECIFIC_USER' && error) {
    return (
      <>
        <span className='text-sm mt-1 text-red-600 mb-1'>{error}</span>
      </>
    );
  }
  return '';
}

export const CreateShareInfoComponent = () => {
  const navigate = useNavigate();
  const formikRef = useRef(null);
  const uploadComponentRef = useRef(null);

  const [modalConfirmation, setModalConfirmation] = useState(false);

  const { mutateAsync: createInfo, isLoading: isCreating } = useCreateNewInfo();

  const { mutateAsync: uploadInfoBanner, isLoading: isUploading } = useUploadSingleFile({
    type: UPLOAD_IMAGE_CONSTANT.TYPE.ORIGINAL,
    path: UPLOAD_IMAGE_CONSTANT.DIRECTORY_PATH.SHARE_INFO,
  });

  const [selectedFileBuffer, setSelectedFileBuffer] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(DEFAULT_IMAGE);

  const handleOnFormSubmit = async (formValues, submitType) => {
    try {
      let infoImageBannerURL = null;
      if (selectedFileBuffer) {
        const formData = new FormData();
        formData.append('image', selectedFileBuffer);
        const response = await uploadInfoBanner(formData);
        infoImageBannerURL = response?.data?.url;
      }

      const filesBuffer = uploadComponentRef.current?.getFiles();

      if (filesBuffer.length > 0) {
        const attachmentsURL = await Promise.all(
          filesBuffer.map(async (file) => {
            const formData = new FormData();
            formData.append('image', file);
            const response = await uploadInfoBanner(formData);
            return {
              file_name: file.name,
              url: response?.data?.url,
            };
          })
        );
        formValues.attachments = attachmentsURL;
      }

      const payload = {
        ...formValues,
        status: submitType,
        image: infoImageBannerURL,
        is_all_users: formValues.is_all_users === 'ALL_USERS',
      };

      await createInfo(payload);
      toast.success('Info created successfully', {
        autoClose: 2000,
      });
      navigate(PATH_CONSTANT.SHARE_INFO.LIST_INFO);
    } catch (error) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleOnUploadFileChange = (e) => {
    //chedck file size with maximum 5mb and type, only accept image
    if (e.target.files[0].size > 5000000) {
      toast('File size is too large. Maximum 5mb is allowed');
      return;
    }
    if (!e.target.files[0].type.includes('image')) {
      toast('Only image file is allowed');
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setSelectedFileBuffer(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const isDisabledAllForm = isCreating || isUploading;

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      <Formik innerRef={formikRef} initialValues={formInitialValue} validationSchema={CreateShareInfoValidationSchema}>
        {({
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setTouched,
          setFieldTouched,
          touched,
          setFieldValue,
        }) => {
          return (
            <>
              <div className='flex justify-between mb-8'>
                <div class='w-[40%] '>
                  <div class='w-auto'>
                    <img
                      src={imagePreviewUrl}
                      class='w-[400px] h-[400px] overflow-hidden object-cover object-center border border-solid border-gray-1'
                      alt='Preview'
                    />
                    <div class='mt-7 flex justify-center'>
                      <input
                        type='file'
                        id='upload'
                        class='hidden'
                        onChange={handleOnUploadFileChange}
                        accept='image/*'
                      />
                      <label
                        for='upload'
                        class='bg-green w-28 inline-block text-center hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-3 cursor-pointer'
                      >
                        <span class='icon-ico-upload mr-2'></span>
                        <span class='text-sm'>Upload</span>
                      </label>
                    </div>
                  </div>
                  <div class=' mb-7'>
                    <div class='w-full mb-2'>
                      <Select
                        label='Share To'
                        onBlur={handleBlur}
                        onChange={(selectedValue) => {
                          setFieldValue('is_all_users', selectedValue.target?.value);
                          setTouched({ ...touched, users: true });
                        }}
                        name='is_all_users'
                        containerClassName='form-input btn-arrow-create z-1   relative mb-3'
                        placeholder='Pilih Share To'
                        error={touched.is_all_users && errors.is_all_users}
                        options={SHARE_TYPE}
                        value={values.is_all_users}
                      />
                    </div>

                    <p className='text-sm text-gray-2 italic mb-4'>
                      {getShareTypeDescription(values.is_all_users, values.users.length, errors.users)}
                    </p>

                    {values.is_all_users === 'ALL_USERS' ? null : (
                      <SelectShareUserComponent
                        selectedUserIds={values.users}
                        onSelectUser={(selectedUsers) => {
                          setFieldValue('users', selectedUsers);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div class='w-[55%]'>
                  <InputComponent
                    disabled={isDisabledAllForm}
                    name='title'
                    value={values.title}
                    error={touched.title && errors.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Title'
                    placeholder='Enter title...'
                  />
                  <div class='mb-7'>
                    <div class='text-sm block text-dark font-semibold mb-2'>Description</div>

                    <TextEditorComponent
                      toolbarOptions={[
                        TOOLBAR_OPTIONS.BOLD,
                        TOOLBAR_OPTIONS.ITALIC,
                        TOOLBAR_OPTIONS.UNDERLINE,
                        TOOLBAR_OPTIONS.CENTER_ALIGN,
                        TOOLBAR_OPTIONS.RIGHT_ALIGN,
                        TOOLBAR_OPTIONS.LEFT_ALIGN,
                      ]}
                      initialValue={undefined}
                      onChange={(nodeValue) => setFieldValue('description', nodeValue)}
                      error={touched.description && errors.description}
                      setFieldTouched={setFieldTouched}
                      name='description'
                    />
                  </div>

                  <div className='w-full flex-row flex justify-between'>
                    <div class='w-1/2 py-2 pr-6'>
                      <InputComponent
                        disabled={isDisabledAllForm}
                        name='start_date'
                        type='date'
                        min={minDate.join('-')}
                        error={touched.start_date && errors.start_date}
                        onChange={(e) => {
                          setFieldValue('start_date', e.target.value);
                        }}
                        value={values.start_date}
                        onBlur={handleBlur}
                        label='Start Date'
                        placeholder='Start Date'
                      />
                    </div>
                    <div class='w-1/2 py-2 pr-6'>
                      <InputComponent
                        disabled={isDisabledAllForm}
                        name='start_time'
                        type='time'
                        error={touched.start_time && errors.start_time}
                        value={values.start_time}
                        onChange={(e) => {
                          setFieldValue('start_time', e.target.value);
                        }}
                        onBlur={handleBlur}
                        label='Start Time'
                        placeholder='Start Time'
                      />
                    </div>
                  </div>
                  <div className='w-full flex-row flex justify-between'>
                    <div class='w-1/2 py-2 pr-6'>
                      <InputComponent
                        disabled={isDisabledAllForm}
                        name='end_date'
                        type='date'
                        value={values.end_date}
                        min={minDate.join('-')}
                        error={touched.end_date && errors.end_date}
                        onChange={(e) => {
                          setFieldValue('end_date', e.target.value);
                        }}
                        onBlur={handleBlur}
                        label='End Date'
                        placeholder='End Date'
                      />
                    </div>
                    <div class='w-1/2 py-2 pr-6'>
                      <InputComponent
                        disabled={isDisabledAllForm}
                        name='end_time'
                        type='time'
                        value={values.end_time}
                        error={touched.end_time && errors.end_time}
                        onChange={(e) => {
                          setFieldValue('end_time', e.target.value);
                        }}
                        onBlur={handleBlur}
                        label='End Time'
                        placeholder='End Time'
                      />
                    </div>
                  </div>
                  <p class='text-md block text-dark font-semibold mt-4'>Attachments</p>
                  <UploadAttachmentInfo ref={uploadComponentRef} />
                  <p class='text-md block text-dark font-semibold mt-4'>Push Notification</p>
                  <div className='w-full flex-row flex justify-between'>
                    <div class='w-1/2 py-2 pr-6'>
                      <InputComponent
                        disabled={isDisabledAllForm}
                        name='broadcast_date'
                        type='date'
                        value={values.broadcast_date}
                        min={minDate.join('-')}
                        error={touched.broadcast_date && errors.broadcast_date}
                        onChange={(e) => {
                          setFieldValue('broadcast_date', e.target.value);
                        }}
                        onBlur={handleBlur}
                        label='Start Date'
                        placeholder='Start Date'
                      />
                    </div>
                    <div class='w-1/2 py-2 pr-6'>
                      <InputComponent
                        disabled={isDisabledAllForm}
                        name='broadcast_time'
                        type='time'
                        value={values.broadcast_time}
                        error={touched.broadcast_time && errors.broadcast_time}
                        onChange={(e) => {
                          setFieldValue('broadcast_time', e.target.value);
                        }}
                        onBlur={handleBlur}
                        label='Start Time'
                        placeholder='Start Time'
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class='py-14 relative flex justify-center'>
                <ButtonSubmit
                  disabled={!CreateShareInfoValidationSchema?.isValidSync(values)}
                  loading={isCreating | isUploading}
                  onClick={() => handleOnFormSubmit(values, SUBMIT_TYPE.DRAFT)}
                  className='bg-gray-1 mx-3 hover:bg-gray-2 font-semibold transition duration-75 ease-in-out rounded-sm text-dark py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-10'
                >
                  <StandartImageComponent src='/img/icon/file.svg' class='max-w-full mr-3' /> Save As Draft
                </ButtonSubmit>
                <ButtonSubmit
                  loading={isCreating | isUploading}
                  disabled={!CreateShareInfoValidationSchema.isValidSync(values)}
                  onClick={() => handleOnFormSubmit(values, SUBMIT_TYPE.SAVE)}
                  className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
                >
                  <span class='icon-ico-plus-circle mr-3'></span> Save dan Publish
                </ButtonSubmit>
              </div>
            </>
          );
        }}
      </Formik>
      <ModalConfirmation
        description='Apakah anda yakin ingin membuat product baru?'
        title='Create Prodcut'
        imageIcon='/img/info.svg'
        textConfirm='Create'
        loading={isCreating}
        visible={modalConfirmation}
        onConfirm={() => {
          formikRef?.current?.handleSubmit();
        }}
        onClose={() => setModalConfirmation(false)}
      />
    </MainDashboardLayoutComponent>
  );
};
