import React from 'react';

export const ForgotPasswordComponent = () => {
  return (
    <body className='bg-login'>
      <div className='flex justify-center h-screen items-center relative'>
        <div className='w-[400px]'>
          <div className='block text-center mb-12'>
            <img src='/img/logo.png' className='max-w-full inline-block' alt='Logo' />
          </div>

          <div className='w-full bg-white rounded-sm p-4 pb-14'>
            <div className='text-2xl font-bold text-green text-center pt-2 pb-6'>Forgot Password</div>
            <div className='text-center block mb-3'>
              <img src='/img/forgot.png' className='max-w-full inline-block' alt='Forgot Password' />
            </div>
            <p className='text-sm text-gray mb-5'>
              Masukkan email anda dan klik submit. Kami akan mengirimkan link untuk reset password melalui inbox email
              anda
            </p>
            <div className='form-input mb-5'>
              <input
                type='email'
                id='email'
                className='w-full border rounded-sm outline-none border-solid border-gray-1 py-2.5 px-4 bg-white text-sm text-dark placeholder:text-gray-2 focus:border-green focus:text-green focus:bg-gray-focus transition duration-100 ease-in-out'
                placeholder='Input Email'
                value=''
              />
              <label for='email' className='text-sm font-semibold text-gray mb-2'>
                Email
              </label>
            </div>
            <button
              className='bg-green w-full hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
              type='button'
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </body>
  );
};
