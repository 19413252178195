import qs from 'query-string';
import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const REPORT_NED_PRODUCT_QUERY_KEY = {
  NED_PRODUCT_LIST: 'NED_PRODUCT_LIST',
  NED_PRODUCT_DETAILS_REPORT: 'NED_PRODUCT_DETAILS_REPORT',
  NED_PRODUCT_LOG: 'NED_PRODUCT_LOG',
};

export function useReportBANEDProductList({ filter, body }) {
  return useQuery([REPORT_NED_PRODUCT_QUERY_KEY.NED_PRODUCT_LIST, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/ned-report?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}

export function useReportBANEDProductDetails(recordId) {
  return useQuery([REPORT_NED_PRODUCT_QUERY_KEY.NED_PRODUCT_DETAILS_REPORT, recordId], () =>
    axios.get(`/api/v1/admin/ned-report/detail/${recordId}`).then((res) => res.data)
  );
}

export function useLogBANEDProductDetails(recordId) {
  return useQuery([REPORT_NED_PRODUCT_QUERY_KEY.NED_PRODUCT_LOG, recordId], () =>
    axios.get(`/api/v1/admin/ned-report/log/${recordId}`).then((res) => res.data)
  );
}
