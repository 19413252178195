import clsx from 'clsx';
import { usePagination, DOTS } from './table-pagination.hooks';

export const Pagination = ({
  onPageChange = () => {},
  totalCount = 1,
  siblingCount = 1,
  currentPage = 1,
  pageSize = 0,
  containerClassName = 'flex justify-center items-center my-8',
}) => {
  const paginationRange = usePagination({
    currentPage: +currentPage,
    totalCount,
    siblingCount: siblingCount || 1,
    pageSize,
  });

  if (+currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(+currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(+currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul class={containerClassName}>
      <li className='mr-3'>
        <span
          class={clsx(
            'text-sm font-bold uppercase text-green hover:text-dark-green cursor-pointer',
            +currentPage === 1 && 'opacity-50 cursor-not-allowed'
          )}
          disabled={+currentPage === 1}
          onClick={() => +currentPage !== 1 && onPrevious()}
        >
          PREV
        </span>
      </li>

      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <li key={`${pageNumber}-page-number`} className='pagination-item dots'>
              &#8230;
            </li>
          );
        }

        return (
          <li className='mx-4'>
            <span
              class={clsx(
                'text-sm  inline-block font-bold  w-6 h-6 rounded-full bg-white text-center leading-6 hover:bg-green-3 hover:text-white transition-all',
                pageNumber !== +currentPage && 'text-green',
                pageNumber === +currentPage && 'text-white bg-green-3'
              )}
              style={(pageNumber === +currentPage && { cursor: 'default' }) || { cursor: 'pointer' }}
              onClick={() => {
                if (pageNumber === +currentPage) return;
                onPageChange(pageNumber);
              }}
            >
              {pageNumber}
            </span>
          </li>
        );
      })}

      <li className='ml-3'>
        <span
          className={clsx(
            'text-sm font-bold uppercase text-green hover:text-dark-green cursor-pointer',
            +currentPage === lastPage && 'opacity-50 cursor-not-allowed'
          )}
          disabled={+currentPage === lastPage}
          href={null}
          style={{ cursor: 'pointer' }}
          onClick={() => +currentPage !== lastPage && onNext()}
        >
          NEXT
        </span>
      </li>
    </ul>
  );
};
