import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const STOCK_MOVEMENT_OUTBOND = {
  PRINT_DATA: 'PRINT_DATA_OUTBOND',
};

export function useGetPrintDataOutbond(poIds, enable) {
  return useQuery(
    [STOCK_MOVEMENT_OUTBOND.PRINT_DATA, { poIds }],
    () =>
      axios
        .post(`/api/v1/admin/pre-order/outbond/list-awb-po`, {
          pre_order_ids: poIds,
        })
        .then((res) => res.data),
    {
      enabled: !!poIds?.length && !!enable,
    }
  );
}
