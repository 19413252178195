import qs from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { TableSearchInputComponent } from 'components/table/table-search.component';
import { TableButtonExport } from 'components/table/table-button-export.component';
import { TableButtonLimitComponent } from 'components/table/table-button-limit.component';
import { TableButtonSortComponent } from 'components/table/table-button-sort.component';
import { BasicTableComponent } from 'components/table/table-basic.component';
import { Pagination } from 'components/table/table-pagination.component';
import { EmptyStateComponent } from 'components/empty-data';
import { BounceLoading } from 'components/loader/bounce.loading';
import { ModalTableSortComponent } from 'components/modal/modal-table-sort.component';
import { ModalTableLimitComponent } from 'components/modal/modal-table-limit.component';
import { ModalConfirmation } from 'components/modal/moda.confirmation.component';
import { setToolsReducer } from 'reducers/tools.reducer';
import { StandartImageComponent } from 'components/image/standart-image.component';
import { useStoreList } from 'api/store/store-list/store-list.query.api';
import { useExportStoreList } from 'api/store/store-list/store-list.mutation.api';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { PATH_CONSTANT } from 'config/path.constant';
import { catchErrorMessage } from 'ui-utils/string.utils';
import { HighlightedText } from 'components/highlighted-text';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Product',
    path: PATH_CONSTANT.PRODUCT.PRODUCT_LIST,
  },
  {
    title: 'List Stok Product Store',
    path: PATH_CONSTANT.PRODUCT.PRODUCT_STOCK_STORE_LIST,
  },
];

const SORT_OPTIONS = [
  { label: 'Urutkan Nama A-Z', sortType: 'asc', sortBy: 'name' },
  { label: 'Urutkan Nama Z-A', sortType: 'desc', sortBy: 'name' },
  { label: 'Tanggal Buat Terbaru - Terdahulu', sortType: 'desc', sortBy: 'created_at' },
  { label: 'Tanggal Buat Terdahulu - Terbaru', sortType: 'asc', sortBy: 'created_at' },
];

export const StockInStoreListComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const INITIAL_VALUE_FILTER_QUERY = {
    limit: searchParams.get('limit')?.replace(/[^0-9]/g, '') || 20,
    page: searchParams.get('page')?.replace(/[^0-9]/g, '') || 1,
    orderBy: searchParams.get('orderBy')?.replace(/[^a-zA-Z_]/g, '') || 'name',
    orderType: searchParams.get('orderType')?.replace(/[^a-zA-Z]/g, '') || 'desc',
    search: searchParams.get('search')?.replace(/[^a-zA-Z0-9 ]/g, '') || undefined,
    withStock: 1,
  };

  const [queryFilter, setQueryFilter] = useState(INITIAL_VALUE_FILTER_QUERY);

  const { selectedRowKeys: selectedIds } = useSelector((state) => state.tools);

  const [modals, setModals] = useState({
    sort: false,
    limit: false,
    export: false,
    filter: false,
  });

  const { mutate: exportStore, isLoading: isExporting } = useExportStoreList();

  const {
    data: storeList,
    isLoading,
    isError,
    error,
  } = useStoreList({
    filter: queryFilter,
  });

  const columns = useMemo(
    () => [
      {
        type: 'checkbox',
        isRightBorderAvailable: true,
        render: (record) => {
          return (
            <div className='form-check px-2'>
              <input
                className='form-check-input appearance-none h-4 w-4 border border-green rounded-sm bg-white checked:bg-green checked:border-green focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer'
                type='checkbox'
                readOnly
              />
            </div>
          );
        },
      },

      {
        name: 'Nama Store',
        className: 'w-[30%] text-sm font-semibold text-green text-center',
        dataIndex: 'name',
        render: (record) => (
          <div class='w-[30%] text-sm font-semibold text-green '>
            <div class='flex items-center lg:pl-[10%]'>
              <div class='w-12'>
                <StandartImageComponent
                  src={record?.image || '/img/default-store.jpg'}
                  class='w-10 h-10 rounded-sm overflow-hidden object-cover object-center'
                />
              </div>
              <div class='w-9/12  pl-3 xl:pl-5'>
                <Link to={`${PATH_CONSTANT.PRODUCT.PRODUCT_STOCK_STORE_INFO}/${record?.id}`}>
                  <div class=' block text-ellipsis whitespace-normal line-clamp-3 text-xs font-semibold text-dark hover:text-green mb-1'>
                    <HighlightedText text={record?.name} highlight={queryFilter?.search} />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        ),
      },
      {
        name: 'Alamat Store',
        className: 'w-[45%] text-sm font-semibold text-green',
        dataIndex: 'address',
        render: (record) => (
          <div class='w-[45%] pr-10 xl:pr-[10%]'>
            <div class='text-sm text-gray line-clamp-4'>
              <HighlightedText text={record?.address} highlight={queryFilter?.search} />
            </div>
          </div>
        ),
      },
      {
        name: 'Stock',
        className: 'w-[20%] text-sm font-semibold text-green',
        dataIndex: 'location',
        render: (record) => (
          <div class='w-[20%]'>
            <div class='text-sm text-gray'>{`${record?.stock?.toLocaleString()} unit`}</div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryFilter?.search]
  );

  const resetCheckList = () => {
    if (selectedIds.length > 0) {
      dispatch(setToolsReducer({ selectedRowKeys: [], selectedRows: [] }));
    }
  };

  const handleExport = () => {
    exportStore(
      {
        queryFilter: queryFilter,
        bodyFilter: { store_ids: selectedIds },
      },
      {
        onSuccess: (res) => {
          const link = document.createElement('a');
          link.href = res.data.download_url;
          document.body.appendChild(link);
          link.click();
          setModals({ ...modals, export: false });
        },
      }
    );
  };

  useEffect(() => {
    resetCheckList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeList]);

  return (
    <MainDashboardLayoutComponent breadCumbs={BREAD_CUMB_ITEMS}>
      <div className='flex items-center mb-5'>
        <div className='w-full flex items-center'>
          <TableSearchInputComponent
            containerClassName='w-[30%] mr-2 relative'
            placeholder='Cari nama store...'
            inputProps={{
              disabled: isLoading,
            }}
            initialValue={INITIAL_VALUE_FILTER_QUERY.search}
            onSearch={(value) => {
              setQueryFilter({ ...queryFilter, search: value.keyword, page: 1 });
              navigate({
                search: qs.stringify({
                  ...queryFilter,
                  search: value.keyword || undefined,
                  page: 1,
                }),
              });
            }}
          />

          <div className='xl:w-[calc(100%-40%)] lg:w-[calc(100%-30%)] md:w-[calc(100%-30%)] flex justify-start items-center'>
            <TableButtonSortComponent
              disabled={isLoading}
              className='mr-2 outline-none flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
              onClick={() => {
                setModals((prev) => ({ ...prev, sort: true }));
              }}
            />

            <TableButtonLimitComponent
              disabled={isLoading}
              className='mr-2 outline-none  relative flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
              value={queryFilter.limit}
              onClick={() => setModals({ ...modals, limit: true })}
            />
            <TableButtonExport
              className='mr-2 outline-none flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3'
              disabled={isLoading}
              loading={isExporting}
              onClick={() => {
                setModals({ ...modals, export: true });
              }}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className='h-[70vh] flex items-center'>
          <BounceLoading color='#5E755A' />
        </div>
      ) : (
        <>
          {Boolean(storeList?.data?.total_result) && (
            <div className='italic text-dark text-sm mb-5'>{`Total ${storeList?.data?.total_result} store`}</div>
          )}

          <BasicTableComponent
            rowClassName='flex mb-3 items-center rounded-sm py-4 px-2 border border-solid border-transparent hover:border-green bg-white hover:bg-light-green transition duration-75 ease-in-out cursor-pointer'
            columns={columns}
            dataSource={storeList?.data?.rows || []}
            loading={isLoading}
            error={isError}
            errorTitle={`${error?.response?.data?.status} - ${error?.response?.data?.code}`}
            errorMessage={catchErrorMessage(error?.response?.data?.message)}
          />

          {storeList?.data?.total_result === 0 && (
            <EmptyStateComponent
              message={queryFilter.search ? `No result for query "${queryFilter.search}"` : 'No data available'}
            />
          )}

          <Pagination
            currentPage={queryFilter.page}
            pageSize={queryFilter.limit}
            siblingCount={1}
            totalCount={storeList?.data?.total_result || 0}
            onPageChange={(page) => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
              setQueryFilter({ ...queryFilter, page });
              navigate({
                search: qs.stringify({
                  ...queryFilter,
                  page,
                }),
              });
            }}
          />
        </>
      )}
      <ModalTableSortComponent
        options={SORT_OPTIONS}
        initialValue={SORT_OPTIONS.find(
          (sortItem) => sortItem.sortBy === queryFilter.orderBy && sortItem.sortType === queryFilter.orderType
        )}
        onChange={({ selected }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            orderBy: selected.sortBy,
            orderType: selected.sortType,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              orderBy: selected.sortBy,
              orderType: selected.sortType,
            }),
          });
          setModals({ ...modals, sort: false });
        }}
        visible={modals.sort}
        onClose={() => setModals({ ...modals, sort: false })}
      />

      <ModalTableLimitComponent
        onChange={({ limit }) => {
          setQueryFilter((prevState) => ({
            ...prevState,
            limit,
            page: 1,
          }));
          navigate({
            search: qs.stringify({
              ...queryFilter,
              limit,
              page: 1,
            }),
          });
          setModals({ ...modals, limit: false });
        }}
        initialValue={queryFilter.limit}
        visible={modals.limit}
        onClose={() => setModals({ ...modals, limit: false })}
      />

      <ModalConfirmation
        description='Apakah anda yakin ingin eksport data yang anda pilih ?'
        title='Export Data'
        visible={modals.export}
        onConfirm={handleExport}
        onClose={() => setModals({ ...modals, export: false })}
      />
    </MainDashboardLayoutComponent>
  );
};
