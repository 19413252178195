import qs from 'query-string';
import axios from 'api/api.config';
import { useMutation, useQueryClient } from 'react-query';
import { REPORT_NED_PRODUCT_QUERY_KEY } from './ned-product.query.api';

export function useExportNEDReportList() {
  return useMutation(({ queryFilter, bodyFilter }) =>
    axios.post(`/api/v1/admin/ned-report/export?${qs.stringify(queryFilter)}`, bodyFilter).then((res) => res.data)
  );
}

export function useApproveNEDReport() {
  const queryClient = useQueryClient();
  return useMutation((body) => axios.put(`/api/v1/admin/ned-report/approve`, body).then((res) => res.data), {
    onSuccess: () => {
      queryClient.refetchQueries([REPORT_NED_PRODUCT_QUERY_KEY.NED_PRODUCT_LIST]);
      queryClient.removeQueries([REPORT_NED_PRODUCT_QUERY_KEY.NED_PRODUCT_DETAILS_REPORT]);
      queryClient.invalidateQueries([REPORT_NED_PRODUCT_QUERY_KEY.NED_PRODUCT_LOG]);
    },
  });
}
export function useExportNEDDetailReport() {
  return useMutation(({ recordId }) =>
    axios.get(`/api/v1/admin/ned-report/export/${recordId}`).then((res) => res.data)
  );
}
