import * as Yup from 'yup';

/**
 * Form validation schema for /auth/login
 */

export const CreateUserValidationSchema = Yup.object().shape({
  email: Yup.string().email('Email must be valid email').required('Email is required'),
  name: Yup.string().required('Name is required').max(80, 'Name is too long'),
  phone: Yup.string().test('phone', 'Phone number is not valid', (value) => {
    if (!value) return true;
    return value.length >= 7 && value.length <= 15;
  }),
  password: Yup.string().required('Password is required').min(6, 'Password at least 6 characters'),
  store_id: Yup.string().required('Store is required').nullable(),
  join_date: Yup.string().required('Join date is required'),
});

export const EditUserValidationSchema = Yup.object().shape({
  email: Yup.string().email('Email must be valid email').required('Email is required'),
  name: Yup.string().required('Name is required').max(80, 'Name is too long'),
  phone: Yup.string().test('phone', 'Phone number is not valid', (value) => {
    if (!value) return true;
    return value.length >= 7 && value.length <= 15;
  }),

  store_id: Yup.string().required('Store is required').nullable(),
  join_date: Yup.string().required('Join date is required'),
});
