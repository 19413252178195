import * as Yup from 'yup';
import { useState } from 'react';
import { InputComponent } from 'components/form/input.component';
import { ModalContainer } from 'components/modal/modal-container.component';
import { Formik } from 'formik';
import { useCreateScheduleUser, useUpdateScheduleUser } from 'api/schedules/jadwal/jadwai.mutation.api';
import { useParams } from 'react-router-dom';
import { useGetDetailSchedule } from 'api/schedules/jadwal/jadwal.query.api';
import { useEffect, useRef } from 'react';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { ModalResult } from 'components/modal/modal.result.component';
const formInitialValues = {
  date: '',
  clock_in: '',
  clock_out: '',
};

const formValidationSchema = Yup.object().shape({
  date: Yup.string().required('Tanggal harus diisi'),
  clock_in: Yup.string().required('Waktu clock in harus diisi'),
  clock_out: Yup.string().required('Waktu clock out harus diisi'),
});

export const MODAL_CREATE_SCHEDULE_TYPE = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
};

export const MODAL_LABEL_TEXT = {
  [MODAL_CREATE_SCHEDULE_TYPE.CREATE]: {
    title: 'Tambah Jadwal',
    submitButton: 'Apply',
    modalResutTitle: 'Schedule Created',
    modalResutDescription: 'Schedule created successfully!',
  },
  [MODAL_CREATE_SCHEDULE_TYPE.UPDATE]: {
    title: 'Ubah Jadwal',
    submitButton: 'Update',
    modalResutTitle: 'Schedule Updated',
    modalResutDescription: 'Schedule updated successfully!',
  },
};
export function ModalCreateScheduleComponent({
  visible,
  onClose,
  type = MODAL_CREATE_SCHEDULE_TYPE.CREATE,
  scheduleId = null,
}) {
  const formikRef = useRef(null);
  const { userId } = useParams();
  const [showModalResult, setShowModalResult] = useState(false);
  const { data: scheduleDetails } = useGetDetailSchedule(scheduleId);
  const { mutate: createScheduleUser, isLoading: isCreating } = useCreateScheduleUser();
  const { mutate: updateScheduleUser, isLoading: isUpdating } = useUpdateScheduleUser();
  const [modalResultType, setModalResultType] = useState(MODAL_CREATE_SCHEDULE_TYPE.CREATE);

  useEffect(() => {
    if (scheduleDetails?.data) {
      formikRef.current.setValues({
        date: scheduleDetails.data.date,
        clock_in: scheduleDetails.data.clock_in_formatted,
        clock_out: scheduleDetails.data.clock_out_formatted,
      });
    }
  }, [scheduleDetails]);

  const handleSubmitForm = (data) => {
    if (type === MODAL_CREATE_SCHEDULE_TYPE.CREATE) {
      createScheduleUser(
        {
          user_id: userId,
          ...data,
        },
        {
          onSuccess: () => {
            handleOnClose();
            setShowModalResult(true);
            setModalResultType(MODAL_CREATE_SCHEDULE_TYPE.CREATE);
          },
        }
      );
    }
    if (type === MODAL_CREATE_SCHEDULE_TYPE.UPDATE) {
      updateScheduleUser(
        {
          scheduleId,
          ...data,
        },
        {
          onSuccess: () => {
            handleOnClose();
            setShowModalResult(true);
            setModalResultType(MODAL_CREATE_SCHEDULE_TYPE.UPDATE);
          },
        }
      );
    }
  };

  const handleOnClose = () => {
    formikRef.current.setValues(formInitialValues);
    onClose();
  };

  const isMutating = isCreating || isUpdating;
  return (
    <>
      <ModalContainer visible={visible}>
        <Formik
          innerRef={formikRef}
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={handleSubmitForm}
        >
          {({ values, handleChange, handleSubmit }) => (
            <>
              <div id='status' class='w-[330px] bg-white rounded-sm p-5 relative'>
                <div class='mb-5 text-center font-bold text-sm'>{MODAL_LABEL_TEXT[type].title}</div>

                <button onClick={handleOnClose} type='button' class='absolute top-2 right-3'>
                  <span class='icon-ico-close text-xl text-gray-2'></span>
                </button>
                <div class='max-h-[45vh] scroll mb-3'>
                  <InputComponent
                    name='date'
                    disabled={isMutating}
                    onChange={handleChange}
                    value={values.date}
                    label='Tanggal'
                    type='date'
                    placeholder='dd-mm-yyyy'
                  />
                  <InputComponent
                    name='clock_in'
                    disabled={isMutating}
                    onChange={handleChange}
                    value={values.clock_in}
                    label='Waktu Clock In'
                    type='time'
                    placeholder='Pilih waktu clock in...'
                  />
                  <InputComponent
                    name='clock_out'
                    disabled={isMutating}
                    onChange={handleChange}
                    value={values.clock_out}
                    label='Waktu Clock Out'
                    type='time'
                    placeholder='Pilih waktu clock in...'
                  />
                </div>

                <div class='flex justify-between items-center pb-2'>
                  <div class='w-[48%]'>
                    <button
                      type='button'
                      onClick={handleOnClose}
                      class='block w-full rounded-sm border border-solid border-gray-1 py-2 px-2 text-sm font-semibold text-dark hover:bg-green-status transition duration-75 ease-in-out'
                    >
                      Cancel
                    </button>
                  </div>
                  <div class='w-[48%]'>
                    <ButtonSubmit
                      onClick={handleSubmit}
                      disabled={isMutating || !formValidationSchema.isValidSync(values)}
                      loading={isCreating || isUpdating}
                      className='bg-green w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
                      type='button'
                    >
                      {MODAL_LABEL_TEXT[type].submitButton}
                    </ButtonSubmit>
                  </div>
                </div>
              </div>
            </>
          )}
        </Formik>
      </ModalContainer>

      <ModalResult
        description={MODAL_LABEL_TEXT[modalResultType].modalResutDescription}
        title={MODAL_LABEL_TEXT[modalResultType].modalResutTitle}
        visible={showModalResult}
        textConfirm='OK'
        onConfirm={() => setShowModalResult(false)}
        onClose={() => setShowModalResult(false)}
      />
    </>
  );
}
