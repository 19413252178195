import { ModalContainer } from './modal-container.component';

export function ModalInfoStatusPO({ visible, onClose }) {
  return (
    <ModalContainer visible={visible}>
      <div id='status' class='w-[330px] bg-white rounded-sm p-5 relative'>
        <div class='mb-5 text-center font-bold text-sm'>Status Type</div>

        <button onClick={onClose} type='button' class='absolute top-2 right-3'>
          <span class='icon-ico-close text-xl text-gray-2'></span>
        </button>
        <div class='max-h-[45vh] scroll mb-3'>
          <div class='flex items-center py-3'>
            <div class='inline-block bg-yellow-light text-center w-28 py-1 px-1 rounded-sm text-yellow text-sm font-medium'>
              Submitted
            </div>
            <div class='text-sm ml-3 text-gray'>Request Submitted</div>
          </div>
          <div class='flex items-center py-3'>
            <div class='inline-block bg-green-status text-center w-28 py-1 px-1 rounded-sm text-green-3 text-sm font-medium'>
              Approve
            </div>
            <div class='text-sm ml-3 text-gray'>Request Approved</div>
          </div>
          <div class='flex items-center py-3'>
            <div class='inline-block bg-blue-light text-center w-28 py-1 px-1 rounded-sm text-blue text-sm font-medium'>
              Waiting for Payment
            </div>
            <div class='text-sm ml-3 text-gray'>Waiting for Payment</div>
          </div>
          <div class='flex items-center py-3'>
            <div class='inline-block bg-olive-light text-center w-28 py-1 px-1 rounded-sm text-olive text-sm font-medium'>
              Picking
            </div>
            <div class='text-sm ml-3 text-gray'>Picking Process</div>
          </div>
          <div class='flex items-center py-3'>
            <div class='inline-block bg-violet-light text-center w-28 py-1 px-1 rounded-sm text-violet text-sm font-medium'>
              Packing
            </div>
            <div class='text-sm ml-3 text-gray'>Packing Process</div>
          </div>
          <div class='flex items-center py-3'>
            <div class='inline-block bg-brown-light text-center w-28 py-1 px-1 rounded-sm text-brown text-sm font-medium'>
              Packed
            </div>
            <div class='text-sm ml-3 text-gray'>Already Packed</div>
          </div>
          <div class='flex items-center py-3'>
            <div class='inline-block bg-purple-light text-center w-28 py-1 px-1 rounded-sm text-purple text-sm font-medium'>
              Delivery
            </div>
            <div class='text-sm ml-3 text-gray'>Delivery Process</div>
          </div>
          <div class='flex items-center py-3'>
            <div class='inline-block bg-maroon-light text-center w-28 py-1 px-1 rounded-sm text-maroon text-sm font-medium'>
              Validate
            </div>
            <div class='text-sm ml-3 text-gray'>Need to Validate</div>
          </div>
          <div class='flex items-center py-3'>
            <div class='inline-block bg-complete-light text-center w-28 py-1 px-1 rounded-sm text-gray text-sm font-medium'>
              Completed
            </div>
            <div class='text-sm ml-3 text-gray'>Completed</div>
          </div>
        </div>
        <div class='flex items-center pb-2 pt-5'>
          <button
            onClick={onClose}
            class='bg-green w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
            type='button'
          >
            OK
          </button>
        </div>
      </div>
    </ModalContainer>
  );
}
