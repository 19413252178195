import { useRef } from 'react';
import clsx from 'clsx';
import { PrintAWBPoComponent } from './print-awb-po.component';
import { ModalContainer } from 'components/modal/modal-container.component';
import { useGetPrintDataOutbond } from 'api/stock-movement/outbond/outbond.query.api';

export function ModalPrintAWBPO({
  title = 'Print Surat Jalan',
  onClose = () => {},
  poIds = [],
  visible = false,
  enable = false,
  onFinish = () => {},
}) {
  const printRef = useRef(null);

  const { data, isLoading } = useGetPrintDataOutbond(poIds, enable);

  const handleDownload = () => {
    printRef?.current && printRef.current?.handleDownload();
  };

  if (isLoading) return null;
  return (
    <ModalContainer visible={visible}>
      <div id='show-item' class={clsx('w-[1030px] bg-white rounded-sm p-5 relative', !visible && 'hidden')}>
        <div class='mb-5 text-center font-bold text-sm'>{title}</div>
        <button type='button' class='absolute top-2 right-3' disabled={isLoading} onClick={onClose}>
          <span class='icon-ico-close text-xl text-gray-2'></span>
        </button>
        <div className='w-full max-h-[80vh]  scroll overflow-auto border border-solid border-gray-1 justify-center items-center'>
          <PrintAWBPoComponent data={data?.data} onFinish={onFinish} ref={printRef} />
        </div>
        <div class='flex justify-between items-center pb-2 px-[10%] mt-3'>
          <div class='w-[48%]'>
            <button
              type='button'
              onClick={onClose}
              class='block w-full rounded-sm border border-solid border-gray-1 py-2 px-2 text-sm font-semibold text-dark hover:bg-green-status transition duration-75 ease-in-out'
            >
              Cancel
            </button>
          </div>
          <div class='w-[48%]'>
            <button
              onClick={handleDownload}
              class='bg-green w-full hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-7'
              type='button'
            >
              <span class='icon-ico-print mr-2'></span> Print Surat Jalan
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
}
