import axios from 'api/api.config';
import qs from 'query-string';
import { useQuery } from 'react-query';

export const STOCK_TRANSFER_QUERY_KEY = {
  STOCK_TRANSFER_QUERY_LIST: 'STOCK_TRANSFER_QUERY_LIST',
  STOCK_TRANSFER_QUERY_LIST_DETAILS: 'STOCK_TRANSFER_QUERY_LIST_DETAILS',
  STOCK_TRANSFER_QUERY_LIST_ACTION_LOG: 'STOCK_TRANSFER_QUERY_LIST_ACTION_LOG',
};

export function useListStockTransfer({ filter, bodyFilter }) {
  return useQuery([STOCK_TRANSFER_QUERY_KEY.STOCK_TRANSFER_QUERY_LIST, { filter, bodyFilter }], () =>
    axios.post(`/api/v1/admin/stock-transfer?${qs.stringify(filter)}`, bodyFilter).then((res) => res.data)
  );
}

export function useGetStockTransferDetails(stockTransferId) {
  return useQuery([STOCK_TRANSFER_QUERY_KEY.STOCK_TRANSFER_QUERY_LIST_DETAILS, stockTransferId], () =>
    axios.get(`/api/v1/admin/stock-transfer/detail/${stockTransferId}`).then((res) => res.data)
  );
}

export function useGetStockTransferActionLog(recordId) {
  return useQuery([STOCK_TRANSFER_QUERY_KEY.STOCK_TRANSFER_QUERY_LIST_ACTION_LOG, recordId], () =>
    axios.get(`/api/v1/admin/stock-transfer/logs/${recordId}?orderType=desc`).then((res) => res.data)
  );
}
