import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { useMemo, useRef, useState, useEffect } from 'react';
import { MainDashboardLayoutComponent } from 'components/main-layout/main.component';
import { InputComponent } from 'components/form/input.component';
import { Select } from 'components/form/select.component';
import { ButtonSubmit } from 'components/button/button-submit.component';
import { BounceLoading } from 'components/loader/bounce.loading';
import { useNavigate, useParams } from 'react-router-dom';
import { useMasterCityList } from 'api/master-data/master-data.api';
import { CreateStoreValidationSchema } from 'config/form/store/store-validation.schema';
import { useEditStore } from 'api/store/store-create/store-create.mutation.api';
import { useStoreDetail } from 'api/store/store-list/store-list.query.api';
import { UPLOAD_IMAGE_CONSTANT, useUploadSingleImage } from 'api/upload/upload-single-image.api';
import { catchErrorMessage } from 'ui-utils/string.utils';

const BREAD_CUMB_ITEMS = [
  {
    title: 'Store',
    path: '/store/list',
  },
  {
    title: 'Edit Store',
    path: '/store/list',
  },
];

const formInitialValue = {
  city_id: null,
  name: '',
  address: '',
  phone: '',
  image: null,
};

export const StoreEditComponent = () => {
  const formikRef = useRef(null);
  const navigate = useNavigate();
  const { storeId } = useParams();

  const { data: masterCityList } = useMasterCityList();
  const { data: storeDetails, isLoading, isError, error } = useStoreDetail(storeId);

  const { mutateAsync: updateStore, isLoading: isCreating } = useEditStore();

  const { mutateAsync: uploadStoreImage, isLoading: isUploading } = useUploadSingleImage({
    type: UPLOAD_IMAGE_CONSTANT.TYPE.COMPRESSED,
    path: UPLOAD_IMAGE_CONSTANT.DIRECTORY_PATH.STORE_IMAGE,
  });

  const [selectedFileBuffer, setSelectedFileBuffer] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('/img/default-store.jpg');

  const storeOptions = useMemo(() => {
    if (masterCityList) {
      return masterCityList?.data?.map((item) => ({
        id: item.id,
        name: item.name,
      }));
    }
    return [];
  }, [masterCityList]);

  useEffect(() => {
    if (storeDetails) {
      Object.keys(formInitialValue).forEach((key) => {
        if (storeDetails?.data[key]) {
          if (key === 'phone') {
            formikRef.current.setFieldValue(key, storeDetails?.data[key].replace('+62', ''));
          } else {
            formikRef.current.setFieldValue(key, storeDetails.data[key]);
          }
        }

        setImagePreviewUrl(storeDetails?.data?.image || '/img/default-store.jpg');
      });
    }
  }, [storeDetails, formikRef]);

  const handleOnFormSubmit = async (formValues) => {
    try {
      let storePictureURL = null;
      if (selectedFileBuffer) {
        const formData = new FormData();
        formData.append('image', selectedFileBuffer);
        const response = await uploadStoreImage(formData);
        storePictureURL = response?.data?.url;
      }
      const payload = {
        ...formValues,
        image: selectedFileBuffer ? storePictureURL : formValues.image,
        phone: `+62${formValues.phone}`,
      };

      if (!formValues.phone) payload.phone = '';
      await updateStore({ ...payload, id: storeId });
      toast.success('Store updated successfully', {
        autoClose: 2000,
      });
      navigate('/store/list');
    } catch (error) {}
  };

  const handleOnUploadFileChange = (e) => {
    //chedck file size with maximum 5mb and type, only accept image
    if (e.target.files[0].size > 5000000) {
      toast('File size is too large. Maximum 5mb is allowed');
      return;
    }
    if (!e.target.files[0].type.includes('image')) {
      toast('Only image file is allowed');
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setSelectedFileBuffer(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const isDisabledAllForm = isCreating || isUploading;

  return (
    <MainDashboardLayoutComponent
      breadCumbs={BREAD_CUMB_ITEMS}
      isError={isError}
      errorTitle={error?.response?.data?.status}
      erroMessage={catchErrorMessage(error?.response?.data?.message)}
    >
      <div className='flex'>
        {isLoading ? (
          <div className='h-[70vh] flex w-full items-center text-center'>
            <BounceLoading color='#5E755A' />
          </div>
        ) : (
          <Formik
            innerRef={formikRef}
            initialValues={formInitialValue}
            onSubmit={handleOnFormSubmit}
            validationSchema={CreateStoreValidationSchema}
          >
            {({ errors, values, handleChange, handleBlur, handleSubmit, touched }) => (
              <>
                <div class='w-64 flex justify-center'>
                  <div class='w-auto'>
                    <img
                      src={imagePreviewUrl}
                      class='w-40 h-40 object-cover object-center overflow-hidden'
                      alt='Preview'
                    />
                    <div class='mt-7 flex justify-center'>
                      <input
                        type='file'
                        id='upload'
                        class='hidden'
                        onChange={handleOnUploadFileChange}
                        accept='image/*'
                      />
                      <label
                        for='upload'
                        class='bg-green w-28 inline-block text-center hover:bg-dark-green transition duration-75 ease-in-out rounded-sm text-white py-2 font-semibold text-sm px-3 cursor-pointer'
                      >
                        <span class='icon-ico-upload mr-2'></span>
                        <span class='text-sm'>Upload</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class='w-1/2'>
                  <InputComponent
                    disabled={isDisabledAllForm}
                    name='name'
                    value={values.name}
                    error={touched.name && errors.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Nama Store'
                    placeholder='Input Nama Store'
                  />

                  <Select
                    disabled={isDisabledAllForm}
                    name='city_id'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city_id}
                    options={storeOptions}
                    error={touched.city_id && errors.city_id}
                    placeholder='Pilih Lokasi Store'
                    containerClassName='form-input  relative mb-3'
                    label='Lokasi Store'
                  />
                  <InputComponent
                    disabled={isDisabledAllForm}
                    name='address'
                    element='textarea'
                    rows={7}
                    value={values.address}
                    error={touched.address && errors.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label='Alamat Store'
                    placeholder='Nama jalan, lantai gedung, nomor bangunan....'
                  />
                  <InputComponent
                    disabled={isDisabledAllForm}
                    numberOnly={true}
                    name='phone'
                    value={values.phone}
                    error={touched.phone && errors.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    containerClass='form-input relative mb-3'
                    label='Nomor Telepon Store'
                    prefix='+62'
                    inputClass='w-full border rounded-sm outline-none border-solid border-gray-1 py-2.5 pl-16 pr-4 bg-white text-sm text-dark placeholder:text-gray-2 focus:border-green focus:text-green focus:bg-gray-focus transition duration-100 ease-in-out'
                    type='tel'
                    placeholder='Nomor Telepon Store'
                  />

                  <div class='py-14 relative flex justify-center'>
                    <ButtonSubmit
                      loading={isCreating | isUploading}
                      disabled={!CreateStoreValidationSchema.isValidSync(values)}
                      onClick={handleSubmit}
                      className='bg-green hover:bg-dark-green font-semibold transition duration-75 ease-in-out rounded-sm text-white py-2 text-center flex items-center justify-center min-w-[180px] text-sm px-7'
                    >
                      <span class='icon-ico-apply mr-3'></span> Update Store
                    </ButtonSubmit>
                  </div>
                </div>
              </>
            )}
          </Formik>
        )}
      </div>
    </MainDashboardLayoutComponent>
  );
};
