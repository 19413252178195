import qs from 'query-string';
import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const REPORT_CYCLE_COUNT_QUERY = {
  CYCLE_COUNT_LIST: 'CYCLE_COUNT_LIST',
  CYCLE_COUNT_DETAILS_REPORT: 'CYCLE_COUNT_DETAILS_REPORT',
  CYCLE_COUNT_LOGS: 'CYCLE_COUNT_LOGS',
};

export function useReportCycleCountList({ filter, body }) {
  return useQuery([REPORT_CYCLE_COUNT_QUERY.CYCLE_COUNT_LIST, { filter, body }, body], () =>
    axios.post(`/api/v1/admin/cycle-count?${qs.stringify(filter)}`, body).then((res) => res.data)
  );
}

export function useReportCycleCountDetails(recordId) {
  return useQuery([REPORT_CYCLE_COUNT_QUERY.CYCLE_COUNT_DETAILS_REPORT, recordId], () =>
    axios.get(`/api/v1/admin/cycle-count/detail/${recordId}`).then((res) => res.data)
  );
}

export function useLogCycleCountReportDetails(recordId) {
  return useQuery([REPORT_CYCLE_COUNT_QUERY.CYCLE_COUNT_LOGS, recordId], () =>
    axios.get(`/api/v1/admin/cycle-count/log/${recordId}`).then((res) => res.data)
  );
}
