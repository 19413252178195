/**
 * isAllPropertyObjectFalsy - check if all properties in an object are falsy
 * @param {Object} obj - object to check
 * @returns {Boolean} true if all properties in the object are falsy, otherwise false
 */

// This function will return true if the object is empty or falsy
export function isAllPropertyObjectFalsy(obj) {
  if (!obj) {
    return true;
  }

  return Object.values(obj).every((value) => {
    if (Array.isArray(value)) {
      // This is an array and we will return true if the array is empty
      return value.length === 0;
    }

    // This is not an array and we will return true if it is falsy
    return !value;
  });
}

// where id not in array
export function whereNotIn(array, arrayId) {
  return array.filter((item) => !arrayId.includes(item.id));
}

export function isURLImage(url) {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
}
