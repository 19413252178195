import qs from 'query-string';
import axios from 'api/api.config';
import { useMutation, useQueryClient } from 'react-query';
import { REPORT_CYCLE_COUNT_QUERY } from './cycle-count.query.api';

export function useExportCycleCountList() {
  return useMutation(({ queryFilter, bodyFilter }) =>
    axios.post(`/api/v1/admin/cycle-count/export?${qs.stringify(queryFilter)}`, bodyFilter).then((res) => res.data)
  );
}

export function useApproveCycleCountReport() {
  const queryClient = useQueryClient();
  return useMutation((body) => axios.put(`/api/v1/admin/cycle-count/approve`, body).then((res) => res.data), {
    onSuccess: () => {
      queryClient.refetchQueries([REPORT_CYCLE_COUNT_QUERY.CYCLE_COUNT_LIST]);
      queryClient.removeQueries([REPORT_CYCLE_COUNT_QUERY.CYCLE_COUNT_DETAILS_REPORT]);
      queryClient.invalidateQueries([REPORT_CYCLE_COUNT_QUERY.CYCLE_COUNT_LOGS]);
    },
  });
}
export function useExportCycleCountReport() {
  return useMutation(({ recordId }) =>
    axios.get(`/api/v1/admin/cycle-count/export/${recordId}`).then((res) => res.data)
  );
}
