import clsx from 'clsx';
import { BounceLoading } from 'components/loader/bounce.loading';

export function TableButtonImport({
  onClick,
  disabled,
  loading,
  title = 'Import',
  className = 'outline-none flex items-center border border-gray-1 rounded-sm hover:bg-light-green transition hover:border-green duration-150 ease-in-out border-solid py-1 px-3',
}) {
  return (
    <button
      className={clsx('disabled:opacity-50 disabled:cursor-not-allowed ', className)}
      onClick={onClick}
      disabled={disabled || loading}
      type='button'
    >
      {loading ? (
        <BounceLoading color='#5E755A' />
      ) : (
        <>
          <span className='icon-ico-download text-xl text-green'></span>
          <span className='pl-2 font-semibold text-dark text-sm'>{title}</span>
        </>
      )}
    </button>
  );
}
