import qs from 'query-string';
import axios from 'api/api.config';
import { useQuery } from 'react-query';

export const MASTER_DATA_QUERY = {
  ALL_CITY: 'ALL_CITY',
};

export function useMasterCityList(filter) {
  return useQuery([MASTER_DATA_QUERY.ALL_CITY, { filter }], () =>
    axios.get(`/api/v1/admin/master/city?${qs.stringify(filter)}`).then((res) => res.data)
  );
}
