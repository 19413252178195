import axios from 'api/api.config';
import { useMutation, useQueryClient } from 'react-query';
import { PRODUCT_LIST_QUERY } from '../product-list/product-list.query.api';

export function useImportVariantStock() {
  const queryClient = useQueryClient();
  return useMutation((body) => axios.put(`/api/v1/admin/variant-stock/import`, body).then((res) => res.data), {
    onSuccess: (data) => {
      queryClient.resetQueries([PRODUCT_LIST_QUERY.PRODUCT_STOCK_IN_STORE]);
    },
  });
}
